import { ClientType, MongoObject } from ".";

export enum ProvincesTable {
  _ID = "_id",
  PROVINCE = "نام استان",
  CODE = "کد استان",
  CITIESCOUNT = "تعداد شهرها",
  TELECOMSCOUNT = "تعداد مراکز",
  CREATEDAT = "تاریخ ایجاد",
  UPDATEDAT = "تاریخ اخرین تغییر",
  ACTIONS = "عملیات",
  ROW = "ردیف"
}

export interface ProvincesSchema {
  referedId: string | ClientType;
  used: boolean;
  usedDate?: Date;
  amount?: number;
}

export interface ProvincesType extends MongoObject {
  prefixNumber: string;
  name: string;
  code?: number;
  cities?: number;
  telecoms?: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface AddProvince {
  name: string;
  prefixNumber: string | number;
}
