export enum TASK_TYPES {
  TASK_LISTS = "TASK_LISTS",
  TASK_RESET = "TASK_RESET",
  TASK = "TASK",
  TASK_DELETE = "TASK_DELETE",
  TASK_LOADING = "TASK_LOADING",
  DISABLE_LOADING = "DISABLE_LOADING",
  TASK_ASSIGNABLE_USERS = "TASK_ASSIGNABLE_USERS",
  SELECTED_TASK_IMAGES = "SELECTED_TASK_IMAGES",
  TASK_UPLOAD = "TASK_UPLOAD",
  RESET_IMAGE_ITEM = "RESET_IMAGE_ITEM"
}
