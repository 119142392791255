import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ReactModal from "react-modal";
import { SubmitButton, UploadButton } from ".";
import { CancelButton } from "./CancelButton";
import Resizer from "react-image-file-resizer";

interface PhotoCropperType {
  message: string;
  onSuccess?: (image: File) => any;
  title: string;
  onCancel?: () => any;
  isOpen: boolean;
  cr: number;
  ext: "png" | "jpeg" | "jpg";
  children?: React.ReactNode;
}

const _PhotoCropper: FC<PhotoCropperType> = ({
  children,
  onCancel,
  onSuccess,
  isOpen,
  message,
  cr,
  ext,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);

  const [upImg, setUpImg] = useState<string | ArrayBuffer | null>(null);
  const [crop, setCrop] = useState<Partial<Crop>>({
    unit: "px",
    aspect: cr
  });
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);

  const onSelectFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]!);
    }
  }, []);

  const onLoad = useCallback((img: HTMLImageElement) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx!.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx!.imageSmoothingQuality = "high";

    ctx!.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  return (
    <>
      <ReactModal
        style={{
          content: {}
        }}
        {...rest}
        isOpen={isOpen}
        onRequestClose={onCancel}
        ariaHideApp={false}>
        <div className='photo-cropper'>
          <div>
            <h4 className='photo-cropper__message'>{message}</h4>
            {upImg && <small>( لطفا ابعاد تصویر خود را تعیین کنید)</small>}
            <section className='upload-wrapper'>
              <input
                accept={`image/${ext}`}
                onChange={onSelectFile}
                ref={inputRef}
                className='upload-file'
                type='file'
              />
              <UploadButton
                onClick={() => {
                  inputRef.current?.click();
                }}
                title='ارسال تصویر'
              />
            </section>
            <section className='photo-cropper__croper-wrapper'>
              {upImg && (
                <div className='crop-wrapper'>
                  <ReactCrop
                    imageStyle={{ maxHeight: 300, maxWidth: "100%" }}
                    src={upImg as string}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                  />
                </div>
              )}
              {upImg && (
                <div className='canvas-wrapper'>
                  {" "}
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: "1px solid #e5e5e5",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: Math.round(completedCrop?.width ?? 0),
                      height: Math.round(completedCrop?.height ?? 0)
                    }}
                  />
                </div>
              )}
            </section>

            <section>{children}</section>
            {upImg && (
              <section className='photo-cropper__button-wrapper'>
                <div className='button-wrapper'>
                  <CancelButton onClick={onCancel} title='لغو' />
                </div>
                <div className='button-wrapper'>
                  <SubmitButton
                    disabled={!(crop.width && crop.height)}
                    onClick={() => {
                      if (!previewCanvasRef || !imgRef) {
                        return;
                      }

                      previewCanvasRef.current?.toBlob(
                        (blob) => {
                          try {
                            Resizer.imageFileResizer(
                              blob!,
                              1000,
                              1000,
                              ext === "png" ? "PNG" : "JPEG",
                              50,
                              0,
                              (uri) => {
                                onSuccess && onSuccess(uri as File);
                              },
                              "blob",
                              200,
                              200
                            );
                          } catch (err: unknown) {
                            window.openToastError((err as any).message);
                          }
                        },
                        `image/${ext}`,
                        0.5
                      );
                      setUpImg(null);
                    }}
                    title='تایید'
                  />
                </div>
              </section>
            )}
          </div>
        </div>
      </ReactModal>
    </>
  );
};

const PhotoCropper = memo(_PhotoCropper);

export { PhotoCropper };
