import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { LOG_TYPES, store, LOG_ROOT_API, GENERAL_EVENTS_API } from "..";
import { customizedAxios } from "../../config";
import { getCatch } from "../../catch";
import { AdvancedResult, QueryParams, LogsType, ItemType } from "../../types";
import { LogsActionTypes } from "../actionTypes";

export const fetchLogs =
  (body: QueryParams) => async (dispatch: Dispatch<LogsActionTypes>) => {
    try {
      dispatch({ type: LOG_TYPES.LOG_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: body
      };

      let url = encodeURI(LOG_ROOT_API);
      const res = await customizedAxios.get<AdvancedResult<LogsType>>(
        url,
        config
      );
      dispatch({
        type: LOG_TYPES.LOG_LISTS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: LOG_TYPES.DISABLE_LOADING });
    }
  };

export const getEvents = () => async (dispatch: Dispatch<LogsActionTypes>) => {
  try {
    dispatch({ type: LOG_TYPES.EVENTS_LOADING });

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${store.getState().auth.token}`
      }
    };

    let url = GENERAL_EVENTS_API;
    const res = await customizedAxios.get<ItemType<string[]>>(url, config);

    dispatch({
      type: LOG_TYPES.EVENTS,
      payload: res.data.data
    });
    dispatch({
      type: LOG_TYPES.DISABLE_EVENTS_LOADING
    });
  } catch (err: any) {
    const { message } = getCatch(err);
    window.openToastError(message);
  } finally {
    dispatch({ type: LOG_TYPES.DISABLE_EVENTS_LOADING });
  }
};

export const fetchSmsLogs =
  (body: QueryParams) => async (dispatch: Dispatch<LogsActionTypes>) => {
    try {
      dispatch({ type: LOG_TYPES.LOG_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: body
      };

      let url = encodeURI(LOG_ROOT_API).concat("/smsLogs");
      const res = await customizedAxios.get<AdvancedResult<LogsType>>(
        url,
        config
      );
      dispatch({
        type: LOG_TYPES.LOG_SMS_LISTS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: LOG_TYPES.DISABLE_LOADING });
    }
  };
