import { useFormik } from "formik";
import { useCallback, useEffect } from "react";
import Select from "react-select";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { DataCenterType } from "../../../../core/types";

const AddDataCenter = () => {
  const { addDataCenter, getDataCenterProvinces } = useActions();
  const { provincesLoading, provinces, loading } = useTypedSelector(
    (state) => state.dataCenter
  );
  const formik = useFormik({
    initialValues: {
      name: "",
      provinceId: "",
      address: ""
    },
    validationSchema: yup.object({
      name: yup.string().trim().required("نام  الزامی است"),
      provinceId: yup.string().trim().required("انتخاب استان الزامی است"),
      address: yup.string().trim().required("آدرس الزامی است")
    }),
    onSubmit: ({ name, provinceId, address }: DataCenterType) => {
      addDataCenter({
        name,
        provinceId,
        address
      });
    }
  });
  useEffect(() => {
    getDataCenterProvinces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProvince = useCallback(() => {
    return provinces?.data.map((item) => {
      return { value: item._id, label: item.name };
    });
  }, [provinces?.data]);

  const {
    name: nameError,
    provinceId: provinceError,
    address: addressError
  } = formik.errors;
  const {
    name: nameTouched,
    provinceId: provinceTouched,
    address: addressTouched
  } = formik.touched;
  const { setValues } = formik;
  const { name, address } = formik.values;

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'>اضافه کردن دیتاسنتر جدید</h5>
        <form autoComplete='on' onSubmit={formik.handleSubmit}>
          <Input
            name='name'
            title='نام دیتاسنتر: '
            autoComplete='off'
            error={nameTouched && nameError ? nameError : undefined}
            value={name}
            onChange={formik.handleChange}
            type='text'
          />

          <div className='select-wrapper--high '>
            <label>استان مربوطه:</label>
            <Select
              onChange={(e) => {
                setValues({ ...formik.values, provinceId: e?.value ?? "" });
              }}
              isClearable
              isDisabled={!provinces || provinces.count === 0}
              placeholder='استان مربوطه را انتخاب کنید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={getProvince()}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {provinceTouched && provinceError && (
              <p className='input-error--select'>{provinceError}</p>
            )}
          </div>
          <Input
            name='address'
            title='آدرس : '
            multiline
            autoComplete='off'
            error={addressTouched && addressError ? addressError : undefined}
            value={address}
            onChange={formik.handleChange}
            type='text'
          />

          <SubmitButton
            title='ثبت'
            loading={loading || provincesLoading}
            disabled={loading || provincesLoading}
            type='submit'
          />
        </form>
      </div>
    </>
  );
};

export default AddDataCenter;
