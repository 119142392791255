import React, { memo, useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { SubmitButton, CancelButton } from ".";
import Select from "react-select";
import { useActions, useTypedSelector } from "../hooks";

interface ChangeAssignmentTaskType {
  onSuccess?: (assignedTo: string) => any;
  title: string;
  onCancel?: () => any;
  isOpen: boolean;
}

const _ChangeAssignmentTask: React.FC<ChangeAssignmentTaskType> = ({
  onCancel,
  onSuccess,
  title,
  isOpen,
  ...rest
}) => {
  const { fetchAssignAbleTaskUsers } = useActions();
  const { assignAbleUsers } = useTypedSelector((state) => state.task);

  const [assignedTo, setAssignedTo] = useState<string | undefined>("");

  useEffect(() => {
    fetchAssignAbleTaskUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAssignAbleUsersList = useCallback(() => {
    return assignAbleUsers?.map((item) => {
      return { value: item._id, label: item.fullName };
    });
  }, [assignAbleUsers]);

  return (
    <>
      <ReactModal
        style={{
          content: {
            maxWidth: 500,
            maxHeight: 400,
            margin: "auto",
            left: 16,
            right: 16
          }
        }}
        {...rest}
        isOpen={isOpen}
        onRequestClose={onCancel}
        ariaHideApp={false}>
        <div className='delete-modal__wrapper'>
          <div>
            <h5 className='title'>{title}</h5>{" "}
            <form>
              <div className='select-wrapper--high '>
                <label>به چه کسی ارجاع می کنید:</label>
                <Select
                  onChange={(e) => {
                    setAssignedTo(e?.value);
                  }}
                  isClearable
                  placeholder=' لطفا کاربر مورد نظر را انتخاب نمایید'
                  openMenuOnClick={true}
                  classNamePrefix='react-select'
                  options={getAssignAbleUsersList()}
                  noOptionsMessage={() => {
                    return <h6>آیتمی یافت نشد</h6>;
                  }}
                />
                {/*  */}
              </div>
            </form>
          </div>
          <div className='delete-modal__button-wrapper'>
            <div className='delete-modal__button-wrapper--item'>
              <CancelButton onClick={onCancel} title='لغو' />
            </div>
            <div className='delete-modal__button-wrapper--item'>
              <SubmitButton
                onClick={() => {
                  if (assignedTo) onSuccess && onSuccess(assignedTo);
                }}
                title='ثبت'
              />
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

const ChangeAssignmentTask = memo(_ChangeAssignmentTask);

export { ChangeAssignmentTask };
