import React, { useCallback, useMemo, useState } from "react";
import Select from "react-select";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { AlbumSchema } from "../types";
import { DeleteModalContent } from "./DeleteModalContent";
import { ImageLoader } from "./ImageLoader";

interface ImageWrapperProps {
  albums: Array<AlbumSchema> | undefined;
  onSuccess: (file: File) => void;
  onDelete: (albumItemId: string) => void;
  noDelete?: boolean;
}

const ImageWrapper: React.FC<ImageWrapperProps> = ({
  albums = [],
  onDelete,
  noDelete
}) => {
  const [category, setCategory] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<AlbumSchema | null>(null);
  const getAlbums = useMemo(() => {
    if (category) {
      return albums.filter((album) => {
        return album.category === category;
      });
    }
    return albums;
  }, [category, albums]);

  const onSuccessDeleteModal = useCallback(() => {
    onDelete(selectedItem?._id ?? "");
    setIsOpen(false);
    setSelectedItem(null);
  }, [onDelete, selectedItem?._id]);

  if (albums?.length === 0) return null;

  return (
    <>
      <DeleteModalContent
        isOpen={isOpen}
        title={"حذف تصویر " + selectedItem?.title}
        message='آیا از حذف تصویر مطمئن هستید؟'
        onCancel={() => {
          setIsOpen(false);
          setSelectedItem(null);
        }}
        onSuccess={onSuccessDeleteModal}
      />
      <div className='main-image-wrapper'>
        <div className='images-wrapper'>
          <div className='list-top'>
            <p>
              تعداد کل تصاویر : <span> {albums && albums?.length} </span>
            </p>
          </div>
        </div>
        <div className='images-wrapper--high'>
          <label>دسته:</label>
          <Select
            isClearable
            placeholder='دسته مربوطه را انتخاب کنید'
            openMenuOnClick={true}
            onChange={(option) => {
              if (option?.value) {
                return setCategory(option.value);
              }
              setCategory("");
            }}
            classNamePrefix='react-select'
            options={[
              ...new Set(
                albums.map((album) => {
                  return album.category;
                })
              )
            ].map((item) => {
              return { label: item, value: item };
            })}
            noOptionsMessage={() => {
              return <h6>آیتمی یافت نشد</h6>;
            }}
          />
        </div>
        <div className='swiper-main-wrapper'>
          <Swiper
            cssMode={true}
            navigation={true}
            pagination={true}
            modules={[Navigation]}
            spaceBetween={10}
            breakpoints={{
              420: {
                slidesPerView: 1,
                spaceBetween: 10
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 10
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 10
              }
            }}
            className='mySwiper'>
            {getAlbums.map((item) => {
              return (
                <SwiperSlide className='swiper-slide'>
                  {!noDelete && (
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedItem(item);
                        setIsOpen(true);
                      }}
                      title='حذف'
                      className='fas fa-trash-alt delete-icon'
                    />
                  )}
                  <ImageLoader
                    title={item.title}
                    alt={item.title}
                    src={item.path}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export { ImageWrapper };
