import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { customizedAxios } from "../../../config";
import { getCatch } from "../../../catch";
import { AdvancedResult, QueryParams, InputObject } from "../../../types";
import {
  AddDataCenter,
  DataCenterType,
  DataCenterProvincesType,
  AlbumSchema,
  UploadType
} from "../../../types";
import { DataCenterActionTypes } from "../../actionTypes";
import { DATACENTER_TYPES } from "../../types";
import { DATACENTER_ROOT_API } from "../../api";
import { store } from "../../store";
import { DeleteType } from "../../../types";
import { createFormData } from "../../../constants";

export const fetchDataCenters =
  (query: QueryParams) => async (dispatch: Dispatch<DataCenterActionTypes>) => {
    try {
      dispatch({ type: DATACENTER_TYPES.DATACENTER_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: query
      };

      let url = DATACENTER_ROOT_API;
      const res = await customizedAxios.get<AdvancedResult<DataCenterType>>(
        url,
        config
      );
      dispatch({
        type: DATACENTER_TYPES.DATACENTER_LISTS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: DATACENTER_TYPES.DISABLE_LOADING });
    }
  };

export const addDataCenter =
  (body: AddDataCenter) =>
  async (dispatch: Dispatch<DataCenterActionTypes>) => {
    try {
      dispatch({ type: DATACENTER_TYPES.DATACENTER_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = DATACENTER_ROOT_API;
      await customizedAxios.post(url, body, config);

      dispatch({
        type: DATACENTER_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" دیتاسنتر  با موفقیت افزوده شد.");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: DATACENTER_TYPES.DISABLE_LOADING });
    }
  };

export const getDataCenterProvinces =
  () => async (dispatch: Dispatch<DataCenterActionTypes>) => {
    try {
      dispatch({ type: DATACENTER_TYPES.DATACENTER_PROVINCES_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = DATACENTER_ROOT_API.concat("/provinces");
      const res = await customizedAxios.get<
        AdvancedResult<DataCenterProvincesType>
      >(url, config);

      dispatch({
        type: DATACENTER_TYPES.DATACENTER_PROVINCES,
        payload: res.data
      });
      dispatch({
        type: DATACENTER_TYPES.DISABLE_DATACENTER_PROVINCES_LOADING
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: DATACENTER_TYPES.DISABLE_DATACENTER_PROVINCES_LOADING });
    }
  };

export const editDataCenter =
  (body: DataCenterType, id: string) =>
  async (dispatch: Dispatch<DataCenterActionTypes>) => {
    try {
      dispatch({ type: DATACENTER_TYPES.DATACENTER_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = DATACENTER_ROOT_API.concat("/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: DATACENTER_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("دیتاسنتر با موفقیت ویرایش شد");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: DATACENTER_TYPES.DISABLE_LOADING });
    }
  };

export const fetchOneDataCenter =
  (id: string) => async (dispatch: Dispatch<DataCenterActionTypes>) => {
    try {
      dispatch({ type: DATACENTER_TYPES.DATACENTER_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = DATACENTER_ROOT_API.concat("/" + id);
      const res = await customizedAxios.get<InputObject<DataCenterType>>(
        url,
        config
      );
      dispatch({
        type: DATACENTER_TYPES.DATACENTER,
        payload: res.data.data!
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: DATACENTER_TYPES.DISABLE_LOADING });
    }
  };

export const deleteDataCenter =
  (id: string) => async (dispatch: Dispatch<DataCenterActionTypes>) => {
    try {
      dispatch({ type: DATACENTER_TYPES.DATACENTER_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = DATACENTER_ROOT_API.concat("/" + id);
      await customizedAxios.delete<DeleteType<DataCenterType>>(url, config);

      dispatch({
        type: DATACENTER_TYPES.DATACENTER_DELETE,
        payload: { owaId: id }
      });
      window.openToastSuccess("دیتاسنتر  با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: DATACENTER_TYPES.DISABLE_LOADING });
    }
  };
export const uploadDataCenterPhoto =
  (file: File, { _id, category, title }: AlbumSchema) =>
  async (dispatch: Dispatch<DataCenterActionTypes>) => {
    try {
      dispatch({ type: DATACENTER_TYPES.DATACENTER_LOADING });

      let bodyData = createFormData(file, { title, category });

      const config: AxiosRequestConfig = {
        headers: {
          "Content-Type": "multipart/form-data; boundary=form._boundary",
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = DATACENTER_ROOT_API.concat("/photo/" + _id);
      const res = await customizedAxios.put<UploadType<DataCenterType>>(
        url,
        bodyData,
        config
      );
      dispatch({
        type: DATACENTER_TYPES.DATACENTER_UPLOAD,
        payload: res.data.data.albums ?? []
      });
      window.openToastSuccess("تصویر با موفقیت افزوده شد");
      window.navigate(-1);
      return res.data.data;
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: DATACENTER_TYPES.DISABLE_LOADING });
    }
  };

export const deleteDataCenterPhoto =
  (id: string, dataCenterId: string) =>
  async (dispatch: Dispatch<DataCenterActionTypes>) => {
    try {
      dispatch({ type: DATACENTER_TYPES.DATACENTER_LOADING });
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        data: {
          id
        }
      };

      let url = DATACENTER_ROOT_API.concat("/photo/" + dataCenterId);
      const res = await customizedAxios.delete<DeleteType<DataCenterType>>(
        url,
        config
      );

      dispatch({
        type: DATACENTER_TYPES.DATACENTER_UPLOAD,
        payload: res.data.data.albums ?? []
      });
      window.openToastSuccess("تصویر با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: DATACENTER_TYPES.DISABLE_LOADING });
    }
  };
