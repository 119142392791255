import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import { defaultTablePageCount } from "../../../../core/constants";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { PhoneBookType } from "../../../../core/types";

const AddPhoneBook = () => {
  const { addPhoneBook, getPhoneBookProvinces, fetchPhoneBooks } = useActions();
  const [currentPage] = useState<number>(0);
  const { loading, provincesLoading, provinces, list } = useTypedSelector(
    (state) => state.phoneBook
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      category: "",
      provinceId: ""
    },
    validationSchema: yup.object({
      name: yup.string().trim().required("نام شهر الزامی است"),
      phone: yup.string().trim().required("شماره تلفن الزامی است"),
      category: yup.string().trim().required("انتخاب دسته الزامی است"),
      provinceId: yup.string().trim().required("انتخاب استان الزامی است")
    }),
    onSubmit: ({ name, phone, provinceId, category }: PhoneBookType) => {
      addPhoneBook({
        name,
        phone,
        category,
        provinceId
      });
    }
  });
  useEffect(() => {
    fetchPhoneBooks({ limit: defaultTablePageCount, page: currentPage + 1 });
    getPhoneBookProvinces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTablePageCount, currentPage]);

  const categories = useMemo(() => {
    if (list && list != null && Array.isArray(list.data)) {
      return [
        ...new Set(
          (list?.data).map((item) => {
            return item.category;
          })
        )
      ];
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const getProvince = useCallback(() => {
    return provinces?.data.map((item) => {
      return { value: item._id, label: item.name };
    });
  }, [provinces?.data]);

  const { name, phone, category } = formik.values;
  const {
    name: nameError,
    provinceId: provinceError,
    phone: phoneError,
    category: categoryError
  } = formik.errors;
  const {
    name: nameTouched,
    provinceId: provinceTouched,
    phone: phoneTouched,
    category: categoryTouched
  } = formik.touched;
  const { setValues } = formik;

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'>اضافه کردن مخاطب جدید</h5>
        <form autoComplete='on' onSubmit={formik.handleSubmit}>
          <Input
            name='name'
            title='نام مخاطب : '
            autoComplete='off'
            error={nameTouched && nameError ? nameError : undefined}
            value={name}
            type='text'
            onChange={formik.handleChange}
          />
          <Input
            name='phone'
            title='شماره تلفن : '
            autoComplete='off'
            error={phoneTouched && phoneError ? phoneError : undefined}
            value={phone}
            type='text'
            onChange={formik.handleChange}
          />
          <Input
            name='category'
            title='دسته : '
            autoComplete='off'
            error={categoryTouched && categoryError ? categoryError : undefined}
            value={category}
            type='text'
            onChange={formik.handleChange}
          />

          <div className='select-chip'>
            {categories.map((category) => {
              return (
                <div
                  className='chip'
                  onClick={() => {
                    setValues({
                      ...formik.values,
                      category: category.toString() ?? ""
                    });
                  }}>
                  {category.toString()}
                </div>
              );
            })}
          </div>

          <div className='select-wrapper--high '>
            <label>استان مربوطه:</label>
            <Select
              onChange={(e) => {
                setValues({ ...formik.values, provinceId: e?.value ?? "" });
              }}
              isClearable
              isDisabled={!provinces || provinces.count === 0}
              placeholder='استان مربوطه را انتخاب کنید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={getProvince()}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {provinceTouched && provinceError && (
              <p className='input-error--select'>{provinceError}</p>
            )}
          </div>

          <SubmitButton
            title='ثبت'
            loading={loading || provincesLoading}
            disabled={loading || provincesLoading}
            type='submit'
          />
        </form>
      </div>
    </>
  );
};

export default AddPhoneBook;
