import { MongoObject } from "..";

export enum DCRackTable {
  _ID = "_id",
  RACKNUMBER = " شماره رک",
  ROOM = "اتاق",
  AISLE = "راهرو",
  UNIT = "تعداد یونیت",
  POWERMODULEDESC = "مدل پاور ماژول",
  DIMENSION = "ابعاد",
  PROPERTYNUMBER = "شماره اموال",
  SERIALNUMBER = "شماره سریال",
  ACTIONS = "عملیات",
  ROW = "ردیف"
}

export interface DCRackSchema extends MongoObject {
  _id?: string;
  id?: string | null;
  rackNumber?: string;
  room?: string;
  aisle?: string;
  unit?: string;
  powerModuleDesc?: string;
  dimension?: string;
  propertyNumber?: string;
  serialNumber?: string;

  description?: string;

  createdAt?: Date;
  updatedAt?: Date;
}
export interface AddDCRack {
  rackNumber?: string;
  room?: string;
  aisle?: string;
  unit?: string;
  powerModuleDesc?: string;
  dimension?: string;
  propertyNumber?: string;
  serialNumber?: string;

  description?: string;
}
