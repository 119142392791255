export enum DATACENTER_TYPES {
  DATACENTER_LISTS = "DATACENTER_LISTS",
  DATACENTER = "DATACENTER",
  DATACENTER_DELETE = "DATACENTER_DELETE",
  DATACENTER_LOADING = "DATACENTER_LOADING",
  DISABLE_LOADING = "DISABLE_LOADING",
  DATACENTER_PROVINCES_LOADING = "DATACENTER_PROVINCES_LOADING",
  DATACENTER_PROVINCES = "DATACENTER_PROVINCES",
  DISABLE_DATACENTER_PROVINCES_LOADING = "DISABLE_DATACENTER_PROVINCES_LOADING",
  DATACENTER_UPLOAD = "DATACENTER_UPLOAD",
  SELECTED_DATACENTER_IMAGES = "SELECTED_DATACENTER_IMAGES",
  RESET_IMAGE_ITEM = "RESET_IMAGE_ITEM"
}
