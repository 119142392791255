import { CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import copy from "copy-to-clipboard";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import moment from "jalali-moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import {
  AddCommentModalContent,
  ChangeAssignmentModalContent,
  ConcludeFault,
  DeleteModalContent,
  NoItem,
  ImageWrapper,
  DetailModalContent,
  Input
} from "../../../../core/components";
import { ConcludeFaultNeedExpert } from "../../../../core/components/ConcludeFaultNeedExpert";
import {
  defaultFaultTablePageCount,
  NEED_EXPERT_STATUS_TYPES_LIST,
  STATUS_TYPES,
  tableRowColors,
  USER_ACCESS
} from "../../../../core/constants";
import { defaultColumnDefinition } from "../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { BASE_API, FAULTS_ROOT_API, FAULT_TYPES } from "../../../../core/redux";
import {
  FaultCommentType,
  FaultType,
  NeedExpertFaultTable,
  NEED_EXPERT_STATUS_TYPES,
  TelecomsType,
  UserType,
  USER_ROLES
} from "../../../../core/types";
import {
  UserAccessType,
  checkUserAccessToSection,
  fileDownload
} from "../../../../core/utility";
import { useDispatch } from "react-redux";
import useIsMobile from "src/core/hooks/useIsMobile";
import { useQueryParams } from "src/core/hooks/useQueryParams";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";

const ReferredToExpert = () => {
  const {
    fetchFaults,
    changeAssignment,
    addCommentFault,
    deleteFault,
    concludeAFault,
    cancelNeedExpert,
    deleteFaultPhoto,
    uploadFaultPhoto
  } = useActions();

  const [alterAssignment, setAlterAssignment] = useState<null | string>(null);
  const [addComment, setAddComment] = useState<null | FaultType>(null);
  const [concludeFault, setConcludeFault] = useState<null | FaultType>(null);
  const { token } = useTypedSelector((state) => state.auth);
  const { me } = useTypedSelector((state) => state.auth);

  const [concludeFaultNeedExpert, setConcludeFaultNeedExpert] =
    useState<null | FaultType>(null);

  const [deleteModal, setDeleteModal] = useState<null | FaultType>(null);
  const [cancelNeedExpertFault, setCancelNeedExpert] =
    useState<null | FaultType>(null);
  const [isCommentModalOpen, setIsCommentModalOpen] =
    useState<null | FaultType>(null);

  const { searchParams, setSearchParams } = useQueryParams();
  const { loading, list, imageItem } = useTypedSelector((state) => state.fault);
  const [currentPage, setCurrentPage] = useState<number>(
    +(searchParams("page") ?? 1) - 1
  );
  const isMobile = useIsMobile();

  const navigator = useNavigate();

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      status: searchParams("needExpert.status") ?? "",
      phone: searchParams("phone[regex]") ?? ""
    },
    validationSchema: yup.object({
      status: yup.string(),
      phone: yup.string()
    }),
    onSubmit: () => {}
  });

  const { status, phone } = formik.values;

  const queryParams = useMemo(() => {
    return {
      limit: defaultFaultTablePageCount,
      page: currentPage + 1,
      status: STATUS_TYPES.REFERRED_TO_EXPERT,
      "needExpert.status": status,
      "phone[regex]": phone
    };
  }, [currentPage, status, phone]);

  useEffect(() => {
    fetchFaults(queryParams);
    setSearchParams(queryParams);
    dispatch({ type: FAULT_TYPES.RESET_IMAGE_ITEM });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFaultTablePageCount, currentPage, queryParams]);

  const userAccess = useMemo(() => {
    return checkUserAccessToSection(me?.access ?? []);
  }, [me]);

  const getUserAccess = useCallback(
    (section: keyof UserAccessType) => {
      return !!(me && (me.role === USER_ROLES.ADMIN || userAccess[section]));
    },
    [me, userAccess]
  );

  const hasAlbum = useCallback(
    (cellEvent: CellClickedEvent) =>
      list?.data?.find((fault) => {
        return fault._id === cellEvent?.data?._id;
      })?.albums?.length !== 0,
    [list?.data]
  );

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          {cellEvent?.data[NeedExpertFaultTable.FORM_PATH] && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                const url =
                  BASE_API +
                  FAULTS_ROOT_API +
                  "/form/" +
                  cellEvent?.data[NeedExpertFaultTable._ID] +
                  "?photoId=" +
                  cellEvent?.data[NeedExpertFaultTable.FORM_PATH];
                fileDownload(
                  url,
                  token ?? "",
                  cellEvent?.data[NeedExpertFaultTable.PHONE] + ".pdf"
                );
              }}>
              <i className='fas fa-file-pdf add-icon'></i>
            </span>
          )}
          {getUserAccess(USER_ACCESS.FAULTS_READ_ALL) && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                setAlterAssignment(cellEvent?.data[NeedExpertFaultTable._ID]);
              }}>
              <i
                title='ارجاع به دیگری'
                className='fas fa-user-friends edit-icon'></i>
            </span>
          )}
          <span
            onClick={() => {
              setIsCommentModalOpen(cellEvent?.data);
            }}>
            <i
              title='اطلاعات حضوری'
              className='fas fa-comment comment-icon'></i>
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              setAddComment(
                list?.data?.find((item) => {
                  return (
                    item._id?.toString() ===
                    cellEvent?.data[NeedExpertFaultTable._ID]
                  );
                }) ?? null
              );
            }}>
            <i title='توضیحات' className='fas fa-comment edit-icon'></i>
          </span>
          {cellEvent?.data[NeedExpertFaultTable.STATUS] ===
            NEED_EXPERT_STATUS_TYPES.WAITING_EXPERT && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                navigator(
                  `/faults/edit-fault?faultId=${cellEvent?.data[
                    NeedExpertFaultTable._ID
                  ]}`
                );
              }}>
              <i title='ویرایش' className='fas fa-pen edit-icon'></i>
            </span>
          )}

          {(cellEvent?.data[NeedExpertFaultTable.STATUS] ===
            NEED_EXPERT_STATUS_TYPES.WAITING_EXPERT ||
            getUserAccess(USER_ACCESS.FAULTS_READ_ALL)) && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                if (
                  cellEvent?.data[NeedExpertFaultTable.STATUS] ===
                  NEED_EXPERT_STATUS_TYPES.WAITING_EXPERT
                ) {
                  navigator(
                    `/faults/conclude-need-expert?faultId=${cellEvent?.data[
                      NeedExpertFaultTable._ID
                    ]}`
                  );
                } else {
                  setConcludeFaultNeedExpert(
                    list?.data?.find((item) => {
                      return (
                        item._id?.toString() ===
                        cellEvent?.data[NeedExpertFaultTable._ID]
                      );
                    }) ?? null
                  );
                }
              }}>
              <i title='بستن' className='fas fa-info-circle edit-icon'></i>
            </span>
          )}

          {cellEvent?.data[NeedExpertFaultTable.STATUS] ===
            NEED_EXPERT_STATUS_TYPES.WAITING_EXPERT && (
            <span
              onClick={() => {
                setCancelNeedExpert(
                  list?.data?.find((item) => {
                    return (
                      item._id?.toString() ===
                      cellEvent?.data[NeedExpertFaultTable._ID]
                    );
                  }) ?? null
                );
              }}>
              <i title='لغو' className='fas fa-times-circle delete-icon'></i>
            </span>
          )}
          {getUserAccess(USER_ACCESS.FAULTS_READ_ALL) && (
            <span
              onClick={() => {
                setDeleteModal(
                  list?.data?.find((item) => {
                    return (
                      item._id?.toString() ===
                      cellEvent?.data[NeedExpertFaultTable._ID]
                    );
                  }) ?? null
                );
              }}>
              <i title='حذف' className='fas fa-trash delete-icon' />
            </span>
          )}

          <span
            onClick={(e) => {
              e.stopPropagation();
              dispatch({
                type: FAULT_TYPES.SELECTED_FAULT_IMAGES,
                payload: list?.data?.find((fault) => {
                  return fault._id === cellEvent?.data?._id;
                })
              });
              navigator(`/faults/add-fault-photo?_id=${cellEvent?.data?._id}`);
            }}>
            <i title='اضافه کردن عکس' className='fas fa-camera add-icon'></i>
          </span>
          {hasAlbum(cellEvent) && (
            <span
              onClick={(e) => {
                e.stopPropagation();

                dispatch({
                  type: FAULT_TYPES.SELECTED_FAULT_IMAGES,
                  payload: list?.data?.find((fault) => {
                    return fault._id === cellEvent?.data?._id;
                  })
                });
              }}>
              <i title='گالری' className='fas fa-images edit-icon'></i>
            </span>
          )}
          {cellEvent?.data[NeedExpertFaultTable.STATUS] !==
            NEED_EXPERT_STATUS_TYPES.EXPERT_CANCELED &&
            cellEvent?.data[NeedExpertFaultTable.STATUS] !==
              NEED_EXPERT_STATUS_TYPES.EXPERT_DONE && (
              <Link
                to={`/download-form?_id=${cellEvent?.data?._id}`}
                className='download-btn'>
                دانلود فرم حضوری
              </Link>
            )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list?.data]
  );
  const rowData = useCallback(() => {
    return list?.data.map((item, index) => {
      return {
        [NeedExpertFaultTable.ROW]: `${index + 1 + currentPage * 10}`,
        [NeedExpertFaultTable._ID]: item._id,
        [NeedExpertFaultTable.TELECOM]: (
          item.telecomId as unknown as TelecomsType
        )?.name,
        [NeedExpertFaultTable.DATE]:
          item?.needExpert?.doneDate &&
          moment(item?.needExpert?.doneDate)
            .locale("fa")
            .format("YYYY-MM-DD hh:mm"),
        [NeedExpertFaultTable.ADDRESS]: item?.needExpert?.address,
        [NeedExpertFaultTable.NAME]: item?.needExpert?.name,
        [NeedExpertFaultTable.MOBILENUMBER]: item?.needExpert?.mobileNumber,
        [NeedExpertFaultTable.FORM_PATH]: item?.needExpert?.formPath,
        [NeedExpertFaultTable.UUID]: item.uuid,
        [NeedExpertFaultTable.STATUS]: item?.needExpert?.status,
        [NeedExpertFaultTable.PHONE]: item.phone,
        [NeedExpertFaultTable.PRICELIST]: item?.needExpert?.priceList,
        [NeedExpertFaultTable.ASSIGNEDTODATE]:
          item?.needExpert?.date &&
          moment(item?.needExpert?.date)
            .locale("fa")
            .format("YYYY-MM-DD hh:mm"),
        [NeedExpertFaultTable.PRICE]:
          item.needExpert?.price?.toLocaleString() ?? "-",
        [NeedExpertFaultTable.ASSIGNEDTO]: (
          item?.assignedTo as unknown as UserType
        )?.fullName
      };
    });
  }, [list?.data, currentPage]);

  const resetPagination = () => currentPage !== 0 && setCurrentPage(0);

  return (
    <>
      <DetailModalContent
        content={isCommentModalOpen}
        isOpen={!!isCommentModalOpen}
        message={
          (isCommentModalOpen && isCommentModalOpen.needExpert?.priceList) ?? ""
        }
        title={"توضیحات حضوری"}
        onClose={() => {
          setIsCommentModalOpen(null);
        }}
      />
      {!!concludeFaultNeedExpert && (
        <ConcludeFaultNeedExpert
          comments={concludeFaultNeedExpert.comments as Array<FaultCommentType>}
          faultData={concludeFaultNeedExpert}
          isOpen={!!concludeFaultNeedExpert}
          faultId={concludeFaultNeedExpert!._id ?? ""}
          onSuccess={async (comment) => {
            if (comment) {
              await addCommentFault({
                faultId: concludeFaultNeedExpert?._id ?? "",
                comment: { desc: comment }
              });
            }
            (
              concludeAFault(
                concludeFaultNeedExpert._id ?? "",
                false
              ) as unknown as Promise<void>
            ).then(() => {
              fetchFaults(queryParams);
              setConcludeFaultNeedExpert(null);
            });
          }}
          onClose={() => {
            setConcludeFaultNeedExpert(null);
          }}
        />
      )}
      {!!deleteModal && (
        <DeleteModalContent
          isOpen={!!deleteModal}
          onSuccess={() => {
            (
              deleteFault(deleteModal._id ?? "") as unknown as Promise<void>
            ).then(() => {
              fetchFaults(queryParams);
              setDeleteModal(null);
            });
          }}
          onCancel={() => {
            setDeleteModal(null);
          }}
          message={`آیا از حذف خرابی به شماره  ${deleteModal.phone} مطمئن هستید؟`}
          title={`حذف خرابی به شناسه ${deleteModal.uuid}`}
        />
      )}

      {!!cancelNeedExpertFault && (
        <DeleteModalContent
          isOpen={!!cancelNeedExpertFault}
          onSuccess={() => {
            (
              cancelNeedExpert(
                cancelNeedExpertFault._id ?? ""
              ) as unknown as Promise<void>
            ).then(() => {
              fetchFaults(queryParams);
              setCancelNeedExpert(null);
            });
          }}
          onCancel={() => {
            setCancelNeedExpert(null);
          }}
          actionTitle='لغو خرابی'
          message={`آیا از لغو خرابی به شماره  ${cancelNeedExpertFault.phone} مطمئن هستید؟`}
          title={`لغو خرابی به شناسه ${cancelNeedExpertFault.uuid}`}
        />
      )}
      <ChangeAssignmentModalContent
        isOpen={!!alterAssignment}
        title='تغییر ارجاع خرابی'
        onSuccess={(assignedTo) => {
          (
            changeAssignment({
              faultId: alterAssignment!,
              assignedTo
            }) as unknown as Promise<void>
          ).then(() => {
            fetchFaults(queryParams);
            setAlterAssignment(null);
          });
        }}
        onCancel={() => {
          setAlterAssignment(null);
        }}
      />
      {!!addComment && (
        <AddCommentModalContent
          faultPhone={addComment.phone}
          isOpen={!!addComment}
          comments={
            addComment ? (addComment.comments as Array<FaultCommentType>) : []
          }
          onSuccess={(comment) => {
            (
              addCommentFault({
                faultId: addComment?._id ?? "",
                comment: { desc: comment }
              }) as unknown as Promise<void>
            ).then(() => {
              fetchFaults(queryParams);
              setAddComment(null);
            });
          }}
          onClose={() => {
            setAddComment(null);
          }}
        />
      )}
      {!!concludeFault && (
        <ConcludeFault
          faultPhone={concludeFault.phone}
          faultCurrentStatus={concludeFault.status}
          isOpen={!!concludeFault}
          comments={
            concludeFault && Array.isArray(concludeFault.comments)
              ? concludeFault.comments
              : []
          }
          onSuccess={() => {
            (
              concludeAFault(
                concludeFault?._id ?? "",
                false
              ) as unknown as Promise<void>
            ).then(() => {
              fetchFaults(queryParams);
              setConcludeFault(null);
            });
          }}
          onClose={() => {
            setConcludeFault(null);
          }}
        />
      )}
      <section className='clients-search-wrapper'>
        <div className='list-input-wrapper'>
          <Select
            onChange={(e) => {
              formik.setValues({
                ...formik.values,
                status: (e?.value as NEED_EXPERT_STATUS_TYPES) ?? ""
              });
              resetPagination();
            }}
            defaultValue={{
              label: status,
              value: status
            }}
            isClearable
            placeholder='وضعیت '
            openMenuOnClick={true}
            classNamePrefix='react-select'
            options={NEED_EXPERT_STATUS_TYPES_LIST}
            noOptionsMessage={() => {
              return <h6>آیتمی یافت نشد</h6>;
            }}
          />
          <Input
            name='phone'
            placeholder='جستجو شماره'
            autoComplete='off'
            value={phone}
            onChange={(e) => {
              formik.handleChange(e);
              resetPagination();
            }}
          />
        </div>
      </section>
      <div className='main-route-wrapper'>
        {" "}
        <div className='table-wrapper'>
          <div className='list-top'>
            <p>
              لیست حضوری ها : <span>{list && list.total} عدد</span>
              <i
                onClick={() => {
                  fetchFaults(queryParams);
                }}
                className={`fas fa-sync-alt ${loading ? "rotate" : ""}`}></i>
            </p>
            <Link to='/download-form' className='download-btn'>
              دانلود فرم حضوری
            </Link>
          </div>

          {(!list || list.count === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}
          {list && list.count !== 0 && (
            <>
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  animateRows={true}
                  onCellClicked={(e) => {
                    if (e.colDef.field !== NeedExpertFaultTable.ACTIONS)
                      copy(e.value);
                  }}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  rowSelection={"single"}
                  enableRtl={true}
                  getRowStyle={(params) => {
                    if (params?.data) {
                      if (
                        params?.data[NeedExpertFaultTable.STATUS] ===
                        NEED_EXPERT_STATUS_TYPES.EXPERT_CANCELED
                      )
                        return { background: tableRowColors.fail };
                      if (
                        params?.data[NeedExpertFaultTable.STATUS] ===
                        NEED_EXPERT_STATUS_TYPES.EXPERT_DONE
                      )
                        return { background: tableRowColors.success };
                    }
                  }}>
                  <AgGridColumn
                    sortable={true}
                    field={NeedExpertFaultTable.ROW}
                    width={84}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={NeedExpertFaultTable.TELECOM}
                    maxWidth={200}
                    minWidth={200}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={NeedExpertFaultTable.STATUS}
                    width={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={NeedExpertFaultTable.DATE}
                    width={170}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={NeedExpertFaultTable.ADDRESS}
                    minWidth={250}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={NeedExpertFaultTable.NAME}
                    width={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={NeedExpertFaultTable.MOBILENUMBER}
                    width={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={NeedExpertFaultTable.PHONE}
                    width={150}
                  />

                  <AgGridColumn
                    sortable={true}
                    field={NeedExpertFaultTable.ASSIGNEDTO}
                    maxWidth={160}
                    minWidth={120}
                  />

                  <AgGridColumn
                    sortable={true}
                    field={NeedExpertFaultTable.ASSIGNEDTODATE}
                    width={180}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={NeedExpertFaultTable.PRICE}
                    width={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={NeedExpertFaultTable.ACTIONS}
                    minWidth={400}
                    flex={1}
                  />
                </AgGridReact>
              </div>
            </>
          )}
        </div>
        <ReactPaginate
          marginPagesDisplayed={isMobile ? 1 : 3}
          forcePage={currentPage}
          breakLabel='...'
          nextLabel='بعدی'
          onPageChange={(e) => {
            setCurrentPage(e.selected);
          }}
          pageRangeDisplayed={5}
          pageCount={
            (list && Math.ceil(list.total / defaultFaultTablePageCount)) ?? 1
          }
          previousLabel='قبلی'
          containerClassName='pagination-container'
        />
      </div>
      {imageItem && (
        <ImageWrapper
          albums={imageItem?.albums?.map((album) => {
            return {
              ...album,
              path:
                BASE_API +
                FAULTS_ROOT_API +
                "/photo/" +
                imageItem._id +
                "?photoId=" +
                album.path
            };
          })}
          onDelete={(albumItemId) => {
            deleteFaultPhoto(albumItemId, imageItem?._id ?? "");
          }}
          onSuccess={(file) => {
            uploadFaultPhoto(file, {});
          }}
        />
      )}
    </>
  );
};

export default ReferredToExpert;
