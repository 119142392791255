import React, { InputHTMLAttributes, memo, useCallback } from "react";

interface FileCatcherProps extends InputHTMLAttributes<HTMLInputElement> {
  accept: string;
  description: string;
  onSuccess: (file: File) => void;
}

const _FaultFileReader: React.FC<FileCatcherProps> = ({
  accept,
  description,
  onSuccess
}) => {
  const onSelectFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        onSuccess(e.target.files[0]!);
        e.target.value = "";
      }
    },
    [onSuccess]
  );
  return (
    <div className='file-catcher-wrapper'>
      <p>{description}</p>
      <input
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onSelectFile(e);
        }}
        type='file'
        accept={accept}
      />
    </div>
  );
};

const FaultFileReader = memo(_FaultFileReader);

export { FaultFileReader };
