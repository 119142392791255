import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { CITY_TYPES, store, CITIES_ROOT_API, PROVINCES_CITY_API } from "..";
import { customizedAxios } from "../../config";
import { getCatch } from "../../catch";
import {
  AdvancedResult,
  CitiesType,
  QueryParams,
  AddCity,
  CityProvinceType,
  InputObject
} from "../../types";
import { CitiesActionTypes } from "../actionTypes";

export const fetchCities =
  (query: QueryParams) => async (dispatch: Dispatch<CitiesActionTypes>) => {
    try {
      dispatch({ type: CITY_TYPES.CITY_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: query
      };

      let url = CITIES_ROOT_API;
      const res = await customizedAxios.get<AdvancedResult<CitiesType>>(
        url,
        config
      );
      dispatch({
        type: CITY_TYPES.CITY_LISTS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: CITY_TYPES.DISABLE_LOADING });
    }
  };
export const addCity =
  (body: AddCity) => async (dispatch: Dispatch<CitiesActionTypes>) => {
    try {
      dispatch({ type: CITY_TYPES.CITY_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = CITIES_ROOT_API;
      await customizedAxios.post(url, body, config);

      dispatch({
        type: CITY_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" شهر با موفقیت افزوده شد.");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: CITY_TYPES.DISABLE_LOADING });
    }
  };

export const getProvinces =
  () => async (dispatch: Dispatch<CitiesActionTypes>) => {
    try {
      dispatch({ type: CITY_TYPES.CITY_PROVINCES_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PROVINCES_CITY_API;
      const res = await customizedAxios.get<AdvancedResult<CityProvinceType>>(
        url,
        config
      );

      dispatch({
        type: CITY_TYPES.CITY_PROVINCES_LISTS,
        payload: res.data
      });
      dispatch({
        type: CITY_TYPES.DISABLE_PROVINCES_LOADING
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: CITY_TYPES.DISABLE_PROVINCES_LOADING });
    }
  };

export const editCity =
  (body: CitiesType, id: string) =>
  async (dispatch: Dispatch<CitiesActionTypes>) => {
    try {
      dispatch({ type: CITY_TYPES.CITY_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = CITIES_ROOT_API.concat("/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: CITY_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("شهر با موفقیت ویرایش شد");
      window.navigate("/cities");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: CITY_TYPES.DISABLE_LOADING });
    }
  };
export const fetchOneCity =
  (id: string) => async (dispatch: Dispatch<CitiesActionTypes>) => {
    try {
      dispatch({ type: CITY_TYPES.CITY_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = CITIES_ROOT_API.concat("/" + id);
      const res = await customizedAxios.get<InputObject<CitiesType>>(
        url,
        config
      );
      dispatch({
        type: CITY_TYPES.CITY,
        payload: res.data.data!
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: CITY_TYPES.DISABLE_LOADING });
    }
  };
