import { useFormik } from "formik";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import { OWA_TYPES } from "../../../../core/constants";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { OwaProvincesType, OwaType } from "../../../../core/types";

const EditOwa = () => {
  let [searchParams] = useSearchParams();
  const OwaList = Object.values(OWA_TYPES).map((el) => ({
    label: el,
    value: el
  }));

  const _id = searchParams.get("_id");
  const { editOwa, getOwaProvinces, fetchOneOwa } = useActions();
  const { item, provincesLoading, provinces, loading } = useTypedSelector(
    (state) => state.owa
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: item?.name ?? "",
      provinceId: item?.provinceId ?? "",
      towerType: item?.towerType ?? "",
      towerLength: item?.towerLength ?? "",
      towerSection: item?.towerSection ?? "",
      address: item?.address ?? ""
    },
    validationSchema: yup.object({
      name: yup.string().trim().required("نام پاپ سایت الزامی است"),
      provinceId: yup.string().trim().required("انتخاب استان الزامی است"),
      towerType: yup.string().trim(),
      towerLength: yup.number().required("  ارتفاع دکل الزامی است"),
      towerSection: yup.number().required("تعداد سکشن الزامی است"),
      address: yup.string().trim()
    }),
    onSubmit: ({
      name,
      towerType,
      provinceId,
      towerLength,
      towerSection,
      address
    }: OwaType) => {
      editOwa(
        {
          name,
          towerType,
          provinceId,
          towerLength,
          towerSection,
          address
        },
        item?._id ?? ""
      );
    }
  });

  const {
    name: nameError,
    provinceId: provinceError,
    towerType: towerTypeError,
    towerLength: towerLengthError,
    towerSection: towerSectionError,
    address: addressError
  } = formik.errors;
  const {
    name: nameTouched,
    provinceId: provinceTouched,
    towerType: towerTypeTouched,
    towerLength: towerLengthTouched,
    towerSection: towerSectionTouched,
    address: addressTouched
  } = formik.touched;
  const { name, towerLength, towerSection, address } = formik.values;
  const { setValues } = formik;

  const getProvince = useCallback(() => {
    return provinces?.data.map((item) => {
      return { value: item._id, label: item.name };
    });
  }, [provinces?.data]);

  useEffect(() => {
    getOwaProvinces();
    if (_id) {
      fetchOneOwa(_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const getDefaultProvince = useCallback(() => {
    if (provinces?.data?.length !== 0) {
      const found = provinces?.data?.find((province) => {
        return (
          province._id?.toString() === (item as OwaType)?.provinceId?.toString()
        );
      });

      if (
        found &&
        (found as OwaProvincesType)._id &&
        (found as OwaProvincesType).name
      ) {
        return {
          value: found._id,
          label: found.name
        };
      }
      return {
        value: "",
        label: ""
      };
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinces?.data, item?.provinceId]);

  const getDefaultOwaType = useCallback(() => {
    const found = OwaList?.find((el) => {
      return el?.value?.toString() === (item as OwaType)?.towerType?.toString();
    });

    if (found) {
      return {
        value: found?.value,
        label: found?.value
      };
    }
    return {
      value: "",
      label: ""
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OwaList, item]);

  return (
    <>
      {item && item !== null && (
        <div className='add-item-wrapper'>
          <h5 className='title'>ویرایش پاپ سایت {item?.name}</h5>
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <Input
              name='name'
              title='نام پاپ سایت: '
              autoComplete='off'
              error={nameTouched && nameError ? nameError : undefined}
              value={name}
              onChange={formik.handleChange}
              type='text'
            />

            <div className='select-wrapper--high '>
              <label>نوع دکل :</label>
              <CreatableSelect
                onChange={(e) => {
                  setValues({
                    ...formik.values,
                    towerType: e?.value ?? ""
                  });
                }}
                isClearable
                formatCreateLabel={(el) => <p>{`  ${el}`}</p>}
                placeholder='نمونه : سه وجهی'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                defaultValue={getDefaultOwaType()}
                options={OwaList}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              {towerTypeTouched && towerTypeError && (
                <p className='input-error--select'>{towerTypeError}</p>
              )}
            </div>

            <Input
              type='number'
              name='towerSection'
              title='تعداد سکشن: '
              autoComplete='off'
              error={
                towerSectionTouched && towerSectionError
                  ? towerSectionError
                  : undefined
              }
              value={towerSection}
              onChange={formik.handleChange}
            />
            <Input
              type='number'
              name='towerLength'
              title='ارتفاع دکل: '
              autoComplete='off'
              error={
                towerLengthTouched && towerLengthError
                  ? towerLengthError
                  : undefined
              }
              value={towerLength}
              onChange={formik.handleChange}
            />

            <div className='select-wrapper--high '>
              <label>استان مربوطه:</label>
              <Select
                onChange={(e) => {
                  setValues({ ...formik.values, provinceId: e?.value ?? "" });
                }}
                isClearable
                isDisabled={!provinces || provinces.count === 0}
                placeholder='استان مربوطه را انتخاب کنید'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                options={getProvince()}
                defaultValue={getDefaultProvince()}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              {provinceTouched && provinceError && (
                <p className='input-error--select'>{provinceError}</p>
              )}
            </div>
            <Input
              name='address'
              title='آدرس : '
              multiline
              autoComplete='off'
              error={addressTouched && addressError ? addressError : undefined}
              value={address}
              onChange={formik.handleChange}
              type='text'
            />

            <SubmitButton
              title='ثبت'
              loading={loading || provincesLoading}
              disabled={loading || provincesLoading}
              type='submit'
            />
          </form>
        </div>
      )}
    </>
  );
};

export default EditOwa;
