import { useFormik } from "formik";
import { useMemo } from "react";
import CreatableSelect from "react-select/creatable";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../../core/components";
import { RADIO_TYPES } from "../../../../../core/constants";
import { useActions, useTypedSelector } from "../../../../../core/hooks";
import { RadioSchema } from "../../../../../core/types";

const AddRadio = () => {
  const { addRadio } = useActions();
  const { loading, item } = useTypedSelector((state) => state.owa);

  const RADIOList = Object.values(RADIO_TYPES).map((el) => ({
    label: el,
    value: el
  }));

  const formik = useFormik({
    initialValues: {
      customerName: "",
      brandNameModel: "",
      view: "",
      leg: 1,
      height: 1,
      port: 1,
      cableType: "",
      cableSize: 1,
      installers: "",
      installDate: "",
      etherMac: "",
      wirelessMac: "",
      radioPropertyNumber: "",
      radioSerialNumber: "",
      description: ""
    },
    validationSchema: yup.object({
      customerName: yup.string().trim().required("نام مشترک الزامی است"),
      brandNameModel: yup.string().trim(),
      view: yup.string().trim(),
      leg: yup
        .number()
        .min(1, "اندازه لگ نمی تواند کمتر از 1 می باشد")
        .max(4, "اندازه لگ نمی تواند بیشتر از 4 می باشد"),
      height: yup.number().min(1, "ارتفاع رادیو  نمی تواند کمتر از 1 می باشد"),
      port: yup.number().min(1, "پورت نمی تواند کمتر از 1 می باشد"),
      cableType: yup.string().trim(),
      cableSize: yup.number().min(1, "اندازه کابل نمی تواند کمتر از 1 می باشد"),
      installers: yup.string().trim(),
      installDate: yup.string().trim(),
      etherMac: yup.string().trim(),
      wirelessMac: yup.string().trim(),
      radioPropertyNumber: yup.string(),
      radioSerialNumber: yup.string(),
      description: yup.string()
    }),
    onSubmit: ({
      customerName,
      brandNameModel,
      view,
      leg,
      height,
      port,
      cableType,
      cableSize,
      installers,
      installDate,
      etherMac,
      wirelessMac,
      radioPropertyNumber,
      radioSerialNumber,
      description
    }: RadioSchema) => {
      addRadio(
        {
          customerName,
          brandNameModel,
          view,
          leg,
          height,
          port,
          cableType,
          cableSize,
          installers,
          installDate,
          etherMac,
          wirelessMac,
          radioPropertyNumber,
          radioSerialNumber,
          description
        },
        item?._id ?? ""
      );
    }
  });

  const {
    customerName,
    view,
    leg,
    height,
    port,
    cableType,
    cableSize,
    installers,
    installDate,
    etherMac,
    wirelessMac,
    radioPropertyNumber,
    radioSerialNumber,
    description
  } = formik.values;
  const {
    customerName: customerNameError,
    brandNameModel: brandNameModelError,
    view: viewError,
    leg: legError,
    height: heightError,
    port: portError,
    cableType: cableTypeError,
    cableSize: cableSizeError,
    installers: installersError,
    installDate: installDateError,
    etherMac: etherMacError,
    wirelessMac: wirelessMacError,
    radioPropertyNumber: radioPropertyNumberError,
    radioSerialNumber: radioSerialNumberError,
    description: descriptionError
  } = formik.errors;
  const {
    customerName: customerNameTouched,
    brandNameModel: brandNameModelTouched,
    view: viewTouched,
    leg: legTouched,
    height: heightTouched,
    port: portTouched,
    cableType: cableTypeTouched,
    cableSize: cableSizeTouched,
    installers: installersTouched,
    installDate: installDateTouched,
    etherMac: etherMacTouched,
    wirelessMac: wirelessMacTouched,
    radioPropertyNumber: radioPropertyNumberTouched,
    radioSerialNumber: radioSerialNumberTouched,
    description: descriptionTouched
  } = formik.touched;

  const { setValues, handleSubmit } = formik;

  const brandNameModels = useMemo(() => {
    if (item && item != null && Array.isArray(item.radios)) {
      return [
        ...new Set(
          item.radios.map((item) => {
            return item.brandNameModel;
          })
        )
      ];
    }
    return [];
  }, [item]);

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'>اضافه کردن رادیو جدید</h5>
        <form autoComplete='on' onSubmit={handleSubmit}>
          <Input
            name='customerName'
            title='نام مشترک  : '
            autoComplete='off'
            error={
              customerNameTouched && customerNameError
                ? customerNameError
                : undefined
            }
            value={customerName}
            onChange={formik.handleChange}
            type='text'
          />

          <div className='select-wrapper--high '>
            <label> مدل رادیو :</label>
            <CreatableSelect
              onChange={(e) => {
                setValues({
                  ...formik.values,
                  brandNameModel: e?.value ?? ""
                });
              }}
              isClearable
              formatCreateLabel={(el) => <p>{`  ${el}`}</p>}
              placeholder='مدل رادیو  را انتخاب کنید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={RADIOList}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {brandNameModelTouched && brandNameModelError && (
              <p className='input-error--select'>{brandNameModelError}</p>
            )}
          </div>

          <div className='select-chip'>
            {brandNameModels.map((model) => {
              return (
                <div
                  className='chip'
                  onClick={() => {
                    setValues({
                      ...formik.values,
                      brandNameModel: model?.toString() ?? ""
                    });
                  }}>
                  {model?.toString()}
                </div>
              );
            })}
          </div>

          <Input
            name='view'
            title='طرف دید  : '
            autoComplete='off'
            error={viewTouched && viewError ? viewError : undefined}
            value={view}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='leg'
            title=' لگ : '
            autoComplete='off'
            error={legTouched && legError ? legError : undefined}
            value={leg}
            onChange={formik.handleChange}
            type='number'
          />
          <Input
            type='number'
            name='height'
            title='ارتفاع  : '
            autoComplete='off'
            error={heightTouched && heightError ? heightError : undefined}
            value={height}
            onChange={formik.handleChange}
          />
          <Input
            type='number'
            name='port'
            title='پورت  : '
            autoComplete='off'
            error={portTouched && portError ? portError : undefined}
            value={port}
            onChange={formik.handleChange}
          />
          <Input
            name='cableType'
            title='نوع کابل  : '
            autoComplete='off'
            error={
              cableTypeTouched && cableTypeError ? cableTypeError : undefined
            }
            value={cableType}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            type='number'
            name='cableSize'
            title='متراژ کابل  : '
            autoComplete='off'
            error={
              cableSizeTouched && cableSizeError ? cableSizeError : undefined
            }
            value={cableSize}
            onChange={formik.handleChange}
          />
          <Input
            name='installers'
            title='نصاب ها  : '
            autoComplete='off'
            error={
              installersTouched && installersError ? installersError : undefined
            }
            value={installers}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='installDate'
            title='تاریخ نصب  : '
            autoComplete='off'
            error={
              installDateTouched && installDateError
                ? installDateError
                : undefined
            }
            value={installDate}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='radioPropertyNumber'
            title='شماره اموال  : '
            autoComplete='off'
            error={
              radioPropertyNumberTouched && radioPropertyNumberError
                ? radioPropertyNumberError
                : undefined
            }
            value={radioPropertyNumber}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='radioSerialNumber'
            title='شماره سریال  : '
            autoComplete='off'
            error={
              radioSerialNumberTouched && radioSerialNumberError
                ? radioSerialNumberError
                : undefined
            }
            value={radioSerialNumber}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='etherMac'
            title='مک آدرس اتر  : '
            autoComplete='off'
            error={etherMacTouched && etherMacError ? etherMacError : undefined}
            value={etherMac}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='wirelessMac'
            title='مک آدرس وایرلس  : '
            autoComplete='off'
            error={
              wirelessMacTouched && wirelessMacError
                ? wirelessMacError
                : undefined
            }
            value={wirelessMac}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='description'
            title='توضیحات  : '
            multiline
            autoComplete='off'
            error={
              descriptionTouched && descriptionError
                ? descriptionError
                : undefined
            }
            value={description}
            onChange={formik.handleChange}
            type='text'
          />

          <SubmitButton
            title='ثبت'
            loading={loading}
            disabled={loading}
            type='submit'
          />
        </form>
      </div>
    </>
  );
};

export default AddRadio;
