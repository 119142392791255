import { CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import copy from "copy-to-clipboard";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  DeleteModalContent,
  DetailModalContent,
  NoItem,
  SubmitButton
} from "../../../../../core/components";
import { USER_ACCESS } from "../../../../../core/constants";
import { defaultColumnDefinition } from "../../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../../core/hooks";
import { BASE_API, DATACENTER_ROOT_API } from "../../../../../core/redux";
import {
  CRUD_TYPES,
  DCItemSchema,
  DCItemTable
} from "../../../../../core/types";
import {
  checkUserAccessByProvinceCrud,
  fileDownload
} from "../../../../../core/utility";

const ItemsList = () => {
  let [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");
  const { fetchOneDataCenter, deleteDcItem } = useActions();
  const { loading, item } = useTypedSelector((state) => state.dataCenter);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<DCItemSchema | null>(null);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);
  const { me } = useTypedSelector((state) => state.auth);
  const { token } = useTypedSelector((state) => state.auth);

  const navigator = useNavigate();

  useEffect(() => {
    if (_id) {
      fetchOneDataCenter(_id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const onSuccessDeleteModal = useCallback(() => {
    deleteDcItem(selectedItem?._id as string, item?._id as string);
    setIsOpen(false);
    setSelectedItem(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem?._id, item?._id]);

  const rowData = useCallback(() => {
    const items = item?.items;
    if (item !== undefined) {
      return items?.map((_item, index) => {
        return {
          [DCItemTable.ROW]: `${index + 1}`,
          [DCItemTable._ID]: _item._id,
          [DCItemTable.NAME]: _item.name,
          [DCItemTable.TYPE]: _item.type,
          [DCItemTable.UNIT]: _item.unit,
          [DCItemTable.PROPERTYNUMBER]: _item.propertyNumber,
          [DCItemTable.SERIALNUMBER]: _item.serialNumber,
          [DCItemTable.RACKID]:
            (_item?.rackId &&
              item?.racks?.find((rack) => {
                return _item.rackId === rack._id;
              })?.rackNumber) ??
            ""
        };
      });
    }
  }, [item]);

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          <span
            onClick={() => {
              const _item = item?.items?.find((item) => {
                return (
                  item._id?.toString() ===
                  cellEvent?.data[DCItemTable._ID].toString()
                );
              });
              _item && setSelectedItem(_item);
              setIsCommentModalOpen(true);
            }}>
            <i title='توضیحات' className='fas fa-comment comment-icon'></i>
          </span>
          {me &&
            item &&
            checkUserAccessByProvinceCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.DATA_CENTER,
              CRUD_TYPES.EDIT,
              item?.provinceId ?? ""
            ) && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  navigator(
                    `edit?dataCenterId=${item?._id}&itemId=${cellEvent?.data?._id}`
                  );
                }}>
                <i title='ویرایش' className='fas fa-pen edit-icon'></i>
              </span>
            )}
          {me &&
            item &&
            checkUserAccessByProvinceCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.DATA_CENTER,
              CRUD_TYPES.DELETE,
              item?.provinceId ?? ""
            ) && (
              <span
                onClick={() => {
                  const _item = item?.items?.find((item) => {
                    return (
                      item._id?.toString() ===
                      cellEvent?.data[DCItemTable._ID].toString()
                    );
                  });

                  _item && setSelectedItem(_item);
                  setIsOpen(true);
                }}>
                <i title='حذف' className='fas fa-trash delete-icon'></i>
              </span>
            )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item]
  );

  return (
    <>
      <DeleteModalContent
        isOpen={isOpen}
        title={"حذف آیتم " + selectedItem?.name}
        message='آیا از حذف آیتم مطمئن هستید؟'
        onCancel={() => {
          setIsOpen(false);
          setSelectedItem(null);
        }}
        onSuccess={onSuccessDeleteModal}
      />
      <DetailModalContent
        isOpen={isCommentModalOpen}
        title={" توضیحات آیتم " + selectedItem?.name}
        message={
          selectedItem?.description
            ? selectedItem?.description
            : "توضیحی درباره این آیتم ثبت نشده است."
        }
        onClose={() => {
          setIsCommentModalOpen(false);
          setSelectedItem(null);
        }}
      />

      <div className='main-route-wrapper'>
        {" "}
        <div className='table-wrapper'>
          <div className='list-top'>
            <p>
              تعداد کل آیتم های دیتاسنتر {item && item.name} :{" "}
              <span>{item && item.items?.length} عدد</span>
              {item && (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    const url = BASE_API + DATACENTER_ROOT_API;
                    fileDownload(url, token ?? "", "items.xlsx", {
                      xlsx: true,
                      _id: item._id,
                      select: "items"
                    });
                  }}
                  className='fas fa-file-excel excel-icon'></i>
              )}
            </p>
            {me &&
              item &&
              checkUserAccessByProvinceCrud(
                me,
                me?.access ?? [],
                USER_ACCESS.DATA_CENTER,
                CRUD_TYPES.EDIT,
                item?.provinceId ?? ""
              ) && (
                <Link to={"./add?_id=" + _id}>
                  <SubmitButton
                    loading={loading}
                    title='      اضافه کردن آیتم جدید'
                  />
                </Link>
              )}
          </div>

          {(!item || !item.items || item.items.length === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}

          {item && item !== null && (
            <>
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  animateRows={true}
                  onCellClicked={(e) => {
                    if (e.colDef.field !== DCItemTable.ACTIONS) copy(e.value);
                  }}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  rowSelection={"single"}
                  enableRtl={true}>
                  <AgGridColumn
                    sortable={true}
                    field={DCItemTable.ROW}
                    width={84}
                  />
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={DCItemTable.ACTIONS}
                    width={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DCItemTable.NAME}
                    minWidth={150}
                    flex={1}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DCItemTable.TYPE}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DCItemTable.UNIT}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DCItemTable.PROPERTYNUMBER}
                    width={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DCItemTable.SERIALNUMBER}
                    width={200}
                  />

                  <AgGridColumn
                    sortable={true}
                    field={DCItemTable.RACKID}
                    width={120}
                  />
                </AgGridReact>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ItemsList;
