import {
  BANDWIDTH_TYPES,
  CABLE_TYPE,
  CAUSE_TYPES,
  NEED_EXPERT_STATUS_TYPES,
  PAP_TYPE,
  PASSIVE_TYPE,
  PRIORITY_TYPES,
  SATISFACTION_TYPES
} from "../types";
import { SHELF_TYPE } from "../types/PapsTypes/ShelfTypes";

export const DEBOUNCE_TIME = 500;
export const defaultTablePageCount = 10;
export const defaultFaultTablePageCount = 50;
export const defaultCitiesCount = 2000;
export const defaultTelecomsCount = 2000;
export const papFaultRatio = 1;
export const defualtDateDiffrence = 7.776e9;
export const tableRowColors = { success: "#8cffa5", fail: "#ff9185" };
export const infinitiLimit = 50;
export const refetchInterval = 60000;
export const resetPasswordTimeout = 60;
export const mobileScreenSize = 540;

export enum STATUS_TYPES {
  IN_TELECOM = "در دست بررسی از مرکز",
  NORMAL = "مرکز نرمال",
  CUSTOMER = "در دست بررسی با مشترک",
  REFERRED_TO_EXPERT = "ارجاع به حضوری",
  DONE = "پایان یافته"
}

export const CAUSE_TYPES_LIST: { label: string; value: number | string }[] = [
  { value: CAUSE_TYPES.BAD_UP, label: CAUSE_TYPES.BAD_UP },
  { value: CAUSE_TYPES.BREAK_UP, label: CAUSE_TYPES.BREAK_UP },
  { value: CAUSE_TYPES.CUT_OF_LINK, label: CAUSE_TYPES.CUT_OF_LINK },
  { value: CAUSE_TYPES.ONLINE, label: CAUSE_TYPES.ONLINE },
  { value: CAUSE_TYPES.OTHER, label: CAUSE_TYPES.OTHER }
];

export const PRIORITY_TYPES_LIST: { label: string; value: number | string }[] =
  [
    { value: PRIORITY_TYPES.NORMAL, label: PRIORITY_TYPES.NORMAL },
    { value: PRIORITY_TYPES.IMPORTANT, label: PRIORITY_TYPES.IMPORTANT },
    {
      value: PRIORITY_TYPES.ESSENTIAL,
      label: PRIORITY_TYPES.ESSENTIAL
    }
  ];

export const PAP_TYPES_LIST: { label: string; value: string }[] = [
  { value: PAP_TYPE.AT, label: PAP_TYPE.AT },
  { value: PAP_TYPE.BOT, label: PAP_TYPE.BOT }
];

export const NEED_EXPERT_STATUS_TYPES_LIST = (
  Object.keys(NEED_EXPERT_STATUS_TYPES) as Array<
    keyof typeof NEED_EXPERT_STATUS_TYPES
  >
).map((el) => ({
  value: NEED_EXPERT_STATUS_TYPES[el],
  label: NEED_EXPERT_STATUS_TYPES[el]
}));

export const BANDWIDTH_TYPES_LIST: { label: number; value: number | string }[] =
  [
    { value: BANDWIDTH_TYPES.ONE, label: BANDWIDTH_TYPES.ONE },
    { value: BANDWIDTH_TYPES.TWO, label: BANDWIDTH_TYPES.TWO },
    { value: BANDWIDTH_TYPES.THREE, label: BANDWIDTH_TYPES.THREE },
    { value: BANDWIDTH_TYPES.FOUR, label: BANDWIDTH_TYPES.FOUR },
    { value: BANDWIDTH_TYPES.EIGHT, label: BANDWIDTH_TYPES.EIGHT },
    { value: BANDWIDTH_TYPES.SIXTEEN, label: BANDWIDTH_TYPES.SIXTEEN },
    {
      value: BANDWIDTH_TYPES.FIVEHUNDREDTWELVE,
      label: BANDWIDTH_TYPES.FIVEHUNDREDTWELVE
    }
  ];

export const SATISFACTION_TYPES_LIST: {
  label: string;
  value: string;
}[] = [
  {
    value: SATISFACTION_TYPES.PLEASED,
    label: SATISFACTION_TYPES.PLEASED
  },
  {
    value: SATISFACTION_TYPES.DISPLEASED,
    label: SATISFACTION_TYPES.DISPLEASED
  },
  {
    value: SATISFACTION_TYPES.UNANSWERED,
    label: SATISFACTION_TYPES.UNANSWERED
  },
  {
    value: SATISFACTION_TYPES.CANCELED,
    label: SATISFACTION_TYPES.CANCELED
  }
];

export const STATUS_TYPES_LIST: { label: STATUS_TYPES; value: STATUS_TYPES }[] =
  [
    {
      value: STATUS_TYPES.IN_TELECOM,
      label: STATUS_TYPES.IN_TELECOM
    },
    {
      value: STATUS_TYPES.NORMAL,
      label: STATUS_TYPES.NORMAL
    },
    {
      value: STATUS_TYPES.CUSTOMER,
      label: STATUS_TYPES.CUSTOMER
    },
    {
      value: STATUS_TYPES.REFERRED_TO_EXPERT,
      label: STATUS_TYPES.REFERRED_TO_EXPERT
    }
  ];

export const SHELF_TYPE_LIST: { label: string; value: string }[] = [
  {
    value: SHELF_TYPE.S5600,
    label: SHELF_TYPE.S5600
  },
  {
    value: SHELF_TYPE.S5616,
    label: SHELF_TYPE.S5616
  }
];

export const PASSIVE_TYPE_LIST: { label: string; value: string }[] = [
  {
    value: PASSIVE_TYPE.T98,
    label: PASSIVE_TYPE.T98
  },
  {
    value: PASSIVE_TYPE.T70,
    label: PASSIVE_TYPE.T70
  }
];

export const CABLE_TYPE_LIST: { label: string; value: string }[] = [
  {
    value: CABLE_TYPE.P25,
    label: CABLE_TYPE.P25
  },
  {
    value: CABLE_TYPE.P100,
    label: CABLE_TYPE.P100
  }
];

export enum ROUTER_TYPES {
  ROUTER750 = "MikroTik 750",
  ROUTER750HEX = "MikroTik 750 hex",
  ROUTER1072 = "MikroTik 1072",
  ROUTER1036 = "MikroTik 1036",
  ROUTER1100 = "MikroTik 1100",
  ROUTER3011 = "MikroTik 3011",
  ROUTER2011 = "MikroTik 2011",
  ROUTER4011 = "MikroTik 4011",
  ROUTER3750 = "Switch Cisco 3750",
  ROUTER3850 = "Switch Cisco 3850"
}

export enum RADIO_TYPES {
  LHG5 = "LHG5",
  SXT5 = "Sxt5",
  QRT5 = "Qrt5",
  SXTLITE = "Sxt lite",
  LHGXL = "Lhg xl",
  DYNADISH = "DynaDish",
  NETMETAL = "Netmetal",
  BASEBOX = "Basebox"
}

export enum OWA_TYPES {
  THREE = "سه وجهی",
  FOUR = "چهار وجهی"
}
