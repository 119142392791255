import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { customizedAxios } from "../../../config";
import { getCatch } from "../../../catch";
import { AddPassive, PassiveSchema } from "../../../types";
import { PassivesActionTypes } from "../../actionTypes/PapActionTypes";
import { PASSIVE_TYPES } from "../../types";
import { PAPS_ROOT_API } from "../../api";
import { store } from "../../store";
import { DeleteType } from "../../../types";

export const addPassive =
  (body: AddPassive, id: string) =>
  async (dispatch: Dispatch<PassivesActionTypes>) => {
    try {
      dispatch({ type: PASSIVE_TYPES.PASSIVE_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PAPS_ROOT_API.concat("/passive/" + id);
      await customizedAxios.post(url, body, config);

      dispatch({
        type: PASSIVE_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" بوخت با موفقیت افزوده شد.");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PASSIVE_TYPES.DISABLE_LOADING });
    }
  };

export const editPassive =
  (body: PassiveSchema, id: string) =>
  async (dispatch: Dispatch<PassivesActionTypes>) => {
    try {
      dispatch({ type: PASSIVE_TYPES.PASSIVE_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PAPS_ROOT_API.concat("/passive/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: PASSIVE_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("بوخت با موفقیت ویرایش شد");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PASSIVE_TYPES.DISABLE_LOADING });
    }
  };

export const deletePassive =
  (id: string, papId: string) =>
  async (dispatch: Dispatch<PassivesActionTypes>) => {
    try {
      dispatch({ type: PASSIVE_TYPES.PASSIVE_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        data: {
          id
        }
      };

      let url = PAPS_ROOT_API.concat("/passive/" + papId);
      await customizedAxios.delete<DeleteType<PassiveSchema>>(url, config);

      dispatch({
        type: PASSIVE_TYPES.PASSIVE_DELETE,
        payload: { _id: id }
      });
      window.openToastSuccess("بوخت با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PASSIVE_TYPES.DISABLE_LOADING });
    }
  };
