import { MongoObject } from "../../BaseTypes";
import { LineCardSchema } from "./LineCardTypes";

export enum ShelvesTable {
  _ID = "_id",
  SHELFNUMBER = "شماره شلف",
  MODEL = "مدل",
  PROPERTYNUMBER = "اموال",
  SERIALNUMBER = "سریال",
  MANAGESERIALNUMBER = "اموال منیج",
  MANAGEPROPERTYNUMBER = "سریال منیج",
  LINECARDS = " لاین کارت",
  CREATEDAT = "تاریخ ایجاد ",
  UPDATEDAT = "تاریخ اخرین تغییر",
  ACTIONS = "عملیات",
  ROW = "ردیف"
}

export interface ShelfSchema extends MongoObject {
  _id?: string;
  id?: string | null;
  shelfNumber?: string;
  model?: string;
  propertyNumber?: string;
  serialNumber?: string;
  managePropertyNumber?: string;
  manageSerialNumber?: string;
  rackNumber?: string;
  createdAt?: Date;
  updatedAt?: Date;
  lineCards?: Array<LineCardSchema> | null;
}
export interface AddShelf {
  shelfNumber?: string;
  model?: string;
  propertyNumber?: string;
  serialNumber?: string;
  managePropertyNumber?: string;
  manageSerialNumber?: string;
  rackNumber?: string;
}

export enum SHELF_TYPE {
  S5616 = "5616",
  S5600 = "5600"
}
