export const createFormData = (
  item: File,
  body: { [key: string]: any } = {}
) => {
  const data = new FormData();

  data.append("file", item);

  Object.keys(body).forEach((key) => {
    data.append(key, body[key]);
  });

  return data;
};
