import { useFormik } from "formik";
import moment from "jalali-moment";
import React, { memo, useCallback } from "react";
import DatePicker from "react-datepicker2";
import ReactModal from "react-modal";
import Select from "react-select";
import * as yup from "yup";
import { CancelButton, Input, SubmitButton } from ".";
import { useTypedSelector } from "../hooks";
import { FaultCommentType, FaultType, STATUS_TYPES, UserType } from "../types";
import { NoItem } from "./NoItem";
import { getSinceTimeWithHour } from "../utility/getSinceTimeWithHour";

interface AddCommentModalContentType {
  onSuccess?: (values: Partial<FaultType>) => void;
  comments?: FaultCommentType[];
  onClose?: () => void;
  isOpen: boolean;
  faultCurrentStatus: STATUS_TYPES;
  faultPhone: string;
}

const _ConcludeFault: React.FC<AddCommentModalContentType> = ({
  comments,
  onSuccess,
  onClose,
  isOpen,
  faultCurrentStatus,
  faultPhone,
  ...rest
}) => {
  const { assignAbleUsers } = useTypedSelector((state) => state.fault);

  const getAssignAbleUsersList = useCallback(() => {
    return assignAbleUsers?.map((item) => {
      return { value: item._id, label: item.fullName };
    });
  }, [assignAbleUsers]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      password: "",
      name: "",
      mobileNumber: "",
      address: "",
      assignedTo: "",
      comment: "",
      date: undefined,
      status: undefined
    },
    validationSchema: yup.object({
      username: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("نام کاربری الزامی است")
          : schema;
      }),
      password: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("کلمه عبور الزامی است")
          : schema;
      }),
      name: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("نام مشترک الزامی است")
          : schema;
      }),
      mobileNumber: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("شماره همراه مشترک الزامی است")
          : schema;
      }),
      assignedTo: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("خرابی را به چه کسی ارجاع می دهید")
          : schema;
      }),
      address: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("آدرس الزامی است")
          : schema;
      }),
      comment: yup.string(),
      date: yup.date().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("تاریخ حضوری الزامی است")
          : schema;
      }),
      status: yup
        .mixed()
        .oneOf([
          STATUS_TYPES.IN_TELECOM,
          STATUS_TYPES.NORMAL,
          STATUS_TYPES.CUSTOMER,
          STATUS_TYPES.REFERRED_TO_EXPERT,
          STATUS_TYPES.DONE
        ])
        .optional()
    }),
    onSubmit: ({
      assignedTo,
      date,
      username,
      password,
      name,
      mobileNumber,
      address,
      comment,
      status = faultCurrentStatus
    }) => {
      const body: Partial<FaultType> = {
        status: status!,
        comments: comment ?? undefined
      };

      if (status === STATUS_TYPES.REFERRED_TO_EXPERT) {
        body.needExpert = {
          date,
          username: username ?? "",
          password: password ?? "",
          name: name ?? "",
          mobileNumber: mobileNumber ?? "",
          address: address ?? "",
          assignedTo: assignedTo!
        };
        body.assignedTo = assignedTo!;
      }

      onSuccess && onSuccess(body);
    }
  });

  const {
    date,
    username,
    password,
    name,
    mobileNumber,
    assignedTo,
    comment,
    status,
    address
  } = formik.values;
  const {
    date: dateError,
    username: usernameError,
    password: passwordError,
    name: nameError,
    mobileNumber: mobileNumberError,
    assignedTo: assignedToError,
    comment: commentError,
    address: addressError
  } = formik.errors;
  const { comment: commentTouched } = formik.touched;

  const { handleChange, setFieldValue } = formik;

  return (
    <>
      <ReactModal
        style={{
          content: {
            maxWidth: 800,
            maxHeight: 600,
            margin: "auto",
            padding: "0",
            left: 16,
            right: 16
          }
        }}
        {...rest}
        isOpen={isOpen}
        onRequestClose={onClose}
        ariaHideApp={false}>
        <div className='add-comment-modal__wrapper change-status-modal-wrapper'>
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <div className='add-comment-modal-header'>
              <div className='title'>تغییر وضعیت خرابی {faultPhone} </div>
              <div className='action-wrappers'>
                <CancelButton
                  onClick={onClose}
                  loading={false}
                  title='انصراف'
                  type='button'
                />
                <SubmitButton type='submit' title='ثبت وضعیت' />
              </div>
            </div>
            <div className='change-status-modal'>
              <div
                role='group'
                aria-labelledby='checkbox-group'
                className='status-list'>
                {Object.values(STATUS_TYPES)
                  .filter((statusItem) =>
                    faultCurrentStatus === STATUS_TYPES.NORMAL
                      ? statusItem !== STATUS_TYPES.NORMAL
                      : faultCurrentStatus === STATUS_TYPES.CUSTOMER
                      ? statusItem !== STATUS_TYPES.CUSTOMER
                      : statusItem
                  )
                  .reverse()
                  .map((statusItem) => (
                    <label>
                      <input
                        type='radio'
                        name='checked'
                        value={statusItem}
                        checked={status === statusItem}
                        onChange={() => setFieldValue("status", statusItem)}
                      />
                      {statusItem}
                    </label>
                  ))}
              </div>
              <div className='reffer-to-form'>
                {status === STATUS_TYPES.REFERRED_TO_EXPERT && (
                  <>
                    <Input
                      placeholder='نام مشترک'
                      title='نام مشترک'
                      name='name'
                      autoComplete='off'
                      error={nameError ? nameError : undefined}
                      value={name}
                      onChange={handleChange}
                    />
                    <Input
                      placeholder='شماره همراه'
                      title='شماره همراه'
                      name='mobileNumber'
                      autoComplete='off'
                      type='tel'
                      error={mobileNumberError ? mobileNumberError : undefined}
                      value={mobileNumber}
                      onChange={handleChange}
                    />
                    <Input
                      placeholder='آدرس'
                      title='آدرس'
                      name='address'
                      autoComplete='off'
                      error={addressError ? addressError : undefined}
                      value={address}
                      onChange={handleChange}
                    />
                    <div
                      style={{ marginBottom: 0 }}
                      className='select-wrapper--high'>
                      <label> تاریخ حضوری:</label>

                      <DatePicker
                        isGregorian={false}
                        onChange={(value) => {
                          setFieldValue("date", value, true);
                        }}
                        value={date}
                        timePicker={true}
                        min={moment().date(moment().get("date") - 1)}
                      />
                      {dateError && (
                        <p className='input-error--select'>{dateError}</p>
                      )}
                    </div>
                    <Input
                      placeholder='نام کاربری'
                      title='نام کاربری'
                      name='username'
                      autoComplete='off'
                      error={usernameError ? usernameError : undefined}
                      value={username}
                      onChange={handleChange}
                    />
                    <Input
                      placeholder='کلمه عبور'
                      title='کلمه عبور'
                      name='password'
                      autoComplete='off'
                      error={passwordError ? passwordError : undefined}
                      value={password}
                      onChange={handleChange}
                    />

                    <div className='select-wrapper--high '>
                      <label> به چه کسی ارجاع می کنید :</label>
                      <Select
                        value={getAssignAbleUsersList()?.find((user) => {
                          return user.value === assignedTo;
                        })}
                        onChange={(e) => {
                          setFieldValue("assignedTo", e?.value ?? "", true);
                        }}
                        isClearable
                        placeholder=' لطفا کاربر مورد نظر را انتخاب نمایید'
                        openMenuOnClick={true}
                        classNamePrefix='react-select'
                        options={getAssignAbleUsersList()}
                        noOptionsMessage={() => {
                          return <h6>آیتمی یافت نشد</h6>;
                        }}
                      />
                      {assignedToError && (
                        <p className='input-error--select'>{assignedToError}</p>
                      )}
                    </div>
                  </>
                )}
                <Input
                  title=' توضیحات'
                  name='comment'
                  autoComplete='off'
                  error={
                    commentTouched && commentError ? commentError : undefined
                  }
                  value={comment}
                  type='text'
                  onChange={handleChange}
                  multiline
                />
              </div>
            </div>

            {comments && comments.length !== 0 ? (
              <div className='content-wrapper'>
                {comments
                  .sort(function (a, b) {
                    return (
                      new Date(b.createdAt).getTime() -
                      new Date(a.createdAt).getTime()
                    );
                  })
                  .map((item) => {
                    return (
                      <div key={item._id}>
                        <span>
                          {`${(item.userId as unknown as UserType)
                            ?.fullName} ${getSinceTimeWithHour(
                            item.createdAt
                          )}`}
                        </span>
                        &nbsp;
                        <div>{item.desc}</div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <NoItem title='کامنتی ثبت نشده است.' />
            )}
          </form>
        </div>
      </ReactModal>
    </>
  );
};

const ConcludeFault = memo(_ConcludeFault);

export { ConcludeFault };
