import { MongoObject } from "..";

export enum MiscellaneousTable {
  _ID = "_id",
  TITLE = " عنوان",
  RACKNUMBER = "شماره رک",
  PROPERTYNUMBER = "اموال",
  BRANDNAME = "برند",
  SERIALNUMBER = " شماره سریال",
  DIMENSION = "ابعاد ",
  CREATEDAT = " تاریخ ایجاد",
  UPDATEDAT = "تاریخ آخرین تغییر",
  ACTIONS = "عملیات",
  ROW = "ردیف"
}

export interface MiscellaneousSchema extends MongoObject {
  _id?: string;
  id?: string | null;
  title?: string;
  rackNumber?: string | number;
  brandName?: string;
  propertyNumber?: string;
  serialNumber?: string;
  dimension?: string;
  createdAt?: Date;
  updatedAt?: Date;
}
export interface AddMiscellaneous {
  title?: string;
  rackNumber?: string | number;
  brandName?: string;
  propertyNumber?: string;
  serialNumber?: string;
  dimension?: string;
}
