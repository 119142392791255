import { Route, Routes } from "react-router-dom";
import LogsList from "./logs-list";

const Logs = () => {
  return (
    <>
      <Routes>
        <Route path='' element={<LogsList />} />
      </Routes>
    </>
  );
};

export default Logs;
