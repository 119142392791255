import React, { Dispatch, SetStateAction } from "react";
import ClickAwayListener from "react-click-away-listener";
import { createPortal } from "react-dom";
import DrawerNav from "./drawer-nav";
import DrawerTop from "./drawer-top";

interface DrawerType {
  openDrawer?: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

const Drawer: React.FC<DrawerType> = ({ openDrawer, open }) => {
  return (
    <>
      <div className='drawer-wrapper'>
        <DrawerTop />
        <DrawerNav />
      </div>
      {createPortal(
        <ClickAwayListener
          onClickAway={(e) => {
            if (e && e.type === "touchend" && openDrawer) {
              openDrawer(false);
            }
          }}>
          <div
            className={`drawer-wrapper--mobile ${open ? "drawer-open" : ""}`}>
            <i
              onClick={() => {
                openDrawer && openDrawer(false);
              }}
              className='fas fa-arrow-right drawer-wrapper--mobile__back'
            />
            <div className='drawer-wrapper'>
              <DrawerTop openDrawer={openDrawer} />
              <DrawerNav openDrawer={openDrawer} />
            </div>
          </div>
        </ClickAwayListener>,
        document.querySelector("body") as Element
      )}
    </>
  );
};

export default Drawer;
