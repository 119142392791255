import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../../../core/components";
import { useActions, useTypedSelector } from "../../../../../../core/hooks";
import { LineCardSchema } from "../../../../../../core/types/PapsTypes/ShelfTypes/LineCardTypes";

const EditLineCard = () => {
  let [searchParams] = useSearchParams();
  const papId = searchParams.get("papId");
  const shelfID = searchParams.get("shelfId");
  const lineCardId = searchParams.get("lineCardId");
  const { editLineCard, fetchOnePap } = useActions();

  const { loading, item } = useTypedSelector((state) => state.pap);

  const selectedLineCard = useMemo(() => {
    const selectedShelf =
      item &&
      lineCardId &&
      item?.shelves?.find((shelf) => {
        return shelf?._id === shelfID;
      });
    if (selectedShelf) {
      return selectedShelf?.lineCards?.find((lineCard) => {
        return lineCard._id?.toString() === lineCardId;
      });
    }
    return undefined;
  }, [item, shelfID, lineCardId]);

  const formik = useFormik({
    initialValues: {
      cardNumber:
        selectedLineCard && selectedLineCard?.cardNumber
          ? selectedLineCard.cardNumber
          : "",
      installers:
        selectedLineCard && selectedLineCard?.installers
          ? selectedLineCard.installers
          : "",
      propertyNumber:
        selectedLineCard && selectedLineCard?.propertyNumber
          ? selectedLineCard.propertyNumber
          : "",
      serialNumber:
        selectedLineCard && selectedLineCard?.serialNumber
          ? selectedLineCard.serialNumber
          : "",
      comment:
        selectedLineCard && selectedLineCard?.comment
          ? selectedLineCard.comment
          : ""
    },
    validationSchema: yup.object({
      cardNumber: yup.number().required("شماره کارت الزامی است"),
      installers: yup.string().trim(),
      propertyNumber: yup.string().trim(),
      serialNumber: yup.string().trim(),
      comment: yup.string().trim()
    }),
    onSubmit: ({
      cardNumber,
      installers,
      propertyNumber,
      serialNumber,
      comment,
      shelfId = shelfID ?? "",
      id = lineCardId ?? ""
    }: LineCardSchema) => {
      editLineCard(
        {
          shelfId,
          cardNumber,
          installers,
          propertyNumber,
          serialNumber,
          comment,
          id
        },
        item?._id ?? ""
      );
    }
  });
  useEffect(() => {
    if (papId) {
      fetchOnePap(papId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [papId]);

  const { cardNumber, installers, propertyNumber, serialNumber, comment } =
    formik.values;
  const {
    cardNumber: cardNumberError,
    installers: installersError,
    propertyNumber: propertyNumberError,
    serialNumber: serialNumberError,
    comment: commentError
  } = formik.errors;
  const {
    cardNumber: cardNumberTouched,
    installers: installersTouched,
    propertyNumber: propertyNumberTouched,
    serialNumber: serialNumberTouched,
    comment: commentTouched
  } = formik.touched;

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'>ویرایش لاین کارت</h5>
        {item && item !== null && selectedLineCard && (
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <Input
              name='cardNumber'
              title='شماره کارت : '
              autoComplete='off'
              error={
                cardNumberTouched && cardNumberError
                  ? cardNumberError
                  : undefined
              }
              value={cardNumber}
              type='number'
              onChange={formik.handleChange}
            />
            <Input
              name='propertyNumber'
              title='شماره اموال : '
              autoComplete='off'
              error={
                propertyNumberTouched && propertyNumberError
                  ? propertyNumberError
                  : undefined
              }
              value={propertyNumber}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='serialNumber'
              title='شماره سریال : '
              autoComplete='off'
              error={
                serialNumberTouched && serialNumberError
                  ? serialNumberError
                  : undefined
              }
              value={serialNumber}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='installers'
              title='نصاب ها : '
              autoComplete='off'
              error={
                installersTouched && installersError
                  ? installersError
                  : undefined
              }
              value={installers}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='comment'
              title='توضیحات : '
              multiline
              autoComplete='off'
              error={commentTouched && commentError ? commentError : undefined}
              value={comment}
              onChange={formik.handleChange}
              type='text'
            />
            <SubmitButton
              title='ثبت'
              loading={loading}
              disabled={loading}
              type='submit'
            />
          </form>
        )}
      </div>
    </>
  );
};

export default EditLineCard;
