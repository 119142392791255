import React, { memo } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { css, SerializedStyles } from "@emotion/react";

const style = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px auto;
`;

interface LoadingType {
  loading: boolean;
  size?: number;
  color?: string;
  override?: SerializedStyles;
}

const _Loading: React.FC<LoadingType> = ({
  loading,
  size = 10,
  color = "white",
  override = style
}) => {
  return (
    <>
      <BeatLoader color={color} loading={loading} css={override} size={size} />
    </>
  );
};

const Loading = memo(_Loading);

export default Loading;
