import { Route, Routes } from "react-router-dom";
import TasksList from "./tasks-list";
import AddTask from "./add-task";
import EditTask from "./edit-task";
import AddPhoto from "./add-photo";
import DoneTasks from "./done-tasks";

const Tasks = () => {
  return (
    <>
      <Routes>
        <Route path='/list' element={<TasksList />} />
        <Route path='/add-task' element={<AddTask />} />
        <Route path='/edit-task' element={<EditTask />} />
        <Route path='/add-task-photo' element={<AddPhoto />} />
        <Route path='/done' element={<DoneTasks />} />
      </Routes>
    </>
  );
};

export default Tasks;
