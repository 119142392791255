export enum USER_ACCESS {
  PROVINCE = "استان ها",

  FAULTS_INSERT = "ایجاد خرابی",
  FAULTS_READ = "اطلاعات خرابی",
  FAULTS_READ_ALL = "اطلاعات کل خرابی",

  TASKS_READ = "اطلاعات تسک ها",
  TASKS_READ_ALL = "اطلاعات کل تسک ها",
  LOGS = "لاگ مشترک",
  SMS = "اس ام اس ها",

  CITY = "شهر ها",
  PHONEBOOK = "دفترچه تلفن",
  OWA = "پاپ سایت",
  DATA_CENTER = "سرورها",
  COMMENT = "توضیحات",

  TELECOM = "مراکز",
  PAPS = "پپ ها"
}
