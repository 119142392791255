import { UserType } from "../../types";
import { AuthActionTypes } from "../actionTypes";
import { AUTH_TYPES } from "../types";

interface StateType {
  token: string | null;
  me: UserType | null;
  userId: UserType | null;
}

const initialState: StateType = {
  token: null,
  me: null,
  userId: null
};

const reducer = (
  state: StateType = initialState,
  action: AuthActionTypes
): StateType => {
  switch (action.type) {
    case AUTH_TYPES.LOGIN:
      return {
        ...state,
        token: action.payload
      };

    case AUTH_TYPES.LOGOUT:
      return initialState;

    case AUTH_TYPES.ME:
      return {
        ...state,
        me: action.payload
      };

    case AUTH_TYPES.AUTH_ERROR:
      return {
        ...state
      };

    default:
      return state;
  }
};

export default reducer;
