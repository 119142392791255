import { Route, Routes } from "react-router-dom";
import AddItem from "./add-item";
import EditItem from "./edit-item";
import ItemsList from "./items-list";

const Items = () => {
  return (
    <>
      <Routes>
        <Route path='' element={<ItemsList />} />
        <Route path='/add' element={<AddItem />} />
        <Route path='/edit' element={<EditItem />} />
      </Routes>
    </>
  );
};

export default Items;
