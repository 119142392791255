/* eslint-disable array-callback-return */
import React, { memo, useEffect, useMemo } from "react";
import ReactModal from "react-modal";
import { AuthGuide } from ".";
import { defaultCitiesCount, USER_ACCESS } from "../constants";
import { useActions, useTypedSelector } from "../hooks";
import { CitiesType, CRUD_TYPES, ProvincesType, UserType } from "../types";

interface AuthModalByCityTypes {
  user: UserType;
  access: USER_ACCESS;
  onClose?: () => any;
  isOpen: boolean;
}

const _AuthModalByCities: React.FC<AuthModalByCityTypes> = ({
  onClose,
  isOpen,
  access,
  user,
  ...rest
}) => {
  const { fetchUserProvinces, fetchUserCities } = useActions();
  const { provinceList, citiesList } = useTypedSelector(
    (state) => state.general
  );

  useEffect(() => {
    fetchUserProvinces();
    fetchUserCities({ limit: defaultCitiesCount, page: 1 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getParentProvincesAndCrudAccess: [
    ProvincesType[],
    ({ city: CitiesType; crud: CRUD_TYPES[] } | undefined)[][]
  ] = useMemo(() => {
    if (user && user.access) {
      const selectedSection = user.access.find((acc) => {
        return acc.section === access;
      });
      const parentProvinces = selectedSection?.province;
      const filteredProvinces =
        provinceList?.data?.filter((province) => {
          return parentProvinces?.find((parentProvince) => {
            return province._id === parentProvince.provinceId;
          });
        }) ?? [];

      const crudAccess = Array.from(filteredProvinces, (province) => {
        return (
          citiesList?.data
            .filter((city) => {
              return city.provinceId === province._id;
            })
            .map((city) => {
              let filteredParentCityIndex =
                parentProvinces?.findIndex((parentProvince) => {
                  if (parentProvince.provinceId === province._id!) {
                    return (
                      parentProvince.cities?.find((parentCity) => {
                        return parentCity.cityId === city._id!;
                      }) ?? false
                    );
                  }
                }) ?? -1;
              if (filteredParentCityIndex > -1) {
                let selectedCity = parentProvinces![
                  filteredParentCityIndex
                ]!.cities!.find((innerCity) => {
                  return innerCity.cityId === city._id!;
                });
                return { city, crud: selectedCity?.crud ?? [] };
              } else {
                return undefined;
              }
            })
            .filter(Boolean) ?? []
        );
      });

      return [filteredProvinces, crudAccess];
    }
    return [[], []];
  }, [user, access, provinceList, citiesList]);
  if (!provinceList || !citiesList) {
    return null;
  }

  const [filteredProvinceList, filteredCrudList] =
    getParentProvincesAndCrudAccess;

  return (
    <>
      <ReactModal
        style={{
          content: {
            maxWidth: 800,
            maxHeight: 500,
            margin: "auto"
          }
        }}
        {...rest}
        isOpen={isOpen}
        onRequestClose={onClose}
        ariaHideApp={false}>
        <div className='auth-modal'>
          <h5 className='title'>
            لیست دسترسی های {access} کاربر {user.fullName}
          </h5>{" "}
          <AuthGuide />
          <div className='auth-access'>
            <div>
              <i className='fas fa-user-lock question-icon'></i>{" "}
              <span>دسترسی</span>
            </div>
            <div>
              <div>
                {filteredProvinceList.map((province, idx) => {
                  return (
                    <div key={province._id}>
                      <>
                        <div>
                          <span className='chip'> {province.name}</span>
                        </div>
                        {filteredCrudList[idx]!.map((row, cityIndex) => {
                          return (
                            <span key={cityIndex} className='chip'>
                              {row?.city.name}{" "}
                              {row?.crud?.includes(CRUD_TYPES.WRITE) && (
                                <i className='fas fa-plus-circle add-icon small-icon'></i>
                              )}
                              {row?.crud?.includes(CRUD_TYPES.EDIT) && (
                                <i
                                  title='ویرایش'
                                  className='fas fa-pen edit-icon small-icon'></i>
                              )}
                              {row?.crud?.includes(CRUD_TYPES.DELETE) && (
                                <i
                                  title='حذف'
                                  className='fas fa-trash delete-icon small-icon'></i>
                              )}
                            </span>
                          );
                        })}
                      </>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* {children} */}
      </ReactModal>
    </>
  );
};

const AuthModalByCities = memo(_AuthModalByCities);

export { AuthModalByCities };
