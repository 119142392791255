import { DashboardFaultType, DashboardTaskType } from "../../types";
import { DashboardActionTypes } from "../actionTypes";
import { DASHBOARD_TYPES } from "../types";

interface StateType {
  faultsList: DashboardFaultType | null;
  tasksList: DashboardTaskType | null;
  loading: boolean;
  taskLastDate: Date;
  faultsLastDate: Date;
}

const initialState: StateType = {
  faultsList: null,
  tasksList: null,
  loading: false,
  taskLastDate: new Date(),
  faultsLastDate: new Date()
};

const reducer = (
  state: StateType = initialState,
  action: DashboardActionTypes
): StateType => {
  switch (action.type) {
    case DASHBOARD_TYPES.DASHBOARD_FAULTS_LISTS:
      return {
        ...state,
        faultsList: action.payload,
        loading: false,
        faultsLastDate: new Date()
      };

    case DASHBOARD_TYPES.DASHBOARD_TASKS_LISTS:
      return {
        ...state,
        tasksList: action.payload,
        loading: false,
        taskLastDate: new Date()
      };

    case DASHBOARD_TYPES.DASHBOARD_LOADING:
      return {
        ...state,
        loading: true
      };

    case DASHBOARD_TYPES.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

export default reducer;
