import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Profile from "./profile";
import Paps from "./paps";
import PhoneBook from "./phoneBook";
import Owas from "./owas";
import DataCenter from "./dataCenter";
import Faults from "./faults";
import Logs from "./logs";
import Tasks from "./tasks";
import Smss from "./sms";
import Dashbaord from "./dashboard";
import Loader from "react-spinners/BeatLoader";
const Users = React.lazy(() => import("./users"));
const Telecoms = React.lazy(() => import("./telecoms"));
const Cities = React.lazy(() => import("./cities"));
const Provinces = React.lazy(() => import("./provinces"));

const Main = () => {
  //TODO fix this
  return (
    <>
      <Routes>
        <Route
          path='provinces/*'
          element={
            <Suspense
              fallback={
                <div>
                  <Loader color='' />
                </div>
              }>
              <Provinces />
            </Suspense>
          }
        />
        <Route
          path='cities/*'
          element={
            <Suspense
              fallback={
                <div>
                  <Loader color='' />
                </div>
              }>
              <Cities />
            </Suspense>
          }
        />
        <Route
          path='telecoms/*'
          element={
            <Suspense
              fallback={
                <div>
                  <Loader color='' />
                </div>
              }>
              <Telecoms />
            </Suspense>
          }
        />
        <Route path='profile/*' element={<Profile />} />
        <Route path='paps/*' element={<Paps />} />
        <Route path='phone-books/*' element={<PhoneBook />} />
        <Route path='owas/*' element={<Owas />} />
        <Route path='data-centers/*' element={<DataCenter />} />
        <Route path='faults/*' element={<Faults />} />
        <Route
          path='users/*'
          element={
            <Suspense
              fallback={
                <div>
                  <Loader color='' />
                </div>
              }>
              <Users />
            </Suspense>
          }
        />
        <Route path='logs/*' element={<Logs />} />
        <Route path='tasks/*' element={<Tasks />} />
        <Route path='sms/*' element={<Smss />} />
        <Route path='dashboard/*' element={<Dashbaord />} />
        <Route path='*' element={<Navigate to='dashboard' />} />
      </Routes>
    </>
  );
};

export default Main;
