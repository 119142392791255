import {
  AdvancedResult,
  CitiesType,
  ProvincesType,
  TelecomsType,
  UserType
} from "../../types";
import { UserActionTypes } from "../actionTypes";
import { USER_TYPES } from "../types";

interface StateType {
  list: AdvancedResult<UserType> | null;
  item: UserType | null;
  accessList: string[];
  loading: boolean;
  provinceList: AdvancedResult<ProvincesType> | null;
  citiesList: AdvancedResult<CitiesType> | null;
  telecomsList: AdvancedResult<TelecomsType> | null;
}

const initialState: StateType = {
  list: null,
  item: null,
  loading: false,
  accessList: [],
  provinceList: null,
  citiesList: null,
  telecomsList: null
};

const reducer = (
  state: StateType = initialState,
  action: UserActionTypes
): StateType => {
  switch (action.type) {
    case USER_TYPES.USER_LISTS:
      return {
        ...state,
        list: action.payload,
        loading: false,
        item: null
      };

    case USER_TYPES.USER:
      return {
        ...state,
        item: action.payload,
        loading: false
      };

    case USER_TYPES.USER_UPLOAD:
      let foundIndex = state.list?.data.findIndex((user) => {
        return user._id?.toString === action.payload._id?.toString();
      });

      if (foundIndex && foundIndex > -1) {
        let _state = Object.assign({}, state);
        _state.list!.data[foundIndex] = action.payload;
        return {
          ..._state,
          loading: false
        };
      }

      return {
        ...state,
        loading: false
      };

    case USER_TYPES.USER_LOADING:
      return {
        ...state,
        loading: true
      };

    case USER_TYPES.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

export default reducer;
