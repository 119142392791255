import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../../core/components";
import { useActions, useTypedSelector } from "../../../../../core/hooks";
import { MiscellaneousSchema } from "../../../../../core/types";

const EditMiscellaneous = () => {
  let [searchParams] = useSearchParams();
  const papId = searchParams.get("papId");
  const miscellaneousId = searchParams.get("miscellaneousId");
  const { editMiscellaneous, fetchOnePap } = useActions();
  const { loading, item } = useTypedSelector((state) => state.pap);

  const selectedMiscellaneous = useMemo(() => {
    return (
      item &&
      miscellaneousId &&
      item?.miscellaneous?.find(
        (miscellaneous) => miscellaneous._id === miscellaneousId
      )
    );
  }, [item, miscellaneousId]);

  const formik = useFormik({
    initialValues: {
      title:
        selectedMiscellaneous && selectedMiscellaneous?.title
          ? selectedMiscellaneous.title
          : "",
      rackNumber:
        selectedMiscellaneous && selectedMiscellaneous?.rackNumber
          ? selectedMiscellaneous.rackNumber
          : "",
      propertyNumber:
        selectedMiscellaneous && selectedMiscellaneous?.propertyNumber
          ? selectedMiscellaneous.propertyNumber
          : "",
      brandName:
        selectedMiscellaneous && selectedMiscellaneous?.brandName
          ? selectedMiscellaneous.brandName
          : "",
      dimension:
        selectedMiscellaneous && selectedMiscellaneous?.dimension
          ? selectedMiscellaneous.dimension
          : "",
      serialNumber:
        selectedMiscellaneous && selectedMiscellaneous?.serialNumber
          ? selectedMiscellaneous.serialNumber
          : ""
    },
    validationSchema: yup.object({
      title: yup.string().required("عنوان ایتم متفرقه الزامی می باشد"),
      propertyNumber: yup.string().trim(),
      rackNumber: yup.number().required("شماره رک الزامی است"),
      brandName: yup.string().trim(),
      dimension: yup.string().trim(),
      serialNumber: yup.string().trim()
    }),
    onSubmit: ({
      title,
      rackNumber,
      propertyNumber,
      brandName,
      dimension,
      serialNumber
    }: MiscellaneousSchema) => {
      editMiscellaneous(
        {
          id: miscellaneousId,
          title,
          rackNumber,
          propertyNumber,
          brandName,
          dimension,
          serialNumber
        },
        item?._id ?? ""
      );
    }
  });
  useEffect(() => {
    if (papId) {
      fetchOnePap(papId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [papId]);

  const {
    title,
    rackNumber,
    propertyNumber,
    brandName,
    dimension,
    serialNumber
  } = formik.values;
  const {
    title: titleError,
    rackNumber: rackNumberError,
    brandName: brandNameError,
    dimension: dimensionError,
    serialNumber: serialNumberError,
    propertyNumber: propertyNumberError
  } = formik.errors;
  const {
    title: titleTouched,
    rackNumber: rackNumberTouched,
    brandName: brandNameTouched,
    dimension: dimensionTouched,
    serialNumber: serialNumberTouched,
    propertyNumber: propertyNumberTouched
  } = formik.touched;

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'>ویرایش آیتم متفرقه</h5>
        {item && item !== null && selectedMiscellaneous && (
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <Input
              name='title'
              autoComplete='off'
              error={titleTouched && titleError ? titleError : undefined}
              value={title}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='rackNumber'
              title=' شماره رک : '
              autoComplete='off'
              error={
                rackNumberTouched && rackNumberError
                  ? rackNumberError
                  : undefined
              }
              value={rackNumber}
              onChange={formik.handleChange}
              type='number'
            />

            <Input
              name='dimension'
              title='  ابعاد : '
              autoComplete='off'
              error={
                dimensionTouched && dimensionError ? dimensionError : undefined
              }
              value={dimension}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='brandName'
              title=' برند  : '
              autoComplete='off'
              error={
                brandNameTouched && brandNameError ? brandNameError : undefined
              }
              value={brandName}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='propertyNumber'
              title=' شماره اموال  : '
              autoComplete='off'
              error={
                propertyNumberTouched && propertyNumberError
                  ? propertyNumberError
                  : undefined
              }
              value={propertyNumber}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='serialNumber'
              title=' شماره سریال  : '
              autoComplete='off'
              error={
                serialNumberTouched && serialNumberError
                  ? serialNumberError
                  : undefined
              }
              value={serialNumber}
              onChange={formik.handleChange}
              type='text'
            />
            <SubmitButton
              title='ثبت'
              loading={loading}
              disabled={loading}
              type='submit'
            />
          </form>
        )}
      </div>
    </>
  );
};

export default EditMiscellaneous;
