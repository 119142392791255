import { ClientType, MongoObject, UserType } from ".";

export enum TelecomsTable {
  _ID = "_id",
  PROVINCEID = "provinceId",
  CITYID = "cityId",
  NAME = "نام مرکز",
  CITY = "شهر",
  PROVINCE = "استان",
  ABBREVIATION = "اختصار مرکز",
  CREATEDAT = "تاریخ ایجاد",
  UPDATEDAT = "تاریخ اخرین تغییر",
  ACTIONS = "عملیات",
  ROW = "ردیف"
}

export interface TelecomsSchema {
  referedId: string | ClientType;
  used: boolean;
  usedDate?: Date;
  amount?: number;
}

export interface RangeType {
  _id: string; // for add
  minRange: number;
  maxRange: number;
}

export interface ExpertType {
  expertId: string;
  _id: string;
}

export interface ExpertTypePopulated {
  expertId: UserType;
  _id: string;
}

export interface TelecomsType extends MongoObject {
  name: string;
  provinceId?: string;
  cityId?: string;
  abbreviation: string;
  createdAt?: Date;
  updatedAt?: Date;
  range?: Array<RangeType>;
  experts?: Array<ExpertTypePopulated>;
}
export interface TelecomCityType extends MongoObject {
  name: string;
  _id?: string;
  provinceId?: string;
}

export interface TelecomProvinceType extends MongoObject {
  name: string;
  _id?: string;
  provinceId?: string;
}

export interface AddTelecom {
  name: string;
  provinceId: string | undefined;
  cityId: string | undefined;
  abbreviation: string;
}
