import { AxiosRequestConfig } from "axios";
import { Dispatch } from "redux";
import { store, USER_ROOT_API, AUTH_RESET_PASSWORD_API } from "..";
import { getCatch } from "../../catch";
import { customizedAxios } from "../../config";
import { createFormData } from "../../constants";
import {
  AdvancedResult,
  CRUD_TYPES,
  InputObject,
  QueryParams,
  UploadType,
  UserAddtype,
  UserEditType,
  UserType
} from "../../types";
import { AuthActionTypes, UserActionTypes } from "../actionTypes";
import { AUTH_TYPES, USER_TYPES } from "../types";

export const editUser =
  (body: UserType, id: string) =>
  async (dispatch: Dispatch<UserActionTypes | AuthActionTypes>) => {
    try {
      dispatch({ type: USER_TYPES.USER_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = USER_ROOT_API.concat("/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: USER_TYPES.DISABLE_LOADING
      });
      dispatch({
        type: AUTH_TYPES.LOGOUT
      });

      window.openToastSuccess(
        " پروفایل با موفقیت ویرایش شد لطفا مجددا وارد شوید."
      );
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: USER_TYPES.DISABLE_LOADING });
    }
  };

export const fetchUsers =
  (query: QueryParams) => async (dispatch: Dispatch<UserActionTypes>) => {
    try {
      dispatch({ type: USER_TYPES.USER_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: query
      };

      let url = USER_ROOT_API;
      const res = await customizedAxios.get<AdvancedResult<UserType>>(
        url,
        config
      );
      dispatch({
        type: USER_TYPES.USER_LISTS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: USER_TYPES.DISABLE_LOADING });
    }
  };

export const addUser =
  (body: UserAddtype) => async (dispatch: Dispatch<UserActionTypes>) => {
    try {
      dispatch({ type: USER_TYPES.USER_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = USER_ROOT_API;
      await customizedAxios.post(url, body, config);

      dispatch({
        type: USER_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" کاربر با موفقیت افزوده شد.");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: USER_TYPES.DISABLE_LOADING });
    }
  };

export const fetchOneUser =
  (id: string) => async (dispatch: Dispatch<UserActionTypes>) => {
    try {
      dispatch({ type: USER_TYPES.USER_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = USER_ROOT_API.concat("/" + id);
      const res = await customizedAxios.get<InputObject<UserType>>(url, config);
      dispatch({
        type: USER_TYPES.USER,
        payload: res.data.data!
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: USER_TYPES.DISABLE_LOADING });
    }
  };

export const editUserParent =
  (body: UserEditType, id: string) =>
  async (dispatch: Dispatch<UserActionTypes>) => {
    try {
      dispatch({ type: USER_TYPES.USER_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = USER_ROOT_API.concat("/" + id);
      await customizedAxios.patch(url, body, config);

      dispatch({
        type: USER_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("کاربر با موفقیت ویرایش شد");
      if (!body.status) window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: USER_TYPES.DISABLE_LOADING });
    }
  };

export const changePasswordUser =
  (body: { password: string }, id: string) =>
  async (dispatch: Dispatch<UserActionTypes>) => {
    try {
      dispatch({ type: USER_TYPES.USER_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = AUTH_RESET_PASSWORD_API.concat("/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: USER_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("رمز عبور با موفقیت تغییر یافت");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: USER_TYPES.DISABLE_LOADING });
    }
  };

export const changeAuthorization =
  (
    body:
      | { allow: boolean; crud: CRUD_TYPES[] }
      | {
          allow: boolean;
          provinces: { provinceId: string; crud: CRUD_TYPES[] }[];
        }
      | {
          allow: boolean;
          provinces: {
            provinceId: string;
            cities?: { cityId: string; crud: CRUD_TYPES[] }[];
            telecoms?: { telecomId: string; crud: CRUD_TYPES[] }[];
          }[];
        },

    endPoint: string,
    id: string
  ) =>
  async (dispatch: Dispatch<UserActionTypes>) => {
    try {
      dispatch({ type: USER_TYPES.USER_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = endPoint.concat("/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: USER_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("دسترسی با موفقیت تغییر یافت");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: USER_TYPES.DISABLE_LOADING });
    }
  };

export const uploadUserPhoto =
  (file: File) => async (dispatch: Dispatch<UserActionTypes>) => {
    try {
      dispatch({ type: USER_TYPES.USER_LOADING });

      let bodyData = createFormData(file, {});

      const config: AxiosRequestConfig = {
        headers: {
          "Content-Type": "multipart/form-data; boundary=form._boundary",
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = USER_ROOT_API.concat("/photo");
      await customizedAxios.put<UploadType<UserType>>(url, bodyData, config);
      dispatch({
        type: USER_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("تصویر با موفقیت افزوده شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: USER_TYPES.DISABLE_LOADING });
    }
  };
