import { USER_ACCESS } from "../../constants";

const AUTH_LOGIN_API = `auth/login`;
const AUTH_RESET_PASSWORD_API = `auth/resetPassword`;
const AUTH_CHANGE_PASSWORD_API = `auth/changePassword`;
const AUTH_FORGET_PASSWORD_API = "auth/forgetPassword";
const AUTH_ME_API = `users/me`;

const AUTHORIZATION_API: { [key in USER_ACCESS]: string } = {
  [USER_ACCESS.PROVINCE]: "/authorization/section/province",
  [USER_ACCESS.LOGS]: "/authorization/section/logs",
  [USER_ACCESS.FAULTS_INSERT]: "/authorization/section/faults/insert",
  [USER_ACCESS.FAULTS_READ]: "/authorization/section/faults/read",
  [USER_ACCESS.FAULTS_READ_ALL]: "/authorization/section/faults/readAll",
  [USER_ACCESS.TASKS_READ]: "/authorization/section/tasks/read",
  [USER_ACCESS.TASKS_READ_ALL]: "/authorization/section/tasks/readAll",
  [USER_ACCESS.CITY]: "/authorization/province/city",
  [USER_ACCESS.COMMENT]: "/authorization/province/comment",
  [USER_ACCESS.DATA_CENTER]: "/authorization/province/dataCenter",
  [USER_ACCESS.OWA]: "/authorization/province/owa",
  [USER_ACCESS.PHONEBOOK]: "/authorization/province/phonebook",
  [USER_ACCESS.TELECOM]: "/authorization/city/telecom",
  [USER_ACCESS.PAPS]: "/authorization/telecom/paps",
  [USER_ACCESS.SMS]: "/authorization/section/sms"
};

export {
  AUTH_LOGIN_API,
  AUTH_ME_API,
  AUTH_CHANGE_PASSWORD_API,
  AUTHORIZATION_API,
  AUTH_FORGET_PASSWORD_API,
  AUTH_RESET_PASSWORD_API
};
