import { CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import copy from "copy-to-clipboard";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  DeleteModalContent,
  DetailModalContent,
  NoItem,
  SubmitButton
} from "../../../../../../core/components";
import { USER_ACCESS } from "../../../../../../core/constants";
import { defaultColumnDefinition } from "../../../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../../../core/hooks";
import { BASE_API, PAPS_ROOT_API } from "../../../../../../core/redux";
import { CRUD_TYPES, TelecomsType } from "../../../../../../core/types";
import {
  LineCardSchema,
  LineCardsTable
} from "../../../../../../core/types/PapsTypes/ShelfTypes/LineCardTypes";
import {
  checkUserAccessByTelecomCrud,
  fileDownload
} from "../../../../../../core/utility";

const LineCardsList = () => {
  let [searchParams] = useSearchParams();
  const papId = searchParams.get("papId");
  const shelfId = searchParams.get("shelfId");
  const { fetchOnePap, deleteLineCard } = useActions();
  const { loading, item } = useTypedSelector((state) => state.pap);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<LineCardSchema | null>(null);
  const navigator = useNavigate();
  const { me } = useTypedSelector((state) => state.auth);
  const { token } = useTypedSelector((state) => state.auth);

  const selectedShelf = useMemo(() => {
    return (
      item && shelfId && item?.shelves?.find((shelf) => shelf._id === shelfId)
    );
  }, [item, shelfId]);

  useEffect(() => {
    if (papId) {
      fetchOnePap(papId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [papId]);

  const onSuccessDeleteModal = useCallback(() => {
    if (shelfId && papId) {
      deleteLineCard(selectedItem?._id as string, shelfId, papId);
      setIsOpen(false);
      setSelectedItem(null);
    }
  }, [selectedItem, deleteLineCard, papId, shelfId]);

  const rowData = useCallback(() => {
    if (selectedShelf) {
      const lineCard = selectedShelf?.lineCards;
      if (lineCard !== undefined) {
        return lineCard?.map((item, index) => {
          return {
            [LineCardsTable.ROW]: `${index + 1}`,
            [LineCardsTable._ID]: item._id,
            [LineCardsTable.CARDNUMBER]: item.cardNumber,
            [LineCardsTable.COMMENT]: item.comment,
            [LineCardsTable.PROPERTYNUMBER]: item.propertyNumber,
            [LineCardsTable.SERIALNUMBER]: item.serialNumber,
            [LineCardsTable.INSTALLERS]: item.installers
          };
        });
      }
    }
    return [];
  }, [selectedShelf]);

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          <span
            onClick={() => {
              setSelectedItem(cellEvent?.data);
              setIsCommentModalOpen(true);
            }}>
            <i title='توضیحات' className='fas fa-comment comment-icon'></i>
          </span>
          {me &&
            item &&
            checkUserAccessByTelecomCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.PAPS,
              CRUD_TYPES.EDIT,
              item.provinceId!,
              (item.telecomId as unknown as TelecomsType)?._id!
            ) && (
              <span
                onClick={() => {
                  navigator(
                    `edit?papId=${papId}&shelfId=${shelfId}&lineCardId=${cellEvent?.data[
                      LineCardsTable._ID
                    ].toString()}`
                  );
                }}>
                <i title='ویرایش' className='fas fa-pen edit-icon'></i>
              </span>
            )}
          {me &&
            item &&
            checkUserAccessByTelecomCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.PAPS,
              CRUD_TYPES.DELETE,
              item.provinceId!,
              (item.telecomId as unknown as TelecomsType)?._id!
            ) && (
              <span
                onClick={() => {
                  const _lineCard =
                    selectedShelf &&
                    selectedShelf?.lineCards?.find((lineCard) => {
                      return (
                        lineCard._id?.toString() ===
                        cellEvent?.data[LineCardsTable._ID].toString()
                      );
                    });

                  _lineCard && setSelectedItem(_lineCard);
                  setIsOpen(true);
                }}>
                <i title='حذف' className='fas fa-trash delete-icon'></i>
              </span>
            )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [papId, selectedShelf, shelfId]
  );

  return (
    <>
      <DeleteModalContent
        isOpen={isOpen}
        title={" حذف لاین کارت " + selectedItem?.cardNumber}
        message='آیا از حذف  لاین کارت  مطمئن هستید؟'
        onCancel={() => {
          setIsOpen(false);
          setSelectedItem(null);
        }}
        onSuccess={onSuccessDeleteModal}
      />
      <DetailModalContent
        content={selectedItem}
        isOpen={isCommentModalOpen}
        title={"توضیحات لاین کارت " + selectedItem?.cardNumber}
        message={
          selectedItem?.comment
            ? selectedItem?.comment
            : "توضیحی درباره این لاین کارت ثبت نشده است."
        }
        onClose={() => {
          setIsCommentModalOpen(false);
          setSelectedItem(null);
        }}
      />

      <div className='main-route-wrapper'>
        {" "}
        <div className='table-wrapper'>
          <div className='list-top'>
            <p>
              تعداد کل لاین کارت های شلف
              {selectedShelf && selectedShelf.shelfNumber} پپ{" "}
              {item && item.type} مرکز{" "}
              {item && (item.telecomId as unknown as TelecomsType)?.name} :
              <span>
                {selectedShelf && selectedShelf.lineCards?.length} عدد
              </span>{" "}
              {item && selectedShelf && (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    const url = BASE_API + PAPS_ROOT_API;
                    fileDownload(url, token ?? "", "lineCards.xlsx", {
                      xlsx: true,
                      _id: item._id,
                      "shelves._id": selectedShelf._id,
                      select: "shelves"
                    });
                  }}
                  className='fas fa-file-excel excel-icon'></i>
              )}
            </p>
            {me &&
              item &&
              checkUserAccessByTelecomCrud(
                me,
                me?.access ?? [],
                USER_ACCESS.PAPS,
                CRUD_TYPES.EDIT,
                item.provinceId!,
                (item.telecomId as unknown as TelecomsType)?._id!
              ) && (
                <Link to={"./add?papId=" + papId + "&shelfId=" + shelfId}>
                  <SubmitButton
                    loading={loading}
                    title='اضافه کردن لاین کارت جدید'
                  />
                </Link>
              )}
          </div>

          {(!selectedShelf ||
            !selectedShelf.lineCards ||
            selectedShelf.lineCards.length === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}

          {selectedShelf && selectedShelf !== null && (
            <div>
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  animateRows={true}
                  onCellClicked={(e) => {
                    if (e.colDef.field !== LineCardsTable.ACTIONS)
                      copy(e.value);
                  }}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  rowSelection={"single"}
                  enableRtl={true}>
                  <AgGridColumn
                    sortable={true}
                    field={LineCardsTable.ROW}
                    width={84}
                  />
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={LineCardsTable.ACTIONS}
                    width={140}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={LineCardsTable.CARDNUMBER}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={LineCardsTable.PROPERTYNUMBER}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={LineCardsTable.SERIALNUMBER}
                    minWidth={120}
                    flex={1}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={LineCardsTable.INSTALLERS}
                    minWidth={120}
                    flex={1}
                  />
                </AgGridReact>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LineCardsList;
