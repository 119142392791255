/* eslint-disable array-callback-return */
import {
  AdvancedResult,
  PapProvincesType,
  PapsType,
  PapTelecomsType
} from "../../types";
import {
  LineCardsActionTypes,
  MiscellaneousActionTypes,
  PapsActionTypes,
  PassivesActionTypes,
  RacksActionTypes,
  ShelvesActionTypes
} from "../actionTypes/PapActionTypes";
import {
  PAP_TYPES,
  SHELF_TYPES,
  RACK_TYPES,
  MISCELLANEOUS_TYPES,
  LINE_CARDS_TYPES,
  PASSIVE_TYPES
} from "../types";

interface StateType {
  list: AdvancedResult<PapsType> | null;
  item: PapsType | null;
  imageItem: PapsType | null;
  loading: boolean;
  telecomsLoading: boolean;
  telecoms: AdvancedResult<PapTelecomsType> | null;
  provincesLoading: boolean;
  provinces: AdvancedResult<PapProvincesType> | null;
}

const initialState: StateType = {
  list: null,
  item: null,
  loading: false,
  telecomsLoading: false,
  telecoms: null,
  provincesLoading: false,
  provinces: null,
  imageItem: null
};

const reducer = (
  state: StateType = initialState,
  action:
    | PapsActionTypes
    | ShelvesActionTypes
    | RacksActionTypes
    | PassivesActionTypes
    | MiscellaneousActionTypes
    | LineCardsActionTypes
): StateType => {
  switch (action.type) {
    case PAP_TYPES.PAP_LISTS:
      return {
        ...state,
        list: action.payload,
        loading: false,
        item: null
      };

    case PAP_TYPES.PAP:
      return {
        ...state,
        item: action.payload,
        loading: false
      };
    case PAP_TYPES.PAP_UPLOAD:
      return {
        ...state,
        imageItem: { ...state.imageItem!, albums: action.payload },
        loading: false
      };

    case PAP_TYPES.PAP_LOADING:
      return {
        ...state,
        loading: true
      };

    case PAP_TYPES.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };
    case PAP_TYPES.PAP_TELECOMS_LOADING:
      return {
        ...state,
        telecomsLoading: true
      };
    case PAP_TYPES.PAP_TELECOMS:
      return {
        ...state,
        telecoms: action.payload
      };
    case PAP_TYPES.DISABLE_PAP_TELECOMS_LOADING:
      return {
        ...state,
        telecomsLoading: false
      };
    case PAP_TYPES.PAP_PROVINCES_LOADING:
      return {
        ...state,
        provincesLoading: true
      };
    case PAP_TYPES.PAP_PROVINCES:
      return {
        ...state,
        provinces: action.payload
      };
    case PAP_TYPES.DISABLE_PAP_PROVINCES_LOADING:
      return {
        ...state,
        provincesLoading: false
      };
    case PAP_TYPES.RESET_IMAGE_ITEM:
      return {
        ...state,
        imageItem: null
      };
    case PAP_TYPES.SELECTED_PAP_IMAGES:
      return {
        ...state,
        imageItem: action.payload
      };

    case SHELF_TYPES.SHELF_DELETE:
      let _item = state.item;
      _item!.shelves = _item?.shelves?.filter((shelf) => {
        return shelf?._id?.toString() !== action.payload._id?.toString();
      });
      return {
        ...state,
        item: _item,
        loading: false
      };
    case RACK_TYPES.RACK_DELETE:
      let __item = state.item;
      __item!.racks = __item?.racks?.filter((rack) => {
        return rack?._id?.toString() !== action.payload._id?.toString();
      });

      return {
        ...state,
        item: __item,
        loading: false
      };
    case PASSIVE_TYPES.PASSIVE_DELETE:
      let ___item = state.item;
      ___item!.passives = ___item?.passives?.filter((passive) => {
        return passive?._id?.toString() !== action.payload._id?.toString();
      });

      return {
        ...state,
        item: ___item,
        loading: false
      };

    case MISCELLANEOUS_TYPES.MISCELLANEOUS_DELETE:
      let ____item = state.item;
      ____item!.miscellaneous = ____item?.miscellaneous?.filter(
        (miscellaneous) => {
          return (
            miscellaneous?._id?.toString() !== action.payload._id?.toString()
          );
        }
      );

      return {
        ...state,
        item: ____item,
        loading: false
      };
    case LINE_CARDS_TYPES.LINE_CARD_DELETE:
      let _____item = state.item;
      _____item!.shelves = _____item?.shelves?.filter((shelf) => {
        shelf!.lineCards = shelf?.lineCards?.filter((lineCard) => {
          return lineCard?._id?.toString() !== action.payload._id?.toString();
        });
      });
      return {
        ...state,
        item: _____item,
        loading: false
      };

    default:
      return state;
  }
};

export default reducer;
