import { Route, Routes } from "react-router-dom";
import ProfileInfo from "./profileInfo";

const Profile = () => {
  return (
    <>
      <Routes>
        <Route path='' element={<ProfileInfo />} />
      </Routes>
    </>
  );
};

export default Profile;
