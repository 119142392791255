import { useFormik } from "formik";
import Select from "react-select";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../../core/components";
import {
  CABLE_TYPE_LIST,
  PASSIVE_TYPE_LIST
} from "../../../../../core/constants";
import { useActions, useTypedSelector } from "../../../../../core/hooks";
import { PassiveSchema } from "../../../../../core/types";

const AddPassive = () => {
  const { addPassive } = useActions();
  const { loading, item } = useTypedSelector((state) => state.pap);
  const formik = useFormik({
    initialValues: {
      row: "",
      floor: "",
      propertyNumber: "",
      terminalType: "",
      cableType: "",
      cableSize: "",
      installers: "",
      comment: "",
      installDate: ""
    },
    validationSchema: yup.object({
      row: yup.number().required("شماره ردیف الزامی است"),
      propertyNumber: yup.string().trim(),
      floor: yup.number().required("ردیف الزامی می باشد"),
      terminalType: yup.string().trim().required("نوع ترمینال را وارد نمایید"),
      cableSize: yup.string().trim(),
      cableType: yup.string().trim().required("نوع کابل را وارد نمایید"),
      installers: yup.string().trim(),
      comment: yup.string().trim(),
      installDate: yup.string().trim()
    }),
    onSubmit: ({
      row,
      floor,
      propertyNumber,
      terminalType,
      cableType,
      cableSize,
      installers,
      comment,
      installDate
    }: PassiveSchema) => {
      addPassive(
        {
          row,
          floor,
          propertyNumber,
          terminalType,
          cableType,
          cableSize,
          installDate,
          installers,
          comment
        },
        item?._id ?? ""
      );
    }
  });

  const {
    row,
    floor,
    propertyNumber,
    installers,
    cableSize,
    comment,
    installDate
  } = formik.values;
  const {
    row: rowError,
    floor: floorError,
    installers: installersError,
    terminalType: terminalTypeError,
    cableSize: cableSizeError,
    cableType: cableTypeError,
    propertyNumber: propertyNumberError,
    comment: commentError,
    installDate: installDateError
  } = formik.errors;
  const {
    row: rowTouched,
    floor: floorTouched,
    installers: installersTouched,
    terminalType: terminalTypeTouched,
    cableSize: cableSizeTouched,
    cableType: cableTypeTouched,
    propertyNumber: propertyNumberTouched,
    comment: commentTouched,
    installDate: installDateTouched
  } = formik.touched;

  const { setValues } = formik;

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'>اضافه کردن بوخت جدید</h5>
        <form autoComplete='on' onSubmit={formik.handleSubmit}>
          <Input
            name='row'
            title=' شماره ردیف : '
            autoComplete='off'
            error={rowTouched && rowError ? rowError : undefined}
            value={row}
            onChange={formik.handleChange}
            type='number'
          />
          <Input
            name='floor'
            title=' طبقه : '
            autoComplete='off'
            error={floorTouched && floorError ? floorError : undefined}
            value={floor}
            type='number'
            onChange={formik.handleChange}
          />
          <div className='select-wrapper--high '>
            <label>نوع ترمینال :</label>
            <Select
              onChange={(e) => {
                setValues({ ...formik.values, terminalType: e?.value ?? "" });
              }}
              isClearable
              placeholder='نوع ترمینال مربوطه را انتخاب کنید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={PASSIVE_TYPE_LIST}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {terminalTypeTouched && terminalTypeError && (
              <p className='input-error--select'>{terminalTypeError}</p>
            )}
          </div>
          <div className='select-wrapper--high '>
            <label>نوع کابل :</label>
            <Select
              onChange={(e) => {
                setValues({ ...formik.values, cableType: e?.value ?? "" });
              }}
              isClearable
              placeholder='نوع کابل مربوطه را انتخاب کنید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={CABLE_TYPE_LIST}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {cableTypeTouched && cableTypeError && (
              <p className='input-error--select'>{cableTypeError}</p>
            )}
          </div>
          <Input
            name='cableSize'
            title=' متراژ کابل : '
            autoComplete='off'
            type='number'
            error={
              cableSizeTouched && cableSizeError ? cableSizeError : undefined
            }
            value={cableSize}
            onChange={formik.handleChange}
          />
          <Input
            name='installers'
            title='نصاب ها  : '
            autoComplete='off'
            error={
              installersTouched && installersError ? installersError : undefined
            }
            value={installers}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='propertyNumber'
            title=' شماره اموال  : '
            autoComplete='off'
            error={
              propertyNumberTouched && propertyNumberError
                ? propertyNumberError
                : undefined
            }
            value={propertyNumber}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='installDate'
            title='تاریخ نصب : '
            autoComplete='off'
            error={
              installDateTouched && installDateError
                ? installDateError
                : undefined
            }
            value={installDate}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='comment'
            title='توضیحات : '
            multiline
            autoComplete='off'
            error={commentTouched && commentError ? commentError : undefined}
            value={comment}
            onChange={formik.handleChange}
            type='text'
          />

          <SubmitButton
            title='ثبت'
            loading={loading}
            disabled={loading}
            type='submit'
          />
        </form>
      </div>
    </>
  );
};

export default AddPassive;
