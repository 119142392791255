import { MongoObject } from "..";
import { DCItemSchema, DCRackSchema, AlbumSchema } from "..";

export enum DataCenterTable {
  _ID = "_id",
  NAME = "نام ",
  PROVINCEID = "provinceId",
  PROVINCE = "استان ",
  ITEMS = "آیتم ها",
  RACKS = "رک ها",
  ADDRESS = " آدرس",
  ACTIONS = "عملیات",
  ROW = "ردیف"
}

export interface DataCenterType extends MongoObject {
  name?: string;
  provinceId?: string;
  province?: string;
  address?: string | undefined;
  albums?: Array<AlbumSchema>;
  items?: Array<DCItemSchema>;
  racks?: Array<DCRackSchema>;
}

export interface DataCenterProvincesType extends MongoObject {
  _id?: string;
  provinceId?: string;
  name: string;
}

export interface AddDataCenter {
  name?: string;
  provinceId?: string;
  address?: string | undefined;
}
