import { MongoObject } from ".";
import { ProvincesSchema } from "./ProvincesTypes";

export enum ClientTable {
  _ID = "_id",
  EMAIL = "ایمیل",
  MOBILENUMBER = "شماره همراه",
  STATUS = "وضعیت",
  NAME = "نام",
  LASTNAME = "نام خانوادگی",
  CREATEDAT = "تاریخ ثبت نام"
}

export enum ClientStatus {
  ACTIVE = "active",
  PENDING = "pending"
}

export interface ClientType extends MongoObject {
  email: string;
  mobileNumber: string;
  photo?: string;
  status: ClientStatus;
  name: string;
  lastName: string;
  address?: ClientAddressType[];
  defaultAddressIndex?: number;
  refererCode: string;
  refered?: ProvincesSchema;
  refrenceList?: ProvincesSchema[];
}
export interface ClientAddressType extends MongoObject {
  address: string;
  mobileNumber: string;
  fullName: string;
  postalCode?: string;
  phone?: string;
}

export type EditClientType = Omit<Omit<ClientType, "status">, "refererCode">;
