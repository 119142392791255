import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { HeaderLogin, Input, SubmitButton } from "../../core/components";
import { useActions, useTypedSelector } from "../../core/hooks";

const Login = () => {
  const { loginAction } = useActions();
  const { authLoading } = useTypedSelector((state) => state.authLoading);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: ""
    },
    validationSchema: yup.object({
      username: yup.string().required("ایمیل یا موبایل الزامی است"),
      password: yup.string().required("رمز عبور الزامی است")
    }),
    onSubmit: (values) => {
      loginAction(values);
    }
  });

  const { username, password } = formik.values;
  const { username: usernameError, password: passwordError } = formik.errors;
  const { username: usernameTouched, password: passwordTouched } =
    formik.touched;

  return (
    <>
      <HeaderLogin />
      <main className='main-login'>
        <div className='main-login__wrapper'>
          <div className='header-logo'>
            <div className='header-logo__wrapper'>
              <Link to={"#"}>
                <img src='/asiatech-logo.png' alt='لوگو آسیاتک' />
              </Link>
            </div>
          </div>
          <h4>خوش آمدید!</h4>
          <h6>
            برای ورود به پنل کاربری لطفا نام کاربری و رمز عبور را وارد نمایید.
            می توانید از شماره همراه یا ایمیل خود به عنوان نام کاربری استفاده
            نماید
          </h6>
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <Input
              icon='fas fa-mobile-alt'
              name='username'
              title='نام کاربری : '
              autoComplete='off'
              error={
                usernameTouched && usernameError ? usernameError : undefined
              }
              value={username}
              onChange={formik.handleChange}
            />
            <Input
              icon='fas fa-key'
              name='password'
              title='رمز عبور : '
              type='password'
              error={
                passwordTouched && passwordError ? passwordError : undefined
              }
              value={password}
              onChange={formik.handleChange}
            />
            <SubmitButton
              title='ورود'
              loading={authLoading}
              disabled={authLoading}
              type='submit'
            />
          </form>
          <span>
            <Link to='/forget-password'>فراموشی رمز عبور؟</Link>
          </span>
        </div>
      </main>
    </>
  );
};

export { Login };
