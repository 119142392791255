import React from "react";

const AuthGuide = () => {
  return (
    <>
      <div className='auth-help'>
        <div>
          <i className='far fa-question-circle question-icon'></i>{" "}
          <span>راهنما</span>
        </div>
        <div>
          <i className='fas fa-plus-circle add-icon'></i>{" "}
          <span>اضافه کردن</span>
          <i title='ویرایش' className='fas fa-pen edit-icon'></i>{" "}
          <span>ویرایش کردن</span>
          <i title='حذف' className='fas fa-trash delete-icon'></i>{" "}
          <span>حذف کردن</span>
        </div>
      </div>
    </>
  );
};

export { AuthGuide };
