import { MongoObject } from "./BaseTypes";

export enum NotificationsTable {
  ROW = "ردیف",
  TITLE = "عنوان",
  DESCRIPTION = "توضیحات",
  SENDER = "ارسال کننده",
  RECIEVER = "دریافت کننده",
  SENDDATE = "تاریخ ارسال",
  ACTION = "عملیات"
}

export interface NotificationType extends MongoObject {
  receiveSms: boolean;
  pushWebNotification: boolean;
}

export interface SmsType extends MongoObject {
  category: string;
  _id: string;
  messages: SmsMessageType[];
}

export interface SmsMessageType {
  message: string;
  _id: string;
}

export interface AddSubmitSmsSendTypes {
  receiver: string;
  description: string;
}

export interface AddSubmitSmsCategoryTypes {
  category: string;
}

export interface AddSubmitSmsTypes {
  message: string;
}

export interface EditSmsCategoryTypes {
  category: string;
}

export interface EditSmsTypes {
  message: string;
  id: string;
}
