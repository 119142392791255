export enum FAULT_TYPES {
  TEMP_FAULTS_LIST = "TEMP_FAULTS_LIST",
  TEMP_FAULT_ITEM = "TEMP_FAULT_ITEM",
  FAULT_STATUS_ITEM = "FAULT_STATUS_ITEM",
  FAULT_ITEM = "FAULT_ITEM",
  CLONING_FAULT_ITEM = "CLONING_FAULT_ITEM",
  FAULT_ASSIGNABLE_USERS = "FAULT_ASSIGNABLE_USERS",
  ADD_FAULT = "ADD_FAULT",
  DELETE_TEMP_FAULT = "DELETE_TEMP_FAULT",
  TEMP_FAULT_LOADING = "TEMP_FAULT_LOADING",
  FAULTS_LIST = "FAULTS_LIST",
  FAULT_LOADING = "FAULT_LOADING",
  DISABLE_LOADING = "DISABLE_LOADING",
  FAULT_UPLOAD = "FAULT_UPLOAD",
  SELECTED_FAULT_IMAGES = "SELECTED_FAULT_IMAGES",
  RESET_IMAGE_ITEM = "RESET_IMAGE_ITEM",
  FAULTS_LIST_STATICS = "FAULTS_LIST_STATICS",
  FAULTS_LIST_USER_STATICS = "FAULTS_LIST_USER_STATICS",
  FAULT_CLEAR_TEMP_ITEM = "FAULT_CLEAR_TEMP_ITEM",
  FAULT_STATUS_ITEM_BY_PHONE = "FAULT_STATUS_ITEM_BY_PHONE",
  RESET_FAULT_STATUS_ITEM_BY_PHONE = "RESET_FAULT_STATUS_ITEM_BY_PHONE"
}
