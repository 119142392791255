import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import * as yup from "yup";
import { HeaderLogin, Input, SubmitButton } from "../../core/components";
import { useSearchParams } from "react-router-dom";
import { useActions, useTypedSelector } from "../../core/hooks";
import { resetPasswordTimeout } from "../../core/constants";
import { getFutureTime } from "../../core/utility";

const ResetPassword = () => {
  let [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");
  const { resetPassword, regenerateCode } = useActions();
  const { authLoading } = useTypedSelector((state) => state.authLoading);

  const formik = useFormik({
    initialValues: {
      code: "",
      password: "",
      passwordConfirmation: ""
    },
    validationSchema: yup.object({
      code: yup.number().required("کد تایید الزامی است"),
      password: yup.string().required("رمز عبور الزامی است"),
      passwordConfirmation: yup
        .string()
        .required("تکرار رمز عبور الزامی است")
        .oneOf([yup.ref("password")], "تکرار رمز عبور صحیح نیست")
    }),
    onSubmit: (values) => {
      resetPassword(values);
    }
  });

  const { code, password, passwordConfirmation } = formik.values;
  const {
    code: confirmCodeError,
    password: passwordError,
    passwordConfirmation: passwordConfirmationError
  } = formik.errors;
  const {
    code: confirmCodeTouched,
    password: passwordTouched,
    passwordConfirmation: passwordConfirmationTouched
  } = formik.touched;

  const { seconds, minutes, isRunning, restart } = useTimer({
    expiryTimestamp: getFutureTime(resetPasswordTimeout)
  });

  return (
    <>
      <HeaderLogin />
      <main className='main-login'>
        <div className='main-login__wrapper'>
          <div className='header-logo'>
            <div className='header-logo__wrapper'>
              <Link to={"#"}>
                <img src='/asiatech-logo.png' alt='لوگو آسیاتک' />
              </Link>
            </div>
          </div>
          <h4>تغییر رمز عبور</h4>
          <h6>
            برای بازیابی رمز عبور کد ارسالی را به همراه رمز عبور جدید وارد
            نمایید
          </h6>
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <Input
              type='number'
              icon='far fa-comment-alt'
              name='code'
              title=' کد ارسالی : '
              autoComplete='off'
              error={
                confirmCodeTouched && confirmCodeError
                  ? confirmCodeError
                  : undefined
              }
              value={code}
              onChange={formik.handleChange}
            />
            <Input
              icon='fas fa-key'
              name='password'
              title='رمز عبور جدید : '
              type='password'
              error={
                passwordTouched && passwordError ? passwordError : undefined
              }
              value={password}
              onChange={formik.handleChange}
            />
            <Input
              icon='fas fa-key'
              name='passwordConfirmation'
              title='تکرار رمز عبور جدید : '
              type='password'
              error={
                passwordConfirmationTouched && passwordConfirmationError
                  ? passwordConfirmationError
                  : undefined
              }
              value={passwordConfirmation}
              onChange={formik.handleChange}
            />
            <SubmitButton
              title='ثبت'
              loading={authLoading}
              disabled={authLoading}
              type='submit'
            />
            <div className='timer-wrapper'>
              <p>
                {minutes}:{seconds}
              </p>
              <button
                className='timer-restart-btn'
                type='button'
                onClick={(e) => {
                  e.preventDefault();
                  _id && regenerateCode(_id);
                  restart(getFutureTime(resetPasswordTimeout), true);
                }}
                disabled={isRunning ? true : false}>
                ارسال دوباره کد
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export { ResetPassword };
