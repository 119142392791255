import { format } from "date-fns";
import timeSince from "./fromNowTime";
import { faIR } from "date-fns/locale";

export const getSinceTimeWithHour = (date: Date) =>
  ` ( ${timeSince(new Date(date))} قبل - ${new Date(
    date ?? ""
  ).toLocaleDateString("fa-IR")} -    ${format(date, "HH:mm", {
    locale: faIR
  })} )`;
