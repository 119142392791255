import { CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import copy from "copy-to-clipboard";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  DeleteModalContent,
  DetailModalContent,
  NoItem,
  SubmitButton
} from "../../../../../core/components";
import { USER_ACCESS } from "../../../../../core/constants";
import { defaultColumnDefinition } from "../../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../../core/hooks";
import { BASE_API, OWA_ROOT_API } from "../../../../../core/redux";
import { CRUD_TYPES, RadioSchema, RadioTable } from "../../../../../core/types";
import {
  checkUserAccessByProvinceCrud,
  fileDownload
} from "../../../../../core/utility";

const RadiosList = () => {
  let [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");
  const { fetchOneOwa, deleteRadio, editRadio } = useActions();
  const { loading, item } = useTypedSelector((state) => state.owa);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenCollect, setIsOpenCollect] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<RadioSchema | null>(null);
  const [selectedItemCollect, setSelectedItemCollect] = useState<string | null>(
    null
  );

  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);
  const { me } = useTypedSelector((state) => state.auth);
  const { token } = useTypedSelector((state) => state.auth);

  const navigator = useNavigate();

  useEffect(() => {
    if (_id) {
      fetchOneOwa(_id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const onSuccessDeleteModal = useCallback(() => {
    deleteRadio(selectedItem?._id as string, item?._id as string);

    setIsOpen(false);
    setSelectedItem(null);
  }, [selectedItem, deleteRadio, item?._id]);

  const rowData = useCallback(
    () => {
      const radios = item?.radios;
      if (item !== undefined) {
        return radios?.map((item, index) => {
          return {
            [RadioTable.ROW]: `${index + 1}`,
            [RadioTable._ID]: item._id,
            [RadioTable.CUSTOMERNAME]: item.customerName,
            [RadioTable.BRANDNAMEMODEL]: item.brandNameModel,
            [RadioTable.VIEW]: item.view,
            [RadioTable.LEG]: item.leg,
            [RadioTable.HEIGHT]: item.height,
            [RadioTable.PORT]: item.port,
            [RadioTable.DESCRIPTION]: item.description,
            [RadioTable.CABLESIZE]: item.cableSize,
            [RadioTable.CABLETYPE]: item.cableType,
            [RadioTable.COLLECTED]: item.collected ? "بله" : "خیر",
            [RadioTable.CREATEDAT]: new Date(
              item.createdAt ?? ""
            ).toLocaleDateString("fa-IR"),
            [RadioTable.UPDATEDAT]: new Date(
              item.updatedAt ?? ""
            ).toLocaleDateString("fa-IR"),
            [RadioTable.INSTALLDATE]: item.installDate,
            [RadioTable.INSTALLERS]: item.installers,
            [RadioTable.ETHERMAC]: item.etherMac,
            [RadioTable.RADIOPROPERTNUMBER]: item.radioPropertyNumber,
            [RadioTable.RADIOSERIALNUMBER]: item.radioSerialNumber,
            [RadioTable.WIRELESSMAC]: item.wirelessMac
          };
        });
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item?.radios]
  );

  const onSuccesCollectModal = useCallback(() => {
    (
      editRadio(
        {
          id: selectedItemCollect,
          collected: true
        },
        _id ?? "",
        true
      ) as unknown as Promise<void>
    ).then(() => {
      fetchOneOwa(_id as string);
      setIsOpenCollect(false);
      setSelectedItemCollect(null);
    });
  }, [selectedItemCollect, editRadio, _id, fetchOneOwa]);

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          <span
            onClick={() => {
              setSelectedItem(cellEvent?.data);
              setIsCommentModalOpen(true);
            }}>
            <i title='توضیحات' className='fas fa-comment comment-icon' />
          </span>

          {me &&
            checkUserAccessByProvinceCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.OWA,
              CRUD_TYPES.EDIT,
              item?.provinceId ?? ""
            ) && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  navigator(
                    `edit?owaId=${item?._id}&radioId=${cellEvent?.data?._id}`
                  );
                }}>
                <i title='ویرایش' className='fas fa-pen edit-icon'></i>
              </span>
            )}
          {me &&
            checkUserAccessByProvinceCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.OWA,
              CRUD_TYPES.DELETE,
              item?.provinceId ?? ""
            ) && (
              <span
                onClick={() => {
                  const _item = item?.radios?.find((item) => {
                    return (
                      item._id?.toString() ===
                      cellEvent?.data[RadioTable._ID].toString()
                    );
                  });

                  _item && setSelectedItem(_item);

                  setIsOpen(true);
                }}>
                <i title='حذف' className='fas fa-trash delete-icon'></i>
              </span>
            )}
          {cellEvent.data[RadioTable.COLLECTED] !== "بله" && (
            <span
              onClick={() => {
                setSelectedItemCollect(cellEvent.data[RadioTable._ID]);
                setIsOpenCollect(true);
              }}>
              <i
                title='جمع آوری'
                className='fas fa-solid fa-database edit-icon'></i>
            </span>
          )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item]
  );

  return (
    <>
      {isOpenCollect && (
        <DeleteModalContent
          isOpen={isOpenCollect}
          title={"جمع آوری  "}
          message='آیا از جمع آوری این مورد مطمئن هستید؟'
          onCancel={() => {
            setIsOpenCollect(false);
            setSelectedItem(null);
          }}
          onSuccess={onSuccesCollectModal}
          actionTitle='بله'
        />
      )}
      {isOpen && (
        <DeleteModalContent
          isOpen={isOpen}
          title={"حذف رادیو " + selectedItem?.customerName}
          message='آیا از حذف رادیو مطمئن هستید؟'
          onCancel={() => {
            setIsOpen(false);
            setSelectedItem(null);
          }}
          onSuccess={onSuccessDeleteModal}
        />
      )}
      <DetailModalContent
        content={selectedItem}
        isOpen={isCommentModalOpen}
        title={"توضیحات رادیو"}
        message={
          selectedItem
            ? selectedItem.description
            : "توضیحی درباره این رادیو ثبت نشده است."
        }
        onClose={() => {
          setIsCommentModalOpen(false);
          setSelectedItem(null);
        }}
      />

      <div className='main-route-wrapper'>
        {" "}
        <div className='table-wrapper'>
          <div className='list-top'>
            <p>
              تعداد کل رادیوهای پاپ سایت {item?.name} :{" "}
              <span>{item && item?.radios?.length} عدد</span>
              {item && (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    const url = BASE_API + OWA_ROOT_API;
                    fileDownload(url, token ?? "", "radios.xlsx", {
                      xlsx: true,
                      _id: item._id,
                      select: "radios"
                    });
                  }}
                  className='fas fa-file-excel excel-icon'></i>
              )}
            </p>
            {me &&
              item &&
              checkUserAccessByProvinceCrud(
                me,
                me?.access ?? [],
                USER_ACCESS.OWA,
                CRUD_TYPES.EDIT,
                item?.provinceId ?? ""
              ) && (
                <Link to={"./add?_id=" + _id}>
                  <SubmitButton
                    loading={loading}
                    title='  اضافه کردن رادیو جدید'
                  />
                </Link>
              )}
          </div>

          {(!item || !item.radios || item.radios.length === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}

          {item && item !== null && (
            <>
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  animateRows={true}
                  onCellClicked={(e) => {
                    if (e.colDef.field !== RadioTable.ACTIONS) copy(e.value);
                  }}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  rowSelection={"single"}
                  enableRtl={true}>
                  <AgGridColumn
                    sortable={true}
                    field={RadioTable.ROW}
                    width={84}
                  />
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={RadioTable.ACTIONS}
                    width={160}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={RadioTable.CUSTOMERNAME}
                    minWidth={120}
                    flex={1}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={RadioTable.BRANDNAMEMODEL}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={RadioTable.VIEW}
                    width={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={RadioTable.LEG}
                    maxWidth={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={RadioTable.HEIGHT}
                    maxWidth={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={RadioTable.PORT}
                    maxWidth={120}
                    flex={1}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={RadioTable.COLLECTED}
                    maxWidth={180}
                  />
                </AgGridReact>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RadiosList;
