import { useFormik } from "formik";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { SmsType, SmsSendTypes } from "../../../../core/types";
import CategoryList from "./category";

const SendSms = ({
  setSelectedCategory,
  selectedCategory,
  copiedMessage
}: {
  setSelectedCategory: (...args: SmsType[]) => void;
  selectedCategory?: SmsType;
  copiedMessage: string;
}) => {
  const { sendSms } = useActions();
  const { loading } = useTypedSelector((state) => state.notification);

  const formik = useFormik({
    initialValues: {
      receiver: "",
      description: copiedMessage
    },
    validationSchema: yup.object({
      description: yup.string().required("  متن پیام الزامی است"),
      receiver: yup.string().required("شماره موبایل  الزامی است")
    }),
    enableReinitialize: true,
    onSubmit: ({ description }: SmsSendTypes) => {
      sendSms({
        receiver: receiver?.toString(),
        description
      });
    }
  });

  const { description, receiver } = formik.values;
  const { description: descriptionError, receiver: phoneError } = formik.errors;
  const { description: descriptionTouched, receiver: phoneTouched } =
    formik.touched;

  return (
    <div className='sms-top-section'>
      <div className='main-route-wrapper-top-1'>
        <div className='list-top'>
          <p>بخش اس ام اس</p>
        </div>
        <form autoComplete='on' onSubmit={formik.handleSubmit}>
          <div className='section-half'>
            <Input
              name='receiver'
              title='شماره موبایل'
              autoComplete='off'
              error={phoneTouched && phoneError ? phoneError : undefined}
              value={receiver}
              onChange={formik.handleChange}
              type='number'
            />
          </div>
          <div className='section-full'>
            <Input
              multiline
              name='description'
              title='متن پیام'
              autoComplete='off'
              error={
                descriptionTouched && descriptionError
                  ? descriptionError
                  : undefined
              }
              value={description}
              onChange={formik.handleChange}
            />
          </div>

          <div className='section-half'>
            <SubmitButton
              title='ثبت'
              loading={loading}
              disabled={loading}
              type='submit'
            />
          </div>
        </form>
      </div>

      <CategoryList
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
    </div>
  );
};

export default SendSms;
