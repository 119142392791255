import React, { InputHTMLAttributes, TextareaHTMLAttributes } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string | undefined;
  title?: string;
  icon?: string;
  multiline?: boolean;
}

interface InputPropsMultiline
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string | undefined;
  title?: string;
  icon?: string;
  multiline?: boolean;
}

type TextFieldProps = InputProps & InputPropsMultiline;

const Input: React.FC<TextFieldProps> = ({
  error,
  title,
  icon,
  multiline,
  rows,
  ...rest
}) => {
  return (
    <div className='input-wrapper'>
      {title && <label className='input-label'>{title}</label>}
      {icon ? <i className={icon} /> : ""}
      {multiline ? (
        <textarea
          rows={rows || 5}
          {...rest}
          className={`input ${error ? "input--error" : ""} input ${
            icon ? "input--icon" : ""
          }`}
        />
      ) : (
        <input
          {...rest}
          className={`input ${error ? "input--error" : ""} input ${
            icon ? "input--icon" : ""
          }`}
        />
      )}
      {!!error && <p className='input-error'>{error}</p>}
    </div>
  );
};

export { Input };
