import { useFormik } from "formik";
import moment from "jalali-moment";
import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker2";
import { Link, useSearchParams } from "react-router-dom";
import Select from "react-select";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import {
  BANDWIDTH_TYPES_LIST,
  CAUSE_TYPES_LIST,
  PRIORITY_TYPES_LIST,
  STATUS_TYPES_LIST,
  defaultTelecomsCount
} from "../../../../core/constants";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import {
  AddFaultSubmitType,
  AddFaultType,
  BANDWIDTH_TYPES,
  CAUSE_TYPES,
  ExpertTypePopulated,
  INSERT_TYPE,
  PRIORITY_TYPES,
  STATUS_TYPES,
  TempFaultsTable
} from "../../../../core/types";
import { uniqueId } from "../../../../core/utility/uniqueId";
import { FAULT_TYPES } from "src/core/redux";
import { useDispatch } from "react-redux";
import { getSinceTimeWithHour } from "src/core/utility/getSinceTimeWithHour";

const AddFault = () => {
  const [searchParams] = useSearchParams();

  const [defaultExpert, setDefaultExpert] = useState<ExpertTypePopulated[]>();
  const dispatch = useDispatch();
  const faultId = searchParams.get("faultId");
  const { getTempFault, fetchAssignAbleUsers, addFault } = useActions();
  const { loading, tempItem, assignAbleUsers, statusItem } = useTypedSelector(
    (state) => state.fault
  );

  const { me } = useTypedSelector((state) => state.auth);
  const { fetchUserProvinces, fetchUserTelecoms, getFaultStatusByPhone } =
    useActions();
  const {
    provinceList,
    telecomsList,
    loading: generalLoading
  } = useTypedSelector((state) => state.general);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: (tempItem && tempItem[TempFaultsTable.ADSL]) ?? "",
      uuid: (tempItem && tempItem[TempFaultsTable.UUID]) ?? "",
      entranceDate:
        tempItem && !isNaN(Date.parse(tempItem[TempFaultsTable.ENTRANCEDATE]))
          ? moment.from(
              tempItem[TempFaultsTable.ENTRANCEDATE],
              "fa",
              "YYYY/MM/DD hh:mm"
            )
          : undefined,
      portUuid: (tempItem && tempItem[TempFaultsTable.PORTUUID]) ?? "",
      provinceId: (tempItem && tempItem[TempFaultsTable.PROVINCEID]) ?? "",
      telecomId: (tempItem && tempItem[TempFaultsTable.TELECOMID]) ?? "",
      bandWidth: BANDWIDTH_TYPES.EIGHT,
      panel: (tempItem && tempItem[TempFaultsTable.OPENTASK]) ?? "",
      cause: null,
      insertType: (tempItem && INSERT_TYPE.XLSX) ?? INSERT_TYPE.MANUAL,
      priority: PRIORITY_TYPES.NORMAL,
      assignedTo: "",
      comments: "",
      telecomBusyFloor: "",
      telecomBusyPort: "",
      telecomBusyRow: "",
      busyFloor: "",
      busyPort: "",
      busyRow: "",
      status: STATUS_TYPES.IN_TELECOM,
      date: undefined
    },
    validationSchema: yup.object({
      phone: yup.string().required(" شماره الزامی است"),
      uuid: yup.string(),
      entranceDate: yup.mixed(),
      portUuid: yup.string(),
      panel: yup.string(),
      insertType: yup.string(),
      bandWidth: yup
        .mixed()
        .required("پهنای باند خرابی الزامی است")
        .oneOf(Object.values(BANDWIDTH_TYPES), "پهنای باند خرابی الزامی است"),
      provinceId: yup.string().trim().required("انتخاب استان الزامی است"),
      telecomId: yup.string().trim().required("انتخاب مرکز الزامی است"),
      busyRow: yup.number().required("ردیف الزامی است"),
      busyFloor: yup.number().required("طبقه الزامی است"),
      busyPort: yup.number().required("پورت الزامی است"),
      cause: yup
        .array()
        .of(yup.mixed().oneOf(Object.values(CAUSE_TYPES)))
        .typeError("علت خرابی الزامی است")
        .required("علت خرابی الزامی است"),
      priority: yup
        .mixed()
        .required("اولویت خرابی الزامی است")
        .oneOf(Object.values(PRIORITY_TYPES), "اولویت خرابی الزامی است"),
      assignedTo: yup
        .string()
        .trim()
        .required("خرابی را به چه کسی ارجاع می دهید"),
      comments: yup.string(),

      status: yup
        .mixed()
        .oneOf(
          [
            STATUS_TYPES.IN_TELECOM,
            STATUS_TYPES.NORMAL,
            STATUS_TYPES.CUSTOMER,
            STATUS_TYPES.REFERRED_TO_EXPERT
          ],
          "وضیعت خرابی الزامی است"
        ),
      date: yup.date().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("تاریخ حضوری الزامی است")
          : schema;
      }),
      username: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("نام کاربری الزامی است")
          : schema;
      }),
      password: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("کلمه عبور الزامی است")
          : schema;
      }),
      name: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("نام مشترک الزامی است")
          : schema;
      }),
      mobileNumber: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("شماره همراه مشترک الزامی است")
          : schema;
      }),
      address: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("آدرس الزامی است")
          : schema;
      })
    }),
    onSubmit: ({
      phone,
      uuid,
      entranceDate,
      portUuid,
      panel,
      insertType,
      provinceId,
      telecomId,
      bandWidth,
      cause,
      priority,
      assignedTo,
      comments,
      busyFloor,
      busyPort,
      busyRow,
      telecomBusyFloor,
      telecomBusyPort,
      telecomBusyRow,
      status,
      date,
      username,
      password,
      name,
      mobileNumber,
      address
    }: AddFaultType) => {
      let body: AddFaultSubmitType = {
        phone,
        uuid: uuid || `asiatechin-${uniqueId()}`,
        entranceDate: entranceDate?.toISOString(),
        portUuid,
        provinceId,
        telecomId,
        bandWidth: bandWidth!,
        panel,
        cause: cause!,
        insertType,
        priority: priority!,
        assignedTo,
        comments: comments ? [{ desc: comments, userId: me?._id ?? "" }] : null,
        busy: { row: +busyRow, floor: +busyFloor, port: +busyPort },
        telecomBusy: {
          row: +telecomBusyRow,
          floor: +telecomBusyFloor,
          port: +telecomBusyPort
        },
        status: status!
      };

      if (status === STATUS_TYPES.REFERRED_TO_EXPERT) {
        body.needExpert = {
          date,
          username: username ?? "",
          password: password ?? "",
          name: name ?? "",
          mobileNumber: mobileNumber ?? "",
          address: address ?? "",
          assignedTo
        };
      }
      addFault(body);
    }
  });

  useEffect(() => {
    return () => {
      dispatch({ type: FAULT_TYPES.FAULT_CLEAR_TEMP_ITEM });
    };
  }, [dispatch]);

  useEffect(() => {
    if (faultId) {
      getTempFault(faultId);
    }
    fetchUserProvinces();
    fetchUserTelecoms({ limit: defaultTelecomsCount, page: 1 });
    fetchAssignAbleUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faultId]);

  const getProvince = useCallback(() => {
    return provinceList?.data.map((item) => {
      return { value: item._id, label: item.name };
    });
  }, [provinceList?.data]);

  const getAssignAbleUsersList = useCallback(() => {
    if (assignAbleUsers) {
      const usersListWithoutExperts = assignAbleUsers.filter(
        (assignAbleUser) =>
          !defaultExpert?.find(
            (defaultExpertItem) =>
              assignAbleUser._id === defaultExpertItem.expertId._id
          )
      );

      return [
        ...(defaultExpert?.map((defaultExpert) => defaultExpert.expertId) ??
          []),
        ...usersListWithoutExperts
      ]?.map((item) => {
        return {
          value: item._id,
          label: item.fullName
        };
      });
    }
  }, [assignAbleUsers, defaultExpert]);

  const getMatchedTelecom = useCallback(() => {
    const _telecoms = telecomsList?.data ?? [];
    return [..._telecoms]
      .filter((item) => item.provinceId === formik.values.provinceId)
      .map((item) => {
        return { value: item._id, label: item.name };
      });
  }, [telecomsList?.data, formik.values.provinceId]);

  const {
    phone,
    uuid,
    portUuid,
    comments,
    panel,
    telecomBusyPort,
    telecomBusyRow,
    telecomBusyFloor,
    busyFloor,
    busyPort,
    busyRow,
    entranceDate,
    date,
    username,
    password,
    name,
    status,
    mobileNumber,
    address
  } = formik.values;
  const {
    phone: phoneError,
    uuid: uuidError,
    entranceDate: entranceDateError,
    portUuid: portUuidError,
    panel: panelError,
    assignedTo: assignedToError,
    provinceId: provinceError,
    telecomId: telecomError,
    status: statusError,
    comments: commentsError,
    priority: priorityError,
    bandWidth: bandWidthError,
    cause: causeError,
    busyRow: busyRowError,
    busyFloor: busyFloorError,
    busyPort: busyPortError,
    telecomBusyFloor: telecomBusyFloorError,
    telecomBusyPort: telecomBusyPortError,
    telecomBusyRow: telecomBusyRowError,
    date: dateError,
    username: usernameError,
    password: passwordError,
    name: nameError,
    mobileNumber: mobileNumberError,
    address: addressError
  } = formik.errors;
  const {
    portUuid: portUuidTouched,
    phone: phoneTouched,
    entranceDate: entranceDateTouched,
    uuid: uuidTouched,
    panel: panelTouched,
    assignedTo: assignedToTouched,
    provinceId: provinceTouched,
    telecomId: telecomTouched,
    status: statusTouched,
    comments: commentsTouched,
    priority: priorityTouched,
    bandWidth: bandWidthTouched,
    cause: causeTouched,
    busyRow: busyRowTouched,
    busyFloor: busyFloorTouched,
    busyPort: busyPortTouched,
    telecomBusyFloor: telecomBusyFloorTouched,
    telecomBusyRow: telecomBusyRowTouched,
    telecomBusyPort: telecomBusyPortTouched
  } = formik.touched;
  const { values, handleChange, setFieldValue } = formik;

  useEffect(() => {
    if (phone) {
      const selectedTelecomByPhone = telecomsList?.data.find(
        (telecom) =>
          telecom.range?.find(
            (rangeItem) =>
              +phone <= rangeItem.maxRange && +phone >= rangeItem.minRange
          )
      );

      if (selectedTelecomByPhone) {
        setFieldValue("provinceId", selectedTelecomByPhone.provinceId);
        setFieldValue("telecomId", selectedTelecomByPhone._id);
        setDefaultExpert(selectedTelecomByPhone.experts);
        setFieldValue(
          "assignedTo",
          selectedTelecomByPhone.experts?.[0]?.expertId._id
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone, getMatchedTelecom, getAssignAbleUsersList]);

  useEffect(() => {
    dispatch({ type: FAULT_TYPES.RESET_FAULT_STATUS_ITEM_BY_PHONE });
  }, [dispatch]);

  const handleBlurPhoneInput = useCallback(() => {
    getFaultStatusByPhone(phone);
  }, [getFaultStatusByPhone, phone]);

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'> ایجاد خرابی جدید</h5>
        <form autoComplete='on' onSubmit={formik.handleSubmit}>
          <Input
            name='phone'
            title=' شماره : '
            autoComplete='off'
            error={phoneTouched && phoneError ? phoneError : undefined}
            value={phone}
            onChange={handleChange}
            onBlur={handleBlurPhoneInput}
          />
          <div className='triple-input-wrapper--high '>
            <label className='input-label'>بوخت آسیاتک :</label>
            <div className='triple-input-wrapper'>
              <Input
                placeholder='ردیف'
                name='busyRow'
                autoComplete='off'
                error={
                  busyRowTouched && busyRowError ? busyRowError : undefined
                }
                value={busyRow}
                type={"number"}
                onChange={handleChange}
              />
              <Input
                placeholder='طبقه'
                name='busyFloor'
                autoComplete='off'
                error={
                  busyFloorTouched && busyFloorError
                    ? busyFloorError
                    : undefined
                }
                value={busyFloor}
                type={"number"}
                onChange={handleChange}
              />
              <Input
                placeholder='پورت'
                name='busyPort'
                autoComplete='off'
                error={
                  busyPortTouched && busyPortError ? busyPortError : undefined
                }
                value={busyPort}
                type={"number"}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='pair-input-wrapper'>
            <Input
              name='uuid'
              title='شناسه  : '
              autoComplete='off'
              error={uuidTouched && uuidError ? uuidError : undefined}
              value={uuid}
              onChange={handleChange}
            />
          </div>
          <div className='select-wrapper--high '>
            <label className='input-label'> به چه کسی ارجاع می کنید :</label>
            <Select
              onChange={(e) => {
                setFieldValue("assignedTo", e?.value ?? "", true);
              }}
              isClearable
              placeholder=' لطفا کاربر مورد نظر را انتخاب نمایید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={getAssignAbleUsersList()}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
              value={
                getAssignAbleUsersList()?.find((user) => {
                  return user.value === values.assignedTo;
                }) ?? null
              }
            />
            {assignedToTouched && assignedToError && (
              <p className='input-error--select'>{assignedToError}</p>
            )}
          </div>
          <div style={{ marginBottom: 0 }} className='select-wrapper--high'>
            <label className='input-label'> تاریخ ورود:</label>

            <DatePicker
              isGregorian={false}
              onChange={(value) => {
                setFieldValue("entranceDate", value, true);
              }}
              value={entranceDate}
              timePicker={true}
              max={moment()}
            />
            {entranceDateTouched && entranceDateError && (
              <p className='input-error--select'>{entranceDateError}</p>
            )}
          </div>
          <Input
            name='portUuid'
            title='شناسه پورت : '
            autoComplete='off'
            error={portUuidTouched && portUuidError ? portUuidError : undefined}
            value={portUuid}
            onChange={handleChange}
          />

          <Input
            placeholder='پنل'
            name='panel'
            title=' پنل : '
            autoComplete='off'
            error={panelTouched && panelError ? panelError : undefined}
            value={panel}
            onChange={handleChange}
          />

          <div className='select-wrapper--high '>
            <label className='input-label'>استان مربوطه:</label>
            <Select
              onChange={(e) => {
                setFieldValue("provinceId", e?.value ?? "", true);
              }}
              value={
                getProvince()?.find((province) => {
                  return province.value === values.provinceId;
                }) ?? null
              }
              isClearable
              isDisabled={!provinceList || provinceList.count === 0}
              placeholder='استان مربوطه را انتخاب کنید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={getProvince()}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {provinceTouched && provinceError && (
              <p className='input-error--select'>{provinceError}</p>
            )}
          </div>
          <div className='select-wrapper--high '>
            <label className='input-label'>مرکز مربوطه:</label>
            <Select
              onChange={(e) => {
                setFieldValue("telecomId", e?.value ?? "", true);
              }}
              value={
                getMatchedTelecom()?.find((telecom) => {
                  return telecom.value === values.telecomId;
                }) ?? null
              }
              isClearable
              isDisabled={
                !telecomsList ||
                telecomsList.count === 0 ||
                !formik.values.provinceId
              }
              placeholder={"مرکز مربوطه را انتخاب کنید"}
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={getMatchedTelecom()}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {telecomTouched && telecomError && (
              <p className='input-error--select'>{telecomError}</p>
            )}
          </div>
          <div className='select-wrapper--high '>
            <label className='input-label'>وضعیت :</label>
            <Select
              onChange={(e) => {
                setFieldValue("status", e?.value ?? "", true);
              }}
              defaultValue={{
                label: STATUS_TYPES.IN_TELECOM,
                value: STATUS_TYPES.IN_TELECOM
              }}
              isClearable
              placeholder='وضعیت مربوطه را انتخاب کنید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={STATUS_TYPES_LIST}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {statusTouched && statusError && (
              <p className='input-error--select'>{statusError}</p>
            )}
          </div>

          <div className='select-wrapper--high '>
            <label className='input-label'>پهنای باند :</label>
            <Select
              placeholder=' لطفا پهنای باند را انتخاب نمایید'
              onChange={(e) => {
                setFieldValue("bandWidth", e?.value ?? "", true);
              }}
              value={
                BANDWIDTH_TYPES_LIST?.find((bandWidth) => {
                  return bandWidth.value === values.bandWidth;
                }) ?? null
              }
              isClearable
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={BANDWIDTH_TYPES_LIST}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {bandWidthTouched && bandWidthError && (
              <p className='input-error--select'>{bandWidthError}</p>
            )}
          </div>
          <div className='select-wrapper--high '>
            <label className='input-label'> علت خرابی :</label>
            <Select
              placeholder=' لطفا علت خرابی را انتخاب نمایید'
              onChange={(selectArray) => {
                setFieldValue(
                  "cause",
                  selectArray.length !== 0
                    ? selectArray.map((item) => {
                        return item.value as CAUSE_TYPES;
                      })
                    : null,
                  true
                );
              }}
              isClearable
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={CAUSE_TYPES_LIST}
              isMulti
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {causeTouched && causeError && (
              <p className='input-error--select'>{causeError}</p>
            )}
          </div>

          <div className='triple-input-wrapper--high '>
            <label className='input-label'>بوخت مخابرات :</label>
            <div className='triple-input-wrapper'>
              <Input
                placeholder='ردیف'
                name='telecomBusyRow'
                autoComplete='off'
                error={
                  telecomBusyRowTouched && telecomBusyRowError
                    ? telecomBusyRowError
                    : undefined
                }
                value={telecomBusyRow}
                type={"number"}
                onChange={handleChange}
              />
              <Input
                placeholder='طبقه'
                name='telecomBusyFloor'
                autoComplete='off'
                error={
                  telecomBusyFloorTouched && telecomBusyFloorError
                    ? telecomBusyFloorError
                    : undefined
                }
                value={telecomBusyFloor}
                type={"number"}
                onChange={handleChange}
              />
              <Input
                placeholder='پورت'
                name='telecomBusyPort'
                autoComplete='off'
                error={
                  telecomBusyPortTouched && telecomBusyPortError
                    ? telecomBusyPortError
                    : undefined
                }
                value={telecomBusyPort}
                type={"number"}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='select-wrapper--high '>
            <label className='input-label'>اولویت :</label>
            <Select
              onChange={(e) => {
                setFieldValue("priority", e?.value ?? "", true);
              }}
              value={
                PRIORITY_TYPES_LIST?.find((priority) => {
                  return priority.value === values.priority;
                }) ?? null
              }
              isClearable
              placeholder='اولویت مربوطه را انتخاب کنید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={PRIORITY_TYPES_LIST}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {priorityTouched && priorityError && (
              <p className='input-error--select'>{priorityError}</p>
            )}
          </div>

          {status === STATUS_TYPES.REFERRED_TO_EXPERT && (
            <>
              {" "}
              <h5 style={{ marginTop: 10 }} className='title'>
                {" "}
                اطلاعات حضوری
              </h5>
              <div style={{ marginBottom: 0 }} className='select-wrapper--high'>
                <label className='input-label'> تاریخ حضوری:</label>

                <DatePicker
                  isGregorian={false}
                  onChange={(value) => {
                    setFieldValue("date", value, true);
                  }}
                  value={date}
                  timePicker={true}
                  min={moment().date(moment().get("date") - 1)}
                />
                {dateError && (
                  <p className='input-error--select'>{dateError}</p>
                )}
              </div>
              <Input
                title='نام کاربری'
                name='username'
                autoComplete='off'
                error={usernameError ? usernameError : undefined}
                value={username}
                onChange={handleChange}
              />
              <Input
                title='کلمه عبور'
                name='password'
                autoComplete='off'
                error={passwordError ? passwordError : undefined}
                value={password}
                onChange={handleChange}
              />
              <Input
                title='نام مشترک'
                name='name'
                autoComplete='off'
                error={nameError ? nameError : undefined}
                value={name}
                onChange={handleChange}
              />
              <Input
                title='شماره همراه'
                name='mobileNumber'
                autoComplete='off'
                type='tel'
                error={mobileNumberError ? mobileNumberError : undefined}
                value={mobileNumber}
                onChange={handleChange}
              />
            </>
          )}

          {status === STATUS_TYPES.REFERRED_TO_EXPERT && (
            <Input
              title='آدرس'
              name='address'
              autoComplete='off'
              error={addressError ? addressError : undefined}
              value={address}
              onChange={handleChange}
            />
          )}
          <Input
            title=' توضیحات'
            name='comments'
            autoComplete='off'
            error={commentsTouched && commentsError ? commentsError : undefined}
            value={comments}
            type='text'
            onChange={handleChange}
            multiline
          />
          {!!statusItem && statusItem?.doneFaultsCount !== 0 && (
            <div className='status-section'>
              <p> تسک باز دارد: {statusItem?.openFault ? "بلی" : "خیر"}</p>
              <p> ارجاعات: {statusItem?.doneFaultsCount}</p>
              {!!statusItem?.lastClosedDate && (
                <p>
                  {" "}
                  آخرین بررسی:{" "}
                  {getSinceTimeWithHour(statusItem?.lastClosedDate)}
                </p>
              )}
              <Link
                target='_blank'
                className='status-link'
                to={`/faults/done-faults/?phone=${phone}&lastClosedDate=${statusItem?.lastClosedDate}`}>
                <i
                  title='بررسی خرابی'
                  className='fas fa-external-link-square-alt edit-icon'></i>
              </Link>
            </div>
          )}
          <SubmitButton
            title='ثبت'
            loading={loading || generalLoading}
            disabled={loading || generalLoading}
            type='submit'
          />
        </form>
      </div>
    </>
  );
};

export default AddFault;
