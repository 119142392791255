import { AdvancedResult, NotificationType, SmsType } from "../../types";
import { NotificationActionTypes } from "../actionTypes/NotificationActionTypes";
import { NOTIFICATION_TYPES } from "../types";

interface StateType {
  list: AdvancedResult<SmsType> | null;
  loading: boolean;
  default?: NotificationType;
}

const initialState: StateType = {
  list: null,
  default: undefined,
  loading: false
};

const reducer = (
  state: StateType = initialState,
  action: NotificationActionTypes
): StateType => {
  switch (action.type) {
    case NOTIFICATION_TYPES.SMS_LISTS:
      return {
        ...state,
        list: action.payload,
        loading: false
      };

    case NOTIFICATION_TYPES.NOTIFICATION_LOADING:
      return {
        ...state,
        loading: true
      };

    case NOTIFICATION_TYPES.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };

    case NOTIFICATION_TYPES.NOTIFICATION:
      return {
        ...state,
        default: action.payload,
        loading: false
      };

    case NOTIFICATION_TYPES.NOTIFICATION_DELETE:
      let _list = state.list;
      if (_list) {
        _list.data = _list?.data?.filter((item) => {
          return (
            item?._id?.toString() !== action.payload.smsCategoryId?.toString()
          );
        });
        --_list.total;
      }
      return {
        ...state,
        list: _list,
        loading: false
      };

    case NOTIFICATION_TYPES.SMS_DELETE:
      let _list2 = state.list;

      if (_list2) {
        _list2?.data.forEach(function (o) {
          o.messages = o.messages.filter(
            (s: any) => s._id !== action.payload.smsId
          );
        });

        --_list2.total;
      }
      return {
        ...state,
        list: _list2,
        loading: false
      };

    case NOTIFICATION_TYPES.SMS:
      return {
        ...state,
        default: {
          receiveSms: !state.default?.receiveSms,
          pushWebNotification: !!state.default?.pushWebNotification
        },
        loading: false
      };

    case NOTIFICATION_TYPES.WEBPUSH:
      return {
        ...state,
        default: {
          receiveSms: !!state.default?.receiveSms,
          pushWebNotification: !state.default?.pushWebNotification
        },
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
