import { useCallback, useEffect } from "react";
import ReactSwitch from "react-switch";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { regSw } from "../../../../core/utility/register.SW";

const PesonalAccess = () => {
  const {
    subscribeWebPushNotification,
    addSmsNotification,
    getNotificationSubscription
  } = useActions();

  const { loading, default: defaultAccess } = useTypedSelector(
    (state) => state.notification
  );

  useEffect(() => {
    getNotificationSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerAndSubscribe = useCallback(async () => {
    try {
      const serviceWorkerReg = await regSw();
      if (serviceWorkerReg) subscribeWebPushNotification(serviceWorkerReg);
      else alert("Push notification not supportted");
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='change-password-wrapper'>
        <h5 className='title'>دسترسی های شخصی </h5>

        <div className='switch-wrapper'>
          <p>دریافت پیامک</p>
          <ReactSwitch
            onColor='#ff9b41'
            disabled={loading}
            onChange={() => {
              addSmsNotification({ receiveSms: !defaultAccess?.receiveSms });
            }}
            checked={defaultAccess?.receiveSms || false}
          />
        </div>
        <div className='switch-wrapper'>
          <p>درخواست پوش نوتیفیکیشن </p>
          <button
            className='notification-button'
            disabled={loading}
            onClick={() => {
              registerAndSubscribe();
            }}>
            درخواست
          </button>
        </div>
      </div>
    </>
  );
};

export default PesonalAccess;
