import { useFormik } from "formik";
import moment, { Moment } from "jalali-moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker2";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import Switch from "react-switch";
import { Column } from "react-table";
import * as yup from "yup";
import { Input, SubmitButton, Table } from "../../../../core/components";
import { defaultTablePageCount } from "../../../../core/constants";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { BASE_API, LOG_ROOT_API } from "../../../../core/redux";
import { LogsTable } from "../../../../core/types";
import { fileDownload } from "../../../../core/utility";
import useIsMobile from "src/core/hooks/useIsMobile";
import { useQueryParams } from "src/core/hooks/useQueryParams";
import Loading from "src/core/components/Loading";

const LogsList = () => {
  const { fetchLogs, getEvents } = useActions();
  const { list, events, loading } = useTypedSelector((state) => state.log);
  const { searchParams, setSearchParams } = useQueryParams();
  const [currentPage, setCurrentPage] = useState<number>(
    +(searchParams("page") ?? 1) - 1
  );
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const { token } = useTypedSelector((state) => state.auth);
  const [checked, setChecked] = useState<boolean>(false);

  const isMobile = useIsMobile();

  const queryParams = useMemo(() => {
    return {
      limit: defaultTablePageCount,
      page: currentPage + 1,
      sort: "-createdAt"
    };
  }, [currentPage]);

  const fetchFilteredLogs = useCallback(
    (values: {
      eventTypes: string;
      text: string;
      startDate: Moment;
      endDate: Moment;
    }) => {
      const { eventTypes, text, startDate, endDate, ...rest } = values;

      if (checked) {
        fetchLogs({
          ...queryParams,
          ...rest,
          text: text,
          "createdAt[gt]": (startDate as Moment).toDate(),
          "createdAt[lt]": (endDate as Moment).toDate()
        });
      } else
        fetchLogs({
          ...queryParams,
          "eventType": eventTypes,
          "createdAt[gt]": (startDate as Moment).toDate(),
          "createdAt[lt]": (endDate as Moment).toDate()
        });

      setSearchParams({
        ...queryParams,
        "eventType": eventTypes,
        "createdAt[gt]": (startDate as Moment).toDate(),
        "createdAt[lt]": (endDate as Moment).toDate(),
        ...(checked && { text: text })
      });
    },
    [checked, fetchLogs, queryParams, setSearchParams]
  );

  const getEventTypes = useCallback(() => {
    return events?.map((item: string) => {
      return { value: item, label: item };
    });
  }, [events]);

  const formik = useFormik({
    initialValues: {
      eventTypes: "",
      text: "",
      startDate: searchParams("createdAt[gt]")
        ? moment(searchParams("createdAt[gt]") ?? "")
        : moment().subtract(1, "month"),
      endDate: searchParams("createdAt[lt]")
        ? moment(searchParams("createdAt[lt]") ?? "")
        : moment()
    },
    validationSchema: yup.object({
      text: yup.string(),
      startDate: yup.mixed(),
      endDate: yup.mixed(),
      eventTypes: yup.string()
    }),
    onSubmit: (values) => {
      setCurrentPage(0);
      fetchFilteredLogs(values);
    }
  });

  const { text, startDate, endDate, eventTypes } = formik.values;

  const {
    text: textError,
    startDate: startDateError,
    endDate: endDateError
  } = formik.errors;
  const {
    text: textTouched,
    startDate: startDateTouched,
    endDate: endDatetouched
  } = formik.touched;

  useEffect(() => {
    fetchFilteredLogs(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, queryParams]);

  useEffect(() => {
    getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const COLUMNS: Column[] = useMemo(
    () => [
      {
        Header: LogsTable.ROW,
        Cell: (val) => {
          return <>{val.row.index + 1 + currentPage * 10}</>;
        }
      },
      {
        Header: LogsTable.USERNAME,
        accessor: "user.fullName"
      },
      {
        Header: LogsTable.EVENTTYPE,
        accessor: "eventType"
      },
      {
        Header: LogsTable.EVENT,
        accessor: "event"
      },
      {
        Header: LogsTable.DESCRIPTION,
        accessor: "comment"
      },
      {
        Header: LogsTable.DATE,
        accessor: "createdAt",

        Cell: ({ value }) => {
          return <>{new Date(value).toLocaleString("fa-IR")}</>;
        }
      }
    ],
    [currentPage]
  );

  return (
    <>
      <form
        className='clients-search-wrapper'
        autoComplete='on'
        onSubmit={formik.handleSubmit}>
        <div className='list-input-wrapper'>
          <div className='switch-item'>
            <label> پیشرفته :</label>
            <Switch
              onColor='#ff9b41'
              onChange={() => setChecked(!checked)}
              checked={checked}
            />
          </div>
          <div className='select-wrapper--high '>
            {checked ? (
              <Input
                name='text'
                title='کلمه کلیدی : '
                type='text'
                autoComplete='off'
                error={textTouched && textError ? textError : undefined}
                value={text}
                onChange={formik.handleChange}
              />
            ) : (
              <>
                <label>رویداد :</label>
                <Select
                  onChange={(e) => {
                    formik.setFieldValue("eventTypes", e?.value ?? "", true);
                  }}
                  isClearable
                  isDisabled={!events || events.length === 0}
                  placeholder='رویداد'
                  openMenuOnClick={true}
                  classNamePrefix='react-select'
                  options={getEventTypes()}
                  noOptionsMessage={() => {
                    return <h6>آیتمی یافت نشد</h6>;
                  }}
                />
              </>
            )}
          </div>
          <div className='select-wrapper--high'>
            <label> تاریخ شروع :</label>

            <DatePicker
              isGregorian={false}
              onChange={(value) => {
                formik.setFieldValue("startDate", value, true);
              }}
              value={startDate}
              timePicker={false}
              max={moment()}
            />
            {startDateTouched && startDateError && (
              <p className='input-error--select'>
                {startDateError as unknown as string}
              </p>
            )}
          </div>
          <div className='select-wrapper--high'>
            <label> تاریخ پایان :</label>

            <DatePicker
              isGregorian={false}
              onChange={(value) => {
                formik.setFieldValue("endDate", value, true);
              }}
              timePicker={false}
              value={endDate}
              max={moment()}
            />
            {endDatetouched && endDateError && (
              <p className='input-error--select'>
                {endDateError as unknown as string}
              </p>
            )}
          </div>

          <SubmitButton
            type='submit'
            title='ثبت'
            loading={loading}
            disabled={loading}
          />
        </div>
      </form>
      <div className='main-route-wrapper'>
        <div className='list-top'>
          <p>
            تعداد کل لاگ ها: <span>{list && list.total} عدد</span>
            {list &&
              list?.data &&
              list.data.length !== 0 &&
              !isLoadingDownload && (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    const url = BASE_API + LOG_ROOT_API;
                    fileDownload(
                      url,
                      token ?? "",
                      "logs.xlsx",
                      {
                        xlsx: true,
                        ...(checked
                          ? { text }
                          : { "eventType[regex]": eventTypes }),
                        "createdAt[gt]": (startDate as Moment).toDate(),
                        "createdAt[lt]": (endDate as Moment).toDate()
                      },
                      setIsLoadingDownload
                    );
                  }}
                  className='fas fa-file-excel excel-icon'></i>
              )}
            <Loading
              size={14}
              color='rgb(50, 119, 50)'
              loading={isLoadingDownload}
            />
          </p>
        </div>
        <div className='react-table-container'>
          <Table columns={COLUMNS as any} data={list?.data ?? []} />
        </div>
        <ReactPaginate
          marginPagesDisplayed={isMobile ? 1 : 3}
          forcePage={currentPage}
          breakLabel='...'
          nextLabel='بعدی'
          onPageChange={(e) => {
            setCurrentPage(e.selected);
          }}
          pageRangeDisplayed={5}
          pageCount={
            list !== null ? Math.ceil(list.total / defaultTablePageCount) : 0
          }
          previousLabel='قبلی'
          containerClassName='pagination-container'
        />
      </div>
    </>
  );
};
export default LogsList;
