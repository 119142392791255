import { Route, Routes } from "react-router-dom";
import AddPap from "./add-pap";
import AddPhoto from "./add-photo";
import EditPap from "./edit-pap";
import Miscellaneous from "./miscellaneous";
import PapsList from "./pap-list";
import Passives from "./passives";
import Racks from "./racks";
import Shelves from "./shelves";

const Paps = () => {
  return (
    <>
      <Routes>
        <Route path='' element={<PapsList />} />
        <Route path='/add-pap' element={<AddPap />} />
        <Route path='/edit-pap' element={<EditPap />} />
        <Route path='/add-pap-photo' element={<AddPhoto />} />
        <Route path='/shelves/*' element={<Shelves />} />
        <Route path='/racks/*' element={<Racks />} />
        <Route path='/passives/*' element={<Passives />} />
        <Route path='/miscellaneous/*' element={<Miscellaneous />} />
      </Routes>
    </>
  );
};

export default Paps;
