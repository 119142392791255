import { useFormik } from "formik";
import Select from "react-select";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../../core/components";
import { SHELF_TYPE_LIST } from "../../../../../core/constants";
import { useActions, useTypedSelector } from "../../../../../core/hooks";
import { ShelfSchema } from "../../../../../core/types";

const AddShelf = () => {
  const { addShelf } = useActions();
  const { loading, item } = useTypedSelector((state) => state.pap);
  const formik = useFormik({
    initialValues: {
      shelfNumber: "",
      model: "",
      propertyNumber: "",
      serialNumber: "",
      rackNumber: "",
      managePropertyNumber: "",
      manageSerialNumber: ""
    },
    validationSchema: yup.object({
      shelfNumber: yup
        .number()
        .required("شماره شلف الزامی است")
        .max(99, "حداکثر مقدار شماره شلف 99 می باشد"),
      model: yup.string().trim().required("نوع شلف الزامی می باشد"),
      propertyNumber: yup.string().trim(),
      serialNumber: yup.string().trim(),
      rackNumber: yup.string().trim(),
      managePropertyNumber: yup.string().trim(),
      manageSerialNumber: yup.string().trim()
    }),
    onSubmit: ({
      shelfNumber,
      model,
      propertyNumber,
      serialNumber,
      rackNumber,
      managePropertyNumber,
      manageSerialNumber
    }: ShelfSchema) => {
      addShelf(
        {
          shelfNumber,
          model,
          propertyNumber,
          serialNumber,
          rackNumber,
          managePropertyNumber,
          manageSerialNumber
        },
        item?._id ?? ""
      );
    }
  });

  const {
    shelfNumber,

    propertyNumber,
    serialNumber,
    rackNumber,
    managePropertyNumber,
    manageSerialNumber
  } = formik.values;
  const {
    shelfNumber: shelfNumberError,
    model: modelError,
    propertyNumber: propertyNumberError,
    serialNumber: serialNumberError,
    rackNumber: rackNumberError,
    managePropertyNumber: managePropertyNumberError,
    manageSerialNumber: manageSerialNumberError
  } = formik.errors;
  const {
    shelfNumber: shelfNumberTouched,
    model: modelTouched,
    propertyNumber: propertyNumberTouched,
    serialNumber: serialNumberTouched,
    rackNumber: rackNumberTouched,
    managePropertyNumber: managePropertyNumberTouched,
    manageSerialNumber: manageSerialNumberTouched
  } = formik.touched;

  const { setValues } = formik;

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'>اضافه کردن شلف جدید</h5>
        <form autoComplete='on' onSubmit={formik.handleSubmit}>
          <Input
            name='shelfNumber'
            title='شماره شلف : '
            autoComplete='off'
            error={
              shelfNumberTouched && shelfNumberError
                ? shelfNumberError
                : undefined
            }
            value={shelfNumber}
            type='number'
            onChange={formik.handleChange}
          />
          <div className='select-wrapper--high '>
            <label>مدل :</label>
            <Select
              onChange={(e) => {
                setValues({ ...formik.values, model: e?.value ?? "" });
              }}
              isClearable
              placeholder='مدل مربوطه را انتخاب کنید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={SHELF_TYPE_LIST}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {modelTouched && modelError && (
              <p className='input-error--select'>{modelError}</p>
            )}
          </div>
          <Input
            name='propertyNumber'
            title='شماره اموال : '
            autoComplete='off'
            error={
              propertyNumberTouched && propertyNumberError
                ? propertyNumberError
                : undefined
            }
            value={propertyNumber}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='serialNumber'
            title='شماره سریال : '
            autoComplete='off'
            error={
              serialNumberTouched && serialNumberError
                ? serialNumberError
                : undefined
            }
            value={serialNumber}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='rackNumber'
            title='شماره رک : '
            autoComplete='off'
            error={
              rackNumberTouched && rackNumberError ? rackNumberError : undefined
            }
            value={rackNumber}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='managePropertyNumber'
            title='شماره اموال منیج : '
            autoComplete='off'
            error={
              managePropertyNumberTouched && managePropertyNumberError
                ? managePropertyNumberError
                : undefined
            }
            value={managePropertyNumber}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='manageSerialNumber'
            title='شماره سریال منیج : '
            autoComplete='off'
            error={
              manageSerialNumberTouched && manageSerialNumberError
                ? manageSerialNumberError
                : undefined
            }
            value={manageSerialNumber}
            onChange={formik.handleChange}
            type='text'
          />
          <SubmitButton
            title='ثبت'
            loading={loading}
            disabled={loading}
            type='submit'
          />
        </form>
      </div>
    </>
  );
};

export default AddShelf;
