import { useFormik } from "formik";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import {
  PAP_TYPES_LIST,
  ROUTER_TYPES,
  defaultTelecomsCount
} from "../../../../core/constants";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import {
  CityProvinceType,
  PAP_TYPE,
  PapTelecomsType,
  PapsType,
  RouterSchema,
  TelecomsType
} from "../../../../core/types";

const EditPap = () => {
  let [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");
  const { editPap, getPapProvinces, getTelecoms, fetchOnePap } = useActions();

  const RouterList = Object.values(ROUTER_TYPES).map((el) => ({
    label: el,
    value: el
  }));

  const {
    telecoms,
    telecomsLoading,
    item,
    provincesLoading,
    provinces,
    loading
  } = useTypedSelector((state) => state.pap);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: item?.type ?? PAP_TYPE.AT,
      provinceId: item?.provinceId ?? "",
      telecomId: (item?.telecomId as unknown as TelecomsType)?._id ?? "",
      brandNameModel: item?.router?.brandNameModel ?? "",
      electricityType: item?.router?.electricityType ?? "",
      propertyNumber: item?.router?.propertyNumber ?? "",
      serialNumber: item?.router?.serialNumber ?? "",
      fileNumber: item?.fileNumber ?? "",
      bandWidth: item?.bandWidth ?? 1,
      networkCableSize: item?.networkCableSize ?? 1,
      portNumber: item?.portNumber ?? 1,
      electricityCableSize: item?.electricityCableSize ?? 1,
      ladderSize: item?.ladderSize ?? 1,
      legsNumber: item?.legsNumber ?? 1,
      LTypeItemNumber: item?.LTypeItemNumber ?? 1,
      fuse: item?.fuse,
      fuseAmpere: item?.fuseAmpere ?? 1,
      name: item?.name ?? ""
    },
    validationSchema: yup.object({
      type: yup.string().trim().required("انتخاب پپ الزامی است"),
      provinceId: yup.string().trim().required("انتخاب استان الزامی است"),
      telecomId: yup.string().trim().required("انتخاب مرکز الزامی است"),
      brandNameModel: yup.string().trim().required("نام روتر الزامی است."),
      electricityType: yup.string().trim().required("نوع برق روتر الزامی است."),
      propertyNumber: yup.string().trim(),
      serialNumber: yup.string().trim(),
      fileNumber: yup.string().trim(),
      bandWidth: yup.number(),
      networkCableSize: yup.number(),
      portNumber: yup.number(),
      electricityCableSize: yup.number(),
      ladderSize: yup.number(),
      legsNumber: yup.number(),
      LTypeItemNumber: yup.number(),
      fuse: yup.string().trim().required("نوع فیوز الزامی می باشد"),
      fuseAmpere: yup.number(),
      name: yup.string().required("نام مرکز الزامی است")
    }),
    onSubmit: ({
      brandNameModel,
      electricityType,
      propertyNumber,
      serialNumber,
      type,
      provinceId,
      telecomId,
      fileNumber,
      bandWidth,
      networkCableSize,
      portNumber,
      electricityCableSize,
      ladderSize,
      legsNumber,
      LTypeItemNumber,
      fuse,
      fuseAmpere,
      name
    }: PapsType & RouterSchema) => {
      editPap(
        {
          type,
          provinceId,
          telecomId,
          router: {
            brandNameModel,
            electricityType,
            propertyNumber,
            serialNumber
          },
          fileNumber,
          bandWidth,
          networkCableSize,
          portNumber,
          electricityCableSize,
          ladderSize,
          legsNumber,
          LTypeItemNumber,
          fuse,
          fuseAmpere,
          name
        },
        item?._id ?? ""
      );
    }
  });

  const {
    propertyNumber,
    serialNumber,
    fileNumber,
    bandWidth,
    networkCableSize,
    portNumber,
    electricityCableSize,
    ladderSize,
    legsNumber,
    LTypeItemNumber,
    fuseAmpere,
    name
  } = formik.values;
  const {
    type: typeError,
    provinceId: provinceError,
    telecomId: telecomError,
    brandNameModel: brandNameError,
    propertyNumber: propertyNumberError,
    serialNumber: serialNumberError,
    fileNumber: fileNumberError,
    bandWidth: bandWidthError,
    networkCableSize: networkCableSizeError,
    portNumber: portNumberError,
    electricityCableSize: electricityCableSizeError,
    ladderSize: ladderSizeError,
    legsNumber: legsNumberError,
    LTypeItemNumber: LTypeItemNumberError,
    fuse: fuseError,
    fuseAmpere: fuseAmpereError,
    electricityType: electricityTypeError,
    name: nameError
  } = formik.errors;
  const {
    type: typeTouched,
    provinceId: provinceTouched,
    telecomId: telecomTouched,
    brandNameModel: brandNameTouched,
    propertyNumber: propertyNumberTouched,
    serialNumber: serialNumberTouched,
    fileNumber: fileNumberTouched,
    bandWidth: bandWidthTouched,
    networkCableSize: networkCableSizeTouched,
    portNumber: portNumberTouched,
    electricityCableSize: electricityCableSizeTouched,
    ladderSize: ladderSizeTouched,
    legsNumber: legsNumberTouched,
    LTypeItemNumber: LTypeItemNumberTouched,
    fuse: fuseTouched,
    fuseAmpere: fuseAmpereTouched,
    electricityType: electricityTypeTouched,
    name: nameTouched
  } = formik.touched;
  const { values, setFieldValue, setValues } = formik;

  const getProvince = useCallback(() => {
    return provinces?.data.map((item) => {
      return { value: item._id, label: item.name };
    });
  }, [provinces?.data]);

  const getMatchedTelecom = useCallback(() => {
    return telecoms?.data
      .filter((item) => item.provinceId === formik.values.provinceId)
      .map((item) => {
        return { value: item._id, label: item.name };
      });
  }, [telecoms?.data, formik.values.provinceId]);

  useEffect(() => {
    getPapProvinces();
    getTelecoms({ limit: defaultTelecomsCount });
    if (_id) {
      fetchOnePap(_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const getDefaultProvince = useCallback(() => {
    if (provinces?.data?.length !== 0) {
      const found = provinces?.data?.find((province) => {
        return (
          province._id?.toString() ===
          (item as PapsType)?.provinceId?.toString()
        );
      });

      if (
        found &&
        (found as CityProvinceType)._id &&
        (found as CityProvinceType).name
      ) {
        return {
          value: found._id,
          label: found.name
        };
      }
      return {
        value: "",
        label: ""
      };
    }
    return undefined;
  }, [provinces?.data, item]);

  const getDefaultTelecom = useCallback(() => {
    if (telecoms?.data?.length !== 0 && telecoms !== null) {
      const found = telecoms?.data?.find((telecom) => {
        return telecom._id?.toString() === item?.telecomId?.toString();
      });

      if (
        found &&
        (found as PapTelecomsType)._id &&
        (found as PapTelecomsType).name
      ) {
        return {
          value: found._id,
          label: found.name
        };
      }
      return {
        value: "",
        label: ""
      };
    }
    return undefined;
  }, [telecoms, item?.telecomId]);

  return (
    <>
      {item && item !== null && (
        <div className='add-item-wrapper'>
          <h5 className='title'>ویرایش پپ {item?.type}</h5>
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <div className='select-wrapper--high '>
              <label>نام پروژه:</label>
              <Select
                onChange={(e) => {
                  setValues({
                    ...formik.values,
                    type: (e?.value as PAP_TYPE) ?? ""
                  });
                }}
                isClearable
                placeholder='پپ مربوطه را انتخاب کنید'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                defaultInputValue={item.type}
                options={PAP_TYPES_LIST}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              {typeTouched && typeError && (
                <p className='input-error--select'>{typeError}</p>
              )}
            </div>
            <div className='select-wrapper--high '>
              <label>استان مربوطه:</label>
              <Select
                onChange={(e) => {
                  setFieldValue("provinceId", e?.value ?? "", true);
                }}
                value={
                  getProvince()?.find((province) => {
                    return province.value === values.provinceId;
                  }) ?? null
                }
                isClearable
                isDisabled={!provinces || provinces.count === 0}
                placeholder='استان مربوطه را انتخاب کنید'
                openMenuOnClick={true}
                defaultValue={getDefaultProvince()}
                classNamePrefix='react-select'
                options={getProvince()}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              {provinceTouched && provinceError && (
                <p className='input-error--select'>{provinceError}</p>
              )}
            </div>
            <div className='select-wrapper--high '>
              <label>مرکز مربوطه:</label>
              <Select
                onChange={(e) => {
                  setFieldValue("telecomId", e?.value ?? "", true);
                }}
                value={
                  getMatchedTelecom()?.find((telecom) => {
                    return telecom.value === values.telecomId;
                  }) ?? null
                }
                isClearable
                isDisabled={
                  !telecoms || telecoms.count === 0 || telecoms === null
                }
                placeholder={"مرکز مربوطه را انتخاب کنید"}
                openMenuOnClick={true}
                classNamePrefix='react-select'
                defaultValue={getDefaultTelecom()}
                options={getMatchedTelecom()}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              {telecomTouched && telecomError && (
                <p className='input-error--select'>{telecomError}</p>
              )}
            </div>

            <div className='select-wrapper--high '>
              <label> نام روتر :</label>
              <CreatableSelect
                onChange={(e) => {
                  setValues({
                    ...formik.values,
                    brandNameModel: e?.value ?? ""
                  });
                }}
                isClearable
                formatCreateLabel={(el) => <p>{`  ${el}`}</p>}
                placeholder='روتر  را انتخاب کنید'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                options={RouterList}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              {brandNameTouched && brandNameError && (
                <p className='input-error--select'>{brandNameError}</p>
              )}
            </div>

            <div className='select-wrapper--high '>
              <label>نوع برق روتر:</label>
              <Select
                onChange={(e) => {
                  setValues({
                    ...formik.values,
                    electricityType: e?.value ?? ""
                  });
                }}
                isClearable
                placeholder='نوع برق روتر مربوطه را انتخاب کنید'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                defaultValue={{
                  value: item?.router?._id,
                  label: item?.router?.electricityType
                }}
                options={[
                  { value: "آدابتور", label: "آدابتور" },
                  { value: "اینورتر", label: "اینورتر" }
                ]}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              {electricityTypeTouched && electricityTypeError && (
                <p className='input-error--select'>{electricityTypeError}</p>
              )}
            </div>

            <Input
              name='propertyNumber'
              title='اموال روتر: '
              autoComplete='off'
              error={
                propertyNumberTouched && propertyNumberError
                  ? propertyNumberError
                  : undefined
              }
              value={propertyNumber}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='serialNumber'
              title='شماره سریال روتر: '
              autoComplete='off'
              error={
                serialNumberTouched && serialNumberError
                  ? serialNumberError
                  : undefined
              }
              value={serialNumber}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              type='text'
              name='fileNumber'
              title='شماره پرونده : '
              autoComplete='off'
              error={
                fileNumberTouched && fileNumberError
                  ? fileNumberError
                  : undefined
              }
              value={fileNumber}
              onChange={formik.handleChange}
            />
            <Input
              type='number'
              name='bandWidth'
              title='پهنا مرکز : '
              autoComplete='off'
              error={
                bandWidthTouched && bandWidthError ? bandWidthError : undefined
              }
              value={bandWidth}
              onChange={formik.handleChange}
            />
            <Input
              type='number'
              name='networkCableSize'
              title='اندازه کابل شبکه: '
              autoComplete='off'
              error={
                networkCableSizeTouched && networkCableSizeError
                  ? networkCableSizeError
                  : undefined
              }
              value={networkCableSize}
              onChange={formik.handleChange}
            />
            <Input
              type='number'
              name='portNumber'
              title='شماره پورت: '
              autoComplete='off'
              error={
                portNumberTouched && portNumberError
                  ? portNumberError
                  : undefined
              }
              value={portNumber}
              onChange={formik.handleChange}
            />
            <Input
              type='number'
              name='electricityCableSize'
              title='اندازه کابل برق: '
              autoComplete='off'
              error={
                electricityCableSizeTouched && electricityCableSizeError
                  ? electricityCableSizeError
                  : undefined
              }
              value={electricityCableSize}
              onChange={formik.handleChange}
            />
            <Input
              type='number'
              name='ladderSize'
              title='متراژ لدر: '
              autoComplete='off'
              error={
                ladderSizeTouched && ladderSizeError
                  ? ladderSizeError
                  : undefined
              }
              value={ladderSize}
              onChange={formik.handleChange}
            />
            <Input
              type='number'
              name='LTypeItemNumber'
              title='تعداد L سه سوراخه: '
              autoComplete='off'
              error={
                LTypeItemNumberTouched && LTypeItemNumberError
                  ? LTypeItemNumberError
                  : undefined
              }
              value={LTypeItemNumber}
              onChange={formik.handleChange}
            />

            <Input
              type='number'
              name='legsNumber'
              title='تعداد تسمه لدر: '
              autoComplete='off'
              error={
                legsNumberTouched && legsNumberError
                  ? legsNumberError
                  : undefined
              }
              value={legsNumber}
              onChange={formik.handleChange}
            />
            <div className='select-wrapper--high '>
              <label>نوع فیوز:</label>
              <Select
                onChange={(e) => {
                  setValues({ ...formik.values, fuse: e?.value ?? "" });
                }}
                isClearable
                placeholder='فیوز مربوطه را انتخاب کنید'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                defaultValue={{ value: item?._id, label: item?.fuse }}
                options={[
                  { value: "کارتی", label: "کارتی" },
                  { value: "مینیاتوری", label: "مینیاتوری" }
                ]}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              {fuseTouched && fuseError && (
                <p className='input-error--select'>{fuseError}</p>
              )}
            </div>

            <Input
              type='number'
              name='fuseAmpere'
              title='آمپر فیوز: '
              autoComplete='off'
              error={
                fuseAmpereTouched && fuseAmpereError
                  ? fuseAmpereError
                  : undefined
              }
              value={fuseAmpere}
              onChange={formik.handleChange}
            />
            <Input
              type='text'
              name='name'
              title='نام مرکز : '
              autoComplete='off'
              error={nameTouched && nameError ? nameError : undefined}
              value={name}
              onChange={formik.handleChange}
            />
            <SubmitButton
              title='ویرایش'
              loading={loading || provincesLoading || telecomsLoading}
              disabled={loading || provincesLoading || telecomsLoading}
              type='submit'
            />
          </form>
        </div>
      )}
    </>
  );
};

export default EditPap;
