import { CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import copy from "copy-to-clipboard";
import { useFormik } from "formik";
import moment, { Moment } from "jalali-moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker2";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import {
  AddCommentModalContent,
  DetailModalContent,
  ImageWrapper,
  Input,
  NoItem,
  SubmitButton
} from "../../../../core/components";
import { defaultFaultTablePageCount } from "../../../../core/constants";
import { defaultColumnDefinition } from "../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { BASE_API, TASKS_ROOT_API, TASK_TYPES } from "../../../../core/redux";
import {
  DoneTaskType,
  TaskCommentType,
  TasksType,
  UserType
} from "../../../../core/types";
import { fileDownload } from "../../../../core/utility";
import timeSince from "../../../../core/utility/fromNowTime";
import useIsMobile from "src/core/hooks/useIsMobile";
import { useQueryParams } from "src/core/hooks/useQueryParams";
import Loading from "src/core/components/Loading";

const DoneTasks = () => {
  const { fetchDoneTasks, deleteTaskPhoto, uploadTaskPhoto } = useActions();

  const { token } = useTypedSelector((state) => state.auth);
  const [addComment, setAddComment] = useState<null | TasksType>(null);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] =
    useState<null | TasksType>(null);
  const { loading, list, imageItem } = useTypedSelector((state) => state.task);

  const { searchParams, setSearchParams } = useQueryParams();
  const [currentPage, setCurrentPage] = useState<number>(
    +(searchParams("page") ?? 1) - 1
  );

  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const queryParams = useMemo(() => {
    return {
      limit: defaultFaultTablePageCount,
      page: currentPage + 1,
      sort: "-doneDate"
    };
  }, [currentPage]);

  // THIS IS FOR CURRENTPAGE AND LIMIT
  useEffect(() => {
    formik.handleSubmit();

    dispatch({ type: TASK_TYPES.RESET_IMAGE_ITEM });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const fetchTasks = useCallback(
    (values: { title: string; startDate: Moment; endDate: Moment }) => {
      const { title, startDate, endDate, ...rest } = values;

      fetchDoneTasks({
        ...queryParams,
        ...rest,
        "title[regex]": title,
        "createdAt[gt]": (startDate as Moment).toDate(),
        "createdAt[lt]": (endDate as Moment).toDate()
      });

      setSearchParams({
        ...queryParams,
        "title[regex]": title,
        "createdAt[gt]": (startDate as Moment).toDate(),
        "createdAt[lt]": (endDate as Moment).toDate()
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryParams]
  );

  const formik = useFormik({
    initialValues: {
      title: searchParams("title[regex]") ?? "",
      startDate: searchParams("createdAt[gt]")
        ? moment(searchParams("createdAt[gt]") ?? "")
        : moment().subtract(1, "month"),
      endDate: searchParams("createdAt[lt]")
        ? moment(searchParams("createdAt[lt]") ?? "")
        : moment()
    },
    validationSchema: yup.object({
      title: yup.string(),
      startDate: yup.mixed(),
      endDate: yup.mixed()
    }),
    isInitialValid: true,
    onSubmit: (values) => {
      fetchTasks(values);
    }
  });

  const hasAlbum = useCallback(
    (cellEvent: CellClickedEvent) =>
      list?.data?.find((task) => {
        return task._id === cellEvent?.data?._id;
      })?.albums?.length !== 0,
    [list?.data]
  );

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          {hasAlbum(cellEvent) && (
            <span
              onClick={(e) => {
                e.stopPropagation();

                dispatch({
                  type: TASK_TYPES.SELECTED_TASK_IMAGES,
                  payload: list?.data?.find((task) => {
                    return task._id === cellEvent?.data?._id;
                  })
                });
              }}>
              <i title='گالری' className='fas fa-images edit-icon'></i>
            </span>
          )}
          <span
            onClick={(e) => {
              e.stopPropagation();
              setAddComment(
                list?.data?.find((item) => {
                  return (
                    item._id?.toString() === cellEvent?.data[DoneTaskType._ID]
                  );
                }) ?? null
              );
            }}>
            <i title='توضیحات' className='fas fa-comment edit-icon'></i>
          </span>
          <span
            onClick={() => {
              setIsCommentModalOpen(cellEvent?.data);
            }}>
            <i title='اطلاعات تسک' className='fas fa-comment comment-icon' />
          </span>
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, token]
  );

  const { title, startDate, endDate } = formik.values;
  const {
    title: phoneError,
    startDate: startDateError,
    endDate: endDateError
  } = formik.errors;
  const {
    title: phoneTouched,
    startDate: startDateTouched,
    endDate: endDatetouched
  } = formik.touched;

  const rowData = useCallback(() => {
    return list?.data.map((item, index) => {
      return {
        [DoneTaskType.ROW]: `${index + 1 + currentPage * 10}`,
        [DoneTaskType._ID]: item._id,
        [DoneTaskType.TITLE]: item.title,
        [DoneTaskType.USERID]: (item.userId as UserType)?.fullName,
        [DoneTaskType.ASSIGNEDTO]: (item.assignedTo as UserType)?.fullName,
        [DoneTaskType.DESCRIPTION]: item.description,
        [DoneTaskType.CREATEDAT]: new Date(
          item.createdAt ?? ""
        ).toLocaleDateString("fa-IR"),
        [DoneTaskType.DURATION]:
          item.createdAt && `${timeSince(new Date(item.createdAt))} قبل`,
        [DoneTaskType.FINISHEDDAT]: new Date(
          item.doneDate ?? ""
        ).toLocaleDateString("fa-IR")
      };
    });
  }, [list?.data, currentPage]);

  return (
    <>
      {!!addComment && (
        <AddCommentModalContent
          faultPhone={addComment.phone}
          isOpen={!!addComment}
          comments={
            addComment ? (addComment.comments as Array<TaskCommentType>) : []
          }
          onlyComments
          onClose={() => {
            setAddComment(null);
          }}
        />
      )}
      <DetailModalContent
        content={isCommentModalOpen}
        isOpen={!!isCommentModalOpen}
        title={"توضیحات تسک"}
        onClose={() => {
          setIsCommentModalOpen(null);
        }}
        message={
          (isCommentModalOpen as unknown as any)?.[DoneTaskType.DESCRIPTION]
        }
      />
      <form
        className='clients-search-wrapper'
        autoComplete='on'
        onSubmit={(e) => {
          setCurrentPage(0);
          formik.handleSubmit(e);
        }}>
        <div className='list-input-wrapper'>
          <div className='select-wrapper--high'>
            <Input
              name='title'
              title=' عنوان  : '
              autoComplete='off'
              error={phoneTouched && phoneError ? phoneError : undefined}
              value={title}
              onChange={formik.handleChange}
            />
          </div>
          <div className='select-wrapper--high'>
            <label> تاریخ شروع :</label>

            <DatePicker
              isGregorian={false}
              onChange={(value) => {
                formik.setFieldValue("startDate", value, true);
              }}
              value={startDate}
              timePicker={false}
              max={moment()}
            />
            {startDateTouched && startDateError && (
              <p className='input-error--select'>
                {startDateError as unknown as string}
              </p>
            )}
          </div>
          <div className='select-wrapper--high'>
            <label> تاریخ پایان :</label>

            <DatePicker
              isGregorian={false}
              onChange={(value) => {
                formik.setFieldValue("endDate", value, true);
              }}
              timePicker={false}
              value={endDate}
              max={moment()}
            />
            {endDatetouched && endDateError && (
              <p className='input-error--select'>
                {endDateError as unknown as string}
              </p>
            )}
          </div>

          <SubmitButton
            type='submit'
            title='ثبت'
            loading={loading}
            disabled={loading}
          />
        </div>
      </form>

      <div className='main-route-wrapper'>
        <div className='table-wrapper'>
          <div className='list-top'>
            <p>
              تعداد کل تسک ها
              <span>{list && list.total} عدد</span>
              {list &&
                list?.data &&
                list.data.length !== 0 &&
                !isLoadingDownload && (
                  <i
                    onClick={(e) => {
                      e.stopPropagation();
                      const url = BASE_API + TASKS_ROOT_API + "/doneTasks";
                      fileDownload(
                        url,
                        token ?? "",
                        "doneTasks.xlsx",
                        {
                          xlsx: true,
                          "title[regex]": title,
                          "createdAt[gt]": (startDate as Moment).toDate(),
                          "createdAt[lt]": (endDate as Moment).toDate()
                        },
                        setIsLoadingDownload
                      );
                    }}
                    className='fas fa-file-excel excel-icon'></i>
                )}
              <Loading
                size={14}
                color='rgb(50, 119, 50)'
                loading={isLoadingDownload}
              />
            </p>
          </div>

          {(!list || list.count === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}

          {list && list.count !== 0 && (
            <>
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  animateRows={true}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  rowSelection={"single"}
                  enableRtl={true}
                  onCellClicked={(e) => {
                    if (e.colDef.field !== DoneTaskType.ACTIONS) copy(e.value);
                  }}>
                  <AgGridColumn
                    sortable={true}
                    field={DoneTaskType.ROW}
                    width={84}
                  />
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={DoneTaskType.ACTIONS}
                    minWidth={160}
                    flex={1}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DoneTaskType.TITLE}
                    width={240}
                  />

                  <AgGridColumn
                    sortable={true}
                    field={DoneTaskType.USERID}
                    minWidth={180}
                    maxWidth={180}
                  />

                  <AgGridColumn
                    sortable={true}
                    field={DoneTaskType.ASSIGNEDTO}
                    minWidth={150}
                    maxWidth={150}
                  />

                  <AgGridColumn
                    sortable={true}
                    field={DoneTaskType.CREATEDAT}
                    minWidth={120}
                    maxWidth={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DoneTaskType.DURATION}
                    minWidth={200}
                    maxWidth={200}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DoneTaskType.FINISHEDDAT}
                    minWidth={150}
                  />
                </AgGridReact>
              </div>
            </>
          )}
        </div>
        <ReactPaginate
          marginPagesDisplayed={isMobile ? 1 : 3}
          forcePage={currentPage}
          breakLabel='...'
          nextLabel='بعدی'
          onPageChange={(e) => {
            setCurrentPage(e.selected);
          }}
          pageRangeDisplayed={5}
          pageCount={
            (list && Math.ceil(list.total / defaultFaultTablePageCount)) ?? 1
          }
          previousLabel='قبلی'
          containerClassName='pagination-container'
        />
      </div>
      {imageItem && (
        <ImageWrapper
          noDelete
          albums={imageItem?.albums?.map((album) => {
            return {
              ...album,
              path:
                BASE_API +
                TASKS_ROOT_API +
                "/photo/" +
                imageItem._id +
                "?photoId=" +
                album.path
            };
          })}
          onDelete={(albumItemId) => {
            deleteTaskPhoto(albumItemId, imageItem?._id ?? "");
          }}
          onSuccess={(file) => {
            uploadTaskPhoto(file, {});
          }}
        />
      )}
    </>
  );
};

export default DoneTasks;
