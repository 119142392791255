import axios from "axios";
import { BASE_API } from "../redux";
import { removeFalsyValuesObject } from "../utility";

const customizedAxios = axios.create({
  baseURL: `${BASE_API}`,
  headers: {
    "Content-Type": "application/json"
  }
});

customizedAxios.interceptors.request.use(function (config) {
  const params = new URLSearchParams();

  if (config.params) {
    for (let [key, value] of Object.entries(
      removeFalsyValuesObject(config.params)
    )) {
      if (value && Array.isArray(value)) {
        value.forEach((val) => {
          params.append(key + "[in]", val + "");
        });
      } else if (value !== null && value !== undefined) {
        params.append(key.toString(), (value as string).toString());
      }
    }
    return { ...config, params };
  }
  return config;
});

export { customizedAxios };
