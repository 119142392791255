import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { getCatch } from "../../catch";
import { customizedAxios } from "../../config";
import {
  AddFaultSubmitType,
  AdvancedResult,
  AlbumSchema,
  AssignAbleUsers,
  ConcludeNeedExpertType,
  DeleteType,
  FaultsStatus,
  FaultStaticType,
  FaultStaticUserType,
  FaultType,
  ItemType,
  NeedExpertType,
  QueryParams,
  TempFaultsTable,
  TempFaultsType,
  UploadType
} from "../../types";

import { createFormData } from "../../constants";
import { FaultsActionTypes } from "../actionTypes";
import { DONE_FAULTS_API, FAULTS_ROOT_API } from "../api";
import { store } from "../store";
import { FAULT_TYPES } from "../types";

export const fetchTempFaults =
  () => async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = FAULTS_ROOT_API.concat("/temp");
      const res = await customizedAxios.get<ItemType<TempFaultsType[]>>(
        url,
        config
      );
      dispatch({
        type: FAULT_TYPES.TEMP_FAULTS_LIST,
        payload: res.data.data
      });

      if (res.data.data && res.data.data.length !== 0) {
        res.data.data.forEach((temp, index) => {
          let url = FAULTS_ROOT_API.concat("/status");
          setTimeout(() => {
            customizedAxios
              .get<ItemType<FaultsStatus>>(url, {
                ...config,
                params: {
                  uuid: temp[TempFaultsTable.UUID],
                  phone: temp[TempFaultsTable.ADSL]
                }
              })
              .then((res) => {
                dispatch({
                  type: FAULT_TYPES.FAULT_STATUS_ITEM,
                  payload: res.data.data
                });
              });
          }, index * 100);
        });
      }
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const deleteTempFault =
  (faultUuid: string) => async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };
      let url = FAULTS_ROOT_API.concat("/temp/" + faultUuid);
      await customizedAxios.delete(url, config);
      dispatch({
        type: FAULT_TYPES.DELETE_TEMP_FAULT,
        payload: faultUuid
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const getTempFault =
  (faultUuid: string) => async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };
      let url = FAULTS_ROOT_API.concat("/temp/" + faultUuid);
      const res = await customizedAxios.get<ItemType<TempFaultsType>>(
        url,
        config
      );
      dispatch({
        type: FAULT_TYPES.TEMP_FAULT_ITEM,
        payload: res.data.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const getFaultStatus =
  (faultUuid: string) => async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };
      let url = FAULTS_ROOT_API.concat("/status/" + faultUuid);
      const res = await customizedAxios.get<ItemType<FaultsStatus>>(
        url,
        config
      );
      dispatch({
        type: FAULT_TYPES.FAULT_STATUS_ITEM,
        payload: res.data.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const getFault =
  (faultUuid: string) => async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };
      let url = FAULTS_ROOT_API.concat("/" + faultUuid);
      const res = await customizedAxios.get<ItemType<FaultType>>(url, config);
      dispatch({
        type: FAULT_TYPES.FAULT_ITEM,
        payload: res.data.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const deleteFault =
  (faultUuid: string) => async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };
      let url = FAULTS_ROOT_API.concat("/" + faultUuid);
      await customizedAxios.delete(url, config);
      dispatch({
        type: FAULT_TYPES.DELETE_TEMP_FAULT,
        payload: faultUuid
      });
      window.openToastSuccess("خرابی با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const fetchFaults =
  (body: QueryParams) => async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: body
      };
      let url = encodeURI(FAULTS_ROOT_API);

      const res = await customizedAxios.get<AdvancedResult<FaultType>>(
        url,
        config
      );
      dispatch({
        type: FAULT_TYPES.FAULTS_LIST,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const fetchFaultsStatics =
  (params: QueryParams) => async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params
      };
      let url = encodeURI(FAULTS_ROOT_API).concat("/statistics/telecom");

      const res = await customizedAxios.get<AdvancedResult<FaultStaticType>>(
        url,
        config
      );
      dispatch({
        type: FAULT_TYPES.FAULTS_LIST_STATICS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const fetchDoneFaults =
  (body: QueryParams) => async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: body
      };

      let url = encodeURI(DONE_FAULTS_API);
      const res = await customizedAxios.get<AdvancedResult<FaultType>>(
        url,
        config
      );
      dispatch({
        type: FAULT_TYPES.FAULTS_LIST,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const editFault =
  (body: Partial<FaultType>, faultId: string, back = true) =>
  async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = FAULTS_ROOT_API.concat("/edit/", faultId);
      await customizedAxios.put<FaultType>(url, body, config);
      dispatch({
        type: FAULT_TYPES.DISABLE_LOADING
      });
      if (back) window.navigate(-1);
      window.openToastSuccess("خرابی با موفقیت ویرایش شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const concludeAFault =
  (faultId: string, back = true) =>
  async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = FAULTS_ROOT_API.concat("/conclude/", faultId);
      await customizedAxios.put<FaultType>(url, {}, config);
      dispatch({
        type: FAULT_TYPES.DISABLE_LOADING
      });
      if (back) window.navigate(-1);
      window.openToastSuccess("خرابی با موفقیت اتمام یافت");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const concludeNeedExpert =
  (file: File, { _id, price, priceList, comment }: ConcludeNeedExpertType) =>
  async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      let bodyData = createFormData(file, {
        price,
        priceList,
        comment
      });

      const config: AxiosRequestConfig = {
        headers: {
          "Content-Type": "multipart/form-data; boundary=form._boundary",
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = FAULTS_ROOT_API.concat("/needExpert/conclude/" + _id);
      await customizedAxios.put(url, bodyData, config);
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });

      window.openToastSuccess("خرابی با موفقیت بسته شد");
      window.navigate(-1);
      return;
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const editNeedExpert =
  (body: Partial<NeedExpertType>, faultId: string) =>
  async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = FAULTS_ROOT_API.concat("/needExpert/edit/" + faultId);
      await customizedAxios.put(url, body, config);
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });

      window.openToastSuccess("رضایت مشترک ثبت شد");
      return true;
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const cancelNeedExpert =
  (faultId: string) => async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = FAULTS_ROOT_API.concat("/needExpert/cancel/" + faultId);
      await customizedAxios.put(url, {}, config);
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });

      window.openToastSuccess("خرابی با موفقیت لغو شد");
      return;
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const changeAssignment =
  (body: { faultId: string; assignedTo: string }) =>
  async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = FAULTS_ROOT_API.concat("/changeUser");
      await customizedAxios.put<FaultType>(url, body, config);
      dispatch({
        type: FAULT_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("ارجاع خرابی با موفقیت انجام شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const changeMultipleAssignment =
  (body: { faultId: string[]; assignedTo: string }) =>
  async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = FAULTS_ROOT_API.concat("/changeUserMultiple");
      await customizedAxios.put<FaultType>(url, body, config);
      dispatch({
        type: FAULT_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("ارجاع خرابی ها با موفقیت انجام شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const addCommentFault =
  (body: { faultId: string; comment: { desc: string } }) =>
  async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = FAULTS_ROOT_API.concat("/comments");
      await customizedAxios.put<FaultType>(url, body, config);
      dispatch({
        type: FAULT_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("توضیح با موفقیت ثبت شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const fetchAssignAbleUsers =
  () => async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = FAULTS_ROOT_API.concat("/assignableUsers");
      const res = await customizedAxios.get<{ data: AssignAbleUsers[] }>(
        url,
        config
      );
      dispatch({
        type: FAULT_TYPES.FAULT_ASSIGNABLE_USERS,
        payload: res.data.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const addFault =
  (body: AddFaultSubmitType, isClone?: boolean) =>
  async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = FAULTS_ROOT_API.concat("/insert");
      const res = await customizedAxios.post<ItemType<FaultType>>(
        url,
        body,
        config
      );
      if (!isClone) {
        dispatch({
          type: FAULT_TYPES.ADD_FAULT,
          payload: res.data.data
        });
      }
      window.openToastSuccess("خرابی با موفقیت افزوده شد");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const uploadXLSXFIle =
  (file: File) => async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      let bodyData = createFormData(file);

      const config: AxiosRequestConfig = {
        headers: {
          "Content-Type": "multipart/form-data; boundary=form._boundary",
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };
      let url = FAULTS_ROOT_API.concat("/insert/excel");
      await customizedAxios.put<ItemType<TempFaultsType[]>>(
        url,
        bodyData,
        config
      );
      dispatch({
        type: FAULT_TYPES.DISABLE_LOADING
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const uploadFaultPhoto =
  (file: File, { _id, category, title }: AlbumSchema) =>
  async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      let bodyData = createFormData(file, { title, category });

      const config: AxiosRequestConfig = {
        headers: {
          "Content-Type": "multipart/form-data; boundary=form._boundary",
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = FAULTS_ROOT_API.concat("/photo/" + _id);
      const res = await customizedAxios.put<UploadType<FaultType>>(
        url,
        bodyData,
        config
      );
      dispatch({
        type: FAULT_TYPES.FAULT_UPLOAD,
        payload: res.data.data.albums ?? []
      });
      window.openToastSuccess("تصویر با موفقیت افزوده شد");
      window.navigate(-1);
      return res.data.data;
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const deleteFaultPhoto =
  (id: string, Id: string) => async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        data: {
          id
        }
      };

      let url = FAULTS_ROOT_API.concat("/photo/" + Id);
      const res = await customizedAxios.delete<DeleteType<FaultType>>(
        url,
        config
      );

      dispatch({
        type: FAULT_TYPES.FAULT_UPLOAD,
        payload: res.data.data.albums ?? []
      });
      window.openToastSuccess("تصویر با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const fetchFaultsUserStatics =
  (params: QueryParams) => async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params
      };
      let url = encodeURI(FAULTS_ROOT_API).concat("/statistics/user");

      const res = await customizedAxios.get<
        AdvancedResult<FaultStaticUserType>
      >(url, config);
      dispatch({
        type: FAULT_TYPES.FAULTS_LIST_USER_STATICS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };

export const getFaultStatusByPhone =
  (faultPhone: string) => async (dispatch: Dispatch<FaultsActionTypes>) => {
    try {
      dispatch({ type: FAULT_TYPES.FAULT_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: { phone: faultPhone }
      };
      let url = encodeURI(FAULTS_ROOT_API).concat("/status");

      const res = await customizedAxios.get<ItemType<FaultsStatus>>(
        url,
        config
      );

      dispatch({
        type: FAULT_TYPES.FAULT_STATUS_ITEM_BY_PHONE,
        payload: res.data.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: FAULT_TYPES.DISABLE_LOADING });
    }
  };
