import { css } from "@emotion/react";
import axios, { AxiosRequestConfig } from "axios";
import React, { ImgHTMLAttributes, memo, useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { getCatch } from "../catch";
import { useTypedSelector } from "../hooks";
import { BASE_API, USER_ROOT_API } from "../redux";
import Loading from "./Loading";

const override = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  line-height: 0.5rem;
`;

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  _id: string;
  photo?: string;
}

const _UserPhotoLoader: React.FC<ImageProps> = ({
  alt,
  loading = "lazy",
  _id,
  photo,
  ...rest
}) => {
  const { token, me } = useTypedSelector((state) => state.auth);
  const [loader, setLoader] = useState<boolean>(true);
  const [openLightBox, setOpenLightBox] = useState<boolean>(false);

  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    if (photo)
      try {
        setLoader(true);
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${token}`
          },
          responseType: "blob"
        };

        axios
          .get<Blob>(
            BASE_API +
              "/" +
              USER_ROOT_API +
              "/photo/" +
              _id +
              "?photoId=" +
              photo,
            config
          )
          .then((res) => {
            setImageUrl(URL.createObjectURL(res.data));
          });
      } catch (err: any) {
        const { message } = getCatch(err);
        window.openToastError(message);
      } finally {
        setLoader(false);
      }
  }, [token, me, photo, _id]);

  if (imageUrl) {
    return (
      <>
        <Loading override={override} color='blue' loading={loader} />
        {openLightBox && (
          <Lightbox
            mainSrc={imageUrl}
            onCloseRequest={() => {
              setOpenLightBox(false);
            }}
            onMovePrevRequest={() => {}}
            onMoveNextRequest={() => {}}
          />
        )}

        <img
          onLoad={() => {
            setLoader(false);
          }}
          onError={() => {
            setLoader(false);
          }}
          onClick={() => {
            setOpenLightBox(true);
          }}
          className={"user-image"}
          loading={loading}
          alt={alt}
          src={imageUrl}
          {...rest}
        />
      </>
    );
  }
  return (
    <>
      <i className='fa fa-user-circle users_profile-icon'></i>
    </>
  );
};

const UserPhotoLoader = memo(_UserPhotoLoader);

export { UserPhotoLoader };
