import { useFormik } from "formik";
import { useCallback, useEffect } from "react";
import Select, { SingleValue } from "react-select";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import { PRIORITY_TYPES_LIST } from "../../../../core/constants";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { AddTaskTypes } from "../../../../core/types/TaskTypes";

const AddTask = () => {
  const { addTask, fetchAssignAbleTaskUsers } = useActions();
  const { loading, assignAbleUsers } = useTypedSelector((state) => state.task);

  const formik = useFormik({
    initialValues: {
      title: "",
      priority: "",
      assignedTo: "",
      description: "",
      comments: ""
    },
    validationSchema: yup.object({
      title: yup.string().required(" عنوان الزامی است"),
      priority: yup.string().required("الویت الزامی است"),
      assignedTo: yup.string().required("ارجاع به الزامی است"),
      description: yup.string().required(" توضیحات الزامی است")
    }),
    onSubmit: ({
      title,
      priority,
      assignedTo,
      description,
      comments
    }: AddTaskTypes) => {
      addTask({
        title,
        priority,
        assignedTo,
        description,
        comments: comments ? [{ desc: comments }] : null
      });
    }
  });

  const { setFieldValue } = formik;
  const { title, priority, description, comments } = formik.values;
  const {
    title: titleError,
    priority: priorityError,
    assignedTo: assignedToError,
    description: descriptionError
  } = formik.errors;
  const {
    title: titleTouched,
    priority: priorityTouched,
    description: descriptionTouched,
    assignedTo: assignedToTouched
  } = formik.touched;

  const getAssignAbleUsersList = useCallback(() => {
    return assignAbleUsers?.map((item: { _id: string; fullName: string }) => {
      return { value: item._id, label: item.fullName };
    });
  }, [assignAbleUsers]);

  useEffect(() => {
    fetchAssignAbleTaskUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'>اضافه کردن تسک جدید</h5>
        <form autoComplete='on' onSubmit={formik.handleSubmit}>
          <Input
            name='title'
            title='عنوان'
            autoComplete='off'
            error={titleTouched && titleError ? titleError : undefined}
            value={title}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='description'
            title='توضیحات'
            autoComplete='off'
            error={
              descriptionTouched && descriptionError
                ? descriptionError
                : undefined
            }
            value={description}
            multiple
            onChange={formik.handleChange}
            type='text'
          />
          <div className='select-wrapper--high '>
            <label>اولویت :</label>
            <Select
              onChange={(e) => {
                setFieldValue("priority", e?.value ?? "", true);
              }}
              value={
                PRIORITY_TYPES_LIST?.find((priorityEl) => {
                  return priorityEl.value + "" === priority;
                }) ?? null
              }
              isClearable
              placeholder='اولویت مربوطه را انتخاب کنید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={PRIORITY_TYPES_LIST}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {priorityTouched && priorityError && (
              <p className='input-error--select'>{priorityError}</p>
            )}
          </div>
          <div className='select-wrapper--high '>
            <label> به چه کسی ارجاع می کنید :</label>
            <Select
              onChange={(e: SingleValue<{ label: string; value: string }>) => {
                setFieldValue("assignedTo", e?.value ?? "", true);
              }}
              isClearable
              placeholder=' لطفا کاربر مورد نظر را انتخاب نمایید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={getAssignAbleUsersList()}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {assignedToTouched && assignedToError && (
              <p className='input-error--select'>{assignedToError}</p>
            )}
          </div>
          <Input
            name='comments'
            title='توضیحات اضافی'
            autoComplete='off'
            value={comments}
            multiline
            onChange={formik.handleChange}
            type='text'
          />
          <SubmitButton
            title='ثبت'
            loading={loading}
            disabled={loading}
            type='submit'
          />
        </form>
      </div>
    </>
  );
};

export default AddTask;
