import { MongoObject } from ".";
import { USER_ACCESS } from "../constants";

export enum UserTable {
  _ID = "_id",
  EMAIL = "ایمیل",
  MOBILENUMBER = "شماره همراه",
  FULLNAME = "نام و نام خانوادگی",
  NATIONAL_CODE = "کد ملی",
  CREATEDAT = "تاریخ ایجاد",
  UPDATEDAT = "تاریخ اخرین تغییر",
  PHOTO = "تصویر",
  ACTIONS = "عملیات",
  ROW = "ردیف",
  ACCESS = "دسترسی ها",
  STATUS = "وضعیت",
  ADDRESS = "آدرس"
}

export enum UserAccessTable {
  _ID = "_id",
  SECTION = "بخش",
  PROVINCES = "استان ها",
  CITIES = "شهر ها",
  TELECOMS = " مراکز ",
  CREATEDAT = "تاریخ ایجاد",
  UPDATEDAT = "تاریخ اخرین تغییر",
  ACTIONS = "عملیات"
}

export enum USER_ROLES {
  USER = "user",
  ADMIN = "admin"
}
export enum USER_LEVEL {
  ONE = "سطح یک",
  TWO = "سطح دو",
  THREE = "سطح سه"
}

export enum CRUD_TYPES {
  WRITE = "WRITE",
  DELETE = "DELETE",
  EDIT = "EDIT"
}

export interface TelecomAccessDocument {
  telecomId: string;
  crud: CRUD_TYPES[];
}

export interface CityAccessDocument {
  cityId: string;
  crud: CRUD_TYPES[];
}

export interface ProvinceAccessDocument {
  provinceId: string;
  cities?: CityAccessDocument[];
  telecoms?: TelecomAccessDocument[];
  crud?: CRUD_TYPES[];
}

export interface SectionAccessDocument extends MongoObject {
  section: USER_ACCESS;
  province: ProvinceAccessDocument[];
  crud?: CRUD_TYPES[];
}
export interface UserType extends MongoObject {
  email: string;
  mobileNumber: string;
  fullName: string;
  level?: USER_LEVEL;
  role?: USER_ROLES;
  access?: SectionAccessDocument[];
  photo?: string;
  nationalCode?: string;
  address?: string;
  status?: "active" | "inactive";
}
export interface UserPasswordTypes extends MongoObject {
  password?: string;
  passwordConfirmation?: string;
}
export interface UserPanelSmsTypes extends MongoObject {
  apikey: string;
  phone: string;
}

export interface UserAddtype extends MongoObject {
  fullName: string;
  mobileNumber: string;
  password: string;
  email: string;
  nationalCode?: string;
  address?: string;
}

export interface UserEditType extends MongoObject {
  email: string;
  mobileNumber: string;
  fullName: string;
  nationalCode?: string;
  address?: string;
  status?: "active" | "inactive";
}

export interface SmsSendTypes extends MongoObject {
  description: string;
  receiver: string;
}

export interface SmsCategoryTypes extends MongoObject {
  category: string;
}

export interface SmsTypes extends MongoObject {
  message: string;
}
