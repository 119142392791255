import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../../core/components";
import { useActions, useTypedSelector } from "../../../../../core/hooks";
import { DCRackSchema } from "../../../../../core/types";

const EditRack = () => {
  let [searchParams] = useSearchParams();
  const dataCenterId = searchParams.get("dataCenterId");
  const rackId = searchParams.get("rackId");
  const { editDcRack, fetchOneDataCenter } = useActions();
  const { loading, item } = useTypedSelector((state) => state.dataCenter);

  const selectedItem = useMemo(() => {
    return item && rackId && item?.racks?.find((item) => item._id === rackId);
  }, [item, rackId]);

  const formik = useFormik({
    initialValues: {
      propertyNumber:
        selectedItem && selectedItem?.propertyNumber
          ? selectedItem.propertyNumber
          : "",
      serialNumber:
        selectedItem && selectedItem?.serialNumber
          ? selectedItem.serialNumber
          : "",
      rackNumber:
        selectedItem && selectedItem?.rackNumber ? selectedItem.rackNumber : "",
      description:
        selectedItem && selectedItem?.description
          ? selectedItem.description
          : "",

      unit: selectedItem && selectedItem?.unit ? selectedItem.unit : "",
      room: selectedItem && selectedItem?.room ? selectedItem.room : "",
      dimension:
        selectedItem && selectedItem?.dimension ? selectedItem.dimension : "",
      aisle: selectedItem && selectedItem?.aisle ? selectedItem.aisle : "",
      powerModuleDesc:
        selectedItem && selectedItem?.powerModuleDesc
          ? selectedItem.powerModuleDesc
          : ""
    },
    validationSchema: yup.object({
      rackNumber: yup.string().trim().required("شماره رک الزامی است"),
      propertyNumber: yup.string().trim(),
      serialNumber: yup.string().trim(),
      room: yup.string().trim().required("سالن الزامی است"),
      dimension: yup.string().trim(),
      description: yup.string().trim(),
      unit: yup.string().required("تعداد یونیت الزامی است").trim(),
      aisle: yup.string().trim().required("راهرو الزامی است"),
      powerModuleDesc: yup.string().trim()
    }),
    onSubmit: ({
      rackNumber,
      room,
      aisle,
      dimension,
      powerModuleDesc,
      propertyNumber,
      serialNumber,
      unit,
      description
    }: DCRackSchema) => {
      editDcRack(
        {
          id: rackId,
          rackNumber,
          room,
          aisle,
          dimension,
          powerModuleDesc,
          propertyNumber,
          serialNumber,
          unit,
          description
        },
        item?._id ?? ""
      );
    }
  });
  useEffect(() => {
    if (dataCenterId) {
      fetchOneDataCenter(dataCenterId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCenterId]);

  const {
    rackNumber,
    room,
    aisle,
    dimension,
    powerModuleDesc,
    propertyNumber,
    serialNumber,
    unit,
    description
  } = formik.values;
  const {
    rackNumber: rackNumberError,
    propertyNumber: propertyNumberError,
    serialNumber: serialNumberError,
    room: roomError,
    description: descriptionError,
    unit: unitError,
    aisle: aisleError,
    powerModuleDesc: powerModuleDescError,
    dimension: dimensionError
  } = formik.errors;
  const {
    rackNumber: rackNumberTouched,
    propertyNumber: propertyNumberTouched,
    serialNumber: serialNumberTouched,
    room: roomTouched,
    description: descriptionTouched,
    unit: unitTouched,
    aisle: aisleTouched,
    powerModuleDesc: powerModuleDescTouched,
    dimension: dimensionTouched
  } = formik.touched;

  const { setValues } = formik;

  const rooms = useMemo(() => {
    if (item && item != null && Array.isArray(item.racks)) {
      return [
        ...new Set(
          item.racks.map((item) => {
            return item.room;
          })
        )
      ];
    }
    return [];
  }, [item]);

  const aisles = useMemo(() => {
    if (item && item != null && Array.isArray(item.racks)) {
      return [
        ...new Set(
          item.racks.map((item) => {
            return item.aisle;
          })
        )
      ];
    }
    return [];
  }, [item]);

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'>ویرایش رک</h5>
        {item && item !== null && selectedItem && (
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <Input
              name='rackNumber'
              title='شماره رک  : '
              autoComplete='off'
              error={
                rackNumberTouched && rackNumberError
                  ? rackNumberError
                  : undefined
              }
              value={rackNumber}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='room'
              title='اتاق : '
              autoComplete='off'
              error={roomTouched && roomError ? roomError : undefined}
              value={room}
              type='text'
              onChange={formik.handleChange}
            />

            <div className='select-chip'>
              {rooms.map((room) => {
                return (
                  <div
                    className='chip'
                    onClick={() => {
                      setValues({
                        ...formik.values,
                        room: room?.toString() ?? ""
                      });
                    }}>
                    {room?.toString()}
                  </div>
                );
              })}
            </div>
            <Input
              name='aisle'
              title='راهرو : '
              autoComplete='off'
              error={aisleTouched && aisleError ? aisleError : undefined}
              value={aisle}
              type='text'
              onChange={formik.handleChange}
            />

            <div className='select-chip'>
              {aisles.map((aisle) => {
                return (
                  <div
                    className='chip'
                    onClick={() => {
                      setValues({
                        ...formik.values,
                        aisle: aisle?.toString() ?? ""
                      });
                    }}>
                    {aisle?.toString()}
                  </div>
                );
              })}
            </div>

            <Input
              name='unit'
              title='تعداد یونیت : '
              autoComplete='off'
              error={unitTouched && unitError ? unitError : undefined}
              value={unit}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='dimension'
              title='ابعاد  : '
              autoComplete='off'
              error={
                dimensionTouched && dimensionError ? dimensionError : undefined
              }
              value={dimension}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='powerModuleDesc'
              title='پاور ماژول  : '
              autoComplete='off'
              error={
                powerModuleDescTouched && powerModuleDescError
                  ? powerModuleDescError
                  : undefined
              }
              value={powerModuleDesc}
              onChange={formik.handleChange}
              type='text'
            />

            <Input
              name='propertyNumber'
              title='شماره اموال : '
              autoComplete='off'
              error={
                propertyNumberTouched && propertyNumberError
                  ? propertyNumberError
                  : undefined
              }
              value={propertyNumber}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='serialNumber'
              title='شماره سریال : '
              autoComplete='off'
              error={
                serialNumberTouched && serialNumberError
                  ? serialNumberError
                  : undefined
              }
              value={serialNumber}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='description'
              title='توضیحات  : '
              multiline
              autoComplete='off'
              error={
                descriptionTouched && descriptionError
                  ? descriptionError
                  : undefined
              }
              value={description}
              onChange={formik.handleChange}
              type='text'
            />

            <SubmitButton
              title='ثبت'
              loading={loading}
              disabled={loading}
              type='submit'
            />
          </form>
        )}
      </div>
    </>
  );
};

export default EditRack;
