import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DeleteModalContent, SubmitButton } from "../../../../core/components";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { SmsType } from "../../../../core/types";

const CategoryList = ({
  setSelectedCategory,
  selectedCategory
}: {
  setSelectedCategory: (...args: SmsType[]) => void;
  selectedCategory?: SmsType;
}) => {
  const navigator = useNavigate();

  const { fetchSms, deleteSmsCategory } = useActions();
  const { loading, list } = useTypedSelector((state) => state.notification);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<SmsType | null>(null);

  useEffect(() => {
    fetchSms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccessDeleteModal = useCallback(() => {
    deleteSmsCategory(selectedItem?._id as string);

    setIsOpen(false);
    setSelectedItem(null);
    setSelectedCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, deleteSmsCategory]);

  return (
    <>
      {isOpen && (
        <DeleteModalContent
          isOpen={isOpen}
          title={"حذف دسته بندی " + (selectedItem && selectedItem?.category)}
          message='آیا از حذف دسته بندی مطمئن هستید؟'
          onCancel={() => {
            setIsOpen(false);
            setSelectedItem(null);
          }}
          onSuccess={onSuccessDeleteModal}
        />
      )}
      <div className='main-route-wrapper-top-2'>
        <div className='list-top'>
          <p>دسته ها</p>
        </div>
        <div className='category-wrapper'>
          {list?.data?.map((el: SmsType, i: number) => {
            return (
              <span
                className={`category-span ${
                  el.category === selectedCategory?.category
                    ? "category-span-selected"
                    : ""
                }`}
                key={i}
                onClick={(e) => {
                  e.stopPropagation();

                  setSelectedCategory(el);
                }}>
                <p> {el.category}</p>

                <i
                  onClick={() => navigator(`/sms/edit-category?_id=${el?._id}`)}
                  title='ویرایش'
                  className='fas fa-pen edit-icon  '></i>

                <i
                  onClick={() => {
                    setSelectedItem(el);
                    setIsOpen(true);
                  }}
                  title='حذف'
                  className='fas fa-trash delete-icon'></i>
              </span>
            );
          })}
        </div>
        <div className='section-half'>
          <Link to={"/sms/add-category"}>
            <SubmitButton loading={loading} title='اضافه کردن دسته جدید' />
          </Link>
        </div>
      </div>
    </>
  );
};

export default CategoryList;
