import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../../../core/components";
import { useActions, useTypedSelector } from "../../../../../../core/hooks";
import { LineCardSchema } from "../../../../../../core/types/PapsTypes/ShelfTypes/LineCardTypes";

const AddLineCard = () => {
  const { addLineCard } = useActions();
  let [searchParams] = useSearchParams();
  const papId = searchParams.get("papId");
  const _id = searchParams.get("shelfId");

  const { loading } = useTypedSelector((state) => state.pap);

  const formik = useFormik({
    initialValues: {
      cardNumber: "",
      installers: "",
      propertyNumber: "",
      serialNumber: "",
      comment: ""
    },
    validationSchema: yup.object({
      cardNumber: yup.number().required("شماره کارت الزامی است"),
      installers: yup.string().trim(),
      propertyNumber: yup.string().trim(),
      serialNumber: yup.string().trim(),
      comment: yup.string().trim()
    }),
    onSubmit: ({
      shelfId = _id ?? "",
      cardNumber,
      installers,
      propertyNumber,
      serialNumber,
      comment
    }: LineCardSchema) => {
      addLineCard(
        {
          cardNumber,
          installers,
          propertyNumber,
          serialNumber,
          comment,
          shelfId
        },
        papId ?? ""
      );
    }
  });

  const { cardNumber, installers, propertyNumber, serialNumber, comment } =
    formik.values;
  const {
    cardNumber: cardNumberError,
    installers: installersError,
    propertyNumber: propertyNumberError,
    serialNumber: serialNumberError,
    comment: commentError
  } = formik.errors;
  const {
    cardNumber: cardNumberTouched,
    installers: installersTouched,
    propertyNumber: propertyNumberTouched,
    serialNumber: serialNumberTouched,
    comment: commentTouched
  } = formik.touched;

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'>اضافه کردن لاین کارت جدید</h5>
        <form autoComplete='on' onSubmit={formik.handleSubmit}>
          <Input
            name='cardNumber'
            title='شماره کارت : '
            autoComplete='off'
            error={
              cardNumberTouched && cardNumberError ? cardNumberError : undefined
            }
            value={cardNumber}
            type='number'
            onChange={formik.handleChange}
          />
          <Input
            name='propertyNumber'
            title='شماره اموال : '
            autoComplete='off'
            error={
              propertyNumberTouched && propertyNumberError
                ? propertyNumberError
                : undefined
            }
            value={propertyNumber}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='serialNumber'
            title='شماره سریال : '
            autoComplete='off'
            error={
              serialNumberTouched && serialNumberError
                ? serialNumberError
                : undefined
            }
            value={serialNumber}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='installers'
            title='نصاب ها : '
            autoComplete='off'
            error={
              installersTouched && installersError ? installersError : undefined
            }
            value={installers}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='comment'
            title='توضیحات : '
            multiline
            autoComplete='off'
            error={commentTouched && commentError ? commentError : undefined}
            value={comment}
            onChange={formik.handleChange}
            type='text'
          />
          <SubmitButton
            title='ثبت'
            loading={loading}
            disabled={loading}
            type='submit'
          />
        </form>
      </div>
    </>
  );
};

export default AddLineCard;
