import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../../core/components";
import { useActions, useTypedSelector } from "../../../../../core/hooks";
import { RackSchema } from "../../../../../core/types";

const EditRack = () => {
  let [searchParams] = useSearchParams();
  const papId = searchParams.get("papId");
  const rackId = searchParams.get("rackId");
  const { editRack, fetchOnePap } = useActions();
  const { loading, item } = useTypedSelector((state) => state.pap);

  const selectedRack = useMemo(() => {
    return item && rackId && item?.racks?.find((rack) => rack._id === rackId);
  }, [item, rackId]);

  const formik = useFormik({
    initialValues: {
      dimension:
        selectedRack && selectedRack?.dimension ? selectedRack.dimension : "",
      brandName:
        selectedRack && selectedRack?.brandName ? selectedRack.brandName : "",
      propertyNumber:
        selectedRack && selectedRack?.propertyNumber
          ? selectedRack.propertyNumber
          : "",
      serialNumber:
        selectedRack && selectedRack?.serialNumber
          ? selectedRack.serialNumber
          : "",
      rackNumber:
        selectedRack && selectedRack?.rackNumber ? selectedRack.rackNumber : ""
    },
    validationSchema: yup.object({
      brandName: yup.string().trim(),
      propertyNumber: yup.string().trim(),
      serialNumber: yup.string().trim(),
      rackNumber: yup
        .number()
        .required("شماره رک الزامی است")
        .max(99, "حداکثر مقدار شماره رک 99 می باشد"),
      dimension: yup.string().trim()
    }),
    onSubmit: ({ propertyNumber, serialNumber, rackNumber }: RackSchema) => {
      editRack(
        {
          id: rackId,
          brandName,
          dimension,

          propertyNumber,
          serialNumber,
          rackNumber
        },
        item?._id ?? ""
      );
    }
  });
  useEffect(() => {
    if (papId) {
      fetchOnePap(papId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [papId]);

  const { brandName, dimension, propertyNumber, serialNumber, rackNumber } =
    formik.values;
  const {
    brandName: brandNameError,
    dimension: dimensionError,
    propertyNumber: propertyNumberError,
    serialNumber: serialNumberError,
    rackNumber: rackNumberError
  } = formik.errors;
  const {
    brandName: brandNameTouched,
    dimension: dimensionTouched,
    propertyNumber: propertyNumberTouched,
    serialNumber: serialNumberTouched,
    rackNumber: rackNumberTouched
  } = formik.touched;

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'>ویرایش رک</h5>
        {item && item !== null && selectedRack && (
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <Input
              name='rackNumber'
              title='شماره رک : '
              autoComplete='off'
              error={
                rackNumberTouched && rackNumberError
                  ? rackNumberError
                  : undefined
              }
              value={rackNumber}
              onChange={formik.handleChange}
              type='number'
            />
            <Input
              name='brandName'
              title=' برند : '
              autoComplete='off'
              error={
                brandNameTouched && brandNameError ? brandNameError : undefined
              }
              value={brandName}
              onChange={formik.handleChange}
              type='text'
            />

            <Input
              name='propertyNumber'
              title='شماره اموال : '
              autoComplete='off'
              error={
                propertyNumberTouched && propertyNumberError
                  ? propertyNumberError
                  : undefined
              }
              value={propertyNumber}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='serialNumber'
              title='شماره سریال : '
              autoComplete='off'
              error={
                serialNumberTouched && serialNumberError
                  ? serialNumberError
                  : undefined
              }
              value={serialNumber}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='dimension'
              title='ابعاد  : '
              autoComplete='off'
              error={
                dimensionTouched && dimensionError ? dimensionError : undefined
              }
              value={dimension}
              onChange={formik.handleChange}
              type='text'
            />

            <SubmitButton
              title='ثبت'
              loading={loading}
              disabled={loading}
              type='submit'
            />
          </form>
        )}
      </div>
    </>
  );
};

export default EditRack;
