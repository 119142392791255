import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { customizedAxios } from "../../../../config";
import { getCatch } from "../../../../catch";
import { AddShelf, ShelfSchema } from "../../../../types";
import { ShelvesActionTypes } from "../../../actionTypes/PapActionTypes";
import { SHELF_TYPES } from "../../../types";
import { PAPS_ROOT_API } from "../../../api";
import { store } from "../../../store";
import { DeleteType } from "../../../../types";

export const addShelf =
  (body: AddShelf, id: string) =>
  async (dispatch: Dispatch<ShelvesActionTypes>) => {
    try {
      dispatch({ type: SHELF_TYPES.SHELF_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PAPS_ROOT_API.concat("/shelf/" + id);
      await customizedAxios.post(url, body, config);

      dispatch({
        type: SHELF_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" شلف با موفقیت افزوده شد.");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: SHELF_TYPES.DISABLE_LOADING });
    }
  };

export const editShelf =
  (body: ShelfSchema, id: string) =>
  async (dispatch: Dispatch<ShelvesActionTypes>) => {
    try {
      dispatch({ type: SHELF_TYPES.SHELF_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PAPS_ROOT_API.concat("/shelf/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: SHELF_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("شلف با موفقیت ویرایش شد");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: SHELF_TYPES.DISABLE_LOADING });
    }
  };

export const deleteShelf =
  (id: string, papId: string) =>
  async (dispatch: Dispatch<ShelvesActionTypes>) => {
    try {
      dispatch({ type: SHELF_TYPES.SHELF_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        data: {
          id
        }
      };

      let url = PAPS_ROOT_API.concat("/shelf/" + papId);
      await customizedAxios.delete<DeleteType<ShelfSchema>>(url, config);

      dispatch({
        type: SHELF_TYPES.SHELF_DELETE,
        payload: { _id: id }
      });
      window.openToastSuccess("شلف با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: SHELF_TYPES.DISABLE_LOADING });
    }
  };
