import { useFormik } from "formik";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { UserPanelSmsTypes } from "../../../../core/types";

const SmsPanelInfo = () => {
  const { addSmsPanelInfo } = useActions();

  const { loading } = useTypedSelector((state) => state.notification);
  const formik = useFormik({
    initialValues: {
      phone: "",
      apikey: ""
    },
    validationSchema: yup.object({
      apikey: yup.string().required(" API Key الزامی است"),
      phone: yup.string().required("شماره پنل  الزامی است")
    }),
    onSubmit: ({ apikey }: UserPanelSmsTypes) => {
      addSmsPanelInfo({
        API_KEY: apikey,
        panelNumber: phone
      });
    }
  });

  const { apikey, phone } = formik.values;
  const { apikey: apikeyError, phone: phoneError } = formik.errors;
  const { apikey: apikeyTouched, phone: phoneTouched } = formik.touched;

  return (
    <>
      <div className='change-password-wrapper'>
        <h5 className='title'>اطلاعات پنل اس ام اس </h5>
        <form autoComplete='on' onSubmit={formik.handleSubmit}>
          <div className='section-full'>
            <Input
              name='apikey'
              title='کاوه نگار API_KEY'
              autoComplete='off'
              error={apikeyTouched && apikeyError ? apikeyError : undefined}
              value={apikey}
              onChange={formik.handleChange}
            />
          </div>
          <div className='section-half'>
            <Input
              name='phone'
              title='شماره تلفن پنل اس ام اس '
              autoComplete='off'
              error={phoneTouched && phoneError ? phoneError : undefined}
              value={phone}
              onChange={formik.handleChange}
              type='number'
            />
          </div>
          <div className='section-half'>
            <SubmitButton
              title='ثبت'
              loading={loading}
              disabled={loading}
              type='submit'
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default SmsPanelInfo;
