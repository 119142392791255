import { useFormik } from "formik";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../../core/components";
import { useActions, useTypedSelector } from "../../../../../core/hooks";
import { MiscellaneousSchema } from "../../../../../core/types";

const AddMiscellaneous = () => {
  const { addMiscellaneous } = useActions();
  const { loading, item } = useTypedSelector((state) => state.pap);
  const formik = useFormik({
    initialValues: {
      title: "",
      rackNumber: 1,
      propertyNumber: "",
      brandName: "",
      dimension: "",
      serialNumber: ""
    },
    validationSchema: yup.object({
      title: yup.string().required("عنوان ایتم متفرقه الزامی می باشد"),
      propertyNumber: yup.string().trim(),
      rackNumber: yup.number().required("شماره رک الزامی است"),
      brandName: yup.string().trim(),
      dimension: yup.string().trim(),
      serialNumber: yup.string().trim()
    }),
    onSubmit: ({
      title,
      rackNumber,
      propertyNumber,
      brandName,
      dimension,
      serialNumber
    }: MiscellaneousSchema) => {
      addMiscellaneous(
        {
          title,
          rackNumber,
          propertyNumber,
          brandName,
          dimension,
          serialNumber
        },
        item?._id ?? ""
      );
    }
  });

  const {
    title,
    rackNumber,
    propertyNumber,
    brandName,
    dimension,
    serialNumber
  } = formik.values;
  const {
    title: titleError,
    rackNumber: rackNumberError,
    brandName: brandNameError,
    dimension: dimensionError,
    serialNumber: serialNumberError,
    propertyNumber: propertyNumberError
  } = formik.errors;
  const {
    title: titleTouched,
    rackNumber: rackNumberTouched,
    brandName: brandNameTouched,
    dimension: dimensionTouched,
    serialNumber: serialNumberTouched,
    propertyNumber: propertyNumberTouched
  } = formik.touched;

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'>اضافه کردن آیتم متفرقه جدید</h5>
        <form autoComplete='on' onSubmit={formik.handleSubmit}>
          <Input
            name='title'
            title='عنوان : '
            autoComplete='off'
            error={titleTouched && titleError ? titleError : undefined}
            value={title}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='rackNumber'
            title='شماره رک : '
            autoComplete='off'
            error={
              rackNumberTouched && rackNumberError ? rackNumberError : undefined
            }
            value={rackNumber}
            onChange={formik.handleChange}
            type='number'
          />

          <Input
            name='dimension'
            title='ابعاد : '
            autoComplete='off'
            error={
              dimensionTouched && dimensionError ? dimensionError : undefined
            }
            value={dimension}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='brandName'
            title=' برند  : '
            autoComplete='off'
            error={
              brandNameTouched && brandNameError ? brandNameError : undefined
            }
            value={brandName}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='propertyNumber'
            title=' شماره اموال  : '
            autoComplete='off'
            error={
              propertyNumberTouched && propertyNumberError
                ? propertyNumberError
                : undefined
            }
            value={propertyNumber}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='serialNumber'
            title=' شماره سریال  : '
            autoComplete='off'
            error={
              serialNumberTouched && serialNumberError
                ? serialNumberError
                : undefined
            }
            value={serialNumber}
            onChange={formik.handleChange}
            type='text'
          />
          <SubmitButton
            title='ثبت'
            loading={loading}
            disabled={loading}
            type='submit'
          />
        </form>
      </div>
    </>
  );
};

export default AddMiscellaneous;
