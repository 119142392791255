import { MongoObject } from "..";

export enum ItemTable {
  _ID = "_id",
  NAME = " نام",
  TYPE = "نوع",
  PROPERTYNUMBER = "اموال",
  SERIALNUMBER = "سریال",
  DIMENSION = "ابعاد",
  ACTIONS = "عملیات",
  DESCRIPTION = "توضیحات",
  CREATEDAT = "تاریخ ایجاد",
  UPDATEDAT = "تاریخ به‌روز‌رسانی ",
  ROW = "ردیف"
}

export interface ItemSchema extends MongoObject {
  _id?: string;
  id?: string | null;
  name?: string;
  type?: string;
  dimension?: string;
  propertyNumber?: string;
  serialNumber?: string;
  createdAt?: Date;
  updatedAt?: Date;
  description?: string;
}
export interface AddItem {
  type?: string;
  name?: string;
  propertyNumber?: string;
  serialNumber?: string;
  description?: string;
  dimension?: string;
}
