export default function timeSince(date: Date) {
  var seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " سال";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " ماه";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " روز";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " ساعت";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " دقیقه";
  }
  return Math.floor(seconds) + " ثانیه";
}
