import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { HeaderLogin, Input, SubmitButton } from "../../core/components";
import { useActions, useTypedSelector } from "../../core/hooks";

const ForgetPassword = () => {
  const { forgetPassword } = useActions();
  const { authLoading } = useTypedSelector((state) => state.authLoading);

  const formik = useFormik({
    initialValues: {
      username: ""
    },
    validationSchema: yup.object({
      username: yup.string().required("ایمیل یا موبایل الزامی است")
    }),
    onSubmit: (values) => {
      forgetPassword(values);
    }
  });

  const { username } = formik.values;
  const { username: usernameError } = formik.errors;
  const { username: usernameTouched } = formik.touched;

  return (
    <>
      <HeaderLogin />
      <main className='main-login'>
        <div className='main-login__wrapper'>
          <div className='header-logo'>
            <div className='header-logo__wrapper'>
              <Link to={"#"}>
                <img src='/asiatech-logo.png' alt='لوگو آسیاتک' />
              </Link>
            </div>
          </div>
          <h4>بازیابی رمز عبور</h4>
          <h6>
            برای بازیابی رمز عبور خود لطفا نام کاربری خود را وارد نمایید. می
            توانید از شماره همراه یا ایمیل خود به عنوان نام کاربری استفاده
            نماید.
          </h6>
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <Input
              icon='fas fa-mobile-alt'
              name='username'
              title='نام کاربری : '
              autoComplete='off'
              error={
                usernameTouched && usernameError ? usernameError : undefined
              }
              value={username}
              onChange={formik.handleChange}
            />

            <SubmitButton
              title='ثبت'
              loading={authLoading}
              disabled={authLoading}
              type='submit'
            />
          </form>
        </div>
      </main>
    </>
  );
};

export { ForgetPassword };
