import { CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import copy from "copy-to-clipboard";
import { useFormik } from "formik";
import moment, { Moment } from "jalali-moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker2";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import useIsMobile from "src/core/hooks/useIsMobile";
import { useQueryParams } from "src/core/hooks/useQueryParams";
import * as yup from "yup";
import {
  AddCommentModalContent,
  DetailModalContent,
  ImageWrapper,
  Input,
  NoItem,
  SubmitButton
} from "../../../../core/components";
import {
  CAUSE_TYPES_LIST,
  SATISFACTION_TYPES_LIST,
  USER_ACCESS,
  defaultTablePageCount
} from "../../../../core/constants";
import { defaultColumnDefinition } from "../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { BASE_API, FAULTS_ROOT_API, FAULT_TYPES } from "../../../../core/redux";
import {
  DoneFaultTable,
  FaultCommentType,
  FaultType,
  TelecomsType,
  UserType
} from "../../../../core/types";
import {
  getUserAccess as _getUserAccess,
  checkUserAccessToSection,
  fileDownload
} from "../../../../core/utility";
import Loading from "src/core/components/Loading";

const DoneFaults = () => {
  const { fetchDoneFaults, deleteFaultPhoto, uploadFaultPhoto } = useActions();

  let [searchUrlParams] = useSearchParams();
  const _phone = searchUrlParams.get("phone");
  const _lastClosedDate = searchUrlParams.get("lastClosedDate");

  const [addComment, setAddComment] = useState<null | FaultType>(null);

  const { token, me } = useTypedSelector((state) => state.auth);
  const [isCommentModalOpen, setIsCommentModalOpen] =
    useState<null | FaultType>(null);
  const { loading, list, imageItem } = useTypedSelector((state) => state.fault);
  const { searchParams, setSearchParams } = useQueryParams();

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(
    +(searchParams("page") ?? 1) - 1
  );
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const isMobile = useIsMobile();

  const formik = useFormik({
    initialValues: {
      phone: _phone ?? "",
      startDate: _lastClosedDate
        ? moment(_lastClosedDate).subtract(1, "day").startOf("day")
        : moment().subtract(1, "month"),
      endDate: _lastClosedDate
        ? moment(_lastClosedDate)
            .subtract(1, "day")
            .startOf("day")
            .add(1, "month")
        : moment()
    },
    validationSchema: yup.object({
      phone: yup.string(),
      startDate: yup.mixed(),
      endDate: yup.mixed()
    }),
    isInitialValid: true,
    onSubmit: (values) => {
      fetchFaults(values);
    }
  });

  const hasAlbum = useCallback(
    (cellEvent: CellClickedEvent) =>
      list?.data?.find((fault) => {
        return fault._id === cellEvent?.data?._id;
      })?.albums?.length !== 0,
    [list?.data]
  );

  const userAccess = useMemo(() => {
    return checkUserAccessToSection(me?.access ?? []);
  }, [me]);

  const getUserAccess = useMemo(
    () => _getUserAccess.bind(null, userAccess, me),
    [userAccess, me]
  );

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          {getUserAccess(USER_ACCESS.FAULTS_INSERT) && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                dispatch({
                  type: FAULT_TYPES.CLONING_FAULT_ITEM,
                  payload: list?.data?.find((fault) => {
                    return fault._id === cellEvent?.data?._id;
                  })
                });
                navigator(`/faults/clone`);
              }}>
              <i
                title='باز کردن مجدد خرابی '
                className='fas fa-copy add-icon'></i>
            </span>
          )}
          <span
            onClick={(e) => {
              e.stopPropagation();
              setAddComment(
                list?.data?.find((item) => {
                  return (
                    item._id?.toString() === cellEvent?.data[DoneFaultTable._ID]
                  );
                }) ?? null
              );
            }}>
            <i title='توضیحات' className='fas fa-comment edit-icon'></i>
          </span>
          <span
            onClick={() => {
              setIsCommentModalOpen(cellEvent?.data);
            }}>
            <i
              title='اطلاعات حضوری'
              className='fas fa-comment comment-icon'></i>
          </span>
          {cellEvent?.data[DoneFaultTable.FORM_PATH] && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                const url =
                  BASE_API +
                  FAULTS_ROOT_API +
                  "/form/" +
                  cellEvent?.data[DoneFaultTable._ID] +
                  "?photoId=" +
                  cellEvent?.data[DoneFaultTable.FORM_PATH];
                fileDownload(
                  url,
                  token ?? "",
                  cellEvent?.data[DoneFaultTable.PHONE] + ".pdf"
                );
              }}>
              <i className='fas fa-file-pdf add-icon'></i>
            </span>
          )}
          <span
            onClick={(e) => {
              e.stopPropagation();
              dispatch({
                type: FAULT_TYPES.SELECTED_FAULT_IMAGES,
                payload: list?.data?.find((fault) => {
                  return fault._id === cellEvent?.data?._id;
                })
              });
              navigator(`/faults/add-fault-photo?_id=${cellEvent?.data?._id}`);
            }}>
            <i title='اضافه کردن عکس' className='fas fa-camera add-icon'></i>
          </span>
          {hasAlbum(cellEvent) && (
            <span
              onClick={(e) => {
                e.stopPropagation();

                dispatch({
                  type: FAULT_TYPES.SELECTED_FAULT_IMAGES,
                  payload: list?.data?.find((fault) => {
                    return fault._id === cellEvent?.data?._id;
                  })
                });
              }}>
              <i title='گالری' className='fas fa-images edit-icon'></i>
            </span>
          )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, token]
  );

  const { phone, startDate, endDate } = formik.values;

  const {
    phone: phoneError,
    startDate: startDateError,
    endDate: endDateError
  } = formik.errors;
  const {
    phone: phoneTouched,
    startDate: startDateTouched,
    endDate: endDatetouched
  } = formik.touched;

  const queryParams = useMemo(() => {
    return {
      limit: defaultTablePageCount,
      page: currentPage + 1,
      sort: "-doneDate"
    };
  }, [currentPage]);

  const fetchFaults = useCallback(
    (values: { phone: string; startDate?: Moment; endDate?: Moment }) => {
      const { phone, startDate, endDate, ...rest } = values;
      const data = {
        ...queryParams,
        ...rest,
        "phone[regex]": phone,
        "createdAt[gt]": (startDate as Moment).toDate(),
        "createdAt[lt]": (endDate as Moment).toDate()
      };

      if (!_phone) {
        setSearchParams(data);
      }
      fetchDoneFaults(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryParams]
  );

  // THIS IS FOR CURRENT PAGE AND LIMIT
  useEffect(() => {
    formik.handleSubmit();

    dispatch({ type: FAULT_TYPES.RESET_IMAGE_ITEM });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, _phone]);

  const rowData = useCallback(() => {
    return list?.data.map((item, index) => {
      return {
        [DoneFaultTable.ROW]: `${index + 1 + currentPage * 10}`,
        [DoneFaultTable._ID]: item._id,
        [DoneFaultTable.UUID]: item.uuid,
        [DoneFaultTable.PHONE]: item.phone,
        [DoneFaultTable.PANEL]: item.panel,
        [DoneFaultTable.PORTUUID]: item.portUuid,
        [DoneFaultTable.TELECOM]: (item.telecomId as unknown as TelecomsType)
          ?.name,
        [DoneFaultTable.BANDWIDTH]: item.bandWidth,
        [DoneFaultTable.CAUSE]: item.cause.reduce((total, item) => {
          return (total =
            total +
              `${total ? " - " : ""}` +
              CAUSE_TYPES_LIST.find((causeItem) => {
                return causeItem.value === item;
              })?.label ?? "");
        }, "" as string),
        [DoneFaultTable.USER]: (item.userId as unknown as UserType)?.fullName,
        [DoneFaultTable.FORM_PATH]: item?.needExpert?.formPath,
        [DoneFaultTable.PRICE]: item?.needExpert?.price,
        [DoneFaultTable.NAME]: item?.needExpert?.name ?? "",
        [DoneFaultTable.ADDRESS]: item?.needExpert?.address ?? "",
        [DoneFaultTable.MOBILENUMBER]: item?.needExpert?.mobileNumber,
        [DoneFaultTable.USERNAME]: item?.needExpert?.username,
        [DoneFaultTable.PASSWORD]: item?.needExpert?.password,
        [DoneFaultTable.DATE]:
          item?.needExpert?.doneDate &&
          new Date(item.needExpert.doneDate ?? "").toLocaleDateString("fa-IR"),
        [DoneFaultTable.PRICELIST]: item?.needExpert?.priceList,
        [DoneFaultTable.NEED_EXPERT_STATUS]: item?.needExpert?.status ?? "",
        [DoneFaultTable.SATISFACTION]:
          (
            item?.needExpert?.satisfaction &&
            SATISFACTION_TYPES_LIST.find((stat) => {
              return stat.value === item!.needExpert!.satisfaction;
            })
          )?.label ?? ""
      };
    });
  }, [list?.data, currentPage]);

  return (
    <>
      <DetailModalContent
        content={isCommentModalOpen}
        isOpen={!!isCommentModalOpen}
        message={
          (isCommentModalOpen && isCommentModalOpen?.needExpert?.priceList) ??
          ""
        }
        title={"توضیحات خرابی"}
        onClose={() => {
          setIsCommentModalOpen(null);
        }}
      />
      {!!addComment && (
        <AddCommentModalContent
          faultPhone={addComment.phone}
          isOpen={!!addComment}
          comments={
            addComment ? (addComment.comments as Array<FaultCommentType>) : []
          }
          onlyComments={true}
          onClose={() => {
            setAddComment(null);
          }}
        />
      )}

      <form
        className='clients-search-wrapper'
        autoComplete='on'
        onSubmit={(e) => {
          setCurrentPage(0);
          formik.handleSubmit(e);
        }}>
        <div className='list-input-wrapper'>
          <div className='select-wrapper--high'>
            <Input
              name='phone'
              title='شماره : '
              type='number'
              autoComplete='off'
              error={phoneTouched && phoneError ? phoneError : undefined}
              value={phone}
              onChange={formik.handleChange}
            />
          </div>
          <div className='select-wrapper--high'>
            <label> تاریخ شروع :</label>

            <DatePicker
              isGregorian={false}
              onChange={(value) => {
                formik.setFieldValue("startDate", value, true);
              }}
              value={startDate}
              timePicker={false}
              max={moment()}
            />
            {startDateTouched && startDateError && (
              <p className='input-error--select'>
                {startDateError as unknown as string}
              </p>
            )}
          </div>
          <div className='select-wrapper--high'>
            <label> تاریخ پایان :</label>

            <DatePicker
              isGregorian={false}
              onChange={(value) => {
                formik.setFieldValue("endDate", value, true);
              }}
              timePicker={false}
              value={endDate}
              max={moment()}
            />
            {endDatetouched && endDateError && (
              <p className='input-error--select'>
                {endDateError as unknown as string}
              </p>
            )}
          </div>

          <SubmitButton
            type='submit'
            title='ثبت'
            loading={loading}
            disabled={loading}
          />
        </div>
      </form>

      <div className='main-route-wrapper'>
        {" "}
        <div className='table-wrapper'>
          <div className='list-top'>
            <p>
              لیست خرابی های اتمام یافته : <span>{list && list.total} عدد</span>
              {list &&
                list?.data &&
                list.data.length !== 0 &&
                !isLoadingDownload && (
                  <i
                    onClick={(e) => {
                      e.stopPropagation();

                      const url = BASE_API + FAULTS_ROOT_API + "/doneFaults";

                      fileDownload(
                        url,
                        token ?? "",
                        "faults.xlsx",
                        {
                          xlsx: true,
                          "phone[regex]": phone,
                          "createdAt[gt]": (startDate as Moment).toDate(),
                          "createdAt[lt]": (endDate as Moment).toDate()
                        },
                        setIsLoadingDownload
                      );
                    }}
                    className={`fas fa-file-excel excel-icon`}
                  />
                )}
              <Loading
                size={14}
                color='rgb(50, 119, 50)'
                loading={isLoadingDownload}
              />
            </p>
          </div>

          {(!list || list.count === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}

          <>
            {list && list.count !== 0 && (
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  animateRows={true}
                  onCellClicked={(e) => {
                    if (e.colDef.field !== DoneFaultTable.ACTIONS)
                      copy(e.value);
                  }}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  rowSelection={"single"}
                  enableRtl={true}>
                  <AgGridColumn
                    sortable={true}
                    field={DoneFaultTable.ROW}
                    width={84}
                  />
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={DoneFaultTable.ACTIONS}
                    minWidth={200}
                  />

                  <AgGridColumn
                    sortable={true}
                    field={DoneFaultTable.PHONE}
                    minWidth={150}
                    maxWidth={150}
                  />

                  <AgGridColumn
                    sortable={true}
                    field={DoneFaultTable.UUID}
                    minWidth={180}
                    maxWidth={180}
                  />

                  <AgGridColumn
                    sortable={true}
                    field={DoneFaultTable.TELECOM}
                    minWidth={150}
                    maxWidth={150}
                  />

                  <AgGridColumn
                    sortable={true}
                    field={DoneFaultTable.BANDWIDTH}
                    minWidth={80}
                    maxWidth={80}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DoneFaultTable.CAUSE}
                    minWidth={400}
                    maxWidth={400}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DoneFaultTable.USER}
                    minWidth={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DoneFaultTable.NAME}
                    minWidth={150}
                    maxWidth={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DoneFaultTable.ADDRESS}
                    minWidth={400}
                    maxWidth={400}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DoneFaultTable.NEED_EXPERT_STATUS}
                    minWidth={150}
                    maxWidth={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DoneFaultTable.SATISFACTION}
                    minWidth={100}
                    flex={1}
                  />
                </AgGridReact>
              </div>
            )}
          </>
        </div>
        <ReactPaginate
          marginPagesDisplayed={isMobile ? 1 : 3}
          forcePage={currentPage}
          breakLabel='...'
          nextLabel='بعدی'
          onPageChange={(e) => {
            setCurrentPage(e.selected);
          }}
          pageRangeDisplayed={5}
          pageCount={
            (list && Math.ceil(list.total / defaultTablePageCount)) ?? 1
          }
          previousLabel='قبلی'
          containerClassName='pagination-container'
        />
      </div>
      {imageItem && (
        <ImageWrapper
          noDelete
          albums={imageItem?.albums?.map((album) => {
            return {
              ...album,
              path:
                BASE_API +
                FAULTS_ROOT_API +
                "/photo/" +
                imageItem._id +
                "?photoId=" +
                album.path
            };
          })}
          onDelete={(albumItemId) => {
            deleteFaultPhoto(albumItemId, imageItem?._id ?? "");
          }}
          onSuccess={(file) => {
            uploadFaultPhoto(file, {});
          }}
        />
      )}
    </>
  );
};

export default DoneFaults;
