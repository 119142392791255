import { MongoObject } from "..";

export enum RacksTable {
  _ID = "_id",
  RACKNUMBER = "شماره رک",
  BRANDNAME = "برند",
  PROPERTYNUMBER = "اموال",
  SERIALNUMBER = "سریال",
  DIMENSION = "ابعاد",
  CREATEDAT = "تاریخ ایجاد ",
  UPDATEDAT = "تاریخ اخرین تغییر",
  ACTIONS = "عملیات",
  ROW = "ردیف"
}

export interface RackSchema extends MongoObject {
  _id?: string;
  id?: string | null;
  rackNumber?: string;
  brandName?: string;
  dimension?: string;
  propertyNumber?: string;
  serialNumber?: string;
  createdAt?: Date;
  updatedAt?: Date;
}
export interface AddRack {
  brandName?: string;
  propertyNumber?: string;
  serialNumber?: string;
  rackNumber?: string;
  dimension?: string;
}
