import { Route, Routes } from "react-router-dom";
import AddRadio from "./add-radio";
import EditRadio from "./edit-radio";
import RadiosList from "./radios-list";

const Radios = () => {
  return (
    <>
      <Routes>
        <Route path='' element={<RadiosList />} />
        <Route path='/add' element={<AddRadio />} />
        <Route path='/edit' element={<EditRadio />} />
      </Routes>
    </>
  );
};

export default Radios;
