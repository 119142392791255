import {
  AdvancedResult,
  PhoneBookType,
  PhoneBookProvinceType
} from "../../types";
import { PhoneBookActionTypes } from "../actionTypes";
import { PHONE_BOOK_TYPES } from "../types";

interface StateType {
  list: AdvancedResult<PhoneBookType> | null;
  item: PhoneBookType | null;
  loading: boolean;
  provincesLoading: boolean;
  provinces: AdvancedResult<PhoneBookProvinceType> | null;
}

const initialState: StateType = {
  list: null,
  item: null,
  loading: false,
  provincesLoading: false,
  provinces: null
};

const reducer = (
  state: StateType = initialState,
  action: PhoneBookActionTypes
): StateType => {
  switch (action.type) {
    case PHONE_BOOK_TYPES.PHONE_BOOK_LISTS:
      return {
        ...state,
        list: action.payload,
        loading: false,
        item: null
      };

    case PHONE_BOOK_TYPES.PHONE_BOOK:
      return {
        ...state,
        item: action.payload,
        loading: false
      };

    case PHONE_BOOK_TYPES.PHONE_BOOK_LOADING:
      return {
        ...state,
        loading: true
      };

    case PHONE_BOOK_TYPES.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };
    case PHONE_BOOK_TYPES.PHONE_BOOK_PROVINCES_LOADING:
      return {
        ...state,
        provincesLoading: true
      };
    case PHONE_BOOK_TYPES.PHONE_BOOK_PROVINCES_LISTS:
      return {
        ...state,
        provinces: action.payload
      };
    case PHONE_BOOK_TYPES.DISABLE_PROVINCES_LOADING:
      return {
        ...state,
        provincesLoading: false
      };
    case PHONE_BOOK_TYPES.PHONE_BOOK_DELETE:
      let _list = state.list;
      if (_list) {
        _list.data = _list?.data?.filter((item) => {
          return (
            item?._id?.toString() !== action.payload.phoneBookId?.toString()
          );
        });
      }
      return {
        ...state,
        list: _list,
        loading: false
      };

    default:
      return state;
  }
};

export default reducer;
