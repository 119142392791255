import { useFormik } from "formik";
import React, { memo, useCallback } from "react";
import ReactModal from "react-modal";
import Select from "react-select";
import * as yup from "yup";
import { CancelButton, Input, NoItem, SubmitButton } from ".";
import { SATISFACTION_TYPES_LIST } from "../constants";
import { useActions } from "../hooks";
import {
  FaultCommentType,
  FaultType,
  SATISFACTION_TYPES,
  UserType
} from "../types";
import { getSinceTimeWithHour } from "../utility/getSinceTimeWithHour";

interface ConcludeFaultNeedExpertTypes {
  onSuccess?: (comment?: string) => any;
  onClose?: () => any;
  isOpen: boolean;
  faultId: string;
  faultData: FaultType | null;
  comments?: FaultCommentType[];
}

const _ConcludeFaultNeedExpert: React.FC<ConcludeFaultNeedExpertTypes> = ({
  onSuccess,
  onClose,
  isOpen,
  faultId,
  faultData,
  comments,
  ...rest
}) => {
  const submit = useCallback(
    (comment?: string) => {
      onSuccess && onSuccess(comment);
    },
    [onSuccess]
  );

  const { editNeedExpert } = useActions();
  const formik = useFormik({
    initialValues: {
      satisfaction: "",
      comment: ""
    },
    validationSchema: yup.object({
      satisfaction: yup.string().required("توضیحات الزامی است"),
      comment: yup.string()
    }),
    onSubmit: ({ satisfaction, comment }) => {
      if (satisfaction) {
        submit(comment);
      }
    }
  });

  const { satisfaction: satisfactoryError, comment: commentError } =
    formik.errors;
  const { satisfaction: satisfactoryTouched, comment: commentTouched } =
    formik.touched;
  const { comment } = formik.values;

  return (
    <>
      <ReactModal
        style={{
          content: {
            maxWidth: 800,
            maxHeight: 600,
            margin: "auto",
            padding: "0"
          }
        }}
        {...rest}
        isOpen={isOpen}
        onRequestClose={onClose}
        ariaHideApp={false}>
        <div className='add-comment-modal__wrapper'>
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <div className='add-comment-modal-header'>
              <div className='title'>بستن حضوری </div>
              <div className='action-wrappers'>
                <CancelButton
                  onClick={onClose}
                  loading={false}
                  title='انصراف'
                  type='button'
                />
                <SubmitButton type='submit' title='بستن خرابی' />
              </div>
            </div>
            <div className='content-wrapper'>
              <div>نام مشترک : {faultData?.needExpert?.name}</div>
              <div>موبایل : {faultData?.needExpert?.mobileNumber}</div>
              <div>شماره ثابت: {faultData?.phone}</div>
              <div>هزینه: {faultData?.needExpert?.price}</div>
              <div>آدرس: {faultData?.needExpert?.address}</div>
            </div>
            <div className='select-wrapper--high '>
              <label>میران رضایت مشترک را وارد نمایید:</label>
              <Select
                onChange={(e) => {
                  (
                    editNeedExpert(
                      {
                        satisfaction: e?.value
                          ? (e?.value as SATISFACTION_TYPES)
                          : undefined
                      },
                      faultId
                    ) as unknown as Promise<boolean>
                  ).then((res) => {
                    if (res)
                      formik.setFieldValue("satisfaction", e?.value, true);
                  });
                }}
                isClearable
                placeholder=' وضعبت رضایت مشترک را انتخاب نمایید'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                options={SATISFACTION_TYPES_LIST}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              {satisfactoryTouched && satisfactoryError && (
                <p className='input-error--select'>{satisfactoryError}</p>
              )}
            </div>

            <Input
              placeholder=''
              name='comment'
              title='توضیحات  : '
              multiline
              autoComplete='off'
              error={commentTouched && commentError ? commentError : undefined}
              value={comment}
              onChange={formik.handleChange}
            />

            {comments && comments.length !== 0 ? (
              <div className='content-wrapper'>
                {comments
                  .sort(function (a, b) {
                    return (
                      new Date(b.createdAt).getTime() -
                      new Date(a.createdAt).getTime()
                    );
                  })
                  .map((item) => {
                    return (
                      <div key={item._id}>
                        {!!item.createdAt && (
                          <span>
                            {`${(item.userId as unknown as UserType)
                              ?.fullName} ${getSinceTimeWithHour(
                              item.createdAt
                            )}`}
                          </span>
                        )}
                        &nbsp;
                        <div>{item.desc}</div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <NoItem title='کامنتی ثبت نشده است.' />
            )}
          </form>
        </div>
      </ReactModal>
    </>
  );
};

const ConcludeFaultNeedExpert = memo(_ConcludeFaultNeedExpert);

export { ConcludeFaultNeedExpert };
