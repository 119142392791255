import { css } from "@emotion/react";
import axios, { AxiosRequestConfig } from "axios";
import React, { ImgHTMLAttributes, memo, useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { getCatch } from "../catch";
import { useTypedSelector } from "../hooks";
import Loading from "./Loading";

const override = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  line-height: 0.5rem;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(7px);
  border-radius: 8px;
`;

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {}

const _ImageLoader: React.FC<ImageProps> = ({
  alt,
  loading = "lazy",
  style,
  src,
  title,
  ...rest
}) => {
  const { token } = useTypedSelector((state) => state.auth);
  const [loader, setLoader] = useState<boolean>(true);
  const [openLightBox, setOpenLightBox] = useState<boolean>(false);

  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    if (src) {
      try {
        setLoader(true);
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${token}`
          },
          responseType: "blob"
        };

        axios
          .get<Blob>(src, config)
          .then((res) => {
            setImageUrl(URL.createObjectURL(res.data));
          })
          .finally(() => setLoader(false));
      } catch (err: any) {
        const { message } = getCatch(err);

        window.openToastError(message);
        setLoader(false);
      }
    }
  }, [src, token]);

  if (imageUrl) {
    return (
      <div>
        <Loading override={override} color='blue' loading={loader} />
        {openLightBox && (
          <Lightbox
            imageTitle={title || ""}
            mainSrc={imageUrl}
            onCloseRequest={() => {
              setOpenLightBox(false);
            }}
            onMovePrevRequest={() => {}}
            onMoveNextRequest={() => {}}
            enableZoom={false}
          />
        )}
        <img
          onLoad={() => {
            setLoader(false);
          }}
          onError={() => {
            setLoader(false);
          }}
          onClick={() => {
            setOpenLightBox(true);
          }}
          style={{ position: "relative", height: "100%", ...style }}
          loading={loading}
          alt={alt}
          src={imageUrl}
          {...rest}
        />
      </div>
    );
  }
  return <></>;
};

const ImageLoader = memo(_ImageLoader);

export { ImageLoader };
