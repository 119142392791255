import { useFormik } from "formik";
import moment from "jalali-moment";
import { useCallback, useEffect } from "react";
import DatePicker from "react-datepicker2";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import {
  BANDWIDTH_TYPES_LIST,
  CAUSE_TYPES_LIST,
  PRIORITY_TYPES_LIST,
  STATUS_TYPES_LIST
} from "../../../../core/constants";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import {
  AddFaultType,
  BANDWIDTH_TYPES,
  CAUSE_TYPES,
  FaultType,
  PRIORITY_TYPES,
  STATUS_TYPES
} from "../../../../core/types";
import { uniqueId } from "src/core/utility/uniqueId";

const EditFault = () => {
  let [searchParams] = useSearchParams();

  const faultId = searchParams.get("faultId");
  const { getFault, fetchAssignAbleUsers, editFault } = useActions();
  const { loading, assignAbleUsers, item } = useTypedSelector(
    (state) => state.fault
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: item?.phone ?? "",
      portUuid: item?.portUuid ?? "",
      panel: item?.panel ?? "",
      busyFloor: `${item?.busy?.floor || ""}`,
      busyPort: `${item?.busy?.port || ""}`,
      busyRow: `${item?.busy?.row || ""}`,
      telecomBusyFloor: `${item?.telecomBusy?.floor || ""}`,
      telecomBusyPort: `${item?.telecomBusy?.port || ""}`,
      telecomBusyRow: `${item?.telecomBusy?.row || ""}`,
      status: item?.status ?? STATUS_TYPES.IN_TELECOM,
      bandWidth: (item?.bandWidth && +item.bandWidth) ?? BANDWIDTH_TYPES.EIGHT,
      cause: item?.cause,
      priority: item?.priority,
      date: undefined,
      username: item?.needExpert?.username,
      password: item?.needExpert?.password,
      name: item?.needExpert?.name,
      mobileNumber: item?.needExpert?.mobileNumber,
      address: item?.needExpert?.address,
      assignedTo: item?.assignedTo,
      comments: ""
    },
    validationSchema: yup.object({
      phone: yup.string().required(" شماره الزامی است"),
      portUuid: yup.string(),
      panel: yup.string(),
      bandWidth: yup
        .mixed()
        .required("پهنای باند خرابی الزامی است")
        .oneOf(Object.values(BANDWIDTH_TYPES), "پهنای باند خرابی الزامی است"),

      busyRow: yup.number().required("ردیف الزامی است"),
      busyFloor: yup.number().required("طبقه الزامی است"),
      busyPort: yup.number().required("پورت الزامی است"),
      cause: yup
        .array()
        .of(yup.mixed().oneOf(Object.values(CAUSE_TYPES)))
        .typeError("علت خرابی الزامی است")
        .required("علت خرابی الزامی است"),
      priority: yup
        .mixed()
        .required("اولویت خرابی الزامی است")
        .oneOf(Object.values(PRIORITY_TYPES), "اولویت خرابی الزامی است"),

      status: yup
        .mixed()
        .oneOf(
          [
            STATUS_TYPES.IN_TELECOM,
            STATUS_TYPES.NORMAL,
            STATUS_TYPES.CUSTOMER,
            STATUS_TYPES.REFERRED_TO_EXPERT
          ],
          "وضیعت خرابی الزامی است"
        ),
      date: yup.date().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("تاریخ حضوری الزامی است")
          : schema;
      }),
      username: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("نام کاربری الزامی است")
          : schema;
      }),
      password: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("کلمه عبور الزامی است")
          : schema;
      }),
      name: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("نام مشترک الزامی است")
          : schema;
      }),
      mobileNumber: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("شماره همراه مشترک الزامی است")
          : schema;
      }),
      assignedTo: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("خرابی را به چه کسی ارجاع می دهید")
          : schema;
      }),
      address: yup.string().when(["status"], (status, schema) => {
        return status === STATUS_TYPES.REFERRED_TO_EXPERT
          ? schema.required("آدرس الزامی است")
          : schema;
      }),
      comments: yup.string()
    }),
    onSubmit: ({
      phone,
      uuid,
      portUuid,
      panel,
      bandWidth,
      cause,
      priority,
      assignedTo,
      busyFloor,
      busyPort,
      busyRow,
      telecomBusyFloor,
      telecomBusyPort,
      telecomBusyRow,
      status,
      date,
      username,
      password,
      name,
      mobileNumber,
      address,
      comments
    }: Partial<AddFaultType>) => {
      let body: Partial<FaultType> = {
        phone,
        uuid: uuid || `asiatechin-${uniqueId()}`,
        portUuid,
        bandWidth: bandWidth!,
        panel,
        cause: cause!,
        priority: priority!,
        busy: { row: +busyRow!, floor: +busyFloor!, port: +busyPort! },
        telecomBusy: {
          row: telecomBusyRow ? +telecomBusyRow : 0,
          floor: telecomBusyFloor ? +telecomBusyFloor : 0,
          port: telecomBusyPort ? +telecomBusyPort : 0
        },
        status: status!,
        comments: comments ?? undefined
      };
      if (status === STATUS_TYPES.REFERRED_TO_EXPERT) {
        body.needExpert = {
          date,
          username: username ?? "",
          password: password ?? "",
          name: name ?? "",
          mobileNumber: mobileNumber ?? "",
          address: address ?? "",
          assignedTo: assignedTo!
        };
        body.assignedTo = assignedTo!;
      }
      editFault(body, item?._id ?? "");
    }
  });

  useEffect(() => {
    formik.setFieldValue("date", moment(item?.needExpert?.date), true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.needExpert?.date]);

  useEffect(() => {
    if (faultId) {
      getFault(faultId);
    }
    fetchAssignAbleUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faultId]);

  const getAssignAbleUsersList = useCallback(() => {
    return assignAbleUsers?.map((item) => {
      return { value: item._id, label: item.fullName };
    });
  }, [assignAbleUsers]);

  if (!item) {
    return null;
  }

  const {
    phone,
    portUuid,
    panel,
    priority,
    cause,
    telecomBusyPort,
    telecomBusyRow,
    telecomBusyFloor,
    busyFloor,
    busyPort,
    busyRow,
    date,
    username,
    password,
    name,
    status,
    mobileNumber,
    address,
    bandWidth,
    assignedTo,
    comments
  } = formik.values;
  const {
    phone: phoneError,
    portUuid: portUuidError,
    panel: panelError,
    status: statusError,
    priority: priorityError,
    bandWidth: bandWidthError,
    cause: causeError,
    busyRow: busyRowError,
    busyFloor: busyFloorError,
    busyPort: busyPortError,
    telecomBusyFloor: telecomBusyFloorError,
    telecomBusyPort: telecomBusyPortError,
    telecomBusyRow: telecomBusyRowError,
    date: dateError,
    username: usernameError,
    password: passwordError,
    name: nameError,
    mobileNumber: mobileNumberError,
    assignedTo: assignedToError,
    address: addressError,
    comments: commentsError
  } = formik.errors;
  const {
    portUuid: portUuidTouched,
    phone: phoneTouched,
    panel: panelTouched,
    status: statusTouched,
    priority: priorityTouched,
    bandWidth: bandWidthTouched,
    cause: causeTouched,
    busyRow: busyRowTouched,
    busyFloor: busyFloorTouched,
    busyPort: busyPortTouched,
    telecomBusyFloor: telecomBusyFloorTouched,
    telecomBusyRow: telecomBusyRowTouched,
    telecomBusyPort: telecomBusyPortTouched,
    comments: commentsTouched
  } = formik.touched;
  const { handleChange, setFieldValue } = formik;

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'> ویرایش خرابی </h5>
        <form autoComplete='on' onSubmit={formik.handleSubmit}>
          <Input
            placeholder='نمونه : 4133254457'
            name='phone'
            title=' شماره : '
            autoComplete='off'
            error={phoneTouched && phoneError ? phoneError : undefined}
            value={phone}
            onChange={handleChange}
          />

          <Input
            placeholder='نمونه : 123456'
            name='portUuid'
            title='شناسه پورت : '
            autoComplete='off'
            error={portUuidTouched && portUuidError ? portUuidError : undefined}
            value={portUuid}
            onChange={handleChange}
          />

          <Input
            placeholder='پنل'
            name='panel'
            title=' پنل : '
            autoComplete='off'
            error={panelTouched && panelError ? panelError : undefined}
            value={panel}
            onChange={handleChange}
          />

          <div className='select-wrapper--high '>
            <label>وضعیت :</label>
            <Select
              value={
                STATUS_TYPES_LIST.find((item) => {
                  return item.value === status;
                }) ?? null
              }
              onChange={(e) => {
                setFieldValue("status", e?.value ?? "");
              }}
              isClearable
              placeholder='وضعیت مربوطه را انتخاب کنید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={STATUS_TYPES_LIST}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {statusTouched && statusError && (
              <p className='input-error--select'>{statusError}</p>
            )}
          </div>
          <div className='triple-input-wrapper--high '>
            <label>بوخت آسیاتک :</label>
            <div className='triple-input-wrapper'>
              <Input
                placeholder='ردیف'
                name='busyRow'
                autoComplete='off'
                error={
                  busyRowTouched && busyRowError ? busyRowError : undefined
                }
                value={busyRow}
                type={"number"}
                onChange={handleChange}
              />
              <Input
                placeholder='طبقه'
                name='busyFloor'
                autoComplete='off'
                error={
                  busyFloorTouched && busyFloorError
                    ? busyFloorError
                    : undefined
                }
                value={busyFloor}
                type={"number"}
                onChange={handleChange}
              />
              <Input
                placeholder='پورت'
                name='busyPort'
                autoComplete='off'
                error={
                  busyPortTouched && busyPortError ? busyPortError : undefined
                }
                value={busyPort}
                type={"number"}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='triple-input-wrapper--high '>
            <label>بوخت مخابرات :</label>
            <div className='triple-input-wrapper'>
              <Input
                placeholder='ردیف'
                name='telecomBusyRow'
                autoComplete='off'
                error={
                  telecomBusyRowTouched && telecomBusyRowError
                    ? telecomBusyRowError
                    : undefined
                }
                value={telecomBusyRow}
                type={"number"}
                onChange={handleChange}
              />
              <Input
                placeholder='طبقه'
                name='telecomBusyFloor'
                autoComplete='off'
                error={
                  telecomBusyFloorTouched && telecomBusyFloorError
                    ? telecomBusyFloorError
                    : undefined
                }
                value={telecomBusyFloor}
                type={"number"}
                onChange={handleChange}
              />
              <Input
                placeholder='پورت'
                name='telecomBusyPort'
                autoComplete='off'
                error={
                  telecomBusyPortTouched && telecomBusyPortError
                    ? telecomBusyPortError
                    : undefined
                }
                value={telecomBusyPort}
                type={"number"}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='select-wrapper--high '>
            <label> علت خرابی :</label>
            <Select
              value={
                CAUSE_TYPES_LIST.filter((item) => {
                  return cause?.includes(item.value as CAUSE_TYPES);
                }) ?? null
              }
              onChange={(selectArray) => {
                setFieldValue(
                  "cause",
                  selectArray.length !== 0
                    ? selectArray.map((item) => {
                        return item.value as CAUSE_TYPES;
                      })
                    : null,
                  true
                );
              }}
              isClearable
              placeholder=' علت خرابی مربوطه را انتخاب کنید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={CAUSE_TYPES_LIST}
              isMulti
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {causeTouched && causeError && (
              <p className='input-error--select'>{causeError}</p>
            )}
          </div>
          <div className='select-wrapper--high '>
            <label>اولویت :</label>
            <Select
              value={
                PRIORITY_TYPES_LIST.find((item) => {
                  return item.value === priority;
                }) ?? null
              }
              onChange={(e) => {
                setFieldValue("priority", e?.value ?? "", true);
              }}
              isClearable
              placeholder='اولویت مربوطه را انتخاب کنید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={PRIORITY_TYPES_LIST}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {priorityTouched && priorityError && (
              <p className='input-error--select'>{priorityError}</p>
            )}
          </div>

          <div className='select-wrapper--high '>
            <label>پهنای باند :</label>
            <Select
              onChange={(e) => {
                setFieldValue("bandWidth", e?.value ?? "", true);
              }}
              value={
                BANDWIDTH_TYPES_LIST.find((item) => {
                  // eslint-disable-next-line eqeqeq
                  return +item.value == bandWidth;
                }) ?? null
              }
              isClearable
              placeholder='پهنای باند مربوطه را انتخاب کنید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={BANDWIDTH_TYPES_LIST}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {bandWidthTouched && bandWidthError && (
              <p className='input-error--select'>{bandWidthError}</p>
            )}
          </div>

          {status === STATUS_TYPES.REFERRED_TO_EXPERT && (
            <>
              <br />{" "}
              <h5 style={{ margin: "1rem" }} className='title'>
                {" "}
                اطلاعات حضوری
              </h5>
              <br />{" "}
              <div style={{ marginBottom: 0 }} className='select-wrapper--high'>
                <label> تاریخ حضوری:</label>

                <DatePicker
                  isGregorian={false}
                  onChange={(value) => {
                    setFieldValue("date", value, true);
                  }}
                  value={date}
                  timePicker={true}
                  min={moment().date(moment().get("date") - 1)}
                />
                {dateError && (
                  <p className='input-error--select'>{dateError}</p>
                )}
              </div>
              <Input
                placeholder='نام کاربری'
                title='نام کاربری'
                name='username'
                autoComplete='off'
                error={usernameError ? usernameError : undefined}
                value={username}
                onChange={handleChange}
              />
              <Input
                placeholder='کلمه عبور'
                title='کلمه عبور'
                name='password'
                autoComplete='off'
                error={passwordError ? passwordError : undefined}
                value={password}
                onChange={handleChange}
              />
              <Input
                placeholder='نام مشترک'
                title='نام مشترک'
                name='name'
                autoComplete='off'
                error={nameError ? nameError : undefined}
                value={name}
                onChange={handleChange}
              />
              <Input
                placeholder='شماره همراه'
                title='شماره همراه'
                name='mobileNumber'
                autoComplete='off'
                type='tel'
                error={mobileNumberError ? mobileNumberError : undefined}
                value={mobileNumber}
                onChange={handleChange}
              />
              <div className='select-wrapper--high '>
                <label> به چه کسی ارجاع می کنید :</label>
                <Select
                  value={getAssignAbleUsersList()?.find((user) => {
                    return user.value === assignedTo;
                  })}
                  onChange={(e) => {
                    setFieldValue("assignedTo", e?.value ?? "", true);
                  }}
                  isClearable
                  placeholder=' لطفا کاربر مورد نظر را انتخاب نمایید'
                  openMenuOnClick={true}
                  classNamePrefix='react-select'
                  options={getAssignAbleUsersList()}
                  noOptionsMessage={() => {
                    return <h6>آیتمی یافت نشد</h6>;
                  }}
                />
                {assignedToError && (
                  <p className='input-error--select'>{assignedToError}</p>
                )}
              </div>
            </>
          )}

          {status === STATUS_TYPES.REFERRED_TO_EXPERT && (
            <Input
              placeholder='آدرس'
              title='آدرس'
              name='address'
              autoComplete='off'
              error={addressError ? addressError : undefined}
              value={address}
              onChange={handleChange}
            />
          )}
          <Input
            title=' توضیحات'
            name='comments'
            autoComplete='off'
            error={commentsTouched && commentsError ? commentsError : undefined}
            value={comments}
            type='text'
            onChange={handleChange}
            multiline
          />
          <SubmitButton
            title='ثبت'
            loading={loading}
            disabled={loading}
            type='submit'
          />
        </form>
      </div>
    </>
  );
};

export default EditFault;
