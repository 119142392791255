import {
  FaultType,
  AdvancedResult,
  TempFaultsType,
  TempFaultsTable,
  AssignAbleUsers,
  BINARY_STATUS,
  FaultStaticType,
  FaultStaticUserType,
  FaultsStatus
} from "../../types";
import timeSince from "../../utility/fromNowTime";
import { FaultsActionTypes } from "../actionTypes";
import { FAULT_TYPES } from "../types";
interface StateType {
  list: AdvancedResult<FaultType> | null;
  staticsList: AdvancedResult<FaultStaticType> | null;
  tempFaultList: TempFaultsType[] | null;
  tempItem: TempFaultsType | null;
  item: FaultType | null;
  loading: boolean;
  assignAbleUsers: null | AssignAbleUsers[];
  imageItem: FaultType | null;
  cloningItem: FaultType | null;
  staticsUsersList: AdvancedResult<FaultStaticUserType> | null;
  statusItem: FaultsStatus | null;
}

const initialState: StateType = {
  list: null,
  tempFaultList: null,
  item: null,
  loading: false,
  tempItem: null,
  assignAbleUsers: null,
  imageItem: null,
  staticsList: null,
  cloningItem: null,
  staticsUsersList: null,
  statusItem: null
};

const reducer = (
  state: StateType = initialState,
  action: FaultsActionTypes
): StateType => {
  switch (action.type) {
    case FAULT_TYPES.TEMP_FAULTS_LIST:
      return {
        ...state,
        tempFaultList: action.payload,
        loading: false,
        tempItem: null
      };
    case FAULT_TYPES.FAULT_STATUS_ITEM:
      const { uuid, doneFaultsCount, lastClosedDate, openFault } =
        action.payload;

      let _tempFaultList = state.tempFaultList;
      const faultIndex = _tempFaultList?.findIndex((tempFault) => {
        return tempFault[TempFaultsTable.UUID].toString() === uuid;
      });
      if (_tempFaultList && faultIndex !== undefined && faultIndex > -1) {
        _tempFaultList[faultIndex] = {
          ..._tempFaultList[faultIndex],
          [TempFaultsTable.DONEFAULTSCOUNT]: doneFaultsCount + "",
          [TempFaultsTable.LASTDONEDATE]:
            (lastClosedDate && `${timeSince(new Date(lastClosedDate))} قبل`) ??
            "",
          [TempFaultsTable.OPENFAULT]: openFault
            ? BINARY_STATUS.YES
            : BINARY_STATUS.NO
        } as unknown as TempFaultsType;
      }
      return {
        ...state,
        tempFaultList: _tempFaultList,
        loading: false
      };
    case FAULT_TYPES.FAULT_ASSIGNABLE_USERS:
      return {
        ...state,
        loading: false,
        assignAbleUsers: action.payload
      };
    case FAULT_TYPES.FAULT_STATUS_ITEM_BY_PHONE:
      return {
        ...state,
        loading: false,
        statusItem: action.payload
      };
    case FAULT_TYPES.TEMP_FAULT_ITEM:
      return {
        ...state,
        tempItem: action.payload,
        loading: false
      };
    case FAULT_TYPES.FAULT_CLEAR_TEMP_ITEM:
      return {
        ...state,
        tempItem: null,
        loading: false
      };
    case FAULT_TYPES.FAULT_ITEM:
      return {
        ...state,
        item: action.payload,
        loading: false
      };
    case FAULT_TYPES.CLONING_FAULT_ITEM:
      return {
        ...state,
        cloningItem: action.payload,
        loading: false
      };
    case FAULT_TYPES.DELETE_TEMP_FAULT:
      return {
        ...state,
        tempFaultList:
          state.tempFaultList?.filter((fault) => {
            return fault[TempFaultsTable.UUID] !== action.payload;
          }) ?? [],
        loading: false,
        item: null
      };

    case FAULT_TYPES.ADD_FAULT:
      return {
        ...state,
        tempFaultList:
          state.tempFaultList?.filter((fault) => {
            return (
              fault[TempFaultsTable.UUID].toString() !== action.payload.uuid
            );
          }) ?? [],
        loading: false,
        item: null
      };
    case FAULT_TYPES.FAULTS_LIST:
      return {
        ...state,
        list: action.payload,
        loading: false,
        item: null
      };
    case FAULT_TYPES.FAULTS_LIST_STATICS:
      return {
        ...state,
        staticsList: action.payload,
        loading: false,
        item: null
      };
    case FAULT_TYPES.FAULT_LOADING:
      return {
        ...state,
        loading: true
      };

    case FAULT_TYPES.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };
    case FAULT_TYPES.FAULT_UPLOAD:
      return {
        ...state,
        imageItem: { ...state.imageItem!, albums: action.payload },
        loading: false
      };

    case FAULT_TYPES.SELECTED_FAULT_IMAGES:
      return {
        ...state,
        imageItem: action.payload
      };

    case FAULT_TYPES.RESET_IMAGE_ITEM:
      return {
        ...state,
        imageItem: null
      };
    case FAULT_TYPES.FAULTS_LIST_USER_STATICS:
      return {
        ...state,
        staticsUsersList: action.payload,
        loading: false,
        item: null
      };
    case FAULT_TYPES.RESET_FAULT_STATUS_ITEM_BY_PHONE:
      return {
        ...state,
        statusItem: null
      };
    default:
      return state;
  }
};

export default reducer;
