import { AdvancedResult, LogsType } from "../../types";
import { LogsActionTypes } from "../actionTypes";
import { LOG_TYPES } from "../types";

interface StateType {
  list: AdvancedResult<LogsType> | null;
  smsList: AdvancedResult<LogsType> | null;
  loading: boolean;
  eventsLoading: boolean;
  events: string[] | null;
  eventsDisableLoading: boolean;
}

const initialState: StateType = {
  list: null,
  smsList: null,
  loading: false,
  eventsLoading: false,
  events: null,
  eventsDisableLoading: false
};

const reducer = (
  state: StateType = initialState,
  action: LogsActionTypes
): StateType => {
  switch (action.type) {
    case LOG_TYPES.LOG_LISTS:
      return {
        ...state,
        list: action.payload,
        loading: false
      };

    case LOG_TYPES.LOG_SMS_LISTS:
      return {
        ...state,
        smsList: action.payload,
        loading: false
      };

    case LOG_TYPES.LOG_LOADING:
      return {
        ...state,
        loading: true
      };

    case LOG_TYPES.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };

    case LOG_TYPES.EVENTS_LOADING:
      return {
        ...state,
        eventsLoading: true
      };

    case LOG_TYPES.EVENTS:
      return {
        ...state,
        events: action.payload
      };

    case LOG_TYPES.DISABLE_EVENTS_LOADING:
      return {
        ...state,
        eventsDisableLoading: false
      };

    default:
      return state;
  }
};

export default reducer;
