import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import {
  TELECOM_TYPES,
  store,
  TELECOMS_ROOT_API,
  TELECOMS_CITY_ROOT_API
} from "..";
import { customizedAxios } from "../../config";
import { getCatch } from "../../catch";
import {
  AdvancedResult,
  TelecomsType,
  TelecomCityType,
  QueryParams,
  AddTelecom,
  InputObject,
  TelecomProvinceType,
  RangeType,
  ExpertType
} from "../../types";
import { TelecomsActionTypes } from "../actionTypes";

export const fetchTelecoms =
  (query: QueryParams) => async (dispatch: Dispatch<TelecomsActionTypes>) => {
    try {
      dispatch({ type: TELECOM_TYPES.TELECOM_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: query
      };

      let url = TELECOMS_ROOT_API;
      const res = await customizedAxios.get<AdvancedResult<TelecomsType>>(
        url,
        config
      );
      dispatch({
        type: TELECOM_TYPES.TELECOM_LISTS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TELECOM_TYPES.DISABLE_LOADING });
    }
  };
export const addTelecom =
  (body: AddTelecom) => async (dispatch: Dispatch<TelecomsActionTypes>) => {
    try {
      dispatch({ type: TELECOM_TYPES.TELECOM_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = TELECOMS_ROOT_API;
      await customizedAxios.post(url, body, config);

      dispatch({
        type: TELECOM_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" مرکز با موفقیت افزوده شد.");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TELECOM_TYPES.DISABLE_LOADING });
    }
  };

export const getCities =
  ({ limit }: QueryParams) =>
  async (dispatch: Dispatch<TelecomsActionTypes>) => {
    try {
      dispatch({ type: TELECOM_TYPES.TELECOM_CITIES_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: {
          limit
        }
      };

      let url = TELECOMS_CITY_ROOT_API;
      const res = await customizedAxios.get<AdvancedResult<TelecomCityType>>(
        url,
        config
      );

      dispatch({
        type: TELECOM_TYPES.TELECOM_CITIES_LISTS,
        payload: res.data
      });
      dispatch({
        type: TELECOM_TYPES.DISABLE_TELECOM_CITIES_LOADING
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
      dispatch({ type: TELECOM_TYPES.DISABLE_TELECOM_CITIES_LOADING });
    }
  };

export const editTelecom =
  (body: TelecomsType, id: string) =>
  async (dispatch: Dispatch<TelecomsActionTypes>) => {
    try {
      dispatch({ type: TELECOM_TYPES.TELECOM_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = TELECOMS_ROOT_API.concat("/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: TELECOM_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("مرکز با موفقیت ویرایش شد");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TELECOM_TYPES.DISABLE_LOADING });
    }
  };

export const fetchOneTelecom =
  (id: string) => async (dispatch: Dispatch<TelecomsActionTypes>) => {
    try {
      dispatch({ type: TELECOM_TYPES.TELECOM_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = TELECOMS_ROOT_API.concat("/" + id);
      const res = await customizedAxios.get<InputObject<TelecomsType>>(
        url,
        config
      );
      dispatch({
        type: TELECOM_TYPES.TELECOM,
        payload: res.data.data!
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TELECOM_TYPES.DISABLE_LOADING });
    }
  };

export const getTelecomProvinces =
  () => async (dispatch: Dispatch<TelecomsActionTypes>) => {
    try {
      dispatch({ type: TELECOM_TYPES.TELECOM_PROVINCES_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = TELECOMS_ROOT_API.concat("/provinces");
      const res = await customizedAxios.get<
        AdvancedResult<TelecomProvinceType>
      >(url, config);

      dispatch({
        type: TELECOM_TYPES.TELECOM_PROVINCES_LISTS,
        payload: res.data
      });
      dispatch({
        type: TELECOM_TYPES.DISABLE_TELECOM_PROVINCES_LOADING
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
      dispatch({ type: TELECOM_TYPES.DISABLE_TELECOM_PROVINCES_LOADING });
    }
  };

export const addTelecomRange =
  (body: Partial<RangeType>, id: string) =>
  async (dispatch: Dispatch<TelecomsActionTypes>) => {
    try {
      dispatch({ type: TELECOM_TYPES.TELECOM_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = TELECOMS_ROOT_API.concat("/range/" + id);
      const result = await customizedAxios.put(url, body, config);

      dispatch({
        type: TELECOM_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" بازه با موفقیت افزوده شد.");
      return result;
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TELECOM_TYPES.DISABLE_LOADING });
    }
  };

export const deleteTelecomRange =
  (body: { _id: string }, id: string) =>
  async (dispatch: Dispatch<TelecomsActionTypes>) => {
    try {
      dispatch({ type: TELECOM_TYPES.TELECOM_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        data: body
      };

      let url = TELECOMS_ROOT_API.concat("/range/" + id);
      const result = await customizedAxios.delete(url, config);

      dispatch({
        type: TELECOM_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("بازه با موفقیت حذف شد.");
      return result;
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TELECOM_TYPES.DISABLE_LOADING });
    }
  };

export const addTelecomExpert =
  (body: Partial<ExpertType>, id: string) =>
  async (dispatch: Dispatch<TelecomsActionTypes>) => {
    try {
      dispatch({ type: TELECOM_TYPES.TELECOM_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = TELECOMS_ROOT_API.concat("/expert/" + id);
      const result = await customizedAxios.put(url, body, config);

      dispatch({
        type: TELECOM_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" کاربر با موفقیت افزوده شد.");
      return result;
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TELECOM_TYPES.DISABLE_LOADING });
    }
  };

export const deleteTelecomExpert =
  (body: { _id: string }, id: string) =>
  async (dispatch: Dispatch<TelecomsActionTypes>) => {
    try {
      dispatch({ type: TELECOM_TYPES.TELECOM_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        data: body
      };

      let url = TELECOMS_ROOT_API.concat("/expert/" + id);
      const result = await customizedAxios.delete(url, config);

      dispatch({
        type: TELECOM_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("کاربر با موفقیت حذف شد.");
      return result;
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TELECOM_TYPES.DISABLE_LOADING });
    }
  };
