import { Route, Routes } from "react-router-dom";
import AddPassive from "./add-passive";
import EditPassive from "./edit-passive";
import PassivesList from "./passives-list";

const Passives = () => {
  return (
    <>
      <Routes>
        <Route path='' element={<PassivesList />} />
        <Route path='/add' element={<AddPassive />} />
        <Route path='/edit' element={<EditPassive />} />
      </Routes>
    </>
  );
};

export default Passives;
