import { Route, Routes } from "react-router-dom";
import ShelvesList from "./shelves-list";
import LineCards from "./lineCards";
import AddShelf from "./add-shelf";
import EditShelf from "./edit-shelf";

const Shelves = () => {
  return (
    <>
      <Routes>
        <Route path='' element={<ShelvesList />} />
        <Route path='/add' element={<AddShelf />} />
        <Route path='/edit' element={<EditShelf />} />
        <Route path='line-cards/*' element={<LineCards />} />
      </Routes>
    </>
  );
};

export default Shelves;
