export enum TempFaultsTable {
  UUID = "شناسه",
  ADSL = "Adsl",
  OPENTASK = "OpenTask",
  ENTRANCEDATE = "ورود به پنل پشتیبان",
  PORTUUID = "شناسه پورت",
  ABBREVIATION = "abbreviation",
  PROVINCEID = "provinceId",
  TELECOMID = "telecomId",
  PROVINCE_NAME = "استان",
  TELECOM_NAME = "مرکز مخابراتی",
  OPENFAULT = "تسک باز دارد",
  DONEFAULTSCOUNT = "ارجاعات",
  LASTDONEDATE = "آخرین بررسی",
  ACTIONS = "عملیات"
}

export type TempFaultsType = {
  [key in TempFaultsTable]: string;
};

export type FaultsStatus = {
  openFault: boolean;
  doneFaultsCount: number;
  lastClosedDate: Date | null | undefined;
  uuid: string;
};
