import { MongoObject } from "..";

export enum RadioTable {
  _ID = "_id",
  CUSTOMERNAME = " نام مشترک",
  BRANDNAMEMODEL = "مدل رادیو",
  VIEW = "طرف دید",
  LEG = "پایه",
  HEIGHT = "ارتفاع",
  PORT = " پورت ",
  ACTIONS = "عملیات",
  DESCRIPTION = "توضیحات",
  CABLESIZE = "سایز کابل",
  CABLETYPE = "نوع کابل",
  COLLECTED = "جمع‌آوری شده",
  INSTALLERS = "نصب کننده‌ها",
  ETHERMAC = "اتر مک",
  RADIOPROPERTNUMBER = "شماره پورت رادیو",
  RADIOSERIALNUMBER = "شماره سریال رادیو",
  WIRELESSMAC = "وایرلس مک",
  INSTALLDATE = "تاریخ نصب",
  CREATEDAT = "تاریخ ایجاد",
  UPDATEDAT = "تاریخ به‌روز‌رسانی ",
  ROW = "ردیف"
}

export interface RadioSchema extends MongoObject {
  _id?: string;
  id?: string | null;
  customerName?: string;
  brandNameModel?: string;
  view?: string;
  port?: string | number;
  height?: string | number;
  leg?: string | number;
  cableSize?: string | number;
  cableType?: string;
  collected?: boolean;
  installers?: string;
  etherMac?: string;
  radioPropertyNumber?: string;
  radioSerialNumber?: string;
  wirelessMac?: string;
  description?: string;
  installDate?: string;
  createdAt?: Date;
  updatedAt?: Date;
}
export interface AddRadio {
  customerName?: string;
  brandNameModel?: string;
  view?: string;
  port?: string | number;
  height?: string | number;
  leg?: string | number;
  cableSize?: string | number;
  cableType?: string;
  collected?: boolean;
  installers?: string;
  etherMac?: string;
  radioPropertyNumber?: string;
  radioSerialNumber?: string;
  wirelessMac?: string;
  description?: string;
  installDate?: string;
}
