const GENERAL_PROVINCES_API = `general/province`;
const GENERAL_CITIES_API = `general/cities`;
const GENERAL_TELECOMS_API = `general/telecoms`;
const GENERAL_EVENTS_API = `general/eventTypes`;

export {
  GENERAL_PROVINCES_API,
  GENERAL_CITIES_API,
  GENERAL_TELECOMS_API,
  GENERAL_EVENTS_API
};
