import {
  AdvancedResult,
  TelecomCityType,
  TelecomsType,
  TelecomProvinceType
} from "../../types";
import { TelecomsActionTypes } from "../actionTypes";
import { TELECOM_TYPES } from "../types";

interface StateType {
  list: AdvancedResult<TelecomsType> | null;
  item: TelecomsType | null;
  loading: boolean;
  citiesLoading: boolean;
  cities: AdvancedResult<TelecomCityType> | null;
  provincesLoading: boolean;
  provinces: AdvancedResult<TelecomProvinceType> | null;
}

const initialState: StateType = {
  list: null,
  item: null,
  loading: false,
  citiesLoading: false,
  cities: null,
  provincesLoading: false,
  provinces: null
};

const reducer = (
  state: StateType = initialState,
  action: TelecomsActionTypes
): StateType => {
  switch (action.type) {
    case TELECOM_TYPES.TELECOM_LISTS:
      return {
        ...state,
        list: action.payload,
        loading: false,
        item: null
      };

    case TELECOM_TYPES.TELECOM:
      return {
        ...state,
        item: action.payload,
        loading: false
      };

    case TELECOM_TYPES.TELECOM_LOADING:
      return {
        ...state,
        loading: true
      };

    case TELECOM_TYPES.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };

    case TELECOM_TYPES.TELECOM_CITIES_LOADING:
      return {
        ...state,
        citiesLoading: true
      };
    case TELECOM_TYPES.TELECOM_CITIES_LISTS:
      return {
        ...state,
        cities: action.payload
      };
    case TELECOM_TYPES.DISABLE_TELECOM_CITIES_LOADING:
      return {
        ...state,
        citiesLoading: false
      };

    case TELECOM_TYPES.TELECOM_PROVINCES_LOADING:
      return {
        ...state,
        provincesLoading: true
      };
    case TELECOM_TYPES.TELECOM_PROVINCES_LISTS:
      return {
        ...state,
        provinces: action.payload
      };
    case TELECOM_TYPES.DISABLE_TELECOM_PROVINCES_LOADING:
      return {
        ...state,
        provincesLoading: false
      };

    default:
      return state;
  }
};

export default reducer;
