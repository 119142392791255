import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { SmsTypes } from "../../../../core/types";

const AddSms = () => {
  const { addSms } = useActions();
  let [searchParams] = useSearchParams();

  const smsId = searchParams.get("smsId");

  const { loading } = useTypedSelector((state) => state.notification);
  const formik = useFormik({
    initialValues: {
      message: ""
    },
    validationSchema: yup.object({
      message: yup.string().required("  متن پیام الزامی است")
    }),
    onSubmit: ({ message }: SmsTypes) => {
      addSms(
        {
          message
        },
        smsId ?? ""
      );
    }
  });

  const { message } = formik.values;
  const { message: messageError } = formik.errors;
  const { message: messageTouched } = formik.touched;

  return (
    <div className='add-item-wrapper'>
      <div className='list-top'>
        <p>اضافه کردن اس ام اس</p>
      </div>
      <form autoComplete='on' onSubmit={formik.handleSubmit}>
        <Input
          multiline
          name='message'
          title='متن پیام'
          autoComplete='off'
          error={messageTouched && messageError ? messageError : undefined}
          value={message}
          onChange={formik.handleChange}
        />

        <SubmitButton
          title='ثبت'
          loading={loading}
          disabled={loading}
          type='submit'
        />
      </form>
    </div>
  );
};

export default AddSms;
