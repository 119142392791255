import { CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import copy from "copy-to-clipboard";
import { useFormik } from "formik";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import * as yup from "yup";
import {
  DetailModalContent,
  ImageWrapper,
  Input,
  NoItem,
  SubmitButton
} from "../../../../core/components";
import {
  DEBOUNCE_TIME,
  PAP_TYPES_LIST,
  USER_ACCESS,
  defaultTablePageCount
} from "../../../../core/constants";
import { defaultColumnDefinition } from "../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { BASE_API, PAPS_ROOT_API, PAP_TYPES } from "../../../../core/redux";
import {
  CRUD_TYPES,
  PAP_TYPE,
  PapsTable,
  PapsType,
  ProvincesType,
  TelecomsType
} from "../../../../core/types";
import {
  checkUserAccessByTelecomCrud,
  fileDownload
} from "../../../../core/utility";
import useIsMobile from "src/core/hooks/useIsMobile";
import { useQueryParams } from "src/core/hooks/useQueryParams";

const PapsList = () => {
  const { fetchPaps, uploadPapPhoto, deletePapPhoto } = useActions();
  const { loading, list, imageItem } = useTypedSelector((state) => state.pap);
  const { searchParams, setSearchParams } = useQueryParams();

  const [currentPage, setCurrentPage] = useState<number>(
    +(searchParams("page") ?? 1) - 1
  );
  const [selectedItem, setSelectedItem] = useState<PapsType | null>(null);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);

  const isMobile = useIsMobile();
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const { me } = useTypedSelector((state) => state.auth);
  const { token } = useTypedSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      type: searchParams("type[regex]") ?? "",
      name: searchParams("name[regex]") ?? ""
    },
    validationSchema: yup.object({
      type: yup.string(),
      name: yup.string()
    }),
    onSubmit: () => {}
  });

  const { type, name } = formik.values;

  useEffect(() => {
    callApi(currentPage, type, name);
    setSearchParams({
      page: currentPage + 1,
      "type[regex]": type,
      "name[regex]": name
    });
    dispatch({ type: PAP_TYPES.RESET_IMAGE_ITEM });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, type, name]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callApi = useCallback(
    _.debounce((page, type, name) => {
      fetchPaps({
        limit: defaultTablePageCount,
        page: page + 1,
        "type[regex]": type,
        "name[regex]": name
      });
    }, DEBOUNCE_TIME),
    []
  );

  const rowData = useCallback(() => {
    return list?.data.map((item, index) => {
      return {
        [PapsTable.ROW]: `${index + 1 + currentPage * 10}`,
        [PapsTable._ID]: item._id,
        [PapsTable.PROVINCEID]: (item.provinceId as unknown as ProvincesType)
          ?._id,
        [PapsTable.TELECOMID]: (item.telecomId as unknown as TelecomsType)?._id,
        [PapsTable.PAP]: item.type,
        [PapsTable.TELECOM]: item.name,
        [PapsTable.PROVINCE]: (item.provinceId as unknown as ProvincesType)
          ?.name,
        [PapsTable.CREATEDAT]: new Date(
          item.createdAt ?? ""
        ).toLocaleDateString("fa-IR"),
        [PapsTable.UPDATEDAT]: new Date(
          item.updatedAt ?? ""
        ).toLocaleDateString("fa-IR"),
        [PapsTable.ROUTER]: item.router?.brandNameModel,
        [PapsTable.ROUTER_PROPERTY]: item.router?.propertyNumber,
        [PapsTable.ELECTRICITY_TYPE]: item.router?.electricityType,
        [PapsTable.FILE_NUMBER]: item.fileNumber,
        [PapsTable.RACK]: item.racks?.length,
        [PapsTable.SHELF]: item.shelves?.length,
        [PapsTable.PASSIVE]: item.passives?.length,
        [PapsTable.MISCELLANEOUS]: item.miscellaneous?.length
      };
    });
  }, [list, currentPage]);

  const hasAlbum = useCallback(
    (cellEvent: CellClickedEvent) =>
      me &&
      checkUserAccessByTelecomCrud(
        me,
        me?.access ?? [],
        USER_ACCESS.PAPS,
        CRUD_TYPES.DELETE,
        cellEvent?.data[PapsTable.PROVINCEID],
        cellEvent?.data[PapsTable.TELECOMID]
      ) &&
      list?.data?.find((pap) => {
        return pap._id === cellEvent?.data?._id;
      })?.albums?.length !== 0,
    [list?.data, me]
  );

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          <span
            onClick={() => {
              setSelectedItem(cellEvent?.data);
              setIsCommentModalOpen(true);
            }}>
            <i title='توضیحات' className='fas fa-comment comment-icon'></i>
          </span>
          {hasAlbum(cellEvent) && (
            <span
              onClick={(e) => {
                e.stopPropagation();

                dispatch({
                  type: PAP_TYPES.SELECTED_PAP_IMAGES,
                  payload: list?.data?.find((pap) => {
                    return pap._id === cellEvent?.data?._id;
                  })
                });
              }}>
              <i title='گالری' className='fas fa-images edit-icon'></i>
            </span>
          )}

          {me &&
            checkUserAccessByTelecomCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.PAPS,
              CRUD_TYPES.EDIT,
              cellEvent?.data[PapsTable.PROVINCEID],
              cellEvent?.data[PapsTable.TELECOMID]
            ) && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  navigator(`edit-pap?_id=${cellEvent?.data?._id}`);
                }}>
                <i title='ویرایش' className='fas fa-pen edit-icon'></i>
              </span>
            )}

          {me &&
            checkUserAccessByTelecomCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.PAPS,
              CRUD_TYPES.EDIT,
              cellEvent?.data[PapsTable.PROVINCEID],
              cellEvent?.data[PapsTable.TELECOMID]
            ) && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch({
                    type: PAP_TYPES.SELECTED_PAP_IMAGES,
                    payload: list?.data?.find((pap) => {
                      return pap._id === cellEvent?.data?._id;
                    })
                  });
                  navigator(`add-pap-photo?_id=${cellEvent?.data?._id}`);
                }}>
                <i
                  title='اضافه کردن عکس'
                  className='fas fa-camera add-icon'></i>
              </span>
            )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list?.data]
  );

  const resetPagination = () => currentPage !== 0 && setCurrentPage(0);

  return (
    <>
      {isCommentModalOpen && (
        <DetailModalContent
          content={selectedItem}
          isOpen={isCommentModalOpen}
          title={"توضیحات پپ"}
          message='توضیحی درباره این پپ ثبت نشده است.'
          onClose={() => {
            setIsCommentModalOpen(false);
            setSelectedItem(null);
          }}
        />
      )}
      <section className='clients-search-wrapper'>
        <div className='list-input-wrapper'>
          <Select
            onChange={(e) => {
              formik.setValues({
                ...formik.values,
                type: (e?.value as PAP_TYPE) ?? ""
              });
              resetPagination();
            }}
            defaultValue={{
              label: type,
              value: type
            }}
            isClearable
            placeholder='نوع پروژه'
            openMenuOnClick={true}
            classNamePrefix='react-select'
            options={PAP_TYPES_LIST}
            noOptionsMessage={() => {
              return <h6>آیتمی یافت نشد</h6>;
            }}
          />
          <Input
            name='name'
            placeholder='جستجو نام مرکز'
            autoComplete='off'
            value={name}
            onChange={(e) => {
              formik.handleChange(e);
              resetPagination();
            }}
          />
        </div>
      </section>{" "}
      <div className='main-route-wrapper'>
        <div className='table-wrapper'>
          <div className='list-top'>
            <p>
              تعداد کل پپ : <span>{list && list.total} عدد</span>
              <i
                onClick={() => {
                  callApi(currentPage, type, name);
                }}
                className={`fas fa-sync-alt ${loading ? "rotate" : ""}`}></i>
              {list && list?.data && list.data.length !== 0 && (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    const url = BASE_API + PAPS_ROOT_API;
                    fileDownload(url, token ?? "", "paps.xlsx", {
                      xlsx: true,
                      sort: "provinceId"
                    });
                  }}
                  className='fas fa-file-excel excel-icon'></i>
              )}
            </p>
            <Link to={"./add-pap"}>
              <SubmitButton loading={loading} title=' اضافه کردن پپ جدید' />
            </Link>
          </div>

          {(!list || list.count === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}

          {list && list.count !== 0 && (
            <>
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  animateRows={true}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  rowSelection={"single"}
                  onCellClicked={(e) => {
                    if (e.colDef.field !== PapsTable.ACTIONS) copy(e.value);

                    if (e.colDef.field === PapsTable.SHELF)
                      navigator(`shelves?_id=${e?.data?._id}`);
                    else if (e.colDef.field === PapsTable.RACK)
                      navigator(`racks?_id=${e?.data?._id}`);
                    else if (e.colDef.field === PapsTable.PASSIVE)
                      navigator(`passives?_id=${e?.data?._id}`);
                    else if (e.colDef.field === PapsTable.MISCELLANEOUS)
                      navigator(`miscellaneous?_id=${e?.data?._id}`);
                  }}
                  enableRtl={true}>
                  <AgGridColumn
                    sortable={true}
                    field={PapsTable.ROW}
                    width={84}
                  />
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={PapsTable.ACTIONS}
                    width={180}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PapsTable.PAP}
                    width={110}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PapsTable.TELECOM}
                    width={170}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PapsTable.RACK}
                    width={70}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PapsTable.SHELF}
                    width={80}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PapsTable.PASSIVE}
                    width={90}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PapsTable.MISCELLANEOUS}
                    width={90}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PapsTable.ROUTER}
                    width={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PapsTable.ROUTER_PROPERTY}
                    width={130}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PapsTable.FILE_NUMBER}
                    width={130}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PapsTable.ELECTRICITY_TYPE}
                    width={100}
                  />

                  <AgGridColumn
                    sortable={true}
                    field={PapsTable.PROVINCE}
                    width={170}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PapsTable.CREATEDAT}
                    width={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PapsTable.UPDATEDAT}
                    width={150}
                  />
                </AgGridReact>
              </div>
            </>
          )}
        </div>
        <ReactPaginate
          marginPagesDisplayed={isMobile ? 1 : 3}
          forcePage={currentPage}
          breakLabel='...'
          nextLabel='بعدی'
          onPageChange={(e) => {
            setCurrentPage(e.selected);
          }}
          pageRangeDisplayed={5}
          pageCount={
            (list && Math.ceil(list.total / defaultTablePageCount)) ?? 1
          }
          previousLabel='قبلی'
          containerClassName='pagination-container'
        />
      </div>
      {imageItem && (
        <ImageWrapper
          albums={imageItem?.albums?.map((album) => {
            return {
              ...album,
              path:
                BASE_API +
                PAPS_ROOT_API +
                "/photo/" +
                imageItem._id +
                "?photoId=" +
                album.path
            };
          })}
          onDelete={(albumItemId) => {
            deletePapPhoto(albumItemId, imageItem?._id ?? "");
          }}
          onSuccess={(file) => {
            uploadPapPhoto(file, {});
          }}
        />
      )}
    </>
  );
};

export default PapsList;
