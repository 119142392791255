import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import {
  Input,
  SubmitButton,
  SubmitButtonSecondary
} from "../../../../core/components";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { USER_LEVEL, UserType } from "../../../../core/types";
import PesonalAccess from "./PesonalAccess";
import SmsPanelInfo from "./SmsPanelInfo";
import AddUserPhoto from "./add-photo";
import ChangePassword from "./change-password";

const ProfileInfo = () => {
  const { editUser } = useActions();
  const { me } = useTypedSelector((state) => state.auth);
  const { loading } = useTypedSelector((state) => state.user);
  const [isOpenCropper, setIsOpenCropper] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      fullName: me ? me.fullName : "",
      address: me ? me.address : "",
      mobileNumber: me ? me.mobileNumber : "",
      email: me ? me.email : "",
      nationalCode: me ? me.nationalCode : ""
    },
    validationSchema: yup.object({
      fullName: yup.string().required("نام کامل الزامی است"),
      nationalCode: yup.string().required("کد ملی الزامی می باشد"),
      address: yup.string().required("آدرس کامل الزامی می باشد"),
      mobileNumber: yup.string().required("شماره موبایل الزامی است"),
      email: yup
        .string()
        .email("ایمیل معتبر نمی باشد")
        .trim()
        .required("ایمیل الزامی می باشد")
    }),
    onSubmit: ({
      fullName,
      nationalCode,
      address,
      mobileNumber,
      email
    }: UserType) => {
      editUser(
        {
          fullName,
          nationalCode,
          address,
          mobileNumber,
          email
        },
        me?._id as string
      );
    }
  });

  const { fullName, address, mobileNumber, nationalCode, email } =
    formik.values;
  const {
    fullName: fullNameError,
    address: addressError,
    mobileNumber: mobileNumberError,
    nationalCode: nationalCodeError,
    email: emailError
  } = formik.errors;
  const {
    fullName: fullNameTouched,
    address: addressTouched,
    mobileNumber: mobileNumberTouched,
    nationalCode: nationalCodeTouched,
    email: emailTouched
  } = formik.touched;

  return (
    <>
      <div className='user-profile-wrapper'>
        <div className='header-logo'>
          <div className='header-logo__wrapper'>
            <AddUserPhoto
              isOpenCropper={isOpenCropper}
              setIsOpenCropper={setIsOpenCropper}
            />
          </div>
        </div>
        <form autoComplete='on' onSubmit={formik.handleSubmit}>
          <Input
            name='fullName'
            title='نام کامل : '
            autoComplete='off'
            error={fullNameTouched && fullNameError ? fullNameError : undefined}
            value={fullName}
            type={"string"}
            onChange={formik.handleChange}
          />
          <Input
            defaultValue={me?.mobileNumber}
            name='mobileNumber'
            title='شماره همراه: '
            autoComplete='off'
            error={
              mobileNumberTouched && mobileNumberError
                ? mobileNumberError
                : undefined
            }
            value={mobileNumber}
            type={"string"}
            onChange={formik.handleChange}
          />
          <Input
            defaultValue={me?.email}
            name='email'
            title='ایمیل : '
            autoComplete='off'
            error={emailTouched && emailError ? emailError : undefined}
            value={email}
            type={"string"}
            onChange={formik.handleChange}
          />
          <Input
            defaultValue={me?.nationalCode}
            name='nationalCode'
            title='کد ملی : '
            autoComplete='off'
            error={
              nationalCodeTouched && nationalCodeError
                ? nationalCodeError
                : undefined
            }
            value={nationalCode}
            type={"string"}
            onChange={formik.handleChange}
          />
          <Input
            multiline
            defaultValue={me?.address}
            name='address'
            title='آدرس : '
            autoComplete='off'
            error={addressTouched && addressError ? addressError : undefined}
            value={address}
            type={"string"}
            onChange={formik.handleChange}
          />

          <div className='buttons-wrapper'>
            <SubmitButton
              title='ثبت'
              loading={loading}
              disabled={loading}
              type='submit'
            />
            <SubmitButtonSecondary
              title='تغییر تصویر'
              loading={loading}
              disabled={loading}
              type='button'
              onClick={() => {
                setIsOpenCropper(true);
              }}
            />
          </div>
        </form>
      </div>
      <ChangePassword />
      {me?.level === USER_LEVEL.TWO && <SmsPanelInfo />}
      <PesonalAccess />
    </>
  );
};

export default ProfileInfo;
