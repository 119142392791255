import { AxiosError } from "axios";
import { store } from "../redux";
import { AUTH_TYPES } from "../redux";

const getCatch = (
  err: AxiosError
): { message: string; status?: number; type?: string } => {
  const invalidateTokenMasseage = "NOTLOGIN";
  if (err.response && err.response.status && err.response.status === 429) {
    return { message: "درخواست بیش از حد مجاز" };
  } else if (
    err.response &&
    err.response.data &&
    err.response.data.type === invalidateTokenMasseage
  ) {
    store.dispatch({ type: AUTH_TYPES.LOGOUT });
    return { message: "لطفا دوباره وارد سامانه شوید" };
  } else if (err.response && err.response.data) {
    return { message: err.response.data?.error || "خطای سامانه" };
  } else if (!window.navigator.onLine) {
    return { message: "اینترنت وصل نیست" };
  } else {
    return { message: "اشکال در ارتباط سامانه" };
  }
};

export { getCatch };
