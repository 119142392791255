import { useFormik } from "formik";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../../core/components";
import { useActions, useTypedSelector } from "../../../../../core/hooks";
import { RackSchema } from "../../../../../core/types";

const AddRack = () => {
  let [searchParams] = useSearchParams();

  const _id = searchParams.get("_id");
  const { addRack, fetchOnePap } = useActions();
  const { loading, item } = useTypedSelector((state) => state.pap);
  const formik = useFormik({
    initialValues: {
      rackNumber: "",
      brandName: "",
      propertyNumber: "",
      serialNumber: "",
      dimension: ""
    },
    validationSchema: yup.object({
      brandName: yup.string().trim(),
      propertyNumber: yup.string().trim(),
      serialNumber: yup.string().trim(),
      rackNumber: yup
        .number()
        .required("شماره رک الزامی است")
        .max(99, "حداکثر مقدار شماره رک 99 می باشد"),
      dimension: yup.string().trim()
    }),
    onSubmit: ({
      brandName,
      propertyNumber,
      serialNumber,
      rackNumber,
      dimension
    }: RackSchema) => {
      addRack(
        {
          brandName,
          propertyNumber,
          serialNumber,
          rackNumber,
          dimension
        },
        item?._id ?? ""
      );
    }
  });

  useEffect(() => {
    if (_id) {
      fetchOnePap(_id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const { brandName, propertyNumber, serialNumber, rackNumber, dimension } =
    formik.values;
  const {
    brandName: brandNameError,
    dimension: dimensionError,
    propertyNumber: propertyNumberError,
    serialNumber: serialNumberError,
    rackNumber: rackNumberError
  } = formik.errors;
  const {
    brandName: brandNameTouched,
    dimension: dimensionTouched,
    propertyNumber: propertyNumberTouched,
    serialNumber: serialNumberTouched,
    rackNumber: rackNumberTouched
  } = formik.touched;

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'>اضافه کردن رک جدید</h5>
        <form autoComplete='on' onSubmit={formik.handleSubmit}>
          <Input
            placeholder='شماره رک جدید را وارد کنید'
            name='rackNumber'
            title='شماره رک : '
            autoComplete='off'
            error={
              rackNumberTouched && rackNumberError ? rackNumberError : undefined
            }
            value={rackNumber}
            onChange={formik.handleChange}
            type='number'
          />
          <Input
            name='brandName'
            title=' برند : '
            autoComplete='off'
            error={
              brandNameTouched && brandNameError ? brandNameError : undefined
            }
            value={brandName}
            onChange={formik.handleChange}
            type='text'
          />

          <Input
            name='propertyNumber'
            title='شماره اموال : '
            autoComplete='off'
            error={
              propertyNumberTouched && propertyNumberError
                ? propertyNumberError
                : undefined
            }
            value={propertyNumber}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='serialNumber'
            title='شماره سریال : '
            autoComplete='off'
            error={
              serialNumberTouched && serialNumberError
                ? serialNumberError
                : undefined
            }
            value={serialNumber}
            onChange={formik.handleChange}
            type='text'
          />
          <Input
            name='dimension'
            title='ابعاد  : '
            autoComplete='off'
            error={
              dimensionTouched && dimensionError ? dimensionError : undefined
            }
            value={dimension}
            onChange={formik.handleChange}
            type='text'
          />

          <SubmitButton
            title='ثبت'
            loading={loading}
            disabled={loading}
            type='submit'
          />
        </form>
      </div>
    </>
  );
};

export default AddRack;
