import { Moment } from "jalali-moment";
import { GeneralCommentDocument, MongoObject } from "../BaseTypes";
import { AlbumSchema } from "../PapsTypes";
import { UserType } from "../UserTypes";
import { TelecomsType } from "../TelecomsTypes";

export enum STATUS_TYPES {
  IN_TELECOM = "در دست بررسی از مرکز",
  NORMAL = "مرکز نرمال",
  CUSTOMER = "در دست بررسی با مشترک",
  REFERRED_TO_EXPERT = "ارجاع به حضوری",
  DONE = "پایان یافته"
}

export enum NEED_EXPERT_STATUS_TYPES {
  WAITING_EXPERT = "منتظر کارشناس",
  EXPERT_DONE = "حضوری اتمام یافته",
  EXPERT_CANCELED = "لغو شده"
}

export enum CAUSE_TYPES {
  BAD_UP = "بد اپ",
  CUT_OF_LINK = "قطعی لینک",
  BREAK_UP = "قطع و وصلی",
  ONLINE = "ایراد آنلاین",
  OTHER = "سایر"
}

export enum INSERT_TYPE {
  XLSX = "شیت اکسل",
  MANUAL = "دستی"
}

export enum PRIORITY_TYPES {
  NORMAL = "معمولی",
  IMPORTANT = "اولویت",
  ESSENTIAL = "مهم"
}

export enum BINARY_STATUS {
  YES = "بله",
  NO = "خیر"
}

export enum BANDWIDTH_TYPES {
  FIVEHUNDREDTWELVE = 512,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  EIGHT = 8,
  SIXTEEN = 16
}

export enum SATISFACTION_TYPES {
  PLEASED = "راضی",
  DISPLEASED = "ناراضی",
  UNANSWERED = "بی پاسخ",
  CANCELED = "لغو شده"
}

export enum DoneFaultTable {
  _ID = "_id",
  UUID = "شناسه خرابی",
  PHONE = "شماره",
  TELECOM = "مرکز",
  PROVINCE = "استان",
  BANDWIDTH = "پهنا",
  PORTUUID = "شناسه پورت",
  PANEL = "پنل",
  CAUSE = "علت خرابی",
  ACTION = "عملیات",
  STATUS = "وضیعت",
  USER = "ایجاد کننده",
  NAME = "نام مشترک",
  ADDRESS = "آدرس مشترک",
  PRICE = "هزینه",
  PRICELIST = "توضیحات",
  MOBILENUMBER = "موبایل",
  USERNAME = "نام کاربری",
  PASSWORD = "رمز عبور",
  DATE = "تاریخ اتمام حضوری",
  SATISFACTION = "رضایت مشترک",
  NEED_EXPERT_STATUS = "وضیعت حضوری",
  FORM_PATH = "فرم حضوری",
  ACTIONS = "عملیات",
  ROW = "ردیف"
}

export enum InTelecomFaultTable {
  _ID = "_id",
  URGENT = "فوری",
  UUID = "شناسه",
  PHONE = "شماره",
  TELECOM = "مرکز",
  BUSY = "بوخت آسیاتک",
  ROW = "ردیف",
  FLOOR = "طبقه",
  PORT = "پورت",
  BANDWIDTH = "پهنا",
  CAUSE = "علت خرابی",
  ASSIGNEDTO = "ارجاع",
  ACTION = "عملیات",
  STATUS = "وضیعت",
  ENTERANCEDATE = "تاریخ ورود",
  ACTIONS = "عملیات",
  ROW2 = " ردیف"
}

export enum NeedExpertFaultTable {
  _ID = "_id",
  URGENT = "فوری",
  UUID = "شناسه",
  PHONE = "شماره",
  TELECOM = "مرکز",
  BANDWIDTH = "پهنا",
  CAUSE = "علت خرابی",
  ASSIGNEDTO = "ارجاع",
  STATUS = "وضعیت",
  ADDRESS = "آدرس",
  NAME = "نام مشترک",
  MOBILENUMBER = "موبایل",
  USERNAME = "نام کاربری",
  PASSWORD = "رمز عبور",
  FORM_PATH = "فرم حضوری",
  PRICE = "هزینه",
  PRICELIST = "توضیحات",
  DATE = "تاریخ اتمام",
  ASSIGNEDTODATE = "زمان ارجاع حضوری ",
  ACTIONS = "عملیات",
  ROW = "ردیف"
}

export interface AssignAbleUsers {
  _id: string;
  fullName: string;
}
export interface Busy {
  row: number;
  floor: number;
  port: number;
}

export interface FaultCommentType extends MongoObject {
  desc: string;
  userId?: string;
  createdAt: Date;
  modifiedAt: Date;
}

export interface NeedExpertType {
  date?: Date | Moment;
  username: string;
  password: string;
  name: string;
  mobileNumber: string;
  address: string;
  formPath?: string;
  priceList?: string;
  price?: number;
  status?: NEED_EXPERT_STATUS_TYPES;
  satisfaction?: SATISFACTION_TYPES;
  assignedTo: string;
  comment?: string;
  doneDate?: string;
}

export interface ConcludeNeedExpertType extends MongoObject {
  priceList?: string;
  price?: number;
  comment?: string;
}

export interface FaultType {
  _id?: string;
  phone: string;
  uuid: string;
  entranceDate?: Date;
  portUuid: string;
  panel: string;
  insertType: INSERT_TYPE;
  provinceId: string;
  telecomId: string;
  busy: Busy;
  telecomBusy?: Partial<Busy>;
  urgent: boolean;
  priority: PRIORITY_TYPES;
  needExpert?: NeedExpertType;
  bandWidth: BANDWIDTH_TYPES;
  cause: CAUSE_TYPES[];
  comments?: FaultCommentType[] | string;
  status: STATUS_TYPES;
  assignedTo: string;
  albums: Array<AlbumSchema>;
  userId?: string;
  createdAt?: Date;
}

export interface FaultStaticType {
  _id?: string;
  telecom: { slug: string; _id: string };
  count: string;
}

export interface AddFaultType {
  phone: string;
  uuid: string;
  entranceDate?: Date | undefined | Moment;
  portUuid: string;
  panel: string;
  insertType: INSERT_TYPE;
  provinceId: string;
  telecomId: string;
  busyRow: string;
  busyFloor: string;
  busyPort: string;
  telecomBusyRow: string;
  telecomBusyFloor: string;
  telecomBusyPort: string;
  priority: PRIORITY_TYPES | null;
  bandWidth: BANDWIDTH_TYPES | null;
  cause: CAUSE_TYPES[] | null;
  comments: string;
  status: STATUS_TYPES | null;
  assignedTo: string;
  date?: Date | undefined | Moment;
  username?: string;
  password?: string;
  name?: string;
  mobileNumber?: string;
  address?: string;
}

export interface AddFaultSubmitType {
  phone: string;
  uuid: string;
  entranceDate?: Date | string;
  portUuid: string;
  panel: string;
  insertType: INSERT_TYPE;
  provinceId: string;
  telecomId: string;
  busy: Busy;
  telecomBusy: Busy;
  priority: PRIORITY_TYPES;
  bandWidth: BANDWIDTH_TYPES;
  cause: CAUSE_TYPES[];
  comments: GeneralCommentDocument[] | null;
  status: STATUS_TYPES;
  needExpert?: NeedExpertType | null;
  assignedTo: string;
}

export interface FaultStaticUserType {
  _id?: string;
  user: UserType;
  count: string;
  telecoms: TelecomsType[];
}
