import { useFormik } from "formik";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import { PRIORITY_TYPES_LIST } from "../../../../core/constants";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { EditTaskTypes } from "../../../../core/types";

const EditTask = () => {
  let [searchParams] = useSearchParams();

  const _id = searchParams.get("_id");

  const { editTask, fetchOneTask } = useActions();
  const { loading, item } = useTypedSelector((state) => state.task);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: item?.title ?? "",
      priority: item?.priority ?? "",
      description: item?.description ?? "",
      comments: ""
    },
    validationSchema: yup.object({
      title: yup.string().trim().required(" عنوان الزامی است"),
      priority: yup.string().trim().required("الویت الزامی است"),
      description: yup.string().trim().required("توضیحات الزامی است"),
      comments: yup.string()
    }),

    onSubmit: ({ title, priority, description, comments }: EditTaskTypes) => {
      editTask(
        {
          title,
          priority,
          description,
          comments
        },
        item?._id ?? ""
      );
    }
  });

  useEffect(() => {
    if (_id) {
      fetchOneTask(_id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const { setFieldValue } = formik;
  const { title, priority, description, comments } = formik.values;
  const {
    title: titleError,
    priority: priorityError,
    description: descriptionError
  } = formik.errors;
  const {
    title: titleTouched,
    priority: priorityTouched,
    description: descriptionTouched
  } = formik.touched;

  return (
    <>
      {item && item !== null && (
        <div className='add-item-wrapper'>
          <h5 className='title'>ویرایش تسک {item?.title}</h5>
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <Input
              name='title'
              title='عنوان'
              autoComplete='off'
              error={titleTouched && titleError ? titleError : undefined}
              value={title}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='description'
              title='توضیحات'
              autoComplete='off'
              error={
                descriptionTouched && descriptionError
                  ? descriptionError
                  : undefined
              }
              value={description}
              multiple
              onChange={formik.handleChange}
              type='text'
            />
            <div className='select-wrapper--high '>
              <label>اولویت :</label>
              <Select
                onChange={(e) => {
                  setFieldValue("priority", e?.value ?? "", true);
                }}
                value={
                  PRIORITY_TYPES_LIST?.find((priorityEl) => {
                    return priorityEl.value + "" === priority;
                  }) ?? null
                }
                isClearable
                placeholder='اولویت مربوطه را انتخاب کنید'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                options={PRIORITY_TYPES_LIST}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              {priorityTouched && priorityError && (
                <p className='input-error--select'>{priorityError}</p>
              )}
            </div>

            <Input
              name='comments'
              title='توضیحات اضافی'
              autoComplete='off'
              value={comments}
              multiline
              onChange={formik.handleChange}
              type='text'
            />
            <SubmitButton
              title='ویرایش'
              loading={loading}
              disabled={loading}
              type='submit'
            />
          </form>
        </div>
      )}
    </>
  );
};

export default EditTask;
