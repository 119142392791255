import { CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import copy from "copy-to-clipboard";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  DeleteModalContent,
  DetailModalContent,
  NoItem,
  SubmitButton
} from "../../../../../core/components";
import { USER_ACCESS } from "../../../../../core/constants";
import { defaultColumnDefinition } from "../../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../../core/hooks";
import { BASE_API, PAPS_ROOT_API } from "../../../../../core/redux";
import {
  CRUD_TYPES,
  PassiveSchema,
  PassivesTable,
  TelecomsType
} from "../../../../../core/types";
import {
  checkUserAccessByTelecomCrud,
  fileDownload
} from "../../../../../core/utility";

const PassivesList = () => {
  let [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");
  const { fetchOnePap, deletePassive } = useActions();
  const { loading, item } = useTypedSelector((state) => state.pap);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);

  const [selectedItem, setSelectedItem] = useState<
    PassiveSchema | null | undefined
  >(null);
  const { me } = useTypedSelector((state) => state.auth);
  const { token } = useTypedSelector((state) => state.auth);

  const navigator = useNavigate();

  useEffect(() => {
    if (_id) {
      fetchOnePap(_id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const onSuccessDeleteModal = useCallback(() => {
    deletePassive(selectedItem?._id as string, item?._id as string);
    setIsOpen(false);
    setSelectedItem(null);
  }, [deletePassive, selectedItem?._id, item?._id]);

  const rowData = useCallback(() => {
    const passives = item?.passives;
    if (item !== undefined) {
      return passives?.map((item) => {
        return {
          [PassivesTable._ID]: item._id,
          [PassivesTable.ROW]: item.row,
          [PassivesTable.FLOOR]: item.floor,
          [PassivesTable.PROPERTYNUMBER]: item.propertyNumber,
          [PassivesTable.TERMINALTYPE]: item.terminalType,
          [PassivesTable.CABLETYPE]: item.cableType,
          [PassivesTable.CABLESIZE]: item.cableSize,
          [PassivesTable.INSTALLERS]: item.installers,

          [PassivesTable.INSTALLDATE]: item.installDate
        };
      });
    }
    return [];
  }, [item]);

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          <span
            onClick={() => {
              setSelectedItem(cellEvent?.data);
              setIsCommentModalOpen(true);
            }}>
            <i title='توضیحات' className='fas fa-comment comment-icon' />
          </span>
          {me &&
            item &&
            checkUserAccessByTelecomCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.PAPS,
              CRUD_TYPES.EDIT,
              item.provinceId!,
              (item.telecomId as unknown as TelecomsType)?._id!
            ) && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  navigator(
                    `edit?papId=${item?._id}&passiveId=${cellEvent?.data?._id}`
                  );
                }}>
                <i title='ویرایش' className='fas fa-pen edit-icon'></i>
              </span>
            )}

          {me &&
            item &&
            checkUserAccessByTelecomCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.PAPS,
              CRUD_TYPES.DELETE,
              item.provinceId!,
              (item.telecomId as unknown as TelecomsType)?._id!
            ) && (
              <span
                onClick={() => {
                  const _passive =
                    item &&
                    item?.passives?.find((passive) => {
                      return (
                        passive._id?.toString() ===
                        cellEvent?.data[PassivesTable._ID].toString()
                      );
                    });

                  setSelectedItem(_passive);
                  setIsOpen(true);
                }}>
                <i title='حذف' className='fas fa-trash delete-icon'></i>
              </span>
            )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item, me]
  );

  return (
    <>
      <DeleteModalContent
        isOpen={isOpen}
        title={
          "حذف بوخت" +
          " " +
          selectedItem?.row +
          "طبقه" +
          " " +
          selectedItem?.floor
        }
        message='آیا از حذف بوخت مطمئن هستید؟'
        onCancel={() => {
          setIsOpen(false);
          setSelectedItem(null);
        }}
        onSuccess={onSuccessDeleteModal}
      />

      <DetailModalContent
        content={selectedItem}
        isOpen={isCommentModalOpen}
        title={"توضیحات بوخت"}
        message={
          selectedItem?.comment
            ? selectedItem?.comment
            : "توضیحی درباره این بوخت ثبت نشده است."
        }
        onClose={() => {
          setIsCommentModalOpen(false);
          setSelectedItem(null);
        }}
      />

      <div className='main-route-wrapper'>
        {" "}
        <div className='table-wrapper'>
          <div className='list-top'>
            <p>
              تعداد کل بوخت های پپ {item && item.type} مرکز{" "}
              {item && (item.telecomId as unknown as TelecomsType)?.name} :
              <span>{item && item.passives?.length} عدد</span>
              {item && (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    const url = BASE_API + PAPS_ROOT_API;
                    fileDownload(url, token ?? "", "passives.xlsx", {
                      xlsx: true,
                      _id: item._id,
                      select: "passives"
                    });
                  }}
                  className='fas fa-file-excel excel-icon'></i>
              )}
            </p>

            {me &&
              item &&
              checkUserAccessByTelecomCrud(
                me,
                me?.access ?? [],
                USER_ACCESS.PAPS,
                CRUD_TYPES.EDIT,
                item.provinceId!,
                (item.telecomId as unknown as TelecomsType)?._id!
              ) && (
                <Link to={"./add?_id=" + _id}>
                  <SubmitButton
                    loading={loading}
                    title='  اضافه کردن بوخت جدید'
                  />
                </Link>
              )}
          </div>

          {(!item || !item.passives || item.passives.length === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}

          {item && item !== null && (
            <>
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  animateRows={true}
                  onCellClicked={(e) => {
                    if (e.colDef.field !== PassivesTable.ACTIONS) copy(e.value);
                  }}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  rowSelection={"single"}
                  enableRtl={true}>
                  <AgGridColumn
                    sortable={true}
                    field={PassivesTable.ROW}
                    width={84}
                  />
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={PassivesTable.ACTIONS}
                    width={130}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PassivesTable.ROW}
                    width={80}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PassivesTable.FLOOR}
                    width={80}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PassivesTable.TERMINALTYPE}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PassivesTable.CABLETYPE}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PassivesTable.CABLESIZE}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PassivesTable.INSTALLERS}
                    minWidth={120}
                    flex={1}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PassivesTable.PROPERTYNUMBER}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PassivesTable.INSTALLDATE}
                    width={120}
                  />
                </AgGridReact>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PassivesList;
