import { CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import copy from "copy-to-clipboard";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import {
  AddCommentModalContent,
  ChangeAssignmentModalContent,
  ConcludeFault,
  DeleteModalContent,
  NoItem,
  ImageWrapper,
  Input
} from "../../../../core/components";
import {
  CAUSE_TYPES_LIST,
  STATUS_TYPES,
  USER_ACCESS,
  defaultFaultTablePageCount
} from "../../../../core/constants";
import { defaultColumnDefinition } from "../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import {
  BINARY_STATUS,
  FaultCommentType,
  FaultType,
  InTelecomFaultTable,
  TelecomsType,
  UserType,
  USER_ROLES
} from "../../../../core/types";
import { BASE_API, FAULTS_ROOT_API, FAULT_TYPES } from "../../../../core/redux";
import { useDispatch } from "react-redux";
import {
  UserAccessType,
  checkUserAccessToSection
} from "../../../../core/utility";
import useIsMobile from "src/core/hooks/useIsMobile";
import { useQueryParams } from "src/core/hooks/useQueryParams";

const TelecomChecked = () => {
  const {
    fetchFaults,
    fetchFaultsStatics,
    changeAssignment,
    addCommentFault,
    deleteFault,
    editFault,
    deleteFaultPhoto,
    uploadFaultPhoto,
    concludeAFault
  } = useActions();

  const [alterAssignment, setAlterAssignment] = useState<null | string>(null);
  const [addComment, setAddComment] = useState<null | FaultType>(null);
  const [concludeFault, setConcludeFault] = useState<null | FaultType>(null);
  const [deleteModal, setDeleteModal] = useState<null | FaultType>(null);
  const dispatch = useDispatch();
  const { me } = useTypedSelector((state) => state.auth);

  const { searchParams, setSearchParams } = useQueryParams();

  const { loading, list, imageItem, staticsList } = useTypedSelector(
    (state) => state.fault
  );
  const [currentPage, setCurrentPage] = useState<number>(
    +(searchParams("page") ?? 1) - 1
  );
  const [telecomId, setTelecomId] = useState<null | string>(
    searchParams("telecomId") || null
  );
  const [phoneSearchText, setPhoneSearchText] = useState(
    searchParams("phone[regex]") || ""
  );

  const queryParams = useMemo(() => {
    return {
      limit: defaultFaultTablePageCount,
      page: currentPage + 1,
      status: [STATUS_TYPES.CUSTOMER, STATUS_TYPES.NORMAL],
      telecomId,
      "phone[regex]": phoneSearchText
    };
  }, [currentPage, telecomId, phoneSearchText]);

  const navigator = useNavigate();
  const isMobile = useIsMobile();

  useEffect(() => {
    fetchFaultsStatics({
      status: [STATUS_TYPES.CUSTOMER, STATUS_TYPES.NORMAL]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchFaults(queryParams);
    setSearchParams(queryParams);
    dispatch({ type: FAULT_TYPES.RESET_IMAGE_ITEM });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFaultTablePageCount, currentPage, queryParams]);

  const userAccess = useMemo(() => {
    return checkUserAccessToSection(me?.access ?? []);
  }, [me]);

  const getUserAccess = useCallback(
    (section: keyof UserAccessType) => {
      return !!(me && (me.role === USER_ROLES.ADMIN || userAccess[section]));
    },
    [me, userAccess]
  );

  const hasAlbum = useCallback(
    (cellEvent: CellClickedEvent) =>
      list?.data?.find((fault) => {
        return fault._id === cellEvent?.data?._id;
      })?.albums?.length !== 0,
    [list?.data]
  );

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          {getUserAccess(USER_ACCESS.FAULTS_READ_ALL) && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                setAlterAssignment(cellEvent?.data[InTelecomFaultTable._ID]);
              }}>
              <i
                title='ارجاع به دیگری'
                className='fas fa-user-friends edit-icon'></i>
            </span>
          )}
          <span
            onClick={(e) => {
              e.stopPropagation();
              setAddComment(
                list?.data?.find((item) => {
                  return (
                    item._id?.toString() ===
                    cellEvent?.data[InTelecomFaultTable._ID]
                  );
                }) ?? null
              );
            }}>
            <i title='توضیحات' className='fas fa-comment edit-icon'></i>
          </span>

          {getUserAccess(USER_ACCESS.FAULTS_READ_ALL) && (
            <span
              onClick={() => {
                setDeleteModal(
                  list?.data?.find((item) => {
                    return (
                      item._id?.toString() ===
                      cellEvent?.data[InTelecomFaultTable._ID]
                    );
                  }) ?? null
                );
              }}>
              <i title='حذف' className='fas fa-trash delete-icon'></i>
            </span>
          )}
          <span
            onClick={(e) => {
              e.stopPropagation();
              navigator(`/faults/edit-fault?faultId=${cellEvent?.data?._id}`);
            }}>
            <i title='ویرایش' className='fas fa-pen edit-icon'></i>
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              setConcludeFault(
                list?.data?.find((item) => {
                  return (
                    item._id?.toString() ===
                    cellEvent?.data[InTelecomFaultTable._ID]
                  );
                }) ?? null
              );
            }}>
            <i title='تغییر وضعیت' className='fas fa-info-circle edit-icon'></i>
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              dispatch({
                type: FAULT_TYPES.SELECTED_FAULT_IMAGES,
                payload: list?.data?.find((fault) => {
                  return fault._id === cellEvent?.data?._id;
                })
              });
              navigator(`/faults/add-fault-photo?_id=${cellEvent?.data?._id}`);
            }}>
            <i title='اضافه کردن عکس' className='fas fa-camera add-icon'></i>
          </span>
          {hasAlbum(cellEvent) && (
            <span
              onClick={(e) => {
                e.stopPropagation();

                dispatch({
                  type: FAULT_TYPES.SELECTED_FAULT_IMAGES,
                  payload: list?.data?.find((fault) => {
                    return fault._id === cellEvent?.data?._id;
                  })
                });
              }}>
              <i title='گالری' className='fas fa-images edit-icon'></i>
            </span>
          )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list?.data]
  );

  const rowData = useCallback(() => {
    return list?.data
      .map((item, index) => {
        return {
          [InTelecomFaultTable.ROW]: `${index + 1 + currentPage * 10}`,
          [InTelecomFaultTable._ID]: item._id,
          [InTelecomFaultTable.URGENT]: Boolean(item.urgent)
            ? BINARY_STATUS.YES
            : BINARY_STATUS.NO,
          [InTelecomFaultTable.UUID]: item.uuid,
          [InTelecomFaultTable.PHONE]: item.phone,
          [InTelecomFaultTable.TELECOM]: (
            item.telecomId as unknown as TelecomsType
          )?.name,
          [InTelecomFaultTable.STATUS]: item.status,
          [InTelecomFaultTable.BANDWIDTH]: item.bandWidth,
          [InTelecomFaultTable.CAUSE]: item.cause.reduce((total, item) => {
            return (total =
              total +
                `${total ? " - " : ""}` +
                CAUSE_TYPES_LIST.find((causeItem) => {
                  return causeItem.value === item;
                })?.label ?? "");
          }, "" as string),
          [InTelecomFaultTable.ASSIGNEDTO]: (
            item.assignedTo as unknown as UserType
          ).fullName
        };
      })
      .sort((a, b) => {
        if (a[InTelecomFaultTable.URGENT] === BINARY_STATUS.YES) {
          return -1;
        }
        if (b[InTelecomFaultTable.URGENT] === BINARY_STATUS.YES) {
          return 1;
        }
        return 0;
      });
  }, [list?.data, currentPage]);

  return (
    <>
      {!!deleteModal && (
        <DeleteModalContent
          isOpen={!!deleteModal}
          onSuccess={() => {
            (
              deleteFault(deleteModal._id ?? "") as unknown as Promise<void>
            ).then(() => {
              fetchFaults(queryParams);
              setDeleteModal(null);
            });
          }}
          onCancel={() => {
            setDeleteModal(null);
          }}
          message={`آیا از حذف خرابی به شماره  ${deleteModal.phone} مطمئن هستید؟`}
          title={`حذف خرابی به شناسه ${deleteModal.uuid}`}
        />
      )}
      <ChangeAssignmentModalContent
        isOpen={!!alterAssignment}
        title='تغییر ارجاع خرابی'
        onSuccess={(assignedTo) => {
          (
            changeAssignment({
              faultId: alterAssignment!,
              assignedTo
            }) as unknown as Promise<void>
          ).then(() => {
            fetchFaults(queryParams);
            setAlterAssignment(null);
          });
        }}
        onCancel={() => {
          setAlterAssignment(null);
        }}
      />
      {!!addComment && (
        <AddCommentModalContent
          faultPhone={addComment.phone}
          isOpen={!!addComment}
          comments={
            addComment ? (addComment.comments as Array<FaultCommentType>) : []
          }
          onSuccess={(comment) => {
            (
              addCommentFault({
                faultId: addComment?._id ?? "",
                comment: { desc: comment }
              }) as unknown as Promise<void>
            ).then(() => {
              fetchFaults(queryParams);
              setAddComment(null);
            });
          }}
          onClose={() => {
            setAddComment(null);
          }}
        />
      )}

      {!!concludeFault && (
        <ConcludeFault
          isOpen={!!concludeFault}
          comments={
            concludeFault && Array.isArray(concludeFault.comments)
              ? concludeFault.comments
              : []
          }
          faultPhone={concludeFault.phone}
          faultCurrentStatus={concludeFault.status}
          onSuccess={(body: Partial<FaultType>) => {
            const callApi =
              body.status === STATUS_TYPES.DONE
                ? concludeAFault(concludeFault?._id ?? "", false)
                : editFault(body, concludeFault?._id ?? "", false);

            (callApi as unknown as Promise<void>).then(() => {
              fetchFaults(queryParams);
              setConcludeFault(null);
            });
          }}
          onClose={() => {
            setConcludeFault(null);
          }}
        />
      )}
      <section className='clients-search-wrapper'>
        <div className='list-input-wrapper'>
          <Input
            name='phoneSearchText'
            placeholder='جستجو'
            autoComplete='off'
            value={phoneSearchText}
            onChange={(e) => {
              setPhoneSearchText(e.target.value);
              if (currentPage !== 0) setCurrentPage(0);
            }}
          />
        </div>
      </section>
      <div className='main-route-wrapper'>
        {" "}
        <div className='table-wrapper'>
          <div className='list-top'>
            <p>
              لیست خرابی های بررسی شده از مرکز :{" "}
              <span>{list && list.total} عدد</span>
              <i
                onClick={() => {
                  fetchFaults(queryParams);
                }}
                className={`fas fa-sync-alt ${loading ? "rotate" : ""}`}></i>
            </p>
            <div className='statics-wrapper'>
              {staticsList?.data.map((el, i) => {
                return (
                  <div
                    className={`${
                      telecomId === el.telecom._id && "static-item-active"
                    } static-item`}
                    key={i}
                    onClick={() =>
                      setTelecomId((pre) =>
                        pre === el.telecom._id ? null : el.telecom._id
                      )
                    }>
                    {el?.telecom.slug}
                    <span>{el.count}</span>
                  </div>
                );
              })}
              <div
                className={`${
                  telecomId === null && "static-item-active"
                } static-item`}
                key={"0"}
                onClick={() => setTelecomId(null)}>
                همه
              </div>
            </div>
          </div>

          {(!list || list.count === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}

          {list && list.count !== 0 && (
            <>
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  animateRows={true}
                  onCellClicked={(e) => {
                    if (e.colDef.field !== InTelecomFaultTable.ACTIONS)
                      copy(e.value);
                  }}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  rowSelection={"single"}
                  enableRtl={true}>
                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.ROW}
                    width={84}
                  />
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={InTelecomFaultTable.ACTIONS}
                    minWidth={300}
                  />

                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.PHONE}
                    minWidth={150}
                    maxWidth={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.TELECOM}
                    minWidth={180}
                    maxWidth={180}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.STATUS}
                    minWidth={180}
                    maxWidth={180}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.URGENT}
                    minWidth={100}
                    maxWidth={100}
                    cellStyle={({ value }) => {
                      return value === BINARY_STATUS.YES
                        ? { color: "red" }
                        : { color: "green" };
                    }}
                  />

                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.BANDWIDTH}
                    minWidth={80}
                    maxWidth={80}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.CAUSE}
                    minWidth={400}
                    maxWidth={400}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.ASSIGNEDTO}
                    minWidth={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.UUID}
                    minWidth={100}
                    flex={1}
                  />
                </AgGridReact>
              </div>
              <ReactPaginate
                marginPagesDisplayed={isMobile ? 1 : 3}
                forcePage={currentPage}
                breakLabel='...'
                nextLabel='بعدی'
                onPageChange={(e) => {
                  setCurrentPage(e.selected);
                }}
                pageRangeDisplayed={5}
                pageCount={Math.ceil(list.total / defaultFaultTablePageCount)}
                previousLabel='قبلی'
                containerClassName='pagination-container'
              />
            </>
          )}
        </div>
      </div>
      {imageItem && (
        <ImageWrapper
          albums={imageItem?.albums?.map((album) => {
            return {
              ...album,
              path:
                BASE_API +
                FAULTS_ROOT_API +
                "/photo/" +
                imageItem._id +
                "?photoId=" +
                album.path
            };
          })}
          onDelete={(albumItemId) => {
            deleteFaultPhoto(albumItemId, imageItem?._id ?? "");
          }}
          onSuccess={(file) => {
            uploadFaultPhoto(file, {});
          }}
        />
      )}
    </>
  );
};

export default TelecomChecked;
