import { MongoObject } from "..";
import {
  ShelfSchema,
  RackSchema,
  PassiveSchema,
  MiscellaneousSchema,
  AlbumSchema
} from ".";

export enum PapsTable {
  _ID = "_id",
  PROVINCEID = "provinceId",
  TELECOMID = "telecomId",
  PAP = "نوع پروژه",
  TELECOM = "مرکز",
  CITY = "شهر",
  PROVINCE = "استان",
  CREATEDAT = "تاریخ ایجاد",
  UPDATEDAT = "تاریخ اخرین تغییر",
  ROUTER = "روتر",
  ROUTER_PROPERTY = "اموال روتر",
  ELECTRICITY_TYPE = "نوع برق",
  FILE_NUMBER = "شماره پرونده",
  RACK = "رک",
  SHELF = "شلف",
  PASSIVE = "بوخت",
  MISCELLANEOUS = "متفرقه",
  ACTIONS = "عملیات",
  ROW = "ردیف"
}

export enum FUSE_MODEL {
  CARD = "کارتی",
  MINIATURE = "مینیاتوری"
}

export enum PAP_TYPE {
  AT = "AT",
  BOT = "BOT"
}

export interface RouterSchema {
  _id?: string;
  brandNameModel?: string;
  electricityType?: string;
  propertyNumber?: string;
  serialNumber?: string;
}

export interface PapsType extends MongoObject {
  shelves?: Array<ShelfSchema>;
  type: PAP_TYPE;
  provinceId?: string | undefined;
  cityId?: string | undefined;
  city?: string;
  province?: string;
  telecomId?: string | undefined;
  telecom?: string;
  router_property?: number;
  electricity_type?: string;
  file_number?: number;
  fileNumber?: string;
  bandWidth?: number | string;
  networkCableSize?: number | string;
  portNumber?: number | string;
  electricityCableSize?: string | number;
  ladderSize?: number | string;
  legsNumber?: number | string;
  LTypeItemNumber?: number | string;
  fuse?: string;
  fuseAmpere?: number;
  router?: RouterSchema;
  racks?: Array<RackSchema>;
  passives?: Array<PassiveSchema>;
  miscellaneous?: Array<MiscellaneousSchema>;
  albums?: Array<AlbumSchema>;
  createdAt?: Date;
  updatedAt?: Date;
  name: string;
}

export interface PapTelecomsType extends MongoObject {
  name: string;
  _id?: string;
  provinceId?: string;
  cityId?: string;
}
export interface PapProvincesType extends MongoObject {
  _id?: string;
  provinceId?: string;
  name: string;
}

export interface AddPap {
  type: string;
  provinceId: string | undefined;
  telecomId?: string | undefined;
}
