import { URLSearchParamsInit, useSearchParams } from "react-router-dom";
import _ from "lodash";

type GenericQueryParams<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K];
};

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  function convertObjectValuesToString<T extends Record<string, unknown>>(
    obj: GenericQueryParams<T>
  ) {
    const refinedObjects = _.pickBy(obj, _.identity);

    const newObj: URLSearchParamsInit = {};
    for (const key in refinedObjects) {
      newObj[key] = String(obj[key]);
    }

    return newObj;
  }

  const setSearchParamsWithConvert = <T extends Record<string, unknown>>(
    queryParams: GenericQueryParams<T>
  ) => setSearchParams(convertObjectValuesToString(queryParams));

  function getSearchParamValueBasedOnType(name: string) {
    return searchParams.get(name);
  }

  return {
    searchParams: getSearchParamValueBasedOnType,
    setSearchParams: setSearchParamsWithConvert
  };
};

export { useQueryParams };
