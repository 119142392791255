import { CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import copy from "copy-to-clipboard";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useIsMobile from "src/core/hooks/useIsMobile";
import { useQueryParams } from "src/core/hooks/useQueryParams";
import {
  StatisticsTelecomList,
  StatisticsUserList
} from "src/views/dashboard/main/faults/components";
import {
  AddCommentModalContent,
  ChangeAssignmentModalContent,
  DeleteModalContent,
  ImageWrapper,
  Input,
  NoItem
} from "../../../../core/components";
import { ConcludeFaultInTelecom } from "../../../../core/components/ConcludeFaultInTelecom";
import {
  CAUSE_TYPES_LIST,
  STATUS_TYPES,
  USER_ACCESS,
  defaultFaultTablePageCount
} from "../../../../core/constants";
import { defaultColumnDefinition } from "../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { BASE_API, FAULTS_ROOT_API, FAULT_TYPES } from "../../../../core/redux";
import {
  BINARY_STATUS,
  FaultCommentType,
  FaultType,
  InTelecomFaultTable,
  TelecomsType,
  USER_ROLES,
  UserType
} from "../../../../core/types";
import {
  UserAccessType,
  checkUserAccessToSection
} from "../../../../core/utility";

const InTelecomFaultsList = () => {
  const {
    fetchFaults,
    editFault,
    changeAssignment,
    addCommentFault,
    deleteFault,
    deleteFaultPhoto,
    uploadFaultPhoto,
    fetchFaultsStatics,
    fetchFaultsUserStatics,
    changeMultipleAssignment
  } = useActions();

  const [alterAssignment, setAlterAssignment] = useState<
    null | string | string[]
  >(null);
  const [addComment, setAddComment] = useState<null | FaultType>(null);
  const [concludeFault, setConcludeFault] = useState<null | FaultType>(null);
  const [deleteModal, setDeleteModal] = useState<null | FaultType>(null);
  const [showStaticsList, setShowStaticsList] = useState(false);
  const { me } = useTypedSelector((state) => state.auth);
  const { loading, list, imageItem, staticsList, staticsUsersList } =
    useTypedSelector((state) => state.fault);

  const { searchParams, setSearchParams } = useQueryParams();

  const [telecomId, setTelecomId] = useState<null | string>(
    searchParams("telecomId") || null
  );
  const [currentPage, setCurrentPage] = useState<number>(
    +(searchParams("page") ?? 1) - 1
  );
  const [phoneSearchText, setPhoneSearchText] = useState(
    searchParams("phone[regex]") || ""
  );

  const queryParams = useMemo(() => {
    return {
      limit: defaultFaultTablePageCount,
      page: currentPage + 1,
      telecomId: telecomId,
      status: STATUS_TYPES.IN_TELECOM,
      "phone[regex]": phoneSearchText
    };
  }, [currentPage, telecomId, phoneSearchText]);

  const navigator = useNavigate();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const userAccess = useMemo(() => {
    return checkUserAccessToSection(me?.access ?? []);
  }, [me]);

  useEffect(() => {
    fetchFaultsStatics({ status: STATUS_TYPES.IN_TELECOM });
    fetchFaultsUserStatics({ status: STATUS_TYPES.IN_TELECOM });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    callApi();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, queryParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callApi = useCallback(
    () => {
      fetchFaults(queryParams);
      setSearchParams(queryParams);
      dispatch({ type: FAULT_TYPES.RESET_IMAGE_ITEM });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryParams]
  );

  const getUserAccess = useCallback(
    (section: keyof UserAccessType) => {
      return !!(me && (me.role === USER_ROLES.ADMIN || userAccess[section]));
    },
    [me, userAccess]
  );

  const hasAlbum = useCallback(
    (cellEvent: CellClickedEvent) =>
      list?.data?.find((fault) => {
        return fault._id === cellEvent?.data?._id;
      })?.albums?.length !== 0,
    [list?.data]
  );

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          {getUserAccess(USER_ACCESS.FAULTS_READ_ALL) && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                setAlterAssignment(cellEvent?.data[InTelecomFaultTable._ID]);
              }}>
              <i
                title='ارجاع به دیگری'
                className='fas fa-user-friends edit-icon'></i>
            </span>
          )}
          <span
            onClick={(e) => {
              e.stopPropagation();
              setAddComment(
                list?.data?.find((item) => {
                  return (
                    item._id?.toString() ===
                    cellEvent?.data[InTelecomFaultTable._ID]
                  );
                }) ?? null
              );
            }}>
            <i title='توضیحات' className='fas fa-comment edit-icon'></i>
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              navigator(`/faults/edit-fault?faultId=${cellEvent?.data?._id}`);
            }}>
            <i title='ویرایش' className='fas fa-pen edit-icon'></i>
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              setConcludeFault(
                list?.data?.find((item) => {
                  return (
                    item._id?.toString() ===
                    cellEvent?.data[InTelecomFaultTable._ID]
                  );
                }) ?? null
              );
            }}>
            <i
              title='اتمام بررسی از مرکز'
              className='fas fa-info-circle edit-icon'></i>
          </span>
          {getUserAccess(USER_ACCESS.FAULTS_READ_ALL) && (
            <span
              onClick={() => {
                setDeleteModal(
                  list?.data?.find((item) => {
                    return (
                      item._id?.toString() ===
                      cellEvent?.data[InTelecomFaultTable._ID]
                    );
                  }) ?? null
                );
              }}>
              <i title='حذف' className='fas fa-trash delete-icon'></i>
            </span>
          )}
          <span
            onClick={(e) => {
              e.stopPropagation();
              dispatch({
                type: FAULT_TYPES.SELECTED_FAULT_IMAGES,
                payload: list?.data?.find((fault) => {
                  return fault._id === cellEvent?.data?._id;
                })
              });
              navigator(`/faults/add-fault-photo?_id=${cellEvent?.data?._id}`);
            }}>
            <i title='اضافه کردن عکس' className='fas fa-camera add-icon'></i>
          </span>
          {hasAlbum(cellEvent) && (
            <span
              onClick={(e) => {
                e.stopPropagation();

                dispatch({
                  type: FAULT_TYPES.SELECTED_FAULT_IMAGES,
                  payload: list?.data?.find((fault) => {
                    return fault._id === cellEvent?.data?._id;
                  })
                });
              }}>
              <i title='گالری' className='fas fa-images edit-icon'></i>
            </span>
          )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list?.data]
  );

  const rowData = useCallback(() => {
    return list?.data
      .map((item, index) => {
        return {
          [InTelecomFaultTable.ROW2]: `${index + 1 + currentPage * 10}`,
          [InTelecomFaultTable._ID]: item._id,
          [InTelecomFaultTable.URGENT]: Boolean(item.urgent)
            ? BINARY_STATUS.YES
            : BINARY_STATUS.NO,
          [InTelecomFaultTable.UUID]: item.uuid,
          [InTelecomFaultTable.PHONE]: item.phone,
          [InTelecomFaultTable.TELECOM]: (
            item.telecomId as unknown as TelecomsType
          )?.name,
          [InTelecomFaultTable.BUSY]:
            item.busy.row + "-" + item.busy.floor + "-" + item.busy.port,

          [InTelecomFaultTable.ROW]: item.telecomBusy?.row ?? 0,
          [InTelecomFaultTable.FLOOR]: item.telecomBusy?.floor ?? 0,
          [InTelecomFaultTable.PORT]: item.telecomBusy?.port ?? 0,
          [InTelecomFaultTable.BANDWIDTH]: item.bandWidth,
          [InTelecomFaultTable.CAUSE]: item.cause.reduce((total, item) => {
            return (total =
              total +
                `${total ? " - " : ""}` +
                CAUSE_TYPES_LIST.find((causeItem) => {
                  return causeItem.value === item;
                })?.label ?? "");
          }, "" as string),
          [InTelecomFaultTable.ASSIGNEDTO]: (
            item.assignedTo as unknown as UserType
          ).fullName
        };
      })
      .sort((a, b) => {
        if (a[InTelecomFaultTable.URGENT] === BINARY_STATUS.YES) {
          return -1;
        }
        if (b[InTelecomFaultTable.URGENT] === BINARY_STATUS.YES) {
          return 1;
        }
        return 0;
      });
  }, [list?.data, currentPage]);

  return (
    <>
      {!!deleteModal && (
        <DeleteModalContent
          isOpen={!!deleteModal}
          onSuccess={() => {
            (
              deleteFault(deleteModal._id ?? "") as unknown as Promise<void>
            ).then(() => {
              callApi();
              setDeleteModal(null);
            });
          }}
          onCancel={() => {
            setDeleteModal(null);
          }}
          message={`آیا از حذف خرابی به شماره  ${deleteModal.phone} مطمئن هستید؟`}
          title={`حذف خرابی به شناسه ${deleteModal.uuid}`}
        />
      )}
      <ChangeAssignmentModalContent
        isOpen={!!alterAssignment}
        title='تغییر ارجاع خرابی'
        onSuccess={(assignedTo) => {
          (
            (alterAssignment && Array.isArray(alterAssignment)
              ? changeMultipleAssignment({
                  faultId: alterAssignment!,
                  assignedTo
                })
              : changeAssignment({
                  faultId: alterAssignment!,
                  assignedTo
                })) as unknown as Promise<void>
          ).then(() => {
            callApi();
            setAlterAssignment(null);
          });
        }}
        onCancel={() => {
          setAlterAssignment(null);
        }}
      />
      {!!addComment && (
        <AddCommentModalContent
          faultPhone={addComment.phone}
          isOpen={!!addComment}
          comments={
            addComment ? (addComment.comments as Array<FaultCommentType>) : []
          }
          onSuccess={(comment) => {
            (
              addCommentFault({
                faultId: addComment?._id ?? "",
                comment: { desc: comment }
              }) as unknown as Promise<void>
            ).then(() => {
              callApi();
              setAddComment(null);
            });
          }}
          onClose={() => {
            setAddComment(null);
          }}
        />
      )}
      {!!concludeFault && (
        <ConcludeFaultInTelecom
          faultPhone={concludeFault.phone}
          isOpen={!!concludeFault}
          comments={
            concludeFault && Array.isArray(concludeFault.comments)
              ? concludeFault.comments
              : []
          }
          urgent={concludeFault?.urgent}
          onSuccess={(comment, urgent) => {
            (
              editFault(
                {
                  comments: comment,
                  urgent,
                  status: STATUS_TYPES.NORMAL
                },
                concludeFault?._id ?? "",
                false
              ) as unknown as Promise<void>
            ).then(() => {
              callApi();
              setConcludeFault(null);
            });
          }}
          onClose={() => {
            setConcludeFault(null);
          }}
        />
      )}
      <section className='clients-search-wrapper'>
        <div className='list-input-wrapper'>
          <Input
            name='phoneSearchText'
            placeholder='جستجو'
            autoComplete='off'
            value={phoneSearchText}
            onChange={(e) => {
              setPhoneSearchText(e.target.value);
              if (currentPage !== 0) setCurrentPage(0);
            }}
          />
          <button
            className='change-assignment-button'
            onClick={() => {
              const faultIds = list?.data.map((el) => el._id ?? "") ?? null;
              setAlterAssignment(faultIds);
            }}>
            تغییر ارجاع خرابی ها
          </button>
        </div>
      </section>{" "}
      <div className='main-route-wrapper fault-in-telecom'>
        <div className='table-wrapper'>
          <div className='list-top no-flex-end'>
            <p>
              لیست خرابی های در دست بررسی از مرکز :{" "}
              <span>{list && list.total} عدد</span>
              <i
                onClick={() => {
                  if (currentPage !== 0) setCurrentPage(0);
                  callApi();
                }}
                className={`fas fa-sync-alt ${loading ? "rotate" : ""}`}></i>
            </p>
            <button
              className='show-users-list-button'
              onClick={() => setShowStaticsList((pre) => !pre)}>
              {showStaticsList
                ? "مشاهده لیست تخصیص بر اساس مرکز"
                : "مشاهده لیست تخصیص بر اساس کاربر"}
            </button>
          </div>

          <div className='statics-wrapper'>
            {showStaticsList ? (
              <StatisticsUserList list={staticsUsersList} />
            ) : (
              <StatisticsTelecomList
                list={staticsList}
                telecomId={telecomId}
                setTelecomId={setTelecomId}
              />
            )}
          </div>

          {(!list || list.count === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}

          {list && list.count !== 0 && (
            <>
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  animateRows={true}
                  onCellClicked={(e) => {
                    if (e.colDef.field !== InTelecomFaultTable.ACTIONS)
                      copy(e.value);
                  }}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  rowSelection={"single"}
                  enableRtl={true}>
                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.ROW2}
                    width={84}
                  />
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={InTelecomFaultTable.ACTIONS}
                    minWidth={300}
                    maxWidth={300}
                  />

                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.TELECOM}
                    minWidth={180}
                    maxWidth={180}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.CAUSE}
                    maxWidth={200}
                    minWidth={200}
                  />
                  <AgGridColumn
                    field={InTelecomFaultTable.BUSY}
                    minWidth={130}
                    maxWidth={130}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.PHONE}
                    minWidth={150}
                    maxWidth={150}
                  />

                  <AgGridColumn
                    field={InTelecomFaultTable.ROW}
                    type='number'
                    maxWidth={80}
                    minWidth={80}
                    onCellValueChanged={({ newValue, data }) => {
                      editFault(
                        {
                          telecomBusy: {
                            row: +newValue,
                            floor: data[InTelecomFaultTable.FLOOR],
                            port: data[InTelecomFaultTable.PORT]
                          }
                        },
                        data[InTelecomFaultTable._ID],
                        false
                      );
                    }}
                    editable
                  />
                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.FLOOR}
                    maxWidth={80}
                    minWidth={80}
                    onCellValueChanged={({ newValue, data }) => {
                      editFault(
                        {
                          telecomBusy: {
                            row: data[InTelecomFaultTable.ROW],
                            floor: +newValue,
                            port: data[InTelecomFaultTable.PORT]
                          }
                        },
                        data[InTelecomFaultTable._ID],
                        false
                      );
                    }}
                    editable
                  />
                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.PORT}
                    maxWidth={80}
                    minWidth={80}
                    onCellValueChanged={({ newValue, data }) => {
                      editFault(
                        {
                          telecomBusy: {
                            row: data[InTelecomFaultTable.ROW],
                            floor: data[InTelecomFaultTable.FLOOR],
                            port: +newValue
                          }
                        },
                        data[InTelecomFaultTable._ID],
                        false
                      );
                    }}
                    editable
                  />
                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.BANDWIDTH}
                    maxWidth={80}
                    minWidth={80}
                  />

                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.ASSIGNEDTO}
                    minWidth={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={InTelecomFaultTable.UUID}
                    minWidth={100}
                    flex={1}
                  />
                </AgGridReact>
              </div>
            </>
          )}
        </div>
        <ReactPaginate
          marginPagesDisplayed={isMobile ? 1 : 3}
          forcePage={currentPage}
          breakLabel='...'
          nextLabel='بعدی'
          onPageChange={(e) => {
            setCurrentPage(e.selected + 1);
          }}
          pageRangeDisplayed={5}
          pageCount={
            (list && Math.ceil(list.total / defaultFaultTablePageCount)) ?? 1
          }
          previousLabel='قبلی'
          containerClassName='pagination-container'
        />
      </div>
      {imageItem && (
        <ImageWrapper
          albums={imageItem?.albums?.map((album) => {
            return {
              ...album,
              path:
                BASE_API +
                FAULTS_ROOT_API +
                "/photo/" +
                imageItem._id +
                "?photoId=" +
                album.path
            };
          })}
          onDelete={(albumItemId) => {
            deleteFaultPhoto(albumItemId, imageItem?._id ?? "");
          }}
          onSuccess={(file) => {
            uploadFaultPhoto(file, {});
          }}
        />
      )}
    </>
  );
};

export default InTelecomFaultsList;
