import { useEffect, useCallback, useMemo } from "react";
import { defaultTablePageCount } from "../../../../core/constants";
import { Column } from "react-table";
import { Table } from "../../../../core/components";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { LogsTable } from "../../../../core/types";

const SmsLogsList = () => {
  const { fetchSmsLogs } = useActions();
  const { smsList } = useTypedSelector((state) => state.log);

  const queryParams = useMemo(() => {
    return {
      limit: defaultTablePageCount,
      sort: "-createdAt"
    };
  }, []);

  const fetchFilteredLogs = useCallback(
    () => {
      fetchSmsLogs({
        ...queryParams
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryParams]
  );

  useEffect(() => {
    fetchFilteredLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const COLUMNS: Column[] = useMemo(
    () => [
      {
        Header: LogsTable.ROW,
        Cell: (val) => {
          return <>{val.row.index + 1}</>;
        }
      },
      {
        Header: LogsTable.USERNAME,
        accessor: "user.fullName"
      },
      {
        Header: LogsTable.EVENTTYPE,
        accessor: "eventType"
      },
      {
        Header: LogsTable.EVENT,
        accessor: "event"
      },
      {
        Header: LogsTable.DESCRIPTION,
        accessor: "comment"
      },
      {
        Header: LogsTable.DATE,
        accessor: "createdAt",

        Cell: ({ value }) => {
          return <>{new Date(value).toLocaleString("fa-IR")}</>;
        }
      }
    ],
    []
  );

  return (
    <>
      <div className='main-route-wrapper'>
        <div className='list-top'>
          <p>لاگ آخرین اس ام اس ها</p>
        </div>
        <div className='react-table-container'>
          <Table columns={COLUMNS as any} data={smsList?.data ?? []} />
        </div>
      </div>
    </>
  );
};
export default SmsLogsList;
