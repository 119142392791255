import { CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import copy from "copy-to-clipboard";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  DeleteModalContent,
  DetailModalContent,
  NoItem,
  SubmitButton
} from "../../../../../core/components";
import { USER_ACCESS } from "../../../../../core/constants";
import { defaultColumnDefinition } from "../../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../../core/hooks";
import { BASE_API, OWA_ROOT_API } from "../../../../../core/redux";
import { CRUD_TYPES, ItemSchema, ItemTable } from "../../../../../core/types";
import {
  checkUserAccessByProvinceCrud,
  fileDownload
} from "../../../../../core/utility";

const ItemsList = () => {
  let [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");
  const { fetchOneOwa, deleteItem } = useActions();
  const { loading, item } = useTypedSelector((state) => state.owa);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ItemSchema | null>(null);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);
  const { me } = useTypedSelector((state) => state.auth);
  const { token } = useTypedSelector((state) => state.auth);

  const navigator = useNavigate();

  useEffect(() => {
    if (_id) {
      fetchOneOwa(_id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const onSuccessDeleteModal = useCallback(() => {
    deleteItem(selectedItem?._id as string, item?._id as string);
    setIsOpen(false);
    setSelectedItem(null);
  }, [deleteItem, selectedItem?._id, item?._id]);

  const rowData = useCallback(() => {
    const items = item?.items;
    if (item !== undefined) {
      return items?.map((item, index) => {
        return {
          [ItemTable.ROW]: `${index + 1}`,
          [ItemTable._ID]: item._id,
          [ItemTable.NAME]: item.name,
          [ItemTable.TYPE]: item.type,
          [ItemTable.PROPERTYNUMBER]: item.propertyNumber,
          [ItemTable.SERIALNUMBER]: item.serialNumber,
          [ItemTable.DIMENSION]: item.dimension,
          [ItemTable.DESCRIPTION]: item.description,
          [ItemTable.CREATEDAT]: new Date(
            item.createdAt ?? ""
          ).toLocaleDateString("fa-IR"),
          [ItemTable.UPDATEDAT]: new Date(
            item.updatedAt ?? ""
          ).toLocaleDateString("fa-IR")
        };
      });
    }
    return [];
  }, [item]);

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          <span
            onClick={() => {
              setSelectedItem(cellEvent?.data);
              setIsCommentModalOpen(true);
            }}>
            <i title='توضیحات' className='fas fa-comment comment-icon'></i>
          </span>
          {me &&
            checkUserAccessByProvinceCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.OWA,
              CRUD_TYPES.EDIT,
              item?.provinceId ?? ""
            ) && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  navigator(
                    `edit?owaId=${item?._id}&itemId=${cellEvent?.data?._id}`
                  );
                }}>
                <i title='ویرایش' className='fas fa-pen edit-icon'></i>
              </span>
            )}
          {me &&
            checkUserAccessByProvinceCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.OWA,
              CRUD_TYPES.DELETE,
              item?.provinceId ?? ""
            ) && (
              <span
                onClick={() => {
                  const _item = item?.items?.find((item) => {
                    return (
                      item._id?.toString() ===
                      cellEvent?.data[ItemTable._ID].toString()
                    );
                  });

                  _item && setSelectedItem(_item);
                  setIsOpen(true);
                }}>
                <i title='حذف' className='fas fa-trash delete-icon'></i>
              </span>
            )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item]
  );
  return (
    <>
      <DeleteModalContent
        isOpen={isOpen}
        title={"حذف آیتم " + (selectedItem && selectedItem.name)}
        message='آیا از حذف آیتم مطمئن هستید؟'
        onCancel={() => {
          setIsOpen(false);
          setSelectedItem(null);
        }}
        onSuccess={onSuccessDeleteModal}
      />
      <DetailModalContent
        isOpen={isCommentModalOpen}
        content={selectedItem}
        title={" توضیحات آیتم " + (selectedItem && selectedItem.name)}
        message={
          selectedItem
            ? selectedItem.description
            : "توضیحی درباره این آیتم ثبت نشده است."
        }
        onClose={() => {
          setIsCommentModalOpen(false);
          setSelectedItem(null);
        }}
      />

      <div className='main-route-wrapper'>
        {" "}
        <div className='table-wrapper'>
          <div className='list-top'>
            <p>
              تعداد کل آیتم های پاپ سایت {item?.name} :{" "}
              <span>{item && item?.items?.length} عدد</span>
              {item && (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    const url = BASE_API + OWA_ROOT_API;
                    fileDownload(url, token ?? "", "items.xlsx", {
                      xlsx: true,
                      _id: item._id,
                      select: "items"
                    });
                  }}
                  className='fas fa-file-excel excel-icon'></i>
              )}
            </p>
            {me &&
              item &&
              checkUserAccessByProvinceCrud(
                me,
                me?.access ?? [],
                USER_ACCESS.OWA,
                CRUD_TYPES.EDIT,
                item?.provinceId ?? ""
              ) && (
                <Link to={"./add?_id=" + _id}>
                  <SubmitButton
                    loading={loading}
                    title='اضافه کردن آیتم جدید'
                  />
                </Link>
              )}
          </div>

          {(!item || !item.items || item.items.length === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}

          {item && item !== null && (
            <>
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  animateRows={true}
                  onCellClicked={(e) => {
                    if (e.colDef.field !== ItemTable.ACTIONS) copy(e.value);
                  }}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  rowSelection={"single"}
                  enableRtl={true}>
                  <AgGridColumn
                    sortable={true}
                    field={ItemTable.ROW}
                    width={84}
                  />
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={ItemTable.ACTIONS}
                    width={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={ItemTable.NAME}
                    minWidth={150}
                    flex={1}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={ItemTable.TYPE}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={ItemTable.PROPERTYNUMBER}
                    width={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={ItemTable.SERIALNUMBER}
                    width={200}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={ItemTable.DIMENSION}
                    width={120}
                  />
                </AgGridReact>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ItemsList;
