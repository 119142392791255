import React, { ButtonHTMLAttributes, memo } from "react";
import Loading from "./Loading";

interface InputProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  loading?: boolean;
  classNames?: string;
}

const _UploadButton: React.FC<InputProps> = ({
  loading,
  title,
  classNames,
  ...rest
}) => {
  return (
    <>
      <div className='upload-button'>
        <button
          disabled={loading ? true : false}
          className={`upload-button__button ${classNames ? classNames : ""}`}
          {...rest}>
          {loading ? (
            <Loading loading={loading} size={14} />
          ) : (
            <span>
              {title} <i className='fas fa-upload upload-button' />
            </span>
          )}
        </button>
      </div>
    </>
  );
};

const UploadButton = memo(_UploadButton);

export { UploadButton };
