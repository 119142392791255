export enum PAP_TYPES {
  PAP_LISTS = "PAP_LISTS",
  PAP = "PAP",
  PAP_DELETE = "PAP_DELETE",
  PAP_LOADING = "PAP_LOADING",
  PAP_UPLOAD = "PAP_UPLOAD",
  SELECTED_PAP_IMAGES = "SELECTED_PAP_IMAGES",
  DISABLE_LOADING = "DISABLE_LOADING",
  PAP_TELECOMS_LOADING = "PAP_TELECOMS_LOADING",
  PAP_TELECOMS = "PAP_TELECOMS",
  DISABLE_PAP_TELECOMS_LOADING = "DISABLE_PAP_TELECOMS_LOADING",
  PAP_PROVINCES_LOADING = "PAP_PROVINCES_LOADING",
  PAP_PROVINCES = "PAP_PROVINCES",
  DISABLE_PAP_PROVINCES_LOADING = "DISABLE_PAP_PROVINCES_LOADING",
  DELETE_SHELF = "DELETE_SHELF",
  RESET_IMAGE_ITEM = "RESET_IMAGE_ITEM"
}
