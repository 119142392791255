import { AxiosRequestConfig } from "axios";
import { Dispatch } from "redux";
import { store } from "..";
import { getCatch } from "../../catch";
import { customizedAxios } from "../../config";
import { InputObject, QueryParams, UserType } from "../../types";
import {
  AuthActionTypes,
  AuthLoadingActionTypes,
  UserActionTypes
} from "../actionTypes";
import {
  AUTH_CHANGE_PASSWORD_API,
  AUTH_FORGET_PASSWORD_API,
  AUTH_LOGIN_API,
  AUTH_ME_API,
  AUTH_RESET_PASSWORD_API
} from "../api";
import { AUTH_LOADING_TYPES, AUTH_TYPES, USER_TYPES } from "../types";
interface LoginInput {
  username: string;
  password: string;
}

export const loginAction =
  (input: LoginInput) =>
  async (dispatch: Dispatch<AuthActionTypes | AuthLoadingActionTypes>) => {
    try {
      dispatch({ type: AUTH_LOADING_TYPES.AUTH_LOADING });
      let bodyData = input;

      const config: AxiosRequestConfig = {
        headers: {}
      };

      let url = AUTH_LOGIN_API;
      const res = await customizedAxios.post<InputObject<{ token: string }>>(
        url,
        bodyData,
        config
      );

      dispatch({ type: AUTH_TYPES.LOGIN, payload: res.data.data!.token });
      dispatch({ type: AUTH_LOADING_TYPES.AUTH_LOADING_DISABLE });
      window.openToastSuccess("خوش آمدید");
      window.navigate("/");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: AUTH_LOADING_TYPES.AUTH_LOADING_DISABLE });
    }
  };

export const getMeAction =
  () =>
  async (dispatch: Dispatch<AuthActionTypes | AuthLoadingActionTypes>) => {
    try {
      dispatch({ type: AUTH_LOADING_TYPES.AUTH_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = AUTH_ME_API;
      const res = await customizedAxios.get<InputObject<UserType>>(url, config);

      dispatch({ type: AUTH_TYPES.ME, payload: res.data.data! });
      dispatch({ type: AUTH_LOADING_TYPES.AUTH_LOADING_DISABLE });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: AUTH_LOADING_TYPES.AUTH_LOADING_DISABLE });
    }
  };

export const forgetPassword =
  ({ username }: QueryParams) =>
  async (dispatch: Dispatch<AuthActionTypes | AuthLoadingActionTypes>) => {
    try {
      dispatch({ type: AUTH_LOADING_TYPES.AUTH_LOADING });
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: {
          username
        }
      };

      let url = AUTH_FORGET_PASSWORD_API;
      const res = await customizedAxios.get<InputObject<{ _id: string }>>(
        url,
        config
      );

      dispatch({
        type: AUTH_LOADING_TYPES.AUTH_LOADING_DISABLE
      });

      window.openToastSuccess("کد تغییر کلمه عبور ارسال شد");
      window.navigate(`/password-reset/?_id=${res.data.data!._id}`);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: AUTH_LOADING_TYPES.AUTH_LOADING_DISABLE });
    }
  };

export const resetPassword =
  ({ code, password }: QueryParams) =>
  async (dispatch: Dispatch<AuthActionTypes | AuthLoadingActionTypes>) => {
    try {
      dispatch({ type: AUTH_LOADING_TYPES.AUTH_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: {
          code,
          password
        }
      };

      let url = AUTH_RESET_PASSWORD_API;
      await customizedAxios.get<InputObject<{ _id: string }>>(url, config);
      dispatch({
        type: AUTH_LOADING_TYPES.AUTH_LOADING_DISABLE
      });
      window.openToastSuccess("رمز عبور با موفقیت ویرایش شد");

      window.navigate(`/login}`);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: AUTH_LOADING_TYPES.AUTH_LOADING_DISABLE });
    }
  };

export const regenerateCode =
  (id: string) =>
  async (dispatch: Dispatch<AuthActionTypes | AuthLoadingActionTypes>) => {
    try {
      dispatch({ type: AUTH_LOADING_TYPES.AUTH_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = AUTH_FORGET_PASSWORD_API.concat("/" + id);
      await customizedAxios.get<InputObject<{ _id: string }>>(url, config);
      dispatch({
        type: AUTH_LOADING_TYPES.AUTH_LOADING_DISABLE
      });
      window.openToastSuccess("کد تغییر کلمه عبور مجددا ارسال شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: AUTH_LOADING_TYPES.AUTH_LOADING_DISABLE });
    }
  };

export const changePassword =
  (body: { password: string }, id: string) =>
  async (dispatch: Dispatch<UserActionTypes>) => {
    try {
      dispatch({ type: USER_TYPES.USER_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = AUTH_CHANGE_PASSWORD_API.concat("/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: USER_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("رمز عبور با موفقیت تغییر یافت");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: USER_TYPES.DISABLE_LOADING });
    }
  };
