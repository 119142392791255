import { useFormik } from "formik";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import {
  DataCenterProvincesType,
  DataCenterType
} from "../../../../core/types";

const EditDataCenter = () => {
  let [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");
  const { editDataCenter, getDataCenterProvinces, fetchOneDataCenter } =
    useActions();
  const { item, provincesLoading, provinces, loading } = useTypedSelector(
    (state) => state.dataCenter
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: item?.name ?? "",
      provinceId: item?.provinceId ?? "",
      address: item?.address ?? ""
    },
    validationSchema: yup.object({
      name: yup.string().trim().required("نام دیتاسنتر الزامی است"),
      provinceId: yup.string().trim().required("انتخاب استان الزامی است"),
      address: yup.string().trim().required("آدرس الزامی است")
    }),
    onSubmit: ({ name, provinceId, address }: DataCenterType) => {
      editDataCenter(
        {
          name,
          provinceId,
          address
        },
        item?._id ?? ""
      );
    }
  });

  const {
    name: nameError,
    provinceId: provinceError,
    address: addressError
  } = formik.errors;
  const {
    name: nameTouched,
    provinceId: provinceTouched,
    address: addressTouched
  } = formik.touched;
  const { name, address } = formik.values;
  const { setValues } = formik;

  const getProvince = useCallback(() => {
    return provinces?.data.map((item) => {
      return { value: item._id, label: item.name };
    });
  }, [provinces?.data]);

  useEffect(() => {
    getDataCenterProvinces();
    if (_id) {
      fetchOneDataCenter(_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const getDefaultProvince = useCallback(() => {
    if (provinces?.data?.length !== 0) {
      const found = provinces?.data?.find((province) => {
        return (
          province._id?.toString() ===
          (item as DataCenterType)?.provinceId?.toString()
        );
      });

      if (
        found &&
        (found as DataCenterProvincesType)._id &&
        (found as DataCenterProvincesType).name
      ) {
        return {
          value: found._id,
          label: found.name
        };
      }
      return {
        value: "",
        label: ""
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinces?.data, item]);

  return (
    <>
      {item && item !== null && (
        <div className='add-item-wrapper'>
          <h5 className='title'>ویرایش دیتاسنتر {item?.name}</h5>
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <Input
              name='name'
              title='نام دیتاسنتر: '
              autoComplete='off'
              error={nameTouched && nameError ? nameError : undefined}
              value={name}
              onChange={formik.handleChange}
              type='text'
            />

            <div className='select-wrapper--high '>
              <label>استان مربوطه:</label>
              <Select
                onChange={(e) => {
                  setValues({ ...formik.values, provinceId: e?.value ?? "" });
                }}
                isClearable
                isDisabled={!provinces || provinces.count === 0}
                placeholder='استان مربوطه را انتخاب کنید'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                options={getProvince()}
                defaultValue={getDefaultProvince()}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              {provinceTouched && provinceError && (
                <p className='input-error--select'>{provinceError}</p>
              )}
            </div>
            <Input
              name='address'
              title='آدرس : '
              multiline
              autoComplete='off'
              error={addressTouched && addressError ? addressError : undefined}
              value={address}
              onChange={formik.handleChange}
              type='text'
            />

            <SubmitButton
              title='ثبت'
              loading={loading || provincesLoading}
              disabled={loading || provincesLoading}
              type='submit'
            />
          </form>
        </div>
      )}
    </>
  );
};

export default EditDataCenter;
