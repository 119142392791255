import { useFormik } from "formik";
import { useCallback, useEffect, useMemo } from "react";
import Loader from "react-spinners/BeatLoader";
import { Column } from "react-table";
import * as yup from "yup";
import { Input, SubmitButton, Table } from "../../../../core/components";
import { STATUS_TYPES, USER_ACCESS } from "../../../../core/constants";
import {
  useActions,
  useCounter,
  useTypedSelector
} from "../../../../core/hooks";
import { useScrollBottom } from "../../../../core/hooks/useScrollBottom";
import {
  DashboardTaskType,
  FaultCountType,
  LogsTable,
  TaskCountType
} from "../../../../core/types";
import {
  checkUserAccessToSection,
  getUserAccess as _getUserAccess
} from "src/core/utility";

const COLUMNS: Column[] = [
  {
    Header: LogsTable.ROW,
    Cell: (val) => {
      return <>{val.row.index + 1}</>;
    }
  },
  {
    Header: LogsTable.USERNAME,
    accessor: "user.fullName"
  },
  {
    Header: LogsTable.EVENTTYPE,
    accessor: "eventType"
  },
  {
    Header: LogsTable.EVENT,
    accessor: "event"
  },
  {
    Header: LogsTable.DESCRIPTION,
    accessor: "comment"
  },
  {
    Header: LogsTable.DATE,
    accessor: "createdAt",

    Cell: ({ value }) => {
      return <>{new Date(value).toLocaleString("fa-IR")}</>;
    }
  }
];

const DashboardList = () => {
  const { fetchLogs } = useActions();
  const { loading, tasksList, faultsList, taskLastDate, faultsLastDate } =
    useTypedSelector((state) => state.dashboard);
  const { list: logsList, loading: logsLoading } = useTypedSelector(
    (state) => state.log
  );
  const [taskLastDateTimer] = useCounter(taskLastDate);
  const [faultsLastDateTimer] = useCounter(faultsLastDate);

  const { me } = useTypedSelector((state) => state.auth);
  const { limit, trackScrolling } = useScrollBottom(logsList?.total as number);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: ""
    },
    validationSchema: yup.object({
      name: yup.string()
    }),
    onSubmit: () => {
      callApi();
    }
  });

  const { name } = formik.values;
  const { name: nameError } = formik.errors;
  const { name: nameTouched } = formik.touched;

  const userAccess = useMemo(() => {
    return checkUserAccessToSection(me?.access ?? []);
  }, [me]);

  const getUserAccess = useMemo(
    () => _getUserAccess.bind(null, userAccess, me),
    [userAccess, me]
  );

  useEffect(() => {
    if (getUserAccess(USER_ACCESS.LOGS)) callApi();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callApi = useCallback(() => {
    fetchLogs({
      limit,
      page: 1,
      text: name,
      sort: "-createdAt"
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, name]);

  return (
    <div>
      <div className='dashboard-sections-wrapper'>
        <div className='info-head'>
          <p>دشبورد خرابی های {me?.fullName}</p>
          <span>
            آخرین آپدیت :{" "}
            <span className='date'> {faultsLastDateTimer} قبل</span>
          </span>
        </div>
        <div className='items-wrapper'>
          <div>
            تعداد خرابی :
            <span>
              {faultsList && faultsList[0]?.totalFaultCount[0]?.count} عدد
            </span>
          </div>
          <div>
            مرکز نرمال :
            <span>
              {
                faultsList?.[0]?.faultCountByStatus.find(
                  (el: FaultCountType) => el._id === STATUS_TYPES.NORMAL
                )?.count
              }
              عدد
            </span>
          </div>{" "}
          {""}
          <div>
            تعداد مراکز :
            <span>{faultsList?.[0]?.countByTelecom?.[0]?.count} عدد</span>
          </div>
          <div>
            در دست بررسی با مشترک :
            <span>
              {
                faultsList?.[0]?.faultCountByStatus.find(
                  (el: FaultCountType) => el._id === STATUS_TYPES.CUSTOMER
                )?.count
              }
              عدد
            </span>
          </div>
          <div>
            در دست بررسی از مرکز :
            <span>
              {
                faultsList?.[0]?.faultCountByStatus.find(
                  (el: FaultCountType) => el._id === STATUS_TYPES.IN_TELECOM
                )?.count
              }
              عدد
            </span>
          </div>
          <div>
            ارجاع به حضوری :
            <span>
              {faultsList?.[0]?.faultCountByStatusOnlyNeedExpert?.[0]?.count}
              عدد
            </span>
          </div>
        </div>
      </div>
      <div className='dashboard-sections-wrapper'>
        <div className='info-head'>
          <p>دشبورد تسک های {me?.fullName}</p>
          <span>
            آخرین آپدیت :<span className='date'> {taskLastDateTimer} قبل </span>
          </span>
        </div>
        <div className='items-wrapper'>
          {(tasksList as unknown as DashboardTaskType)?.[0]?.tasks?.map(
            (el: TaskCountType, i: number) => {
              return (
                <div key={i}>
                  {el.fullName} : <span>{el.count} عدد</span>
                </div>
              );
            }
          )}
        </div>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className='dashboard-sections-wrapper'
        autoComplete='on'>
        <p>فیلتر لاگ</p>
        <div className='input-wrappers'>
          <div className='input-wrapper-1'>
            <Input
              name='name'
              placeholder='جستجو'
              autoComplete='off'
              error={nameTouched && nameError ? nameError : undefined}
              value={name}
              onChange={formik.handleChange}
            />
          </div>
          <SubmitButton
            type='submit'
            title='به روز رسانی'
            loading={loading}
            disabled={loading}
          />
        </div>
      </form>
      <div className='dashboard-sections-wrapper'>
        <div className='list-top'>
          <p>لاگ کاربران</p>
        </div>
        <div id='dashboard' className='react-table-container'>
          <Table columns={COLUMNS as any} data={logsList?.data ?? []} />

          {logsLoading && <Loader color='#ff7d00' loading={logsLoading} />}
        </div>
        {limit !== logsList?.total && (
          <SubmitButton title='مشاهده بیشتر ' onClick={trackScrolling} />
        )}
      </div>
    </div>
  );
};

export default DashboardList;
