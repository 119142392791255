import React, { ButtonHTMLAttributes, memo } from "react";
import Loading from "./Loading";

interface InputProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  loading?: boolean;
  buttonType?: "primary" | "secondary";
  classNames?: string;
}

const _SubmitButtonSecondary: React.FC<InputProps> = ({
  loading,
  title,
  classNames,
  ...rest
}) => {
  return (
    <>
      <div className='submit-button-secondary'>
        <button
          disabled={loading ? true : false}
          className={`submit-button-secondary__button ${
            classNames ? classNames : ""
          }`}
          {...rest}>
          {loading ? <Loading loading={loading} size={14} /> : title}
        </button>
      </div>
    </>
  );
};

const SubmitButtonSecondary = memo(_SubmitButtonSecondary);

export { SubmitButtonSecondary };
