import { TasksActionTypes } from "../actionTypes/TaskActionTypes";
import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { store, TASKS_ROOT_API } from "..";
import { customizedAxios } from "../../config";
import { getCatch } from "../../catch";
import {
  AdvancedResult,
  QueryParams,
  InputObject,
  AssignAbleUsers,
  DeleteType,
  AlbumSchema,
  UploadType
} from "../../types";
import { TASK_TYPES } from "../types/TasksTypes";
import {
  AddSubmitTaskTypes,
  EditTaskTypes,
  TasksType
} from "../../types/TaskTypes";
import { createFormData } from "../../constants";

export const fetchTasks =
  (query: QueryParams) => async (dispatch: Dispatch<TasksActionTypes>) => {
    try {
      dispatch({ type: TASK_TYPES.TASK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: query
      };

      let url = TASKS_ROOT_API;
      const res = await customizedAxios.get<AdvancedResult<TasksType>>(
        url,
        config
      );
      dispatch({
        type: TASK_TYPES.TASK_LISTS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TASK_TYPES.DISABLE_LOADING });
    }
  };

export const addTask =
  (body: AddSubmitTaskTypes) =>
  async (dispatch: Dispatch<TasksActionTypes>) => {
    try {
      dispatch({ type: TASK_TYPES.TASK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = TASKS_ROOT_API.concat("/insert");
      await customizedAxios.post(url, body, config);

      dispatch({
        type: TASK_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" تسک با موفقیت افزوده شد.");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TASK_TYPES.DISABLE_LOADING });
    }
  };

export const editTask =
  (body: EditTaskTypes, id: string) =>
  async (dispatch: Dispatch<TasksActionTypes>) => {
    try {
      dispatch({ type: TASK_TYPES.TASK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = TASKS_ROOT_API.concat("/edit/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: TASK_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("تسک با موفقیت ویرایش شد");
      window.navigate("/tasks/list");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TASK_TYPES.DISABLE_LOADING });
    }
  };

export const fetchOneTask =
  (id: string) => async (dispatch: Dispatch<TasksActionTypes>) => {
    try {
      dispatch({ type: TASK_TYPES.TASK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = TASKS_ROOT_API.concat("/" + id);
      const res = await customizedAxios.get<InputObject<TasksType>>(
        url,
        config
      );
      dispatch({
        type: TASK_TYPES.TASK,
        payload: res.data.data!
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TASK_TYPES.DISABLE_LOADING });
    }
  };

export const fetchAssignAbleTaskUsers =
  () => async (dispatch: Dispatch<TasksActionTypes>) => {
    try {
      dispatch({ type: TASK_TYPES.TASK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = TASKS_ROOT_API.concat("/assignableUsers");
      const res = await customizedAxios.get<{ data: AssignAbleUsers[] }>(
        url,
        config
      );
      dispatch({
        type: TASK_TYPES.TASK_ASSIGNABLE_USERS,
        payload: res.data.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TASK_TYPES.DISABLE_LOADING });
    }
  };

export const deleteTask =
  (taskUuid: string) => async (dispatch: Dispatch<TasksActionTypes>) => {
    try {
      dispatch({ type: TASK_TYPES.TASK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };
      let url = TASKS_ROOT_API.concat("/" + taskUuid);
      await customizedAxios.delete(url, config);
      dispatch({
        type: TASK_TYPES.TASK_DELETE,
        payload: { taskUuid }
      });
      window.openToastSuccess("تسک با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TASK_TYPES.DISABLE_LOADING });
    }
  };

export const concludeATask =
  (taskId: string, back = true, comment?: string) =>
  async (dispatch: Dispatch<TasksActionTypes>) => {
    try {
      dispatch({ type: TASK_TYPES.TASK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = TASKS_ROOT_API.concat("/conclude/", taskId);
      await customizedAxios.put<TasksType>(url, { comment }, config);
      dispatch({
        type: TASK_TYPES.DISABLE_LOADING
      });
      if (back) window.navigate(-1);
      window.openToastSuccess("تسک با موفقیت اتمام یافت");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TASK_TYPES.DISABLE_LOADING });
    }
  };

export const addCommentTask =
  (body: { taskId: string; comment: { desc: string } }) =>
  async (dispatch: Dispatch<TasksActionTypes>) => {
    try {
      dispatch({ type: TASK_TYPES.TASK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = TASKS_ROOT_API.concat("/comments");
      await customizedAxios.put<TasksType>(url, body, config);
      dispatch({
        type: TASK_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("توضیح با موفقیت ثبت شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TASK_TYPES.DISABLE_LOADING });
    }
  };

export const changeAssignmentTask =
  (body: { taskId: string; assignedTo: string }) =>
  async (dispatch: Dispatch<TasksActionTypes>) => {
    try {
      dispatch({ type: TASK_TYPES.TASK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = TASKS_ROOT_API.concat("/changeUser");
      await customizedAxios.put<TasksType>(url, body, config);
      dispatch({
        type: TASK_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("ارجاع تسک با موفقیت انجام شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TASK_TYPES.DISABLE_LOADING });
    }
  };

export const uploadTaskPhoto =
  (file: File, { _id, category, title }: AlbumSchema) =>
  async (dispatch: Dispatch<TasksActionTypes>) => {
    try {
      dispatch({ type: TASK_TYPES.TASK_LOADING });

      let bodyData = createFormData(file, { title, category });

      const config: AxiosRequestConfig = {
        headers: {
          "Content-Type": "multipart/form-data; boundary=form._boundary",
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = TASKS_ROOT_API.concat("/photo/" + _id);
      const res = await customizedAxios.put<UploadType<TasksType>>(
        url,
        bodyData,
        config
      );
      dispatch({
        type: TASK_TYPES.TASK_UPLOAD,
        payload: res.data.data.albums ?? []
      });
      window.openToastSuccess("تصویر با موفقیت افزوده شد");
      window.navigate(-1);
      return res.data.data;
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TASK_TYPES.DISABLE_LOADING });
    }
  };

export const deleteTaskPhoto =
  (id: string, Id: string) => async (dispatch: Dispatch<TasksActionTypes>) => {
    try {
      dispatch({ type: TASK_TYPES.TASK_LOADING });
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        data: {
          id
        }
      };

      let url = TASKS_ROOT_API.concat("/photo/" + Id);
      const res = await customizedAxios.delete<DeleteType<TasksType>>(
        url,
        config
      );

      dispatch({
        type: TASK_TYPES.TASK_UPLOAD,
        payload: res.data.data.albums ?? []
      });
      window.openToastSuccess("تصویر با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TASK_TYPES.DISABLE_LOADING });
    }
  };

export const fetchDoneTasks =
  (body: QueryParams) => async (dispatch: Dispatch<TasksActionTypes>) => {
    try {
      dispatch({ type: TASK_TYPES.TASK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: body
      };

      let url = encodeURI("/tasks/doneTasks");
      const res = await customizedAxios.get<AdvancedResult<TasksType>>(
        url,
        config
      );
      dispatch({
        type: TASK_TYPES.TASK_LISTS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: TASK_TYPES.DISABLE_LOADING });
    }
  };
