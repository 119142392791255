import { useFormik } from "formik";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { UserPasswordTypes } from "../../../../core/types";

const ChangePassword = () => {
  const { changePassword } = useActions();
  const { me } = useTypedSelector((state) => state.auth);
  const userId = me?._id;
  const { loading } = useTypedSelector((state) => state.user);
  const formik = useFormik({
    initialValues: {
      passwordConfirmation: "",
      password: ""
    },
    validationSchema: yup.object({
      password: yup.string().required("رمز عبور الزامی است"),
      passwordConfirmation: yup
        .string()
        .required("تکرار رمز عبور الزامی است")
        .oneOf([yup.ref("password")], "تکرار رمز عبور صحیح نیست")
    }),
    onSubmit: ({ password }: UserPasswordTypes) => {
      password &&
        userId &&
        changePassword(
          {
            password
          },
          userId
        );
    }
  });

  const { password, passwordConfirmation } = formik.values;
  const {
    password: passwordError,
    passwordConfirmation: passwordConfirmationError
  } = formik.errors;
  const {
    password: passwordTouched,
    passwordConfirmation: passwordConfirmationTouched
  } = formik.touched;
  return (
    <>
      <div className='change-password-wrapper'>
        <h5 className='title'>تغییر کلمه عبور</h5>
        <form autoComplete='on' onSubmit={formik.handleSubmit}>
          <Input
            name='password'
            title='کلمه عبور : '
            autoComplete='off'
            error={passwordTouched && passwordError ? passwordError : undefined}
            value={password}
            type='password'
            onChange={formik.handleChange}
          />
          <Input
            name='passwordConfirmation'
            title='تکرار کلمه عبور : '
            autoComplete='off'
            error={
              passwordConfirmationTouched && passwordConfirmationError
                ? passwordConfirmationError
                : undefined
            }
            value={passwordConfirmation}
            type='password'
            onChange={formik.handleChange}
          />

          <SubmitButton
            title='ثبت'
            loading={loading}
            disabled={loading}
            type='submit'
          />
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
