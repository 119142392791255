import { Dispatch, FC, SetStateAction } from "react";
import { UserPhotoLoader } from "../../../../core/components";
import { PhotoCropper } from "../../../../core/components/PhotoCropper";
import { papFaultRatio } from "../../../../core/constants";
import { useActions, useTypedSelector } from "../../../../core/hooks";

interface AddUserPhotoType {
  isOpenCropper: boolean;
  setIsOpenCropper: Dispatch<SetStateAction<boolean>>;
}

const AddUserPhoto: FC<AddUserPhotoType> = ({
  isOpenCropper,
  setIsOpenCropper
}) => {
  const { uploadUserPhoto, getMeAction } = useActions();
  const { me } = useTypedSelector((state) => state.auth);
  return (
    <>
      <PhotoCropper
        title='آپلود تصویر پروفایل کاربر'
        message='تصویر برند حداکثر تا سایز 200kb را آپلود نمایید'
        isOpen={isOpenCropper}
        cr={papFaultRatio}
        ext='jpg'
        onCancel={() => {
          setIsOpenCropper(false);
        }}
        onSuccess={(file: File) => {
          (uploadUserPhoto(file) as unknown as Promise<void>).then(() => {
            getMeAction();
          });
          setIsOpenCropper(false);
        }}
      />{" "}
      <UserPhotoLoader _id={me?._id ?? ""} photo={me?.photo} />
    </>
  );
};

export default AddUserPhoto;
