import { useCallback, useState } from "react";
import { defaultTablePageCount, infinitiLimit } from "../constants";

const useScrollBottom = (total: number) => {
  const [limit, setLimit] = useState(defaultTablePageCount);

  const isBottom = useCallback(() => {
    const wrappedElement = document.getElementById("dashboard");

    if (wrappedElement) {
      return (
        wrappedElement.getBoundingClientRect().bottom + 60 <= window.innerHeight
      );
    }
    return false;
  }, []);

  const trackScrolling = useCallback(() => {
    setLimit((pre) =>
      !!isBottom() && pre <= total ? pre + infinitiLimit : pre
    );
  }, [total, isBottom]);

  return { limit, trackScrolling };
};

export { useScrollBottom };
