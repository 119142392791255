import React, { Dispatch, memo, SetStateAction } from "react";
import { Link } from "react-router-dom";

interface DrawerType {
  openDrawer?: Dispatch<SetStateAction<boolean>>;
}

const _HeaderDashboard: React.FC<DrawerType> = ({ openDrawer }) => {
  return (
    <header id='header'>
      <div className='header-hamburger'>
        <i
          onClick={() => {
            openDrawer && openDrawer(true);
          }}
          className='fas fa-bars'
        />
      </div>
      <div className='header-left'>
        <Link to='/'>
          <img src='/asiatech_minimal_logo.png' alt='asiatech minimal logo' />
        </Link>
      </div>
    </header>
  );
};
const HeaderDashboard = memo(_HeaderDashboard);

export { HeaderDashboard };
