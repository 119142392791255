import { Route, Routes } from "react-router-dom";
import AddPhoneBook from "./add-phone-book";
import EditPhoneBook from "./edit-phone-book";
import PhoneBooksList from "./phone-books-list";

const PhoneBook = () => {
  return (
    <>
      <Routes>
        <Route path='' element={<PhoneBooksList />} />
        <Route path='/add-phone-book' element={<AddPhoneBook />} />
        <Route path='/edit-phone-book' element={<EditPhoneBook />} />
      </Routes>
    </>
  );
};

export default PhoneBook;
