import { DRAWER_TYPES } from "../types";

export const drawerDataCenterAction = () => ({
  type: DRAWER_TYPES.DRAWER_DATACENTER
});

export const drawerFaultsAction = () => ({
  type: DRAWER_TYPES.DRAWER_FAULTS
});

export const drawerOwasAction = () => ({
  type: DRAWER_TYPES.DRAWER_OWAS
});

export const drawerPapAction = () => ({
  type: DRAWER_TYPES.DRAWER_PAP
});

export const drawerTaskAction = () => ({
  type: DRAWER_TYPES.DRAWER_TASK
});

export const drawerTelecomAction = () => ({
  type: DRAWER_TYPES.DRAWER_TELECOM
});

export const drawerCloseAllAction = () => ({
  type: DRAWER_TYPES.DRAWER_CLOSE_ALL
});
