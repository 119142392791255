import axios, { AxiosRequestConfig } from "axios";
import { getCatch } from "../catch";
import { QueryParams } from "../types";
import { Dispatch, SetStateAction } from "react";

const fileDownload = (
  url: string,
  token: string,
  name: string,
  options?: QueryParams,
  setIsLoading?: Dispatch<SetStateAction<boolean>>
) => {
  try {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`
      },
      ...(options && { params: options }),
      responseType: "blob"
    };
    if (setIsLoading) setIsLoading(true);
    axios.get<Blob>(url, config).then((res) => {
      const downloadEle = document.createElement("a");
      downloadEle.setAttribute("href", URL.createObjectURL(res.data));
      downloadEle.setAttribute("download", name);
      document.body.appendChild(downloadEle);
      downloadEle.click();
      if (setIsLoading) setIsLoading(false);
      downloadEle.parentElement?.removeChild(downloadEle);
    });
  } catch (err: any) {
    if (setIsLoading) setIsLoading(false);
    const { message } = getCatch(err);
    window.openToastError(message);
  }
};

export { fileDownload };
