import {
  AdvancedResult,
  DataCenterProvincesType,
  DataCenterType
} from "../../types";

import { DATACENTER_TYPES, DC_ITEM_TYPES, DC_RACK_TYPES } from "../types";
import {
  DataCenterActionTypes,
  DcItemsActionTypes,
  DcRackActionTypes
} from "../actionTypes";

interface StateType {
  list: AdvancedResult<DataCenterType> | null;
  item: DataCenterType | null;
  loading: boolean;
  provincesLoading: boolean;
  provinces: AdvancedResult<DataCenterProvincesType> | null;
  imageItem: DataCenterType | null;
}

const initialState: StateType = {
  list: null,
  item: null,
  loading: false,
  provincesLoading: false,
  provinces: null,
  imageItem: null
};

const reducer = (
  state: StateType = initialState,
  action: DataCenterActionTypes | DcRackActionTypes | DcItemsActionTypes
): StateType => {
  switch (action.type) {
    case DATACENTER_TYPES.DATACENTER_LISTS:
      return {
        ...state,
        list: action.payload,
        loading: false,
        item: null
      };

    case DATACENTER_TYPES.DATACENTER:
      return {
        ...state,
        item: action.payload,
        loading: false
      };

    case DATACENTER_TYPES.DATACENTER_LOADING:
      return {
        ...state,
        loading: true
      };

    case DATACENTER_TYPES.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };

    case DATACENTER_TYPES.DATACENTER_PROVINCES_LOADING:
      return {
        ...state,
        provincesLoading: true
      };
    case DATACENTER_TYPES.DATACENTER_PROVINCES:
      return {
        ...state,
        provinces: action.payload
      };
    case DATACENTER_TYPES.DISABLE_DATACENTER_PROVINCES_LOADING:
      return {
        ...state,
        provincesLoading: false
      };
    case DATACENTER_TYPES.DATACENTER_DELETE:
      let _list = state.list;
      if (_list) {
        _list.data = _list?.data?.filter((item) => {
          return item?._id?.toString() !== action.payload.owaId?.toString();
        });
        --_list.total;
      }
      return {
        ...state,
        list: _list,
        loading: false
      };
    case DATACENTER_TYPES.DATACENTER_UPLOAD:
      return {
        ...state,
        imageItem: { ...state.imageItem!, albums: action.payload },
        loading: false
      };

    case DATACENTER_TYPES.SELECTED_DATACENTER_IMAGES:
      return {
        ...state,
        imageItem: action.payload
      };
    case DC_ITEM_TYPES.DC_ITEM_DELETE:
      let _item = state.item;
      _item!.items = _item?.items?.filter((item) => {
        return item?._id?.toString() !== action.payload._id?.toString();
      });
      return {
        ...state,
        item: _item,
        loading: false
      };

    case DC_RACK_TYPES.DC_RACK_DELETE:
      let __item = state.item;
      __item!.racks = __item?.racks?.filter((rack) => {
        return rack?._id?.toString() !== action.payload._id?.toString();
      });
      return {
        ...state,
        item: __item,
        loading: false
      };

    case DATACENTER_TYPES.RESET_IMAGE_ITEM:
      return {
        ...state,
        imageItem: null
      };

    default:
      return state;
  }
};

export default reducer;
