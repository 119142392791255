import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { customizedAxios } from "../../../config";
import { getCatch } from "../../../catch";
import { AddRadio, RadioSchema } from "../../../types";
import { RadiosActionTypes } from "../../actionTypes";
import { RADIO_TYPES } from "../../types";
import { OWA_ROOT_API } from "../../api";
import { store } from "../../store";
import { DeleteType } from "../../../types";

export const addRadio =
  (body: AddRadio, id: string) =>
  async (dispatch: Dispatch<RadiosActionTypes>) => {
    try {
      dispatch({ type: RADIO_TYPES.RADIO_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = OWA_ROOT_API.concat("/radio/" + id);
      await customizedAxios.post(url, body, config);

      dispatch({
        type: RADIO_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" رادیو با موفقیت افزوده شد.");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: RADIO_TYPES.DISABLE_LOADING });
    }
  };

export const editRadio =
  (body: RadioSchema, id: string, goBack?: boolean) =>
  async (dispatch: Dispatch<RadiosActionTypes>) => {
    try {
      dispatch({ type: RADIO_TYPES.RADIO_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = OWA_ROOT_API.concat("/radio/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: RADIO_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("رادیو با موفقیت ویرایش شد");
      if (!goBack) window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: RADIO_TYPES.DISABLE_LOADING });
    }
  };

export const deleteRadio =
  (id: string, owaId: string) =>
  async (dispatch: Dispatch<RadiosActionTypes>) => {
    try {
      dispatch({ type: RADIO_TYPES.RADIO_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        data: {
          id
        }
      };

      let url = OWA_ROOT_API.concat("/radio/" + owaId);
      await customizedAxios.delete<DeleteType<RadioSchema>>(url, config);

      dispatch({
        type: RADIO_TYPES.RADIO_DELETE,
        payload: { _id: id }
      });
      window.openToastSuccess("رادیو با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: RADIO_TYPES.DISABLE_LOADING });
    }
  };
