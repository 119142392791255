import { Route, Routes } from "react-router-dom";
import AddDataCenter from "./add-data-center";
import AddPhoto from "./add-photo";
import DataCenterList from "./data-center-list";
import EditDataCenter from "./edit-data-center";
import Items from "./items";
import Racks from "./racks";

const DataCenter = () => {
  return (
    <>
      <Routes>
        <Route path='' element={<DataCenterList />} />
        <Route path='/add-data-center' element={<AddDataCenter />} />
        <Route path='/add-data-center-photo' element={<AddPhoto />} />
        <Route path='/edit-data-center' element={<EditDataCenter />} />
        <Route path='/add-data-center-photo' element={<AddDataCenter />} />

        <Route path='/items/*' element={<Items />} />
        <Route path='/racks/*' element={<Racks />} />

        {/* <Route path="/items/edit" element={<EditItem />} /> */}
      </Routes>
    </>
  );
};

export default DataCenter;
