export enum PHONE_BOOK_TYPES {
  PHONE_BOOK_LISTS = "PHONE_BOOK_LISTS",
  PHONE_BOOK = "PHONE_BOOK",
  PHONE_BOOK_DELETE = "PHONE_BOOK_DELETE",
  PHONE_BOOK_LOADING = "PHONE_BOOK_LOADING",
  DISABLE_LOADING = "DISABLE_LOADING",
  PHONE_BOOK_PROVINCES_LOADING = "PHONE_BOOK_PROVINCES_LOADING",
  PHONE_BOOK_PROVINCES_LISTS = "PHONE_BOOK_PROVINCES_LISTS",
  DISABLE_PROVINCES_LOADING = "DISABLE_PROVINCES_LOADING"
}
