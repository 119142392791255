import { AdvancedResult, AssignAbleUsers } from "../../types";
import { TasksType } from "../../types/TaskTypes";
import { TasksActionTypes } from "../actionTypes";
import { TASK_TYPES } from "../types/TasksTypes";

interface StateType {
  list: AdvancedResult<TasksType> | null;
  item: TasksType | null;
  loading: boolean;
  assignAbleUsers: null | AssignAbleUsers[];
  imageItem: TasksType | null;
}

const initialState: StateType = {
  list: null,
  item: null,
  loading: false,
  assignAbleUsers: null,
  imageItem: null
};

const reducer = (
  state: StateType = initialState,
  action: TasksActionTypes
): StateType => {
  switch (action.type) {
    case TASK_TYPES.TASK_LISTS:
      return {
        ...state,
        list: action.payload,
        loading: false
      };

    case TASK_TYPES.TASK:
      return {
        ...state,
        item: action.payload,
        loading: false
      };

    case TASK_TYPES.TASK_LOADING:
      return {
        ...state,
        loading: true
      };
    case TASK_TYPES.TASK_ASSIGNABLE_USERS:
      return {
        ...state,
        loading: false,
        assignAbleUsers: action.payload
      };
    case TASK_TYPES.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };
    case TASK_TYPES.TASK_DELETE:
      let _list = state.list;
      if (_list) {
        _list.data = _list?.data?.filter((item) => {
          return item?._id?.toString() !== action.payload.taskUuid?.toString();
        });
        --_list.total;
      }
      return {
        ...state,
        list: _list,
        loading: false
      };
    case TASK_TYPES.TASK_UPLOAD:
      return {
        ...state,
        imageItem: { ...state.imageItem!, albums: action.payload },
        loading: false
      };

    case TASK_TYPES.SELECTED_TASK_IMAGES:
      return {
        ...state,
        imageItem: action.payload
      };
    case TASK_TYPES.RESET_IMAGE_ITEM:
      return {
        ...state,
        imageItem: null
      };
    default:
      return state;
  }
};

export default reducer;
