import { Route, Routes } from "react-router-dom";
import RacksList from "./racks-list";
import AddRack from "./add-rack";
import EditRack from "./edit-rack";

const Racks = () => {
  return (
    <>
      <Routes>
        <Route path='' element={<RacksList />} />
        <Route path='/add' element={<AddRack />} />
        <Route path='/edit' element={<EditRack />} />
      </Routes>
    </>
  );
};

export default Racks;
