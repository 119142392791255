import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../../core/components";
import { useActions, useTypedSelector } from "../../../../../core/hooks";
import { ItemSchema } from "../../../../../core/types";

const EditItem = () => {
  let [searchParams] = useSearchParams();
  const owaId = searchParams.get("owaId");
  const itemId = searchParams.get("itemId");
  const { editItem, fetchOneOwa } = useActions();
  const { loading, item } = useTypedSelector((state) => state.owa);

  const selectedItem = useMemo(() => {
    return item && itemId && item?.items?.find((item) => item._id === itemId);
  }, [item, itemId]);

  const formik = useFormik({
    initialValues: {
      dimension:
        selectedItem && selectedItem?.dimension ? selectedItem.dimension : "",
      propertyNumber:
        selectedItem && selectedItem?.propertyNumber
          ? selectedItem.propertyNumber
          : "",
      serialNumber:
        selectedItem && selectedItem?.serialNumber
          ? selectedItem.serialNumber
          : "",
      name: selectedItem && selectedItem?.name ? selectedItem.name : "",
      description:
        selectedItem && selectedItem?.description
          ? selectedItem.description
          : "",
      type: selectedItem && selectedItem?.type ? selectedItem.type : ""
    },
    validationSchema: yup.object({
      name: yup.string().trim().required("نام آیتم الزامی است"),
      propertyNumber: yup.string().trim(),
      serialNumber: yup.string().trim(),
      type: yup.string().trim().required("نوع آیتم الزامی است"),
      dimension: yup.string().trim(),
      description: yup.string().trim()
    }),
    onSubmit: ({
      propertyNumber,
      serialNumber,
      name,
      type,
      description,
      dimension
    }: ItemSchema) => {
      editItem(
        {
          id: itemId,
          propertyNumber,
          serialNumber,
          name,
          type,
          description,
          dimension
        },
        item?._id ?? ""
      );
    }
  });
  useEffect(() => {
    if (owaId) {
      fetchOneOwa(owaId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owaId]);

  const { type, propertyNumber, serialNumber, name, dimension, description } =
    formik.values;
  const {
    type: typeError,
    dimension: dimensionError,
    propertyNumber: propertyNumberError,
    serialNumber: serialNumberError,
    name: nameError,
    description: descriptionError
  } = formik.errors;
  const {
    type: typeTouched,
    dimension: dimensionTouched,
    propertyNumber: propertyNumberTouched,
    serialNumber: serialNumberTouched,
    name: nameTouched,
    description: descriptionTouched
  } = formik.touched;

  const { setValues } = formik;

  const types = useMemo(() => {
    if (item && item != null && Array.isArray(item.items)) {
      return [
        ...new Set(
          item.items.map((item) => {
            return item.type;
          })
        )
      ];
    }
    return [];
  }, [item]);

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'>ویرایش آیتم</h5>
        {item && item !== null && selectedItem && (
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <Input
              name='name'
              title='نام  : '
              autoComplete='off'
              error={nameTouched && nameError ? nameError : undefined}
              value={name}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='type'
              title='نوع : '
              autoComplete='off'
              error={typeTouched && typeError ? typeError : undefined}
              value={type}
              type='text'
              onChange={formik.handleChange}
            />

            <div className='select-chip'>
              {types.map((type) => {
                return (
                  <div
                    className='chip'
                    onClick={() => {
                      setValues({
                        ...formik.values,
                        type: type?.toString() ?? ""
                      });
                    }}>
                    {type?.toString()}
                  </div>
                );
              })}
            </div>

            <Input
              name='propertyNumber'
              title='شماره اموال : '
              autoComplete='off'
              error={
                propertyNumberTouched && propertyNumberError
                  ? propertyNumberError
                  : undefined
              }
              value={propertyNumber}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='serialNumber'
              title='شماره سریال : '
              autoComplete='off'
              error={
                serialNumberTouched && serialNumberError
                  ? serialNumberError
                  : undefined
              }
              value={serialNumber}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='dimension'
              title='ابعاد  : '
              autoComplete='off'
              error={
                dimensionTouched && dimensionError ? dimensionError : undefined
              }
              value={dimension}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='description'
              title='توضیحات  : '
              autoComplete='off'
              multiline
              error={
                descriptionTouched && descriptionError
                  ? descriptionError
                  : undefined
              }
              value={description}
              onChange={formik.handleChange}
              type='text'
            />

            <SubmitButton
              title='ثبت'
              loading={loading}
              disabled={loading}
              type='submit'
            />
          </form>
        )}
      </div>
    </>
  );
};

export default EditItem;
