import { CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import copy from "copy-to-clipboard";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import _ from "lodash";
import {
  NoItem,
  DeleteModalContent,
  Input,
  SubmitButton
} from "../../../../core/components";
import {
  DEBOUNCE_TIME,
  defaultTablePageCount,
  USER_ACCESS
} from "../../../../core/constants";
import { defaultColumnDefinition } from "../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { BASE_API, PHONEbOOKS_ROOT_API } from "../../../../core/redux";
import {
  CRUD_TYPES,
  PhoneBookTable,
  PhoneBookType,
  ProvincesType
} from "../../../../core/types";
import {
  checkUserAccessByProvinceCrud,
  fileDownload
} from "../../../../core/utility";
import { useFormik } from "formik";
import useIsMobile from "src/core/hooks/useIsMobile";
import { useQueryParams } from "src/core/hooks/useQueryParams";

const PhoneBooksList = () => {
  const { fetchPhoneBooks, deletePhoneBook } = useActions();
  const { loading, list, item } = useTypedSelector((state) => state.phoneBook);
  const { searchParams, setSearchParams } = useQueryParams();
  const [currentPage, setCurrentPage] = useState<number>(
    +(searchParams("page") ?? 1) - 1
  );
  const navigator = useNavigate();
  const isMobile = useIsMobile();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<
    PhoneBookType | null | undefined
  >(null);
  const { me } = useTypedSelector((state) => state.auth);
  const { token } = useTypedSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      name: searchParams("name[regex]") ?? ""
    },
    validationSchema: yup.object({
      name: yup.string()
    }),
    onSubmit: () => {}
  });

  const { name } = formik.values;
  const { name: nameError } = formik.errors;
  const { name: nameTouched } = formik.touched;

  useEffect(() => {
    callApi(currentPage, name);
    setSearchParams({ page: currentPage + 1, "name[regex]": name });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, name, item]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callApi = useCallback(
    _.debounce((page, name) => {
      fetchPhoneBooks({
        limit: defaultTablePageCount,
        page: page + 1,
        "name[regex]": name
      });
    }, DEBOUNCE_TIME),
    []
  );

  const onSuccessDeleteModal = useCallback(() => {
    deletePhoneBook(selectedItem?._id as string);
    setIsOpen(false);
    setSelectedItem(null);
  }, [selectedItem, deletePhoneBook]);

  const rowData = useCallback(() => {
    return list?.data.map((item, index) => {
      return {
        [PhoneBookTable.ROW]: `${index + 1 + currentPage * 10}`,
        [PhoneBookTable._ID]: item._id,
        [PhoneBookTable.NAME]: item.name,
        [PhoneBookTable.PHONE]: item.phone,
        [PhoneBookTable.CATEGORY]: item.category,
        [PhoneBookTable.PROVINCE]: (item.provinceId as unknown as ProvincesType)
          ?.name,
        [PhoneBookTable.PROVINCEID]: (
          item.provinceId as unknown as ProvincesType
        )?._id,
        [PhoneBookTable.CREATEDAT]: new Date(
          item.createdAt ?? ""
        ).toLocaleDateString("fa-IR"),
        [PhoneBookTable.UPDATEDAT]: new Date(
          item.updatedAt ?? ""
        ).toLocaleDateString("fa-IR"),
        [PhoneBookTable.ACTIONS]: "edit"
      };
    });
  }, [list?.data, currentPage]);

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          {me &&
            checkUserAccessByProvinceCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.PHONEBOOK,
              CRUD_TYPES.EDIT,
              cellEvent?.data[PhoneBookTable.PROVINCEID]
            ) && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  navigator(`edit-phone-book?_id=${cellEvent?.data?._id}`);
                }}>
                <i title='ویرایش' className='fas fa-pen edit-icon'></i>
              </span>
            )}
          {me &&
            checkUserAccessByProvinceCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.PHONEBOOK,
              CRUD_TYPES.DELETE,
              cellEvent?.data[PhoneBookTable.PROVINCEID]
            ) && (
              <span
                onClick={() => {
                  setSelectedItem(
                    list?.data?.find((item) => {
                      return (
                        item._id?.toString() ===
                        cellEvent?.data[PhoneBookTable._ID]
                      );
                    }) ?? null
                  );

                  setIsOpen(true);
                }}>
                <i title='حذف' className='fas fa-trash delete-icon'></i>
              </span>
            )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [me, list]
  );

  return (
    <>
      <DeleteModalContent
        isOpen={isOpen}
        title={"حذف مخاطب " + (selectedItem && selectedItem.name)}
        message='آیا از حذف مخاطب مطمئن هستید؟'
        onCancel={() => {
          setIsOpen(false);
          setSelectedItem(null);
        }}
        onSuccess={onSuccessDeleteModal}
      />
      <section className='clients-search-wrapper'>
        <div className='list-input-wrapper'>
          <Input
            name='name'
            placeholder='جستجو'
            autoComplete='off'
            error={nameTouched && nameError ? nameError : undefined}
            value={name}
            onChange={(e) => {
              formik.handleChange(e);
              if (currentPage !== 0) setCurrentPage(0);
            }}
          />
        </div>
      </section>{" "}
      <div className='main-route-wrapper'>
        <div className='table-wrapper'>
          <div className='list-top'>
            <p>
              تعداد کل مخاطبین : <span>{list && list.total} عدد</span>
              <i
                onClick={() => {
                  callApi(currentPage, name);
                }}
                className={`fas fa-sync-alt ${loading ? "rotate" : ""}`}></i>
              {list && list?.data && list.data.length !== 0 && (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    const url = BASE_API + PHONEbOOKS_ROOT_API;
                    fileDownload(url, token ?? "", "phonebook.xlsx", {
                      xlsx: true,
                      "name[regex]": name
                    });
                  }}
                  className='fas fa-file-excel excel-icon'></i>
              )}
            </p>
            <Link to={"./add-phone-book"}>
              <SubmitButton loading={loading} title='اضافه کردن مخاطب جدید' />
            </Link>
          </div>

          {(!list || list.count === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}

          {list && list.count !== 0 && (
            <>
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  rowSelection='multiple'
                  animateRows={true}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  enableRtl={true}
                  onCellClicked={(e) => {
                    if (e.colDef.field !== PhoneBookTable.ACTIONS)
                      copy(e.value);
                  }}>
                  <AgGridColumn
                    sortable={true}
                    field={PhoneBookTable.ROW}
                    width={84}
                  />
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={PhoneBookTable.ACTIONS}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PhoneBookTable.NAME}
                    flex={1}
                    minWidth={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PhoneBookTable.PHONE}
                    width={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PhoneBookTable.CATEGORY}
                    flex={1}
                    minWidth={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PhoneBookTable.PROVINCE}
                    width={170}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PhoneBookTable.CREATEDAT}
                    width={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={PhoneBookTable.UPDATEDAT}
                    width={150}
                  />
                </AgGridReact>
              </div>
            </>
          )}
        </div>
        <ReactPaginate
          marginPagesDisplayed={isMobile ? 1 : 3}
          forcePage={currentPage}
          breakLabel='...'
          nextLabel='بعدی'
          onPageChange={(e) => {
            setCurrentPage(e.selected);
          }}
          pageRangeDisplayed={5}
          pageCount={
            (list && Math.ceil(list.total / defaultTablePageCount)) ?? 1
          }
          previousLabel='قبلی'
          containerClassName='pagination-container'
        />
      </div>
    </>
  );
};

export default PhoneBooksList;
