import { Field, FormikProvider, useFormik } from "formik";
import React, { memo, useCallback } from "react";
import ReactModal from "react-modal";
import * as yup from "yup";
import { CancelButton, Input, SubmitButton } from ".";
import { FaultCommentType, UserType } from "../types";
import { getSinceTimeWithHour } from "../utility/getSinceTimeWithHour";
import { NoItem } from "./NoItem";

interface AddCommentModalContentType {
  onSuccess?: (comment: string, urgent: boolean) => any;
  comments?: FaultCommentType[];
  onClose?: () => any;
  isOpen: boolean;
  urgent: boolean;
  faultPhone: string;
}

const _ConcludeFaultInTelecom: React.FC<AddCommentModalContentType> = ({
  comments,
  onSuccess,
  onClose,
  isOpen,
  urgent,
  faultPhone,
  ...rest
}) => {
  const submit = useCallback(
    (comment: string, urgent: boolean) => {
      onSuccess && onSuccess(comment, urgent);
    },
    [onSuccess]
  );
  const formik = useFormik({
    initialValues: {
      comment: "",
      urgent: urgent ?? false
    },
    validationSchema: yup.object({
      comment: yup.string().required("توضیحات الزامی است"),
      urgent: yup.boolean()
    }),
    onSubmit: ({ comment, urgent }: any) => {
      submit(comment, urgent);
    }
  });

  const { comment } = formik.values;
  const { comment: commentError } = formik.errors;
  const { comment: commentTouched } = formik.touched;

  return (
    <>
      <ReactModal
        style={{
          content: {
            maxWidth: 800,
            maxHeight: 600,
            margin: "auto",
            padding: "0",
            left: 16,
            right: 16
          }
        }}
        {...rest}
        isOpen={isOpen}
        onRequestClose={onClose}
        ariaHideApp={false}>
        <div className='add-comment-modal__wrapper'>
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <div className='add-comment-modal-header'>
              <div className='title'>اتمام بررسی {faultPhone} </div>
              <div className='action-wrappers'>
                <CancelButton
                  onClick={onClose}
                  loading={false}
                  title='انصراف'
                  type='button'
                />
                <SubmitButton type='submit' title='ثبت' />
              </div>
            </div>
            <div className='checkbox-wrapper'>
              <FormikProvider value={formik}>
                <Field type='checkbox' name='urgent' />
                <label> فوری</label>
              </FormikProvider>
            </div>
            <Input
              placeholder=''
              name='comment'
              title='توضیحات  : '
              multiline
              autoComplete='off'
              error={commentTouched && commentError ? commentError : undefined}
              value={comment}
              onChange={formik.handleChange}
            />
            {comments && comments.length !== 0 ? (
              <div className='content-wrapper'>
                {comments
                  .sort(function (a, b) {
                    return (
                      new Date(b.createdAt).getTime() -
                      new Date(a.createdAt).getTime()
                    );
                  })
                  .map((item) => {
                    return (
                      <div key={item._id}>
                        <span>
                          {`${(item.userId as unknown as UserType)
                            ?.fullName} ${getSinceTimeWithHour(
                            item.createdAt
                          )}`}
                        </span>
                        &nbsp;
                        <div>{item.desc}</div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <NoItem title='کامنتی ثبت نشده است.' />
            )}
          </form>
        </div>
      </ReactModal>
    </>
  );
};

const ConcludeFaultInTelecom = memo(_ConcludeFaultInTelecom);

export { ConcludeFaultInTelecom };
