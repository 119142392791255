import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { customizedAxios } from "../../../config";
import { getCatch } from "../../../catch";
import { AddDCItem, DCItemSchema } from "../../../types";
import { DcItemsActionTypes } from "../../actionTypes";
import { DC_ITEM_TYPES } from "../../types";
import { DATACENTER_ROOT_API } from "../../api";
import { store } from "../../store";
import { DeleteType } from "../../../types";

export const addDcItem =
  (body: AddDCItem, id: string) =>
  async (dispatch: Dispatch<DcItemsActionTypes>) => {
    try {
      dispatch({ type: DC_ITEM_TYPES.DC_ITEM_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = DATACENTER_ROOT_API.concat("/item/" + id);
      await customizedAxios.post(url, body, config);

      dispatch({
        type: DC_ITEM_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" آیتم با موفقیت افزوده شد.");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: DC_ITEM_TYPES.DISABLE_LOADING });
    }
  };

export const editDcItem =
  (body: DCItemSchema, id: string) =>
  async (dispatch: Dispatch<DcItemsActionTypes>) => {
    try {
      dispatch({ type: DC_ITEM_TYPES.DC_ITEM_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = DATACENTER_ROOT_API.concat("/item/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: DC_ITEM_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("آیتم با موفقیت ویرایش شد");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: DC_ITEM_TYPES.DISABLE_LOADING });
    }
  };

export const deleteDcItem =
  (id: string, dataCenterId: string) =>
  async (dispatch: Dispatch<DcItemsActionTypes>) => {
    try {
      dispatch({ type: DC_ITEM_TYPES.DC_ITEM_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        data: {
          id
        }
      };

      let url = DATACENTER_ROOT_API.concat("/item/" + dataCenterId);
      await customizedAxios.delete<DeleteType<DCItemSchema>>(url, config);

      dispatch({
        type: DC_ITEM_TYPES.DC_ITEM_DELETE,
        payload: { _id: id }
      });
      window.openToastSuccess("آیتم با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: DC_ITEM_TYPES.DISABLE_LOADING });
    }
  };
