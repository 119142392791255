import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { PROVINCE_TYPES, store, PROVINCES_ROOT_API } from "..";
import { customizedAxios } from "../../config";
import { getCatch } from "../../catch";
import {
  AdvancedResult,
  ProvincesType,
  QueryParams,
  AddProvince,
  InputObject
} from "../../types";
import { ProvincesActionTypes } from "../actionTypes";

export const fetchProvinces =
  (query: QueryParams) => async (dispatch: Dispatch<ProvincesActionTypes>) => {
    try {
      dispatch({ type: PROVINCE_TYPES.PROVINCE_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: query
      };

      let url = PROVINCES_ROOT_API;
      const res = await customizedAxios.get<AdvancedResult<ProvincesType>>(
        url,
        config
      );
      dispatch({
        type: PROVINCE_TYPES.PROVINCE_LISTS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PROVINCE_TYPES.DISABLE_LOADING });
    }
  };
export const addProvince =
  (body: AddProvince) => async (dispatch: Dispatch<ProvincesActionTypes>) => {
    try {
      dispatch({ type: PROVINCE_TYPES.PROVINCE_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PROVINCES_ROOT_API;
      await customizedAxios.post(url, body, config);

      dispatch({
        type: PROVINCE_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" استان با موفقیت افزوده شد.");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PROVINCE_TYPES.DISABLE_LOADING });
    }
  };

export const editProvince =
  (body: ProvincesType, id: string) =>
  async (dispatch: Dispatch<ProvincesActionTypes>) => {
    try {
      dispatch({ type: PROVINCE_TYPES.PROVINCE_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PROVINCES_ROOT_API.concat("/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: PROVINCE_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("استان با موفقیت ویرایش شد");
      window.navigate("/provinces");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PROVINCE_TYPES.DISABLE_LOADING });
    }
  };

export const fetchOneProvince =
  (id: string) => async (dispatch: Dispatch<ProvincesActionTypes>) => {
    try {
      dispatch({ type: PROVINCE_TYPES.PROVINCE_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PROVINCES_ROOT_API.concat("/" + id);
      const res = await customizedAxios.get<InputObject<ProvincesType>>(
        url,
        config
      );
      dispatch({
        type: PROVINCE_TYPES.PROVINCE,
        payload: res.data.data!
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PROVINCE_TYPES.DISABLE_LOADING });
    }
  };
