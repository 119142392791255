export enum OWA_TYPES {
  OWA_LISTS = "OWA_LISTS",
  OWA = "OWA",
  OWA_DELETE = "OWA_DELETE",
  OWA_LOADING = "OWA_LOADING",
  OWA_UPLOAD = "OWA_UPLOAD",
  SELECTED_OWA_IMAGES = "SELECTED_OWA_IMAGES",
  DISABLE_LOADING = "DISABLE_LOADING",
  OWA_PROVINCES_LOADING = "OWA_PROVINCES_LOADING",
  OWA_PROVINCES = "OWA_PROVINCES",
  DISABLE_OWA_PROVINCES_LOADING = "DISABLE_OWA_PROVINCES_LOADING",
  RESET_IMAGE_ITEM = "RESET_IMAGE_ITEM"
}
