import { CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import copy from "copy-to-clipboard";
import { useFormik } from "formik";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  AddCommentModalContent,
  DeleteModalContent,
  DetailModalContent,
  ImageWrapper,
  Input,
  NoItem,
  SubmitButton
} from "../../../../core/components";
import { ChangeAssignmentTask } from "../../../../core/components/ChangeAssignmentTask";
import { ConcludeTask } from "../../../../core/components/ConcludeTask";
import {
  DEBOUNCE_TIME,
  USER_ACCESS,
  defaultFaultTablePageCount
} from "../../../../core/constants";
import { defaultColumnDefinition } from "../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { BASE_API, TASKS_ROOT_API, TASK_TYPES } from "../../../../core/redux";
import { BINARY_STATUS, UserType } from "../../../../core/types";
import {
  TaskCommentType,
  TasksTable,
  TasksType,
  TASK_SIDE
} from "../../../../core/types/TaskTypes";
import {
  fileDownload,
  getUserAccess as _getUserAccess,
  checkUserAccessToSection
} from "../../../../core/utility";
import timeSince from "../../../../core/utility/fromNowTime";
import useIsMobile from "src/core/hooks/useIsMobile";
import { useQueryParams } from "src/core/hooks/useQueryParams";
import Loading from "src/core/components/Loading";

const TasksList = () => {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const {
    fetchTasks,
    deleteTask,
    concludeATask,
    addCommentTask,
    changeAssignmentTask,
    deleteTaskPhoto,
    uploadTaskPhoto,
    editTask
  } = useActions();
  const { loading, list, imageItem } = useTypedSelector((state) => state.task);
  const { me } = useTypedSelector((state) => state.auth);
  const { token } = useTypedSelector((state) => state.auth);

  const [isCommentModalOpen, setIsCommentModalOpen] =
    useState<null | TasksType>(null);
  const [changeAssignment, setChangeAssignment] = useState<null | string>(null);
  const [addComment, setAddComment] = useState<null | TasksType>(null);
  const [concludeTask, setConcludeTask] = useState<null | TasksType>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [selectedItem, setSelectedItem] = useState<TasksType | null>(null);

  const { searchParams, setSearchParams } = useQueryParams();
  const [currentPage, setCurrentPage] = useState<number>(
    +(searchParams("page") ?? 1) - 1
  );

  const queryParams = {
    limit: defaultFaultTablePageCount,
    page: currentPage + 1
  };

  const formik = useFormik({
    initialValues: {
      name: searchParams("title[regex]") ?? ""
    },
    validationSchema: yup.object({
      name: yup.string()
    }),
    onSubmit: () => {}
  });

  const { name } = formik.values;
  const { name: nameError } = formik.errors;
  const { name: nameTouched } = formik.touched;

  useEffect(() => {
    callApi();
    setSearchParams({ ...queryParams, "title[regex]": name });
    dispatch({ type: TASK_TYPES.RESET_IMAGE_ITEM });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, name]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callApi = useCallback(
    _.debounce(() => {
      fetchTasks({
        ...queryParams,
        "title[regex]": name
      });
    }, DEBOUNCE_TIME),
    [name, queryParams]
  );

  const userAccess = checkUserAccessToSection(me?.access ?? []);

  const getUserAccess = _getUserAccess.bind(null, userAccess, me);

  const hasAlbum = useCallback(
    (cellEvent: CellClickedEvent) =>
      list?.data?.find((task) => {
        return task._id === cellEvent?.data?._id;
      })?.albums?.length !== 0,
    [list?.data]
  );

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          {
            <span
              onClick={(e) => {
                e.stopPropagation();
                navigator(`/tasks/edit-task?_id=${cellEvent?.data?._id}`);
              }}>
              <i title='ویرایش' className='fas fa-pen edit-icon'></i>
            </span>
          }
          <span
            onClick={() => {
              setSelectedItem(
                list?.data?.find((item) => {
                  return (
                    item._id?.toString() === cellEvent?.data[TasksTable._ID]
                  );
                }) ?? null
              );

              setIsOpen(true);
            }}>
            <i title='حذف' className='fas fa-trash delete-icon'></i>
          </span>
          <span
            onClick={() => {
              const selectedItem =
                list?.data?.find((item) => {
                  return (
                    item._id?.toString() === cellEvent?.data[TasksTable._ID]
                  );
                }) ?? null;
              (
                editTask(
                  {
                    side:
                      selectedItem?.side === TASK_SIDE.ASSIGNER
                        ? TASK_SIDE.ASSIGNEE
                        : TASK_SIDE.ASSIGNER
                  },
                  cellEvent?.data[TasksTable._ID] ?? ""
                ) as unknown as Promise<void>
              ).then(() => callApi());
            }}>
            <i
              title='تغییر سمت تسک'
              className='fas fa-arrows-alt-h edit-icon'></i>{" "}
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              setAddComment(
                list?.data?.find((item) => {
                  return (
                    item._id?.toString() === cellEvent?.data[TasksTable._ID]
                  );
                }) ?? null
              );
            }}>
            <i title='توضیحات' className='fas fa-comment edit-icon' />
          </span>

          {(cellEvent?.data[TasksTable.USER_CREATOR] === me?._id ||
            getUserAccess(USER_ACCESS.TASKS_READ_ALL)) && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                setConcludeTask(
                  list?.data?.find((item) => {
                    return (
                      item._id?.toString() === cellEvent?.data[TasksTable._ID]
                    );
                  }) ?? null
                );
              }}>
              <i title='بستن تسک' className='fas fa-info-circle edit-icon'></i>
            </span>
          )}

          <span
            onClick={(e) => {
              e.stopPropagation();
              setChangeAssignment(cellEvent?.data[TasksTable._ID]);
            }}>
            <i
              title='ارجاع به دیگری'
              className='fas fa-user-friends edit-icon'></i>
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              dispatch({
                type: TASK_TYPES.SELECTED_TASK_IMAGES,
                payload: list?.data?.find((task) => {
                  return task._id === cellEvent?.data?._id;
                })
              });
              navigator(`/tasks/add-task-photo?_id=${cellEvent?.data?._id}`);
            }}>
            <i title='اضافه کردن عکس' className='fas fa-camera add-icon'></i>
          </span>
          {hasAlbum(cellEvent) && (
            <span
              onClick={(e) => {
                e.stopPropagation();

                dispatch({
                  type: TASK_TYPES.SELECTED_TASK_IMAGES,
                  payload: list?.data?.find((task) => {
                    return task._id === cellEvent?.data?._id;
                  })
                });
              }}>
              <i title='گالری' className='fas fa-images edit-icon'></i>
            </span>
          )}
          <span
            onClick={() => {
              setIsCommentModalOpen(cellEvent?.data);
            }}>
            <i title='اطلاعات تسک' className='fas fa-comment comment-icon' />
          </span>
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [me, list]
  );

  const rowData = useCallback(() => {
    return list?.data.map((item, index) => {
      return {
        [TasksTable.ROW]: `${index + 1 + currentPage * 10}`,
        [TasksTable._ID]: item._id,
        [TasksTable.TITLE]: item.title,
        [TasksTable.SIDE]:
          item.side === TASK_SIDE.ASSIGNEE &&
          me?._id === (item.userId as UserType)?._id
            ? BINARY_STATUS.YES
            : item.side === TASK_SIDE.ASSIGNER &&
              me?._id === (item.assignedTo as UserType)?._id
            ? BINARY_STATUS.YES
            : BINARY_STATUS.NO,
        [TasksTable.DESCRIPTION]: item.description,
        [TasksTable.PRIORITY]: item.priority,
        [TasksTable.USERID]: (item.userId as UserType)?.fullName,
        [TasksTable.USER_CREATOR]: (item.userId as UserType)?._id,
        [TasksTable.ASSIGNEDTO]: (item.assignedTo as UserType)?.fullName,
        [TasksTable.CREATEDAT]: new Date(
          item.createdAt ?? ""
        ).toLocaleDateString("fa-IR"),
        [TasksTable.DURATION]:
          item.createdAt && `${timeSince(new Date(item.createdAt))} قبل`
      };
    });
  }, [list?.data, currentPage, me?._id]);

  const onSuccessDeleteModal = useCallback(() => {
    deleteTask(selectedItem?._id as string);

    setIsOpen(false);
    setSelectedItem(null);
  }, [selectedItem, deleteTask]);

  return (
    <>
      <DeleteModalContent
        isOpen={isOpen}
        title={"حذف تسک " + (selectedItem && selectedItem.title)}
        message='آیا از حذف تسک مطمئن هستید؟'
        onCancel={() => {
          setIsOpen(false);
          setSelectedItem(null);
        }}
        onSuccess={onSuccessDeleteModal}
      />
      <DetailModalContent
        content={isCommentModalOpen}
        isOpen={!!isCommentModalOpen}
        title={"توضیحات تسک"}
        onClose={() => {
          setIsCommentModalOpen(null);
        }}
        message={
          (isCommentModalOpen as unknown as any)?.[TasksTable.DESCRIPTION]
        }
      />
      <section className='clients-search-wrapper'>
        <div className='list-input-wrapper'>
          <Input
            name='name'
            placeholder='جستجو'
            autoComplete='off'
            error={nameTouched && nameError ? nameError : undefined}
            value={name}
            onChange={(e) => {
              formik.handleChange(e);
              if (currentPage !== 0) setCurrentPage(0);
            }}
          />
        </div>
      </section>{" "}
      <div className='main-route-wrapper'>
        <div className='table-wrapper'>
          <div className='list-top'>
            <p>
              تعداد کل تسک ها : <span>{list && list.total} عدد</span>
              <i
                onClick={() => {
                  callApi();
                }}
                className={`fas fa-sync-alt ${loading ? "rotate" : ""}`}></i>
              {list &&
                list?.data &&
                list.data.length !== 0 &&
                !isLoadingDownload && (
                  <i
                    onClick={(e) => {
                      e.stopPropagation();
                      const url = BASE_API + TASKS_ROOT_API;
                      fileDownload(
                        url,
                        token ?? "",
                        "tasks.xlsx",
                        {
                          xlsx: true
                        },
                        setIsLoadingDownload
                      );
                    }}
                    className='fas fa-file-excel excel-icon'></i>
                )}
              <Loading
                size={14}
                color='rgb(50, 119, 50)'
                loading={isLoadingDownload}
              />
            </p>

            <Link to={"/tasks/add-task"}>
              <SubmitButton loading={loading} title='اضافه کردن تسک جدید' />
            </Link>
          </div>

          {(!list || list.count === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}

          {list && list.count !== 0 && (
            <>
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  animateRows={true}
                  onCellClicked={(e) => {
                    if (e.colDef.field !== TasksTable.ACTIONS) copy(e.value);
                  }}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  rowSelection={"single"}
                  enableRtl={true}>
                  <AgGridColumn
                    sortable={true}
                    field={TasksTable.ROW}
                    width={84}
                  />
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={TasksTable.ACTIONS}
                    flex={1}
                    minWidth={320}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={TasksTable.TITLE}
                    minWidth={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={TasksTable.DESCRIPTION}
                    minWidth={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={TasksTable.DURATION}
                    minWidth={200}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={TasksTable.SIDE}
                    minWidth={130}
                    cellStyle={({ value }) => {
                      return value === BINARY_STATUS.YES
                        ? { color: "red" }
                        : { color: "green" };
                    }}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={TasksTable.USERID}
                    minWidth={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={TasksTable.ASSIGNEDTO}
                    minWidth={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={TasksTable.CREATEDAT}
                    minWidth={150}
                  />
                </AgGridReact>
              </div>
            </>
          )}
        </div>
        <ReactPaginate
          marginPagesDisplayed={isMobile ? 1 : 3}
          forcePage={currentPage}
          breakLabel='...'
          nextLabel='بعدی'
          onPageChange={(e) => {
            setCurrentPage(e.selected);
          }}
          pageRangeDisplayed={5}
          pageCount={
            (list && Math.ceil(list.total / defaultFaultTablePageCount)) ?? 1
          }
          previousLabel='قبلی'
          containerClassName='pagination-container'
        />
      </div>
      {!!addComment && (
        <AddCommentModalContent
          faultPhone={addComment.phone}
          isOpen={!!addComment}
          comments={
            addComment ? (addComment.comments as Array<TaskCommentType>) : []
          }
          onSuccess={(comment) => {
            (
              addCommentTask({
                taskId: addComment?._id ?? "",
                comment: { desc: comment }
              }) as unknown as Promise<void>
            ).then(() => {
              callApi();
              setAddComment(null);
            });
          }}
          onClose={() => {
            setAddComment(null);
          }}
        />
      )}
      {!!concludeTask && (
        <ConcludeTask
          isOpen={!!concludeTask}
          comments={
            concludeTask && Array.isArray(concludeTask.comments)
              ? concludeTask.comments
              : []
          }
          onSuccess={(comment: string) => {
            (
              concludeATask(
                concludeTask?._id ?? "",
                false,
                comment
              ) as unknown as Promise<void>
            ).then(() => {
              callApi();
              setConcludeTask(null);
            });
          }}
          onClose={() => {
            setConcludeTask(null);
          }}
        />
      )}
      {!!changeAssignment && (
        <ChangeAssignmentTask
          isOpen={!!changeAssignment}
          title='تغییر ارجاع تسک'
          onSuccess={(assignedTo) => {
            (
              changeAssignmentTask({
                taskId: changeAssignment!,
                assignedTo
              }) as unknown as Promise<void>
            ).then(() => {
              callApi();
              setChangeAssignment(null);
            });
          }}
          onCancel={() => {
            setChangeAssignment(null);
          }}
        />
      )}
      {imageItem && (
        <ImageWrapper
          albums={imageItem?.albums?.map((album) => {
            return {
              ...album,
              path:
                BASE_API +
                TASKS_ROOT_API +
                "/photo/" +
                imageItem._id +
                "?photoId=" +
                album.path
            };
          })}
          onDelete={(albumItemId) => {
            deleteTaskPhoto(albumItemId, imageItem?._id ?? "");
          }}
          onSuccess={(file) => {
            uploadTaskPhoto(file, {});
          }}
        />
      )}
    </>
  );
};

export default TasksList;
