import { MongoObject } from "../../BaseTypes";

export enum LineCardsTable {
  _ID = "_id",
  CARDNUMBER = "شماره کارت",
  COMMENT = "توضیح",
  INSTALLERS = "نصاب ها",
  SERIALNUMBER = "سریال",
  PROPERTYNUMBER = "اموال",
  CREATEDAT = "تاریخ ایجاد ",
  UPDATEDAT = "تاریخ اخرین تغییر",
  ACTIONS = "عملیات",
  ROW = "ردیف"
}

export interface LineCardSchema extends MongoObject {
  _id?: string;
  id?: string | null;
  shelfId?: string;
  cardNumber?: string;
  comment?: string;
  installers?: string;
  serialNumber?: string;
  propertyNumber?: string;
  createdAt?: Date;
  updatedAt?: Date;
}
export interface AddLineCard {
  shelfId?: string;
  cardNumber?: string;
  comment?: string;
  installers?: string;
  serialNumber?: string;
  propertyNumber?: string;
}
