import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { getCatch } from "../../catch";
import { customizedAxios } from "../../config";
import {
  AddSubmitSmsCategoryTypes,
  AddSubmitSmsSendTypes,
  AddSubmitSmsTypes,
  AdvancedResult,
  EditSmsCategoryTypes,
  EditSmsTypes,
  InputObject,
  NotificationType,
  SmsType
} from "../../types";
import { NotificationActionTypes } from "../actionTypes/NotificationActionTypes";
import { NOTIFICATION_ROOT_API, SMS_ROOT_API, USER_ROOT_API } from "../api";
import { store } from "../store";
import { NOTIFICATION_TYPES } from "../types";

export const subscribeWebPushNotification =
  (serviceWorkerReg: ServiceWorkerRegistration) =>
  async (dispatch: Dispatch<any>) => {
    let subscription;
    try {
      dispatch({ type: NOTIFICATION_TYPES.NOTIFICATION_LOADING });
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };
      subscription = await serviceWorkerReg.pushManager.getSubscription();
      if (subscription === null) {
        subscription = await serviceWorkerReg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey:
            process.env.REACT_APP_PUSH_NOTIFICATION_PUBLIC_KEY
        });
      }
      let url = NOTIFICATION_ROOT_API.concat("/subscribe");
      await customizedAxios.post(url, subscription, config);
      dispatch({
        type: NOTIFICATION_TYPES.WEBPUSH
      });
    } catch (error: any) {
      const { message } = getCatch(error);
      window.openToastError(message);
      subscription?.unsubscribe().then(() => {});
    } finally {
      dispatch({ type: NOTIFICATION_TYPES.DISABLE_LOADING });
    }
  };

export const addSmsPanelInfo =
  (body: { API_KEY: string; panelNumber: string }) =>
  async (dispatch: Dispatch<NotificationActionTypes>) => {
    try {
      dispatch({ type: NOTIFICATION_TYPES.NOTIFICATION_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = USER_ROOT_API;
      await customizedAxios.put(url, body, config);

      dispatch({
        type: NOTIFICATION_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("انجام شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: NOTIFICATION_TYPES.DISABLE_LOADING });
    }
  };

export const getNotificationSubscription =
  () => async (dispatch: Dispatch<NotificationActionTypes>) => {
    try {
      dispatch({ type: NOTIFICATION_TYPES.NOTIFICATION_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = NOTIFICATION_ROOT_API.concat("/subscribe");
      const res = await customizedAxios.get<InputObject<NotificationType>>(
        url,
        config
      );
      dispatch({
        type: NOTIFICATION_TYPES.NOTIFICATION,
        payload: res.data.data!
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: NOTIFICATION_TYPES.DISABLE_LOADING });
    }
  };

export const addSmsNotification =
  (body: { receiveSms: boolean }) =>
  async (dispatch: Dispatch<NotificationActionTypes>) => {
    try {
      dispatch({ type: NOTIFICATION_TYPES.NOTIFICATION_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = NOTIFICATION_ROOT_API.concat("/sms/subscribe");
      await customizedAxios.post(url, body, config);

      dispatch({
        type: NOTIFICATION_TYPES.SMS
      });
      window.openToastSuccess("انجام شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: NOTIFICATION_TYPES.DISABLE_LOADING });
    }
  };

export const fetchSms =
  () => async (dispatch: Dispatch<NotificationActionTypes>) => {
    try {
      dispatch({ type: NOTIFICATION_TYPES.NOTIFICATION_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = SMS_ROOT_API;
      const res = await customizedAxios.get<AdvancedResult<SmsType>>(
        url,
        config
      );
      dispatch({
        type: NOTIFICATION_TYPES.SMS_LISTS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: NOTIFICATION_TYPES.DISABLE_LOADING });
    }
  };

export const sendSms =
  (body: AddSubmitSmsSendTypes) =>
  async (dispatch: Dispatch<NotificationActionTypes>) => {
    try {
      dispatch({ type: NOTIFICATION_TYPES.NOTIFICATION_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = SMS_ROOT_API.concat("/sendSms");
      await customizedAxios.post(url, body, config);

      dispatch({
        type: NOTIFICATION_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" اس ام اس با موفقیت ارسال شد.");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: NOTIFICATION_TYPES.DISABLE_LOADING });
    }
  };

export const addSmsCategory =
  (body: AddSubmitSmsCategoryTypes) =>
  async (dispatch: Dispatch<NotificationActionTypes>) => {
    try {
      dispatch({ type: NOTIFICATION_TYPES.NOTIFICATION_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = SMS_ROOT_API;
      await customizedAxios.post(url, body, config);

      dispatch({
        type: NOTIFICATION_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" دسته بندی اس ام اس با موفقیت ایجاد شد.");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: NOTIFICATION_TYPES.DISABLE_LOADING });
    }
  };

export const addSms =
  (body: AddSubmitSmsTypes, smsId: string) =>
  async (dispatch: Dispatch<NotificationActionTypes>) => {
    try {
      dispatch({ type: NOTIFICATION_TYPES.NOTIFICATION_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = SMS_ROOT_API.concat(`/messages/${smsId}`);
      await customizedAxios.post(url, body, config);

      dispatch({
        type: NOTIFICATION_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" اس ام اس با موفقیت ایجاد شد.");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: NOTIFICATION_TYPES.DISABLE_LOADING });
    }
  };

export const editSmsCategory =
  (body: EditSmsCategoryTypes, id: string) =>
  async (dispatch: Dispatch<NotificationActionTypes>) => {
    try {
      dispatch({ type: NOTIFICATION_TYPES.NOTIFICATION_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = SMS_ROOT_API.concat("/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: NOTIFICATION_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("دسته بندی با موفقیت ویرایش شد");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: NOTIFICATION_TYPES.DISABLE_LOADING });
    }
  };

export const deleteSmsCategory =
  (smsCategoryId: string) =>
  async (dispatch: Dispatch<NotificationActionTypes>) => {
    try {
      dispatch({ type: NOTIFICATION_TYPES.NOTIFICATION_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };
      let url = SMS_ROOT_API.concat("/" + smsCategoryId);
      await customizedAxios.delete(url, config);
      dispatch({
        type: NOTIFICATION_TYPES.NOTIFICATION_DELETE,
        payload: { smsCategoryId }
      });
      window.openToastSuccess("دسته بندی با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: NOTIFICATION_TYPES.DISABLE_LOADING });
    }
  };

export const editSms =
  (body: EditSmsTypes, id: string) =>
  async (dispatch: Dispatch<NotificationActionTypes>) => {
    try {
      dispatch({ type: NOTIFICATION_TYPES.NOTIFICATION_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = SMS_ROOT_API.concat("/messages/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: NOTIFICATION_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("اس ام اس با موفقیت ویرایش شد");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: NOTIFICATION_TYPES.DISABLE_LOADING });
    }
  };

export const deleteSms =
  (smsId: string, categoryId: string) =>
  async (dispatch: Dispatch<NotificationActionTypes>) => {
    try {
      dispatch({ type: NOTIFICATION_TYPES.NOTIFICATION_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        data: {
          id: smsId
        }
      };
      let url = SMS_ROOT_API.concat("/messages/" + categoryId);
      await customizedAxios.delete(url, config);
      dispatch({
        type: NOTIFICATION_TYPES.SMS_DELETE,
        payload: { smsId, categoryId }
      });
      window.openToastSuccess("اس ام اس با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: NOTIFICATION_TYPES.DISABLE_LOADING });
    }
  };
