import { ClientType, MongoObject } from ".";

export enum CitiesTable {
  _ID = "_id",
  PROVINCEID = "provinceId",
  CITY = "نام شهر",
  PROVINCE = " استان",
  TELECOMS = "تعداد مراکز",
  CREATEDAT = "تاریخ ایجاد",
  UPDATEDAT = "تاریخ اخرین تغییر",
  ACTIONS = "عملیات",
  ROW = "ردیف"
}

export interface CitiesSchema {
  referedId: string | ClientType;
  used: boolean;
  usedDate?: Date;
  amount?: number;
}

export interface CitiesType extends MongoObject {
  city?: string;
  name: string;
  provinceId?: string;
  telecoms?: number;
  createdAt?: Date;
  updatedAt?: Date;
}
export interface CityProvinceType extends MongoObject {
  name: unknown;
  _id?: string;
  item?: any;
  provinceId: string;
}

export interface AddCity {
  name: string;
  provinceId?: string;
}
