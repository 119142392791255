import { DrawerActionTypes } from "../actionTypes/DrawerActionTypes";
import { DRAWER_TYPES } from "../types";

const INITIAL_STATE = {
  dataCenterOpen: false,
  faultsOpen: false,
  owaOpen: false,
  papOpen: false,
  taskOpen: false,
  telecomOpen: false
};

const drawerReducer = (state = INITIAL_STATE, action: DrawerActionTypes) => {
  const { type } = action;
  switch (type) {
    case DRAWER_TYPES.DRAWER_DATACENTER:
      return {
        ...INITIAL_STATE,
        dataCenterOpen: !state.dataCenterOpen
      };

    case DRAWER_TYPES.DRAWER_FAULTS:
      return {
        ...INITIAL_STATE,
        faultsOpen: !state.faultsOpen
      };
    case DRAWER_TYPES.DRAWER_CLOSE_ALL:
      return { ...INITIAL_STATE };

    case DRAWER_TYPES.DRAWER_OWAS:
      return { ...INITIAL_STATE, owaOpen: !state.owaOpen };

    case DRAWER_TYPES.DRAWER_PAP:
      return { ...INITIAL_STATE, papOpen: !state.papOpen };

    case DRAWER_TYPES.DRAWER_TASK:
      return { ...INITIAL_STATE, taskOpen: !state.taskOpen };

    case DRAWER_TYPES.DRAWER_TELECOM:
      return {
        ...INITIAL_STATE,
        telecomOpen: !state.telecomOpen
      };

    default:
      return state;
  }
};

export default drawerReducer;
