import { useFormik } from "formik";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { SmsCategoryTypes } from "../../../../core/types";

const EditCategory = () => {
  const { editSmsCategory } = useActions();

  let [searchParams] = useSearchParams();

  const _id = searchParams.get("_id");

  const { loading, list } = useTypedSelector((state) => state.notification);

  const getDefaultCategory = useCallback(() => {
    if (list?.data?.length !== 0) {
      const found = list?.data?.find((categoryEl) => {
        return categoryEl._id?.toString() === _id;
      });

      if (found) {
        return found?.category;
      }
      return "";
    }
    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list?.data, _id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      category: getDefaultCategory() || ""
    },
    validationSchema: yup.object({
      category: yup.string().required("  عنوان دسته بندی الزامی است")
    }),
    onSubmit: ({ category }: SmsCategoryTypes) => {
      editSmsCategory(
        {
          category
        },
        _id ?? ""
      );
    }
  });

  const { category } = formik.values;
  const { category: categoryError } = formik.errors;
  const { category: categoryTouched } = formik.touched;

  return (
    <div className='add-item-wrapper'>
      <div className='list-top'>
        <p> ویرایش دسته ی جدید</p>
      </div>
      <form autoComplete='on' onSubmit={formik.handleSubmit}>
        <Input
          name='category'
          title='عنوان دسته بندی'
          autoComplete='off'
          error={categoryTouched && categoryError ? categoryError : undefined}
          value={category}
          onChange={formik.handleChange}
        />

        <SubmitButton
          title='ویرایش'
          loading={loading}
          disabled={loading}
          type='submit'
        />
      </form>
    </div>
  );
};

export default EditCategory;
