import React, { Dispatch, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { UserPhotoLoader } from "../../../core/components";
import { useTypedSelector } from "../../../core/hooks";
import { AUTH_TYPES } from "../../../core/redux";
interface DrawerTopType {
  openDrawer?: Dispatch<SetStateAction<boolean>>;
}

const DrawerTop: React.FC<DrawerTopType> = () => {
  const { me } = useTypedSelector((state) => state.auth);
  const dispatch = useDispatch();

  return (
    <>
      <div className='drawer-wrapper__top'>
        <NavLink to='/profile'>
          <div className='drawer-wrapper__top-left'>
            <UserPhotoLoader _id={me?._id ?? ""} photo={me?.photo} />
            <div>
              {me && <h5>{me.fullName}</h5>}
              {me && <h6>{me.mobileNumber}</h6>}
            </div>
          </div>
        </NavLink>
        <div className='drawer-wrapper__top-right'>
          <i
            onClick={() => {
              dispatch({
                type: AUTH_TYPES.LOGOUT
              });
            }}
            className='fas fa-sign-out-alt exit-icon'
          />
        </div>
      </div>
    </>
  );
};

export default DrawerTop;
