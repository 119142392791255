import { useFormik } from "formik";
import { memo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";

import {
  FaultFileReader,
  Input,
  SubmitButton
} from "../../../../core/components";
import { FILE_SIZES, FILE_TYPE } from "../../../../core/constants";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { ConcludeNeedExpertType } from "../../../../core/types";

const _RenderPDF = ({ file }: { file?: Blob | MediaSource }) => {
  if (file) {
    return <embed src={URL.createObjectURL(file)} width='100%' height={500} />;
  }
  return null;
};
const RenderPDF = memo(_RenderPDF);

const ConcludeNeedExpert = () => {
  const { concludeNeedExpert, getFault } = useActions();
  let [searchParams] = useSearchParams();
  const faultId = searchParams.get("faultId");
  const { loading, item } = useTypedSelector((state) => state.fault);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: 0,
      priceList: "",
      file: undefined,
      comment: ""
    },
    validationSchema: yup.object({
      comment: yup.string(),
      price: yup.string().trim().required("هزینه حضوری الزامی است"),
      priceList: yup
        .string()
        .trim()
        .required("لیست کاره های انجام شده الزامی است"),
      file: yup
        .mixed()
        .test(
          "fileSize",
          "اندازه فایل بیشتر از مقدار مورد نظر است",
          (value) => {
            if (!value || !value.length) return true;
            return value[0].size <= FILE_SIZES.FAULT_PDF_SIZE;
          }
        )
    }),
    onSubmit: ({
      price,
      priceList,
      comment
    }: ConcludeNeedExpertType & { file?: File }) => {
      if (!file) {
        return window.openToastError("فایل الزامی می باشد");
      }
      concludeNeedExpert(file, { _id: item?._id, price, priceList, comment });
    }
  });

  useEffect(() => {
    if (faultId) {
      getFault(faultId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faultId]);

  const { price, priceList, file, comment } = formik.values;
  const {
    price: priceError,
    priceList: priceListError,
    comment: commentError
  } = formik.errors;
  const {
    price: priceTouched,
    priceList: priceListTouched,
    comment: commentTouched
  } = formik.touched;

  const { handleChange } = formik;

  return (
    <>
      <FaultFileReader
        description='فرم حضوری را آپلود نمایید'
        accept={FILE_TYPE.PDF}
        onSuccess={(file) => {
          formik.setFieldValue("file", file, true);
        }}
      />
      {item && (
        <div className='add-item-wrapper'>
          <h5 className='title'>اتمام حضوری {item.phone}</h5>
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <Input
              name='price'
              title='هزینه : '
              autoComplete='off'
              error={priceTouched && priceError ? priceError : undefined}
              value={price}
              onChange={handleChange}
              type='number'
            />

            <Input
              name='priceList'
              title='لیست هزینه ها : '
              autoComplete='off'
              error={
                priceListTouched && priceListError ? priceListError : undefined
              }
              value={priceList}
              onChange={handleChange}
            />
            <Input
              placeholder=''
              name='comment'
              title='توضیحات  : '
              multiline
              autoComplete='off'
              error={commentTouched && commentError ? commentError : undefined}
              value={comment}
              onChange={formik.handleChange}
            />

            <SubmitButton
              title='ثبت حضوری'
              loading={loading}
              disabled={loading}
              type='submit'
            />
            <div className='show_upload_photo'>
              <RenderPDF file={file} />
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default ConcludeNeedExpert;
