import React, { memo, useEffect, useMemo } from "react";
import ReactModal from "react-modal";
import { AuthGuide } from ".";
import { USER_ACCESS } from "../constants";
import { useActions, useTypedSelector } from "../hooks";
import { CRUD_TYPES, ProvincesType, UserType } from "../types";

interface AuthModalByProvinceTypes {
  user: UserType;
  access: USER_ACCESS;
  onClose?: () => any;
  isOpen: boolean;
}

const _AuthModalByProvince: React.FC<AuthModalByProvinceTypes> = ({
  onClose,
  isOpen,
  access,
  user,
  ...rest
}) => {
  const { fetchUserProvinces } = useActions();
  const { provinceList } = useTypedSelector((state) => state.general);
  useEffect(() => {
    fetchUserProvinces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getParentProvincesAndCrudAccess: [ProvincesType[], CRUD_TYPES[][]] =
    useMemo(() => {
      if (user && user.access) {
        const selectedSection = user.access.find((acc) => {
          return acc.section === access;
        });
        const userProvinces = selectedSection?.province;
        const filteredProvinces =
          provinceList?.data?.filter((province) => {
            return userProvinces?.find((parentProvince) => {
              return province._id === parentProvince.provinceId;
            });
          }) ?? [];
        const crudAccess =
          Array.from(filteredProvinces, (province) => {
            return (
              userProvinces?.find((parentProvince) => {
                return parentProvince.provinceId === province._id;
              })?.crud ?? []
            );
          }) ?? [];

        return [filteredProvinces, crudAccess];
      }
      return [[], []];
    }, [user, access, provinceList]);

  if (!provinceList) {
    return null;
  }
  const [filteredProvinceList, filteredCrudList] =
    getParentProvincesAndCrudAccess;

  return (
    <>
      <ReactModal
        style={{
          content: {
            maxWidth: 500,
            maxHeight: 500,
            margin: "auto"
          }
        }}
        {...rest}
        isOpen={isOpen}
        onRequestClose={onClose}
        ariaHideApp={false}>
        <div className='auth-modal'>
          <h5 className='title'>
            لیست دسترسی های {access} کاربر {user.fullName}
          </h5>{" "}
          <AuthGuide />
          <div className='auth-access'>
            <div>
              <i className='fas fa-user-lock question-icon'></i>{" "}
              <span>دسترسی</span>
            </div>
            <div>
              <div>
                {filteredProvinceList.map((province, idx) => {
                  return (
                    <div key={province._id}>
                      <span className='chip'>
                        {" "}
                        {province.name}{" "}
                        {filteredCrudList[idx]?.includes(CRUD_TYPES.WRITE) && (
                          <i className='fas fa-plus-circle add-icon'></i>
                        )}
                        {filteredCrudList[idx]?.includes(CRUD_TYPES.EDIT) && (
                          <i
                            title='ویرایش'
                            className='fas fa-pen edit-icon'></i>
                        )}
                        {filteredCrudList[idx]?.includes(CRUD_TYPES.DELETE) && (
                          <i
                            title='حذف'
                            className='fas fa-trash delete-icon'></i>
                        )}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* {children} */}
      </ReactModal>
    </>
  );
};

const AuthModalByProvince = memo(_AuthModalByProvince);

export { AuthModalByProvince };
