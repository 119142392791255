import { AdvancedResult, CitiesType, CityProvinceType } from "../../types";
import { CitiesActionTypes } from "../actionTypes";
import { CITY_TYPES } from "../types";

interface StateType {
  list: AdvancedResult<CitiesType> | null;
  item: CitiesType | null;
  loading: boolean;
  provincesLoading: boolean;
  provinces: AdvancedResult<CityProvinceType> | null;
}

const initialState: StateType = {
  list: null,
  item: null,
  loading: false,
  provincesLoading: false,
  provinces: null
};

const reducer = (
  state: StateType = initialState,
  action: CitiesActionTypes
): StateType => {
  switch (action.type) {
    case CITY_TYPES.CITY_LISTS:
      return {
        ...state,
        list: action.payload,
        loading: false,
        item: null
      };

    case CITY_TYPES.CITY:
      return {
        ...state,
        item: action.payload,
        loading: false
      };

    case CITY_TYPES.CITY_LOADING:
      return {
        ...state,
        loading: true
      };

    case CITY_TYPES.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };
    case CITY_TYPES.CITY_PROVINCES_LOADING:
      return {
        ...state,
        provincesLoading: true
      };
    case CITY_TYPES.CITY_PROVINCES_LISTS:
      return {
        ...state,
        provinces: action.payload
      };
    case CITY_TYPES.DISABLE_PROVINCES_LOADING:
      return {
        ...state,
        provincesLoading: false
      };

    default:
      return state;
  }
};

export default reducer;
