import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { PHONE_BOOK_TYPES, store, PHONEbOOKS_ROOT_API } from "..";
import { customizedAxios } from "../../config";
import { getCatch } from "../../catch";
import {
  AdvancedResult,
  QueryParams,
  PhoneBookProvinceType,
  InputObject,
  AddPhoneBook,
  PhoneBookType
} from "../../types";
import { PhoneBookActionTypes } from "../actionTypes";
import { DeleteType } from "../../types";

export const fetchPhoneBooks =
  (query: QueryParams) => async (dispatch: Dispatch<PhoneBookActionTypes>) => {
    try {
      dispatch({ type: PHONE_BOOK_TYPES.PHONE_BOOK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: query
      };

      let url = PHONEbOOKS_ROOT_API;
      const res = await customizedAxios.get<AdvancedResult<PhoneBookType>>(
        url,
        config
      );
      dispatch({
        type: PHONE_BOOK_TYPES.PHONE_BOOK_LISTS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PHONE_BOOK_TYPES.DISABLE_LOADING });
    }
  };
export const addPhoneBook =
  (body: AddPhoneBook) => async (dispatch: Dispatch<PhoneBookActionTypes>) => {
    try {
      dispatch({ type: PHONE_BOOK_TYPES.PHONE_BOOK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PHONEbOOKS_ROOT_API;
      await customizedAxios.post(url, body, config);

      dispatch({
        type: PHONE_BOOK_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" مخاطب با موفقیت افزوده شد.");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PHONE_BOOK_TYPES.DISABLE_LOADING });
    }
  };

export const getPhoneBookProvinces =
  () => async (dispatch: Dispatch<PhoneBookActionTypes>) => {
    try {
      dispatch({ type: PHONE_BOOK_TYPES.PHONE_BOOK_PROVINCES_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PHONEbOOKS_ROOT_API.concat("/provinces");
      const res = await customizedAxios.get<
        AdvancedResult<PhoneBookProvinceType>
      >(url, config);

      dispatch({
        type: PHONE_BOOK_TYPES.PHONE_BOOK_PROVINCES_LISTS,
        payload: res.data
      });
      dispatch({
        type: PHONE_BOOK_TYPES.DISABLE_PROVINCES_LOADING
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
      dispatch({ type: PHONE_BOOK_TYPES.DISABLE_PROVINCES_LOADING });
    }
  };

export const editPhoneBook =
  (body: PhoneBookType, id: string) =>
  async (dispatch: Dispatch<PhoneBookActionTypes>) => {
    try {
      dispatch({ type: PHONE_BOOK_TYPES.PHONE_BOOK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PHONEbOOKS_ROOT_API.concat("/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: PHONE_BOOK_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("مخاطب با موفقیت ویرایش شد");
      window.navigate("/phone-books");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PHONE_BOOK_TYPES.DISABLE_LOADING });
    }
  };
export const fetchOnePhoneBook =
  (id: string) => async (dispatch: Dispatch<PhoneBookActionTypes>) => {
    try {
      dispatch({ type: PHONE_BOOK_TYPES.PHONE_BOOK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PHONEbOOKS_ROOT_API.concat("/" + id);
      const res = await customizedAxios.get<InputObject<PhoneBookType>>(
        url,
        config
      );
      dispatch({
        type: PHONE_BOOK_TYPES.PHONE_BOOK,
        payload: res.data.data!
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PHONE_BOOK_TYPES.DISABLE_LOADING });
    }
  };

export const deletePhoneBook =
  (id: string) => async (dispatch: Dispatch<PhoneBookActionTypes>) => {
    try {
      dispatch({ type: PHONE_BOOK_TYPES.PHONE_BOOK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PHONEbOOKS_ROOT_API.concat("/" + id);
      await customizedAxios.delete<DeleteType<PhoneBookType>>(url, config);

      dispatch({
        type: PHONE_BOOK_TYPES.PHONE_BOOK_DELETE,
        payload: { phoneBookId: id }
      });
      window.openToastSuccess("مخاطب با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PHONE_BOOK_TYPES.DISABLE_LOADING });
    }
  };
