import { MongoObject } from ".";

export enum PhoneBookTable {
  _ID = "_id",
  NAME = "نام مخاطب",
  PHONE = " شماره",
  CATEGORY = "دسته",
  PROVINCE = "استان",
  CREATEDAT = "تاریخ ایجاد",
  UPDATEDAT = "تاریخ اخرین تغییر",
  ACTIONS = "عملیات",
  PROVINCEID = "provinceId",
  ROW = "ردیف"
}

export interface PhoneBookType extends MongoObject {
  name: string;
  phone: string;
  category: string;
  province?: string;
  centers?: number;
  provinceId: string;
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
}
export interface PhoneBookProvinceType extends MongoObject {
  name: unknown;
  _id?: string;
  item?: any;
  provinceId: string;
}

export interface AddPhoneBook {
  name: string;
  phone: string;
  category: string;
  provinceId?: string;
}
