import { AdvancedResult, FaultStaticUserType } from "../../../../../core/types";

interface Props {
  list: AdvancedResult<FaultStaticUserType> | null;
}

export const StatisticsUserList = ({ list }: Props) => {
  return (
    <>
      {list?.data.map((staticItem, index) => {
        return (
          <div className='user-statistic-wrapper'>
            <div className={`static-item`} key={index}>
              {staticItem?.user.fullName}
              <span>{staticItem.count}</span>
            </div>
            {staticItem.telecoms.map((telecomItem) => (
              <p className='static-item'>{telecomItem.name}</p>
            ))}
          </div>
        );
      })}
    </>
  );
};
