import moment from "jalali-moment";
import { useEffect, useRef } from "react";
import DatePicker from "react-datepicker2";
import { Link, useSearchParams } from "react-router-dom";
import Select from "react-select";
import SignatureCanvas from "react-signature-canvas";
import { Input } from "../../../../core/components";
import { useActions, useTypedSelector } from "src/core/hooks";
import { UserType } from "src/core/types";

const products = [
  { label: "...", value: "1" },
  { label: "مودم", value: "1" },
  { label: "اسپلیتر", value: "2" },
  { label: "دو شاخه تلفن", value: "3" },
  { label: "سیم تلفن", value: "4" },
  { label: "رفع مشکل داخلی", value: "5" },
  { label: "سیم کشی", value: "6" },
  { label: "پیدا کردن سرخط", value: "7" },
  { label: "آنتی ویروس", value: "8" },
  { label: "رفع مشکل سرخط", value: "9" },
  { label: "کابل شبکه", value: "9" },
  { label: "تجهیزات سخت افزاری", value: "9" }
];

const issues = [
  { label: "نصب و راه اندازی اولیه", value: "1" },
  { label: "تنظیمات مودم", value: "2" },
  { label: "تنظیمات وایرلس / قطعی و وصلی وایرلس", value: "3" },
  { label: "قطعی کامل لینک", value: "4" },
  { label: "قطعی کامل اینترنت (ارورها)", value: "5" },
  { label: "قطعی و وصلی ( لینک و اینترنت - بد آپ )", value: "6" },
  {
    label: "کندی (صفحه باز کردن-دانلود-پینگ بالا-پکت لاست-صفحات اجتماعی)",
    value: "7"
  },
  { label: "ابهام ترافیک - مغایرت مصرف", value: "8" },
  { label: "پیدا کردن سرخط", value: "9" },
  { label: "سیستم تست", value: "10" },
  { label: "عدم باز شدن سایت", value: "11" },
  { label: "پینگ static ip", value: "12" }
];

const actions = [
  { label: "کانفیگ", value: "1" },
  { label: "ریست", value: "2" },
  { label: "تنظیمات وایرلس", value: "3" },
  { label: "بررسی داخلی", value: "4" },
  { label: "بررسی سرخط", value: "5" },
  { label: "پیداکردن سر خط", value: "6" },
  { label: "بریج", value: "7" },
  { label: "شارژ موقت", value: "8" },
  { label: "تماس با 1818", value: "9" },
  { label: "تغییر اسم", value: "10" },
  { label: "تغییر پسورد", value: "11" },
  { label: "آپدیت مودم", value: "12" },
  { label: "مودم تست", value: "13" },
  { label: "سیستم تست", value: "14" },
  { label: "بررسی سیستم", value: "15" },
  { label: "mac filtering", value: "16" },
  { label: "غیر فعال کردن wps", value: "17" },
  { label: "مخفی کردن ssid", value: "18" },
  { label: "تغییر channel", value: "19" },
  { label: "بررسی transmitpower", value: "20" },
  { label: "بررسی country", value: "21" },
  { label: "پینگ", value: "22" },
  { label: "تست سرعت از سایت test.asiatech.ir", value: "23" },
  { label: "بررسی صفحه بازکردن", value: "24" },
  { label: "trace مقصد", value: "25" },
  { label: "نصب fing", value: "26" },
  { label: "نصب networx-dumeter", value: "27" },
  { label: "تست دانلود", value: "28" },
  { label: "بررسی ریز مصرف 1544", value: "29" },
  { label: "بررسی سایت دیگر", value: "30" },
  { label: "فعال کردن icmp", value: "31" },
  { label: "غیر فعال کردن acl", value: "32" },
  { label: "غیر فعال کردن فایروال مودم", value: "33" },
  { label: "غیر فعال کردن فایروال و آنتی ویروس سیستم", value: "34" }
];

const DownloadForm = () => {
  const { getFault } = useActions();
  const { loading, item } = useTypedSelector((state) => state.fault);

  const sigCanvasAgent = useRef<SignatureCanvas>(null);
  const sigCanvasCustomer = useRef<SignatureCanvas>(null);

  let [searchParams] = useSearchParams();
  const faultId = searchParams.get("_id");

  useEffect(() => {
    if (faultId) {
      getFault(faultId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faultId]);

  const formRef = useRef<HTMLDivElement>(null);

  const downloadPDF = () => {
    window.print();
  };

  if (loading) return <div>Loading...</div>;
  const jalaliDate = item?.createdAt
    ? moment(item.createdAt, "YYYY-MM-DDTHH:mm:ss.SSSZ").locale("fa")
    : "";
  return (
    <>
      <div className='download-form-wrapper' ref={formRef}>
        <div className='title-wrapper'>
          <Link to={"#"}>
            <img width={84} src='/asiatech-logo.png' alt='لوگو آسیاتک' />
          </Link>
          <h5 className='title'> صورتجلسه تحویل سرویس وایرد</h5>
          <span className='date'>
            <span>{new Date().toLocaleDateString("fa-IR")}</span>
            <br />
            کد فرم:SS-FO-27/05
          </span>
        </div>

        <div className='form'>
          <div className='form-item'>
            <label className='input-label'>تاریخ مراجعه</label>
            <DatePicker
              isGregorian={false}
              timePicker={true}
              // value={moment(new Date(item?.createdAt ?? ""))}
              //value={moment(item?.createdAt ? item.createdAt.toString() : "")}
              value={jalaliDate ? jalaliDate : null}
            />
          </div>
          <Input
            name='phone'
            title=' شماره تسک '
            autoComplete='off'
            value={item?.uuid}
          />
          <Input
            title='نام کاربری'
            name='busyRow'
            autoComplete='off'
            value={item?.needExpert?.username}
          />{" "}
          <Input
            title='همراه مشترک'
            name='busyFloor'
            type={"number"}
            value={item?.needExpert?.mobileNumber}
          />{" "}
          <Input
            title='نام مشترک'
            name='busyPort'
            autoComplete='off'
            value={item?.needExpert?.name}
          />
          <Input name='uuid' title='	تست طول خط  ' autoComplete='off' />
          <Input
            name='portUuid'
            title='سرویس مشترک'
            autoComplete='off'
            value={item?.portUuid}
          />
          <Input
            title='آدرس'
            name='panel'
            autoComplete='off'
            value={item?.needExpert?.address}
          />
          <hr />
          <p className='title-hint'>این قسمت توسط کارشناس تکمیل می گردد</p>
          <Input
            title='نام کارشناس'
            name='telecomBusyRow'
            autoComplete='off'
            value={(item?.assignedTo as unknown as UserType)?.fullName}
          />
          <Input
            title='pppoe password'
            name='telecomBusyFloor'
            autoComplete='off'
          />
          <Input
            title='pppoe username'
            name='telecomBusyPort'
            autoComplete='off'
          />
          <Input
            title='wireless ssid'
            name='telecomBusyPort'
            autoComplete='off'
          />
          <hr />
          <div className='form-item'>
            <label className='input-label'> نوع ارجاع</label>
            <Select
              placeholder='نوع ارجاع'
              isClearable
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={issues}
              isMulti
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
          </div>
          <div className='form-item'>
            <label className='input-label'> موارد بررسی شده</label>
            <Select
              placeholder='موارد بررسی شده '
              isClearable
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={actions}
              isMulti
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
          </div>
          <hr />
          <div className='modem-check-title'>
            مودم ADSL به شماره سریال
            <input name='username' autoComplete='off' />
            مدل
            <input name='password' autoComplete='off' />
            تست و تحویل مشترک گردید.
          </div>
          <div className='checkbox-wrapper'>
            <input type='checkbox' name='urgent' />
            <label> نیاز به بررسی مرکز</label>
          </div>
          <div className='form-item full-width'>
            <label>امضا کارشناس:</label>
            <SignatureCanvas
              ref={sigCanvasAgent}
              penColor='black'
              canvasProps={{
                className: "sigCanvas"
              }}
            />
            <button
              type='button'
              onClick={() => sigCanvasAgent.current?.clear()}>
              Clear
            </button>
          </div>
          <hr />
          <p className='title-hint'>کیفیت داخلی</p>
          <Input title='Download ' name='name' autoComplete='off' />
          <Input
            title='snr	 '
            name='mobileNumber'
            autoComplete='off'
            type='tel'
          />
          <Input title=' Upload	 ' name='name' autoComplete='off' />
          <Input
            title='snr	 '
            name='mobileNumber'
            autoComplete='off'
            type='tel'
          />
          <p className='title-hint'> کیفیت سر خط</p>
          <Input title='Download ' name='name' autoComplete='off' />
          <Input
            title='snr	 '
            name='mobileNumber'
            autoComplete='off'
            type='tel'
          />
          <Input title=' Upload	 ' name='name' autoComplete='off' />
          <Input
            title='snr	 '
            name='mobileNumber'
            autoComplete='off'
            type='tel'
          />
          <hr />
          <p className='title-hint'> حد پذیرش کیفیت</p>
          <div>{`Download => 2048 (kbs)`}</div>
          <div>{`Upload => 512 (kbs)`}</div>
          <div>{`SNR => 6(db)`}</div>
          <div>{`Upload => 15(db)`}</div>
          <hr />
          <p className='title-hint'> این قسمت توسط مشترک تکمیل می گردد </p>
          <div className='checkbox-wrapper'>
            <input type='checkbox' name='urgent' />
            <label> اقدامات انجام شده مورد تایید اینجانب می باشد</label>
          </div>
          <div className='checkbox-wrapper'>
            <input type='checkbox' name='urgent' />
            <label>اینترنت تست شده و بصورت کامل تحویل اینجانب گردید.</label>
          </div>
          <hr />
          <p className='title-hint'>توضیحات </p>
          <p>
            توجه 1 مطابق جدول فوق در صورتیکه پارامترهای فنی سرویس کمتر از حد
            پذیرش باشد.سرویس فقط با تایید و رضایت مشترک تحویل می گردد.و امضای
            این صورتجلسه توسط مشترک به منزله قبول و تایید تمامی موارد کمتر از حد
            پذیرش می باشد.
          </p>
          <Input title='نام تحویل گیرنده' name='address' autoComplete='off' />
          <Input
            title=' توضیحات'
            name='comments'
            autoComplete='off'
            multiline
          />
          <div className='form-item full-width'>
            <label> امضا مشترک</label>
            <SignatureCanvas
              ref={sigCanvasCustomer}
              penColor='black'
              canvasProps={{
                className: "sigCanvas"
              }}
            />
            <button
              type='button'
              onClick={() => sigCanvasCustomer.current?.clear()}>
              Clear
            </button>
          </div>
          <hr />
          <p className='title-hint'>لیست کالا و خدمات مجاز</p>
          <div className='input-items-wrapper'>
            <div className='item'>
              <div></div>
              <div></div>
              <div>مبلغ (تومان)</div>
              <div></div>
            </div>
            <div className='item'>
              <div></div>
              <div></div>
              <div>مبلغ (تومان)</div>
              <div></div>
            </div>

            <div className='item'>
              <span>1</span>
              ایاب و ذهاب:
              <Input name='address' autoComplete='off' />
            </div>
            <div className='item'>
              <span>2</span>
              کارشناسی ( تحویل سرویس ):
              <Input name='address' autoComplete='off' />
            </div>
            <div className='item'>
              <span>3</span>
              <Select
                className='input-wrapper'
                placeholder='انتخاب کالا'
                isClearable
                openMenuOnClick={true}
                classNamePrefix='react-select'
                options={products}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              <Input name='address' autoComplete='off' />
            </div>
            <div className='item'>
              <span>4</span>
              <Select
                className='input-wrapper'
                isClearable
                placeholder='انتخاب کالا'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                options={products}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              <Input name='address' autoComplete='off' />
            </div>
            <div className='item'>
              <span>5</span>
              <Select
                className='input-wrapper'
                isClearable
                placeholder='انتخاب کالا'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                options={products}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              <Input name='address' autoComplete='off' />
            </div>
            <div className='item'>
              <span>6</span>
              <Select
                className='input-wrapper'
                isClearable
                placeholder='انتخاب کالا'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                options={products}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              <Input name='address' autoComplete='off' />
            </div>
            <div className='item'>
              <span>7</span>
              <Select
                className='input-wrapper'
                isClearable
                placeholder='انتخاب کالا'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                options={products}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              <Input name='address' autoComplete='off' />
            </div>
            <div className='item'>
              <span>8</span>
              <Select
                className='input-wrapper'
                isClearable
                placeholder='انتخاب کالا'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                options={products}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              <Input name='address' autoComplete='off' />
            </div>
            <div className='item'>
              <span>9</span>
              <Select
                className='input-wrapper'
                isClearable
                placeholder='انتخاب کالا'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                options={products}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              <Input name='address' autoComplete='off' />
            </div>
            <div className='item'>
              <span>10</span>
              <Select
                className='input-wrapper'
                isClearable
                placeholder='انتخاب کالا'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                options={products}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              <Input name='address' autoComplete='off' />
            </div>
            <div className='item'>
              <span>11</span>
              <Select
                className='input-wrapper'
                isClearable
                placeholder='انتخاب کالا'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                options={products}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              <Input name='address' autoComplete='off' />
            </div>
            <div className='item'>
              <span>12</span>
              <Select
                className='input-wrapper'
                isClearable
                placeholder='انتخاب کالا'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                options={products}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              <Input name='address' autoComplete='off' />
            </div>
            <div className='item'>
              <span>13</span>
              ایاب و ذهاب
              <Input name='address' autoComplete='off' />
            </div>
            <div className='item'>
              <span>14</span>
              ایاب و ذهاب
              <Input name='address' autoComplete='off' />
            </div>
          </div>
          <p className='title-hint'>
            از بند ۳ الی 14 به درخواست مشترک انجام میگردد
          </p>
          <Input
            title='جمع کل هزینه دریافت شده از مشترک:'
            name='address'
            autoComplete='off'
          />
          <Input title='توضیحات' />
          <div className='button-wrapper'>
            <button onClick={downloadPDF}>
              <i className={`fas fa-file-pdf pdf-icon`} />
              دانلود PDF
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadForm;
