import { useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  DeleteModalContent,
  SubmitButton,
  Table
} from "../../../../core/components";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import {
  NotificationsTable,
  SmsMessageType,
  SmsType
} from "../../../../core/types";
import SendSms from "./send-sms";
import { Column } from "react-table";
import SmsLogsList from "./sms-logs-list";

const SmsList = () => {
  const navigator = useNavigate();

  const { deleteSms } = useActions();
  const { loading } = useTypedSelector((state) => state.notification);

  const [selectedCategory, setSelectedCategory] = useState<SmsType>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [copiedMessage, setCopiedMessage] = useState<string>("");

  const COLUMNS: Column[] = useMemo(
    () => [
      {
        Header: NotificationsTable.ROW,
        Cell: ({ row: { index } }) => {
          return <>{index + 1}</>;
        }
      },
      {
        Header: NotificationsTable.DESCRIPTION,
        accessor: "message"
      },
      {
        Header: NotificationsTable.ACTION,
        accessor: "_id",
        Cell: ({ value, row }) => {
          return (
            <>
              <i
                onClick={() =>
                  navigator(
                    `/sms/edit-sms?_id=${value}&categoryId=${selectedCategory?._id}`
                  )
                }
                title='ویرایش'
                className='fas fa-pen edit-icon  '></i>

              <i
                onClick={() => {
                  setSelectedItem(value);
                  setIsOpen(true);
                }}
                title='حذف'
                className='fas fa-trash delete-icon'></i>
              <i
                onClick={() => {
                  setCopiedMessage((row.original as SmsMessageType).message);
                }}
                title='کپی'
                className='fas fa-copy add-icon'></i>
            </>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCategory?._id]
  );

  const onSuccessDeleteModal = useCallback(() => {
    deleteSms(selectedItem as string, selectedCategory?._id as string);
    setIsOpen(false);
    setSelectedItem(null);
  }, [selectedItem, deleteSms, selectedCategory?._id]);

  return (
    <>
      {isOpen && (
        <DeleteModalContent
          isOpen={isOpen}
          title={"حذف اس ام اس  "}
          message='آیا از حذف اس ام اس مطمئن هستید؟'
          onCancel={() => {
            setIsOpen(false);
            setSelectedItem(null);
          }}
          onSuccess={onSuccessDeleteModal}
        />
      )}
      <SendSms
        copiedMessage={copiedMessage}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />

      {!!selectedCategory && (
        <div className='main-route-wrapper'>
          <div className='table-wrapper'>
            <div className='list-top'>
              <p>اس ام اس های دسته فروش</p>

              <Link to={`/sms/add-sms?smsId=${selectedCategory?._id}`}>
                <SubmitButton
                  loading={loading}
                  title='اضافه کردن اس ام اس جدید'
                />
              </Link>
            </div>

            <Table
              columns={COLUMNS as any}
              data={selectedCategory?.messages ?? []}
            />
          </div>
        </div>
      )}

      <SmsLogsList />
    </>
  );
};

export default SmsList;
