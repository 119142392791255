import { useFormik } from "formik";
import { useCallback, useEffect } from "react";
import Select from "react-select";
import * as yup from "yup";
import { SubmitButton } from "../../../../core/components";
import {
  defaultTelecomsCount,
  PAP_TYPES_LIST
} from "../../../../core/constants";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { PAP_TYPE, AddPap } from "../../../../core/types";

const PapeAdd = () => {
  const { addPap, getTelecoms, getPapProvinces } = useActions();
  const { telecoms, telecomsLoading, provincesLoading, provinces, loading } =
    useTypedSelector((state) => state.pap);

  const formik = useFormik({
    initialValues: {
      type: PAP_TYPE.AT,
      provinceId: "",
      telecomId: ""
    },
    validationSchema: yup.object({
      type: yup
        .mixed()
        .oneOf(Object.values(PAP_TYPE))
        .required("انتخاب پپ الزامی است"),
      provinceId: yup.string().trim().required("انتخاب استان الزامی است"),
      telecomId: yup.string().trim().required("انتخاب مرکز الزامی است")
    }),
    onSubmit: ({ type, provinceId, telecomId }: AddPap) => {
      addPap({
        type,
        provinceId,
        telecomId
      });
    }
  });
  useEffect(() => {
    getPapProvinces();
    getTelecoms({ limit: defaultTelecomsCount });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProvince = useCallback(() => {
    return provinces?.data.map((item) => {
      return { value: item._id, label: item.name };
    });
  }, [provinces?.data]);

  const getMatchedTelecom = useCallback(() => {
    return telecoms?.data
      .filter((item) => item.provinceId === formik.values.provinceId)
      .map((item) => {
        return { value: item._id, label: item.name };
      });
  }, [telecoms?.data, formik.values.provinceId]);

  const {
    type: typeError,
    provinceId: provinceError,
    telecomId: telecomError
  } = formik.errors;
  const {
    type: typeTouched,
    provinceId: provinceTouched,
    telecomId: telecomTouched
  } = formik.touched;
  const { values, setFieldValue, setValues } = formik;
  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'>اضافه کردن پپ جدید</h5>
        <form autoComplete='on' onSubmit={formik.handleSubmit}>
          <div className='select-wrapper--high '>
            <label>نام پروژه:</label>
            <Select
              onChange={(e) => {
                setValues({
                  ...formik.values,
                  type: (e?.value as PAP_TYPE) ?? ""
                });
              }}
              isClearable
              placeholder='پپ مربوطه را انتخاب کنید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={PAP_TYPES_LIST}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {typeTouched && typeError && (
              <p className='input-error--select'>{typeError}</p>
            )}
          </div>
          <div className='select-wrapper--high '>
            <label>استان مربوطه:</label>
            <Select
              onChange={(e) => {
                setFieldValue("provinceId", e?.value ?? "", true);
              }}
              value={
                getProvince()?.find((province) => {
                  return province.value === values.provinceId;
                }) ?? null
              }
              isClearable
              isDisabled={!provinces || provinces.count === 0}
              placeholder='استان مربوطه را انتخاب کنید'
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={getProvince()}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {provinceTouched && provinceError && (
              <p className='input-error--select'>{provinceError}</p>
            )}
          </div>
          <div className='select-wrapper--high '>
            <label>مرکز مربوطه:</label>
            <Select
              onChange={(e) => {
                setFieldValue("telecomId", e?.value ?? "", true);
              }}
              value={
                getMatchedTelecom()?.find((telecom) => {
                  return telecom.value === values.telecomId;
                }) ?? null
              }
              isClearable
              isDisabled={!telecoms || telecoms.count === 0}
              placeholder={"مرکز مربوطه را انتخاب کنید"}
              openMenuOnClick={true}
              classNamePrefix='react-select'
              options={getMatchedTelecom()}
              noOptionsMessage={() => {
                return <h6>آیتمی یافت نشد</h6>;
              }}
            />
            {telecomTouched && telecomError && (
              <p className='input-error--select'>{telecomError}</p>
            )}
          </div>

          <SubmitButton
            title='ثبت'
            loading={loading || provincesLoading || telecomsLoading}
            disabled={loading || provincesLoading || telecomsLoading}
            type='submit'
          />
        </form>
      </div>
    </>
  );
};

export default PapeAdd;
