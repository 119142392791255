import { useFormik } from "formik";
import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../../core/components";
import { useActions, useTypedSelector } from "../../../../../core/hooks";
import { DCItemSchema } from "../../../../../core/types";

const EditItem = () => {
  let [searchParams] = useSearchParams();
  const dataCenterId = searchParams.get("dataCenterId");
  const itemId = searchParams.get("itemId");
  const { editDcItem, fetchOneDataCenter } = useActions();
  const { loading, item } = useTypedSelector((state) => state.dataCenter);

  const selectedItem = useMemo(() => {
    return item && itemId && item?.items?.find((item) => item._id === itemId);
  }, [item, itemId]);

  const formik = useFormik({
    initialValues: {
      propertyNumber:
        selectedItem && selectedItem?.propertyNumber
          ? selectedItem.propertyNumber
          : "",
      serialNumber:
        selectedItem && selectedItem?.serialNumber
          ? selectedItem.serialNumber
          : "",
      name: selectedItem && selectedItem?.name ? selectedItem.name : "",
      description:
        selectedItem && selectedItem?.description
          ? selectedItem.description
          : "",
      type: selectedItem && selectedItem?.type ? selectedItem.type : "",
      unit: selectedItem && selectedItem?.unit ? selectedItem.unit : "",
      rackId: selectedItem && selectedItem?.rackId ? selectedItem.rackId : ""
    },
    validationSchema: yup.object({
      name: yup.string().trim().required("نام الزامی است"),
      propertyNumber: yup.string().trim(),
      serialNumber: yup.string().trim(),
      type: yup.string().trim().required("نوع الزامی است"),
      description: yup.string().trim(),
      rackId: yup.string().trim().required("رک الزامی است"),
      unit: yup.string().trim().required("یونیت الزامی است")
    }),
    onSubmit: ({
      type,
      propertyNumber,
      serialNumber,
      name,
      unit,
      description,
      rackId
    }: DCItemSchema) => {
      editDcItem(
        {
          id: itemId,
          type,
          propertyNumber,
          serialNumber,
          name,
          unit,
          description,
          rackId
        },
        item?._id ?? ""
      );
    }
  });
  useEffect(() => {
    if (dataCenterId) {
      fetchOneDataCenter(dataCenterId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCenterId]);

  const { type, propertyNumber, serialNumber, name, description, unit } =
    formik.values;
  const {
    type: typeError,
    propertyNumber: propertyNumberError,
    serialNumber: serialNumberError,
    name: nameError,
    description: descriptionError,
    unit: unitError,
    rackId: rackError
  } = formik.errors;
  const {
    type: typeTouched,
    propertyNumber: propertyNumberTouched,
    serialNumber: serialNumberTouched,
    name: nameTouched,
    description: descriptionTouched,
    unit: unitTouched,
    rackId: rackTouched
  } = formik.touched;

  const { setValues } = formik;

  const types = useMemo(() => {
    if (item && item != null && Array.isArray(item.items)) {
      return [
        ...new Set(
          item.items.map((item) => {
            return item.type;
          })
        )
      ];
    }
    return [];
  }, [item]);

  const getRacks = useCallback(() => {
    return item?.racks?.map((item) => {
      return { value: item._id, label: item.rackNumber };
    });
  }, [item?.racks]);

  const getDefaultRack = useCallback(() => {
    if (item?.racks?.length !== 0) {
      const found = item?.racks?.find((rack) => {
        return rack._id?.toString() === (selectedItem as DCItemSchema)?.rackId;
      });

      if (found) {
        return {
          value: found._id,
          label: found.rackNumber
        };
      }
      return {
        value: "",
        label: ""
      };
    }
  }, [item?.racks, selectedItem]);

  return (
    <>
      <div className='add-item-wrapper'>
        <h5 className='title'>ویرایش آیتم</h5>
        {item && item !== null && selectedItem && (
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <Input
              name='name'
              title='نام  : '
              autoComplete='off'
              error={nameTouched && nameError ? nameError : undefined}
              value={name}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='type'
              title='نوع : '
              autoComplete='off'
              error={typeTouched && typeError ? typeError : undefined}
              value={type}
              type='text'
              onChange={formik.handleChange}
            />

            <div className='select-chip'>
              {types.map((type) => {
                return (
                  <div
                    className='chip'
                    onClick={() => {
                      setValues({
                        ...formik.values,
                        type: type?.toString() ?? ""
                      });
                    }}>
                    {type?.toString()}
                  </div>
                );
              })}
            </div>

            <Input
              name='unit'
              title='یونیت  : '
              autoComplete='off'
              error={unitTouched && unitError ? unitError : undefined}
              value={unit}
              onChange={formik.handleChange}
              type='text'
            />

            <Input
              name='propertyNumber'
              title='شماره اموال : '
              autoComplete='off'
              error={
                propertyNumberTouched && propertyNumberError
                  ? propertyNumberError
                  : undefined
              }
              value={propertyNumber}
              onChange={formik.handleChange}
              type='text'
            />
            <Input
              name='serialNumber'
              title='شماره سریال : '
              autoComplete='off'
              error={
                serialNumberTouched && serialNumberError
                  ? serialNumberError
                  : undefined
              }
              value={serialNumber}
              onChange={formik.handleChange}
              type='text'
            />
            <div className='select-wrapper--high '>
              <label> رک:</label>
              <Select
                onChange={(e) => {
                  setValues({ ...formik.values, rackId: e?.value ?? "" });
                }}
                isClearable
                isDisabled={!item?.racks || item.racks.length === 0}
                placeholder='رک مربوطه را انتخاب کنید'
                openMenuOnClick={true}
                classNamePrefix='react-select'
                defaultValue={getDefaultRack()}
                options={getRacks()}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              {rackTouched && rackError && (
                <p className='input-error--select'>{rackError}</p>
              )}
            </div>
            <Input
              name='description'
              multiline
              title='توضیحات  : '
              autoComplete='off'
              error={
                descriptionTouched && descriptionError
                  ? descriptionError
                  : undefined
              }
              value={description}
              onChange={formik.handleChange}
              type='text'
            />

            <SubmitButton
              title='ثبت'
              loading={loading}
              disabled={loading}
              type='submit'
            />
          </form>
        )}
      </div>
    </>
  );
};

export default EditItem;
