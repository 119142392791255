import React, { memo, useMemo } from "react";
import ReactModal from "react-modal";
import { AuthGuide } from ".";
import { USER_ACCESS } from "../constants";
import { CRUD_TYPES, UserType } from "../types";

interface AuthModalBySectionTypes {
  user: UserType;
  access: USER_ACCESS;
  onClose?: () => any;
  isOpen: boolean;
}

const _AuthModalBySection: React.FC<AuthModalBySectionTypes> = ({
  onClose,
  isOpen,
  access,
  user,
  ...rest
}) => {
  const getUserCrudAccess = useMemo(() => {
    if (user && user.access) {
      const selectedSection = user.access.find((acc) => {
        return acc.section === access;
      });
      if (selectedSection) {
        return selectedSection.crud;
      }
      return [];
    }
    return [];
  }, [user, access]);

  return (
    <>
      <ReactModal
        style={{
          content: {
            maxWidth: 500,
            maxHeight: 500,
            margin: "auto"
          }
        }}
        {...rest}
        isOpen={isOpen}
        onRequestClose={onClose}
        ariaHideApp={false}>
        <div className='auth-modal'>
          <h5 className='title'>
            لیست دسترسی های {access} کاربر {user.fullName}
          </h5>{" "}
          <AuthGuide />
          <div className='auth-access'>
            <div>
              <i className='fas fa-user-lock question-icon'></i>{" "}
              <span>دسترسی</span>
            </div>
            <div>
              کاربر به بخش {access} دسترسی دارد{" "}
              <div>
                {getUserCrudAccess?.includes(CRUD_TYPES.WRITE) && (
                  <i className='fas fa-plus-circle add-icon'></i>
                )}
                {getUserCrudAccess?.includes(CRUD_TYPES.EDIT) && (
                  <i title='ویرایش' className='fas fa-pen edit-icon'></i>
                )}
                {getUserCrudAccess?.includes(CRUD_TYPES.DELETE) && (
                  <i title='حذف' className='fas fa-trash delete-icon'></i>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* {children} */}
      </ReactModal>
    </>
  );
};

const AuthModalBySection = memo(_AuthModalBySection);

export { AuthModalBySection };
