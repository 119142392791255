import { AdvancedResult, ProvincesType } from "../../types";
import { ProvincesActionTypes } from "../actionTypes";
import { PROVINCE_TYPES } from "../types";

interface StateType {
  list: AdvancedResult<ProvincesType> | null;
  item: ProvincesType | null;
  loading: boolean;
}

const initialState: StateType = {
  list: null,
  item: null,
  loading: false
};

const reducer = (
  state: StateType = initialState,
  action: ProvincesActionTypes
): StateType => {
  switch (action.type) {
    case PROVINCE_TYPES.PROVINCE_LISTS:
      return {
        ...state,
        list: action.payload,
        loading: false
      };

    case PROVINCE_TYPES.PROVINCE:
      return {
        ...state,
        item: action.payload,
        loading: false
      };

    case PROVINCE_TYPES.PROVINCE_LOADING:
      return {
        ...state,
        loading: true
      };

    case PROVINCE_TYPES.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

export default reducer;
