import { Route, Routes } from "react-router-dom";
import DashboardList from "./dashboard-list";

const Dashboard = () => {
  return (
    <>
      <Routes>
        <Route path='' element={<DashboardList />} />
      </Routes>
    </>
  );
};

export default Dashboard;
