import React, { Dispatch, memo, SetStateAction } from "react";
import { Link } from "react-router-dom";

interface DrawerType {
  openDrawer?: Dispatch<SetStateAction<boolean>>;
}

const _HeaderLogin: React.FC<DrawerType> = () => {
  return (
    <header id='header'>
      <Link to='/'>
        <img src='/asiatech_minimal_logo.png' alt='asiatech minimal logo' />
      </Link>
    </header>
  );
};
const HeaderLogin = memo(_HeaderLogin);

export { HeaderLogin };
