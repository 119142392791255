import { AuthLoadingActionTypes } from "../actionTypes";
import { AUTH_LOADING_TYPES } from "../types";

interface StateType {
  authLoading: boolean;
}

const initialState: StateType = {
  authLoading: false
};

const reducer = (
  state: StateType = initialState,
  action: AuthLoadingActionTypes
): StateType => {
  switch (action.type) {
    case AUTH_LOADING_TYPES.AUTH_LOADING:
      return {
        ...state,
        authLoading: true
      };

    case AUTH_LOADING_TYPES.AUTH_LOADING_DISABLE:
      return {
        ...state,
        authLoading: false
      };

    default:
      return state;
  }
};

export default reducer;
