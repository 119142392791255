import { GeneralCommentDocument, MongoObject, UserType, AlbumSchema } from ".";

export enum TasksTable {
  _ID = "_id",
  TITLE = "عنوان",
  DESCRIPTION = "توضیحات",
  PRIORITY = "الویت",
  USERID = "نام ایجاد کننده",
  USER_CREATOR = "ایجاد کننده",
  ASSIGNEDTO = "ارجاع به",
  CREATEDAT = "تاریخ ایجاد",
  DURATION = "مدت زمان سپری شده",
  ACTIONS = "عملیات",
  ROW = "ردیف",
  SIDE = "سمت من"
}

export enum DoneTaskType {
  _ID = "_id",
  TITLE = "عنوان",
  USERID = "نام ایجاد کننده",
  USER_CREATOR = "ایجاد کننده",
  ASSIGNEDTO = "ارجاع به",
  CREATEDAT = "تاریخ ایجاد",
  DURATION = "مدت زمان سپری شده",
  FINISHEDDAT = "تاریخ پایان",
  ACTIONS = "عملیات",
  DESCRIPTION = "توضیحات",
  ROW = "ردیف"
}

export enum TASK_SIDE {
  ASSIGNEE = "ایجاد کننده",
  ASSIGNER = "ارجاع شونده"
}
export interface TasksType extends MongoObject {
  title: string;
  description: string;
  assignedTo: string | UserType;
  priority?: string;
  createdAt?: Date;
  updatedAt?: Date;
  userId?: string | UserType;
  comments?: TaskCommentType[] | string;
  albums: Array<AlbumSchema>;
  uuid: string;
  doneDate?: Date;
  _id?: string;
  phone: string;
  side?: TASK_SIDE;
}

export interface AddTaskTypes {
  title: string;
  description: string;
  assignedTo: string | UserType;
  priority: string;
  comments: string;
}

export interface EditTaskTypes {
  title?: string;
  description?: string;
  priority?: string;
  comments?: string;
  side?: TASK_SIDE;
}

export interface AddSubmitTaskTypes {
  title: string;
  description: string;
  assignedTo: string | UserType;
  priority: string;
  comments: GeneralCommentDocument[] | null;
}

export interface TaskCommentType extends MongoObject {
  desc: string;
  userId?: string;
  createdAt: Date;
  modifiedAt: Date;
}
