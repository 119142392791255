export enum TELECOM_TYPES {
  TELECOM_LISTS = "TELECOM_LISTS",
  TELECOM = "TELECOM",
  TELECOM_DELETE = "TELECOM_DELETE",
  TELECOM_LOADING = "TELECOM_LOADING",
  DISABLE_LOADING = "DISABLE_LOADING",
  TELECOM_CITIES_LOADING = "TELECOM_CITIES_LOADING",
  TELECOM_CITIES_LISTS = "TELECOM_CITIES_LISTS",
  DISABLE_TELECOM_CITIES_LOADING = "DISABLE_TELECOM_CITIES_LOADING",
  TELECOM_PROVINCES_LOADING = "TELECOM_CITIES_LOADING",
  TELECOM_PROVINCES_LISTS = "TELECOM_PROVINCES_LISTS",
  DISABLE_TELECOM_PROVINCES_LOADING = "DISABLE_TELECOM_PROVINCES_LOADING"
}
