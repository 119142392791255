import React, { ButtonHTMLAttributes, forwardRef, memo } from "react";
import Loading from "./Loading";

interface InputProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  loading?: boolean;
  buttonType?: "primary" | "secondary";
  classNames?: string;
}

const _SubmitButton = forwardRef<HTMLButtonElement, InputProps>(
  ({ loading, title, classNames, ...rest }, ref) => {
    return (
      <>
        <div className='submit-button'>
          <button
            disabled={loading ? true : false}
            className={`submit-button__button ${classNames ? classNames : ""}`}
            ref={ref}
            {...rest}>
            {loading ? <Loading loading={loading} size={14} /> : title}
          </button>
        </div>
      </>
    );
  }
);

const SubmitButton = memo(_SubmitButton);

export { SubmitButton };
