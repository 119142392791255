import { Route, Routes } from "react-router-dom";
import FaultList from "./add-fault-list";
import AddFault from "./add-fault";
import ReferToExpert from "./refered-to-expert-list";
import EditFault from "./edit-fault";
import InTelecomFaults from "./in-telecom-fault-list";
import TelecomChecked from "./telecom-checked-list";
import ConcludeNeedExpert from "./conclude-need-expert";
import DoneFaults from "./done-fault-list";
import AddPhoto from "./add-photo";
import CloneFault from "./clone-fault";

const Faults = () => {
  return (
    <>
      <Routes>
        <Route path='/insert' element={<FaultList />} />
        <Route path='/add-fault' element={<AddFault />} />
        <Route path='/edit-fault' element={<EditFault />} />
        <Route path='/expert-refered-telecom' element={<ReferToExpert />} />
        <Route path='/in-telecom' element={<InTelecomFaults />} />
        <Route path='/normal-telecom' element={<TelecomChecked />} />
        <Route path='/conclude-need-expert' element={<ConcludeNeedExpert />} />
        <Route path='/done-faults' element={<DoneFaults />} />
        <Route path='/add-fault-photo' element={<AddPhoto />} />
        <Route path='/clone' element={<CloneFault />} />
      </Routes>
    </>
  );
};

export default Faults;
