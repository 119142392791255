export enum CITY_TYPES {
  CITY_LISTS = "CITY_LISTS",
  CITY = "CITY",
  CITY_DELETE = "CITY_DELETE",
  CITY_LOADING = "CITY_LOADING",
  DISABLE_LOADING = "DISABLE_LOADING",
  CITY_PROVINCES_LOADING = "CITY_PROVINCES_LOADING",
  CITY_PROVINCES_LISTS = "CITY_PROVINCES_LISTS",
  DISABLE_PROVINCES_LOADING = "DISABLE_PROVINCES_LOADING"
}
