import { MongoObject } from "..";

export enum PassivesTable {
  _ID = "_id",
  ROW = " ردیف",
  FLOOR = "طبقه",
  PROPERTYNUMBER = "اموال",
  TERMINALTYPE = "نوع ترمینال",
  CABLETYPE = "نوع کابل",
  CABLESIZE = "متراژ کابل",
  INSTALLERS = " نصاب ها",
  INSTALLDATE = "تاریخ نصب",
  ACTIONS = "عملیات"
}

export interface PassiveSchema extends MongoObject {
  _id?: string;
  id?: string | null;
  row?: string;
  floor?: string;
  terminalType?: string;
  propertyNumber?: string;
  cableType?: string;
  cableSize?: string;
  installDate?: string;
  installers?: string;
  comment?: string;
}
export interface AddPassive {
  row?: string;
  floor?: string;
  terminalType?: string;
  propertyNumber?: string;
  cableType?: string;
  cableSize?: string;
  installDate?: string;
  installers?: string;
  comment?: string;
}

export enum PASSIVE_TYPE {
  T98 = "98 تایی",
  T70 = "70 تایی"
}

export enum CABLE_TYPE {
  P25 = "25 زوجی",
  P100 = "100 زوجی"
}
