import { useFormik } from "formik";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { SmsCategoryTypes } from "../../../../core/types";

const AddCategory = () => {
  const { addSmsCategory } = useActions();

  const { loading } = useTypedSelector((state) => state.notification);
  const formik = useFormik({
    initialValues: {
      category: ""
    },
    validationSchema: yup.object({
      category: yup.string().required("  عنوان دسته بندی الزامی است")
    }),
    onSubmit: ({ category }: SmsCategoryTypes) => {
      addSmsCategory({
        category
      });
    }
  });

  const { category } = formik.values;
  const { category: categoryError } = formik.errors;
  const { category: categoryTouched } = formik.touched;

  return (
    <div className='add-item-wrapper'>
      <div className='list-top'>
        <p> افزودن دسته ی جدید</p>
      </div>
      <form autoComplete='on' onSubmit={formik.handleSubmit}>
        <Input
          name='category'
          title='عنوان دسته بندی'
          autoComplete='off'
          error={categoryTouched && categoryError ? categoryError : undefined}
          value={category}
          onChange={formik.handleChange}
        />

        <SubmitButton
          title='ثبت'
          loading={loading}
          disabled={loading}
          type='submit'
        />
      </form>
    </div>
  );
};

export default AddCategory;
