import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { getCatch } from "../../catch";
import { customizedAxios } from "../../config";
import {
  AdvancedResult,
  CitiesType,
  ProvincesType,
  QueryParams,
  TelecomsType
} from "../../types";
import { GeneralActionTypes } from "../actionTypes";
import {
  GENERAL_CITIES_API,
  GENERAL_PROVINCES_API,
  GENERAL_TELECOMS_API
} from "../api/GeneralApi";
import { store } from "../store";
import { GENERAL_TYPES } from "../types";

export const fetchUserProvinces =
  () => async (dispatch: Dispatch<GeneralActionTypes>) => {
    try {
      dispatch({ type: GENERAL_TYPES.GENERAL_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = GENERAL_PROVINCES_API;
      const res = await customizedAxios.get<AdvancedResult<ProvincesType>>(
        url,
        config
      );
      dispatch({
        type: GENERAL_TYPES.GENERAL_PROVINCE_LIST,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: GENERAL_TYPES.DISABLE_LOADING });
    }
  };

export const fetchUserCities =
  (query: QueryParams) => async (dispatch: Dispatch<GeneralActionTypes>) => {
    try {
      dispatch({ type: GENERAL_TYPES.GENERAL_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: query
      };

      let url = GENERAL_CITIES_API;
      const res = await customizedAxios.get<AdvancedResult<CitiesType>>(
        url,
        config
      );
      dispatch({
        type: GENERAL_TYPES.GENERAL_CITIES_LIST,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: GENERAL_TYPES.DISABLE_LOADING });
    }
  };

export const fetchUserTelecoms =
  (query: QueryParams) => async (dispatch: Dispatch<GeneralActionTypes>) => {
    try {
      dispatch({ type: GENERAL_TYPES.GENERAL_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: query
      };

      let url = GENERAL_TELECOMS_API;
      const res = await customizedAxios.get<AdvancedResult<TelecomsType>>(
        url,
        config
      );
      dispatch({
        type: GENERAL_TYPES.GENERAL_TELECOMS_LIST,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: GENERAL_TYPES.DISABLE_LOADING });
    }
  };
