import { Dispatch } from "react";
import { AdvancedResult, FaultStaticType } from "../../../../../core/types";

interface Props {
  list: AdvancedResult<FaultStaticType> | null;
  setTelecomId: Dispatch<React.SetStateAction<string | null>>;
  telecomId: string | null;
}

export const StatisticsTelecomList = ({
  list,
  setTelecomId,
  telecomId
}: Props) => {
  return (
    <>
      {list?.data.map((staticItem, index) => {
        return (
          <div
            className={`${
              telecomId === staticItem.telecom._id && "static-item-active"
            } static-item`}
            key={index}
            onClick={() =>
              setTelecomId((pre) =>
                pre === staticItem.telecom._id ? null : staticItem.telecom._id
              )
            }>
            {staticItem?.telecom.slug}
            <span>{staticItem.count}</span>
          </div>
        );
      })}
      <div
        className={`${telecomId === null && "static-item-active"} static-item`}
        key={"0"}
        onClick={() => setTelecomId(null)}>
        همه
      </div>
    </>
  );
};
