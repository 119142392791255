import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { customizedAxios } from "../../../config";
import { getCatch } from "../../../catch";
import {
  AdvancedResult,
  QueryParams,
  InputObject,
  UploadType,
  DeleteType
} from "../../../types";
import {
  AddPap,
  PapsType,
  PapTelecomsType,
  PapProvincesType,
  AlbumSchema
} from "../../../types/PapsTypes";
import { PapsActionTypes } from "../../actionTypes/PapActionTypes";
import { PAP_TYPES } from "../../types/PapsTypes";
import { PAPS_ROOT_API } from "../../api";
import { store } from "../../store";
import { createFormData } from "../../../constants";

export const fetchPaps =
  (query: QueryParams) => async (dispatch: Dispatch<PapsActionTypes>) => {
    try {
      dispatch({ type: PAP_TYPES.PAP_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: query
      };

      let url = PAPS_ROOT_API;
      const res = await customizedAxios.get<AdvancedResult<PapsType>>(
        url,
        config
      );
      dispatch({
        type: PAP_TYPES.PAP_LISTS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PAP_TYPES.DISABLE_LOADING });
    }
  };
export const addPap =
  (body: AddPap) => async (dispatch: Dispatch<PapsActionTypes>) => {
    try {
      dispatch({ type: PAP_TYPES.PAP_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PAPS_ROOT_API;
      await customizedAxios.post(url, body, config);

      dispatch({
        type: PAP_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" پروژه با موفقیت افزوده شد.");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PAP_TYPES.DISABLE_LOADING });
    }
  };

export const getTelecoms =
  ({ limit }: QueryParams) =>
  async (dispatch: Dispatch<PapsActionTypes>) => {
    try {
      dispatch({ type: PAP_TYPES.PAP_TELECOMS_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: {
          limit
        }
      };

      let url = PAPS_ROOT_API.concat("/telecoms");
      const res = await customizedAxios.get<AdvancedResult<PapTelecomsType>>(
        url,
        config
      );

      dispatch({
        type: PAP_TYPES.PAP_TELECOMS,
        payload: res.data
      });
      dispatch({
        type: PAP_TYPES.DISABLE_PAP_TELECOMS_LOADING
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PAP_TYPES.DISABLE_PAP_TELECOMS_LOADING });
    }
  };
export const getPapProvinces =
  () => async (dispatch: Dispatch<PapsActionTypes>) => {
    try {
      dispatch({ type: PAP_TYPES.PAP_PROVINCES_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PAPS_ROOT_API.concat("/provinces");
      const res = await customizedAxios.get<AdvancedResult<PapProvincesType>>(
        url,
        config
      );

      dispatch({
        type: PAP_TYPES.PAP_PROVINCES,
        payload: res.data
      });
      dispatch({
        type: PAP_TYPES.DISABLE_PAP_PROVINCES_LOADING
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PAP_TYPES.DISABLE_PAP_PROVINCES_LOADING });
    }
  };

export const editPap =
  (body: PapsType, id: string) =>
  async (dispatch: Dispatch<PapsActionTypes>) => {
    try {
      dispatch({ type: PAP_TYPES.PAP_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PAPS_ROOT_API.concat("/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: PAP_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("پروژه با موفقیت ویرایش شد");
      window.navigate("/paps");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PAP_TYPES.DISABLE_LOADING });
    }
  };

export const fetchOnePap =
  (id: string) => async (dispatch: Dispatch<PapsActionTypes>) => {
    try {
      dispatch({ type: PAP_TYPES.PAP_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PAPS_ROOT_API.concat("/" + id);
      const res = await customizedAxios.get<InputObject<PapsType>>(url, config);
      dispatch({
        type: PAP_TYPES.PAP,
        payload: res.data.data!
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PAP_TYPES.DISABLE_LOADING });
    }
  };

export const uploadPapPhoto =
  (file: File, { _id, category, title }: AlbumSchema) =>
  async (dispatch: Dispatch<PapsActionTypes>) => {
    try {
      dispatch({ type: PAP_TYPES.PAP_LOADING });

      let bodyData = createFormData(file, { title, category });

      const config: AxiosRequestConfig = {
        headers: {
          "Content-Type": "multipart/form-data; boundary=form._boundary",
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = PAPS_ROOT_API.concat("/photo/" + _id);
      const res = await customizedAxios.put<UploadType<PapsType>>(
        url,
        bodyData,
        config
      );
      dispatch({
        type: PAP_TYPES.PAP_UPLOAD,
        payload: res.data.data.albums ?? []
      });
      window.openToastSuccess("تصویر با موفقیت افزوده شد");
      window.navigate(-1);
      return res.data.data;
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PAP_TYPES.DISABLE_LOADING });
    }
  };

export const deletePapPhoto =
  (id: string, papId: string) =>
  async (dispatch: Dispatch<PapsActionTypes>) => {
    try {
      dispatch({ type: PAP_TYPES.PAP_LOADING });
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        data: {
          id
        }
      };

      let url = PAPS_ROOT_API.concat("/photo/" + papId);
      const res = await customizedAxios.delete<DeleteType<PapsType>>(
        url,
        config
      );

      dispatch({
        type: PAP_TYPES.PAP_UPLOAD,
        payload: res.data.data.albums ?? []
      });
      window.openToastSuccess("تصویر با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: PAP_TYPES.DISABLE_LOADING });
    }
  };
