import { MongoObject } from "..";

export enum DCItemTable {
  _ID = "_id",
  NAME = " نام",
  TYPE = "نوع",
  UNIT = "یونیت",
  PROPERTYNUMBER = "اموال",
  SERIALNUMBER = "سریال",
  DIMENSION = "ابعاد",
  RACKID = "رک",
  ACTIONS = "عملیات",
  ROW = "ردیف"
}

export interface DCItemSchema extends MongoObject {
  _id?: string;
  id?: string | null;
  name?: string;
  type?: string;
  dimension?: string;
  propertyNumber?: string;
  serialNumber?: string;
  createdAt?: Date;
  updatedAt?: Date;
  description?: string;
  rackId?: string;
  unit?: string;
}
export interface AddDCItem {
  type?: string;
  name?: string;
  propertyNumber?: string;
  serialNumber?: string;
  description?: string;
  rackId?: string;
  unit?: string;
}
