import React, { memo } from "react";
import ReactModal from "react-modal";
import { CancelButton } from ".";

function myFunc(obj: { [key in string]: string | number }): [
  string,
  string | number
][] {
  const returnArray = [];
  for (let [key, value] of Object.entries(obj)) {
    returnArray.push([key, value] as [string, string | number]);
  }
  return returnArray;
}
interface DetailModalContentType {
  message?: string;
  title: string;
  onClose?: () => any;
  isOpen: boolean;
  content?: any;
}

const _DetailModalContent: React.FC<DetailModalContentType> = ({
  onClose,
  title,
  isOpen,
  message,
  content,
  ...rest
}) => {
  return (
    <>
      <ReactModal
        style={{
          content: {
            maxWidth: 500,
            maxHeight: 350,
            margin: "auto"
          }
        }}
        {...rest}
        isOpen={isOpen}
        onRequestClose={onClose}
        ariaHideApp={false}>
        <div className='comment-modal__wrapper'>
          <h5 className='title'>{title}</h5>{" "}
          <div className='content-wrapper'>
            {content &&
              myFunc(content).map((item) => {
                if (item[0] === "توضیحات") return null;
                return (
                  <p key={item[0]}>
                    <span>{item[0] === "_id" ? "شناسه" : item[0]} : </span>

                    <div>{item[1]}</div>
                  </p>
                );
              })}
            <h5>توضیحات:</h5>{" "}
            <p className='comment-modal__message'>{message}</p>
          </div>
          <div className='comment-modal__button-wrapper'>
            <div className='comment-modal__button-wrapper--item'>
              <CancelButton onClick={onClose} title='بستن' />
            </div>
          </div>
        </div>
        {/* {children} */}
      </ReactModal>
    </>
  );
};

const DetailModalContent = memo(_DetailModalContent);

export { DetailModalContent };
