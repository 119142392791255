import { useFormik } from "formik";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { SmsTypes } from "../../../../core/types";

const EditSms = () => {
  const { editSms } = useActions();
  const { loading, list } = useTypedSelector((state) => state.notification);

  let [searchParams] = useSearchParams();
  const smsId = searchParams.get("_id");
  const categoryId = searchParams.get("categoryId");

  const getDefaultSms = useCallback(() => {
    if (list?.data?.length !== 0) {
      const found = list?.data?.find((categoryEl) => {
        return categoryEl._id?.toString() === categoryId;
      });

      if (found) {
        const foundSms = found?.messages?.find((smsEl) => {
          return smsEl._id?.toString() === smsId;
        });

        return foundSms?.message;
      }
      return "";
    }
    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list?.data, smsId, categoryId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: getDefaultSms() || ""
    },
    validationSchema: yup.object({
      message: yup.string().required("  متن پیام الزامی است")
    }),
    onSubmit: ({ message }: SmsTypes) => {
      editSms(
        {
          message,
          id: smsId ?? ""
        },
        categoryId ?? ""
      );
    }
  });

  const { message } = formik.values;
  const { message: messageError } = formik.errors;
  const { message: messageTouched } = formik.touched;

  return (
    <div className='add-item-wrapper'>
      <div className='list-top'>
        <p>ویرایش اس ام اس</p>
      </div>
      <form autoComplete='on' onSubmit={formik.handleSubmit}>
        <Input
          multiline
          name='message'
          title='متن پیام'
          autoComplete='off'
          error={messageTouched && messageError ? messageError : undefined}
          value={message}
          onChange={formik.handleChange}
        />

        <SubmitButton
          title='ویرایش'
          loading={loading}
          disabled={loading}
          type='submit'
        />
      </form>
    </div>
  );
};

export default EditSms;
