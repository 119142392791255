import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import copy from "copy-to-clipboard";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import _ from "lodash";
import {
  NoItem,
  DeleteModalContent,
  Input,
  SubmitButton
} from "../../../../core/components";
import { useDispatch } from "react-redux";
import {
  DEBOUNCE_TIME,
  defaultTablePageCount,
  USER_ACCESS
} from "../../../../core/constants";
import { defaultColumnDefinition } from "../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import {
  CRUD_TYPES,
  DataCenterTable,
  DataCenterType,
  ProvincesType
} from "../../../../core/types";
import { CellClickedEvent } from "ag-grid-community";
import { ImageWrapper } from "../../../../core/components/ImageWrapper";
import {
  DATACENTER_ROOT_API,
  BASE_API,
  DATACENTER_TYPES
} from "../../../../core/redux";
import {
  checkUserAccessByProvinceCrud,
  fileDownload
} from "../../../../core/utility";
import { useFormik } from "formik";
import useIsMobile from "src/core/hooks/useIsMobile";
import { useQueryParams } from "src/core/hooks/useQueryParams";

const DataCenterList = () => {
  const {
    fetchDataCenters,
    deleteDataCenter,
    uploadDataCenterPhoto,
    deleteDataCenterPhoto
  } = useActions();
  const { loading, list, imageItem } = useTypedSelector(
    (state) => state.dataCenter
  );
  const { searchParams, setSearchParams } = useQueryParams();
  const [currentPage, setCurrentPage] = useState<number>(
    +(searchParams("page") ?? 1) - 1
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<DataCenterType | null>(null);
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { me } = useTypedSelector((state) => state.auth);
  const { token } = useTypedSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      name: searchParams("name[regex]") ?? ""
    },
    validationSchema: yup.object({
      name: yup.string()
    }),
    onSubmit: () => {}
  });

  const { name } = formik.values;
  const { name: nameError } = formik.errors;
  const { name: nameTouched } = formik.touched;

  useEffect(() => {
    callApi(currentPage, name);
    setSearchParams({ page: currentPage + 1, "name[regex]": name });
    dispatch({ type: DATACENTER_TYPES.RESET_IMAGE_ITEM });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, name]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callApi = useCallback(
    _.debounce((page, name) => {
      fetchDataCenters({
        limit: defaultTablePageCount,
        page: page + 1,
        "name[regex]": name
      });
    }, DEBOUNCE_TIME),
    []
  );

  const onSuccessDeleteModal = useCallback(() => {
    deleteDataCenter(selectedItem?._id as string);
    setIsOpen(false);
    setSelectedItem(null);
  }, [selectedItem, deleteDataCenter]);

  const rowData = useCallback(() => {
    return list?.data.map((item, index) => {
      return {
        [DataCenterTable.ROW]: `${index + 1 + currentPage * 10}`,
        [DataCenterTable._ID]: item._id,
        [DataCenterTable.NAME]: item.name,
        [DataCenterTable.PROVINCEID]: (
          item.provinceId as unknown as ProvincesType
        )?._id,
        [DataCenterTable.PROVINCE]: (
          item.provinceId as unknown as ProvincesType
        )?.name,
        [DataCenterTable.ITEMS]: item.items?.length,
        [DataCenterTable.RACKS]: item.racks?.length,
        [DataCenterTable.ADDRESS]: item.address,
        [DataCenterTable.ACTIONS]: "edit"
      };
    });
  }, [list?.data, currentPage]);

  const hasAlbum = useCallback(
    (cellEvent: CellClickedEvent) =>
      list?.data?.find((dataCenter) => {
        return dataCenter._id === cellEvent?.data?._id;
      })?.albums?.length !== 0,
    [list?.data]
  );

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          {hasAlbum(cellEvent) && (
            <span
              onClick={(e) => {
                e.stopPropagation();

                dispatch({
                  type: DATACENTER_TYPES.SELECTED_DATACENTER_IMAGES,
                  payload: list?.data?.find((dataCenter) => {
                    return dataCenter._id === cellEvent?.data?._id;
                  })
                });
              }}>
              <i title='گالری' className='fas fa-images edit-icon'></i>
            </span>
          )}
          {me &&
            checkUserAccessByProvinceCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.DATA_CENTER,
              CRUD_TYPES.EDIT,
              cellEvent?.data[DataCenterTable.PROVINCEID]
            ) && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  navigator(`edit-data-center?_id=${cellEvent?.data?._id}`);
                }}>
                <i title='ویرایش' className='fas fa-pen edit-icon'></i>
              </span>
            )}

          {me &&
            checkUserAccessByProvinceCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.DATA_CENTER,
              CRUD_TYPES.DELETE,
              cellEvent?.data[DataCenterTable.PROVINCEID]
            ) && (
              <span
                onClick={() => {
                  setSelectedItem(
                    list?.data?.find((item) => {
                      return (
                        item._id?.toString() ===
                        cellEvent?.data[DataCenterTable._ID]
                      );
                    }) ?? null
                  );
                  setIsOpen(true);
                }}>
                <i title='حذف' className='fas fa-trash delete-icon'></i>
              </span>
            )}
          {me &&
            checkUserAccessByProvinceCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.DATA_CENTER,
              CRUD_TYPES.EDIT,
              cellEvent?.data[DataCenterTable.PROVINCEID]
            ) && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch({
                    type: DATACENTER_TYPES.SELECTED_DATACENTER_IMAGES,
                    payload: list?.data?.find((dataCenter) => {
                      return dataCenter._id === cellEvent?.data?._id;
                    })
                  });
                  navigator(
                    `add-data-center-photo?_id=${cellEvent?.data?._id}`
                  );
                }}>
                <i
                  title='اضافه کردن عکس'
                  className='fas fa-camera add-icon'></i>
              </span>
            )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, me]
  );

  return (
    <>
      <DeleteModalContent
        isOpen={isOpen}
        title={"حذف دیتاسنتر " + (selectedItem && selectedItem.name)}
        message='آیا از حذف دیتاسنتر مطمئن هستید؟'
        onCancel={() => {
          setIsOpen(false);
          setSelectedItem(null);
        }}
        onSuccess={onSuccessDeleteModal}
      />
      <div className='main-route-wrapper'>
        <section className='clients-search-wrapper'>
          <div className='list-input-wrapper'>
            <Input
              name='name'
              placeholder='جستجو'
              autoComplete='off'
              error={nameTouched && nameError ? nameError : undefined}
              value={name}
              onChange={(e) => {
                formik.handleChange(e);
                if (currentPage !== 0) setCurrentPage(0);
              }}
            />
          </div>
        </section>{" "}
        <div className='table-wrapper'>
          <div className='list-top'>
            <p>
              تعداد کل دیتاسنتر ها : <span>{list && list.total} عدد</span>
              <i
                onClick={() => {
                  callApi(currentPage, name);
                }}
                className={`fas fa-sync-alt ${loading ? "rotate" : ""}`}></i>
              {list && list?.data && list.data.length !== 0 && (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    const url = BASE_API + DATACENTER_ROOT_API;
                    fileDownload(url, token ?? "", "datacenter.xlsx", {
                      xlsx: true,
                      "name[regex]": name
                    });
                  }}
                  className='fas fa-file-excel excel-icon'></i>
              )}
            </p>
            <Link to={"./add-data-center"}>
              <SubmitButton
                loading={loading}
                title=' اضافه کردن دیتاسنتر جدید'
              />
            </Link>
          </div>

          {(!list || list.count === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}

          {list && list.count !== 0 && (
            <>
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  animateRows={true}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  rowSelection={"single"}
                  onCellClicked={(e) => {
                    copy(e.value);

                    if (e.colDef.field === DataCenterTable.ITEMS)
                      navigator(`items?_id=${e?.data?._id}`);
                    else if (e.colDef.field === DataCenterTable.RACKS)
                      navigator(`racks?_id=${e?.data?._id}`);
                  }}
                  enableRtl={true}>
                  <AgGridColumn
                    sortable={true}
                    field={DataCenterTable.ROW}
                    width={84}
                  />
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={DataCenterTable.ACTIONS}
                    width={170}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DataCenterTable.NAME}
                    minWidth={120}
                    flex={1}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DataCenterTable.PROVINCE}
                    width={170}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DataCenterTable.ITEMS}
                    width={100}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DataCenterTable.RACKS}
                    width={100}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={DataCenterTable.ADDRESS}
                    minWidth={300}
                  />
                </AgGridReact>
              </div>
            </>
          )}
        </div>
        <ReactPaginate
          marginPagesDisplayed={isMobile ? 1 : 3}
          forcePage={currentPage}
          breakLabel='...'
          nextLabel='بعدی'
          onPageChange={(e) => {
            setCurrentPage(e.selected);
          }}
          pageRangeDisplayed={5}
          pageCount={
            (list && Math.ceil(list.total / defaultTablePageCount)) ?? 1
          }
          previousLabel='قبلی'
          containerClassName='pagination-container'
        />
      </div>
      {imageItem && (
        <ImageWrapper
          albums={imageItem?.albums?.map((album) => {
            return {
              ...album,
              path:
                BASE_API +
                DATACENTER_ROOT_API +
                "/photo/" +
                imageItem._id +
                "?photoId=" +
                album.path
            };
          })}
          onDelete={(albumItemId) => {
            deleteDataCenterPhoto(albumItemId, imageItem?._id ?? "");
          }}
          onSuccess={(file) => {
            uploadDataCenterPhoto(file, {});
          }}
        />
      )}
    </>
  );
};

export default DataCenterList;
