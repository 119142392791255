import { CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import copy from "copy-to-clipboard";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  DeleteModalContent,
  DetailModalContent,
  NoItem,
  SubmitButton
} from "../../../../../core/components";
import { USER_ACCESS } from "../../../../../core/constants";
import { defaultColumnDefinition } from "../../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../../core/hooks";
import { BASE_API, PAPS_ROOT_API } from "../../../../../core/redux";
import {
  CRUD_TYPES,
  ShelfSchema,
  ShelvesTable,
  TelecomsType
} from "../../../../../core/types";
import {
  checkUserAccessByTelecomCrud,
  fileDownload
} from "../../../../../core/utility";

const ShelvesList = () => {
  let [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");
  const { fetchOnePap, deleteShelf } = useActions();
  const { loading, item } = useTypedSelector((state) => state.pap);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<
    ShelfSchema | null | undefined
  >(null);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);

  const { me } = useTypedSelector((state) => state.auth);
  const { token } = useTypedSelector((state) => state.auth);

  const navigator = useNavigate();

  useEffect(() => {
    if (_id) {
      fetchOnePap(_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const onSuccessDeleteModal = useCallback(() => {
    deleteShelf(selectedItem?._id as string, item?._id as string);
    setIsOpen(false);
    setSelectedItem(null);
  }, [deleteShelf, selectedItem?._id, item?._id]);

  const rowData = useCallback(() => {
    const shelves = item?.shelves;
    if (item !== undefined) {
      return shelves?.map((item, index) => {
        return {
          [ShelvesTable.ROW]: `${index + 1}`,
          [ShelvesTable._ID]: item._id,
          [ShelvesTable.SHELFNUMBER]: item.shelfNumber,
          [ShelvesTable.MODEL]: item.model,
          [ShelvesTable.LINECARDS]: item.lineCards?.length,
          [ShelvesTable.PROPERTYNUMBER]: item.propertyNumber,
          [ShelvesTable.SERIALNUMBER]: item.serialNumber,
          [ShelvesTable.MANAGEPROPERTYNUMBER]: item.managePropertyNumber,
          [ShelvesTable.MANAGESERIALNUMBER]: item.manageSerialNumber,
          [ShelvesTable.CREATEDAT]: new Date(
            item.createdAt ?? ""
          ).toLocaleDateString("fa-IR"),
          [ShelvesTable.UPDATEDAT]: new Date(
            item.updatedAt ?? ""
          ).toLocaleDateString("fa-IR")
        };
      });
    }
    return [];
  }, [item]);

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          <span
            onClick={() => {
              setSelectedItem(cellEvent?.data);
              setIsCommentModalOpen(true);
            }}>
            <i title='توضیحات' className='fas fa-comment comment-icon'></i>
          </span>
          {me &&
            item &&
            checkUserAccessByTelecomCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.PAPS,
              CRUD_TYPES.EDIT,
              item.provinceId!,
              (item.telecomId as unknown as TelecomsType)?._id!
            ) && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  navigator(
                    `edit?papId=${item?._id}&shelfId=${cellEvent?.data[
                      ShelvesTable._ID
                    ]}`
                  );
                }}>
                <i title='ویرایش' className='fas fa-pen edit-icon'></i>
              </span>
            )}

          {me &&
            item &&
            checkUserAccessByTelecomCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.PAPS,
              CRUD_TYPES.DELETE,
              item.provinceId!,
              (item.telecomId as unknown as TelecomsType)?._id!
            ) && (
              <span
                onClick={() => {
                  const _shelf =
                    item &&
                    item?.shelves?.find((shelf) => {
                      return (
                        shelf._id?.toString() ===
                        cellEvent?.data[ShelvesTable._ID].toString()
                      );
                    });

                  setSelectedItem(_shelf);
                  setIsOpen(true);
                }}>
                <i title='حذف' className='fas fa-trash delete-icon'></i>
              </span>
            )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item?._id]
  );

  return (
    <>
      <DeleteModalContent
        isOpen={isOpen}
        title={" حذف شلف " + (selectedItem && selectedItem.shelfNumber)}
        message='آیا از حذف شلف مطمئن هستید؟'
        onCancel={() => {
          setIsOpen(false);
          setSelectedItem(null);
        }}
        onSuccess={onSuccessDeleteModal}
      />
      {isCommentModalOpen && (
        <DetailModalContent
          content={selectedItem}
          isOpen={isCommentModalOpen}
          title={"توضیحات شلف"}
          message='توضیحی درباره این شلف ثبت نشده است.'
          onClose={() => {
            setIsCommentModalOpen(false);
            setSelectedItem(null);
          }}
        />
      )}
      <div className='main-route-wrapper'>
        {" "}
        <div className='table-wrapper'>
          <div className='list-top'>
            <p>
              تعداد کل شلف های پپ {item && item.type} مرکز{" "}
              {item && (item.telecomId as unknown as TelecomsType)?.name} :
              <span>{item && item.shelves?.length} عدد</span>
              {item && (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    const url = BASE_API + PAPS_ROOT_API;
                    fileDownload(url, token ?? "", "shelves.xlsx", {
                      xlsx: true,
                      _id: item._id,
                      select: "shelves"
                    });
                  }}
                  className='fas fa-file-excel excel-icon'></i>
              )}
            </p>
            {me &&
              item &&
              checkUserAccessByTelecomCrud(
                me,
                me?.access ?? [],
                USER_ACCESS.PAPS,
                CRUD_TYPES.EDIT,
                item.provinceId!,
                (item.telecomId as unknown as TelecomsType)?._id!
              ) && (
                <Link to={"./add?_id=" + _id}>
                  <SubmitButton
                    loading={loading}
                    title='    اضافه کردن شلف جدید'
                  />
                </Link>
              )}
          </div>

          {(!item || !item.shelves || item.shelves.length === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}

          {item && item !== null && (
            <>
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  animateRows={true}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  rowSelection={"single"}
                  enableRtl={true}
                  onCellClicked={(e) => {
                    if (e.colDef.field !== ShelvesTable.ACTIONS) copy(e.value);

                    if (e.colDef.field === ShelvesTable.LINECARDS)
                      navigator(
                        `line-cards?papId=${item?._id}&shelfId=${e?.data?._id}`
                      );
                  }}>
                  <AgGridColumn
                    sortable={true}
                    field={ShelvesTable.ROW}
                    width={84}
                  />
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={ShelvesTable.ACTIONS}
                    width={130}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={ShelvesTable.LINECARDS}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={ShelvesTable.SHELFNUMBER}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={ShelvesTable.MODEL}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={ShelvesTable.PROPERTYNUMBER}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={ShelvesTable.SERIALNUMBER}
                    minWidth={150}
                    flex={1}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={ShelvesTable.MANAGEPROPERTYNUMBER}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={ShelvesTable.MANAGESERIALNUMBER}
                    minWidth={150}
                  />

                  <AgGridColumn
                    sortable={true}
                    field={ShelvesTable.CREATEDAT}
                    width={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={ShelvesTable.UPDATEDAT}
                    width={150}
                  />
                </AgGridReact>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ShelvesList;
