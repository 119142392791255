import { Route, Routes } from "react-router-dom";
import AddLineCard from "./add-line-card";
import EditLineCard from "./edit-line-card";
import LineCardsList from "./line-cards-list";

const LineCards = () => {
  return (
    <>
      <Routes>
        <Route path='' element={<LineCardsList />} />
        <Route path='/add' element={<AddLineCard />} />
        <Route path='/edit' element={<EditLineCard />} />
      </Routes>
    </>
  );
};

export default LineCards;
