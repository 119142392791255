import { CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import copy from "copy-to-clipboard";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import { useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaultFileReader,
  NoItem,
  SubmitButton
} from "../../../../core/components";
import { defaultTablePageCount, FILE_TYPE } from "../../../../core/constants";
import { defaultColumnDefinition } from "../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import {
  BINARY_STATUS,
  TempFaultsTable
} from "../../../../core/types/FaultTypes";

const FaultList = () => {
  const { fetchTempFaults, uploadXLSXFIle, deleteTempFault } = useActions();
  const { loading, tempFaultList } = useTypedSelector((state) => state.fault);
  const navigator = useNavigate();

  useEffect(() => {
    if (!tempFaultList) {
      fetchTempFaults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTablePageCount]);

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          {cellEvent?.data[TempFaultsTable.OPENFAULT] === BINARY_STATUS.NO && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                navigator(
                  `/faults/add-fault/?faultId=${cellEvent?.data[
                    TempFaultsTable.UUID
                  ]}`
                );
              }}>
              {<i title='ویرایش' className='fas fa-info-circle edit-icon'></i>}{" "}
            </span>
          )}
          <span
            onClick={(e) => {
              e.stopPropagation();
              deleteTempFault(cellEvent?.data[TempFaultsTable.UUID]);
            }}>
            <i title='حذف' className='fas fa-trash delete-icon'></i>
          </span>
          {cellEvent?.data[TempFaultsTable.DONEFAULTSCOUNT] > 0 && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                navigator(
                  `/faults/done-faults/?phone=${cellEvent?.data[
                    TempFaultsTable.ADSL
                  ]
                    .match(/\d/g)
                    ?.join("")}`
                );
              }}>
              <i
                title='بررسی خرابی'
                className='fas fa-external-link-square-alt edit-icon'></i>
            </span>
          )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const rowData = useCallback(() => {
    return tempFaultList?.map((item) => {
      return {
        [TempFaultsTable.UUID]: item[TempFaultsTable.UUID],
        [TempFaultsTable.ADSL]: item[TempFaultsTable.ADSL],
        [TempFaultsTable.TELECOM_NAME]: item[TempFaultsTable.TELECOM_NAME],
        [TempFaultsTable.PROVINCE_NAME]: item[TempFaultsTable.PROVINCE_NAME],
        [TempFaultsTable.ENTRANCEDATE]: item[TempFaultsTable.ENTRANCEDATE],
        [TempFaultsTable.DONEFAULTSCOUNT]:
          item[TempFaultsTable.DONEFAULTSCOUNT],

        [TempFaultsTable.LASTDONEDATE]: item[TempFaultsTable.LASTDONEDATE],
        [TempFaultsTable.OPENFAULT]: item[TempFaultsTable.OPENFAULT],

        [TempFaultsTable.ACTIONS]: item[TempFaultsTable.ACTIONS]
      };
    });
  }, [tempFaultList]);

  return (
    <>
      <FaultFileReader
        description='در صورت داشتن فایل اکسل ، آن را در همین کادر دراپ کنید'
        accept={FILE_TYPE.XLSX}
        onSuccess={(file) => {
          (uploadXLSXFIle(file) as unknown as Promise<void>).then(() => {
            fetchTempFaults();
          });
        }}
      />
      <div className='main-route-wrapper'>
        <div className='table-wrapper'>
          <div className='list-top'>
            <p>
              لیست خرابی های بارگذاری شده :{" "}
              <span>{tempFaultList && tempFaultList.length} عدد</span>
              <i
                onClick={() => fetchTempFaults()}
                className={`fas fa-sync-alt ${loading ? "rotate" : ""}`}></i>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  tempFaultList?.forEach((faultItem) => {
                    if (
                      faultItem[TempFaultsTable.OPENFAULT] === BINARY_STATUS.YES
                    ) {
                      deleteTempFault(faultItem[TempFaultsTable.UUID]);
                    }
                  });
                }}>
                <i
                  style={{ color: "red" }}
                  className='fas fa-trash delete-icon'></i>
              </span>
            </p>
            <Link to={"/faults/add-fault"}>
              <SubmitButton loading={loading} title=' ایجاد خرابی جدید' />
            </Link>
          </div>

          {(!tempFaultList || tempFaultList.length === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}

          {tempFaultList && tempFaultList.length !== 0 && (
            <>
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  animateRows={true}
                  onCellClicked={(e) => {
                    if (e.colDef.field !== TempFaultsTable.ACTIONS)
                      copy(e.value);
                  }}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  rowSelection={"single"}
                  enableRtl={true}>
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={TempFaultsTable.ACTIONS}
                    minWidth={120}
                    maxWidth={160}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={TempFaultsTable.OPENFAULT}
                    minWidth={140}
                    maxWidth={140}
                  />
                  <AgGridColumn
                    field={TempFaultsTable.LASTDONEDATE}
                    minWidth={140}
                    maxWidth={140}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={TempFaultsTable.DONEFAULTSCOUNT}
                    minWidth={120}
                    maxWidth={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={TempFaultsTable.UUID}
                    minWidth={100}
                    maxWidth={100}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={TempFaultsTable.ADSL}
                    minWidth={140}
                    maxWidth={140}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={TempFaultsTable.TELECOM_NAME}
                    flex={1}
                    minWidth={200}
                    maxWidth={200}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={TempFaultsTable.PROVINCE_NAME}
                    minWidth={150}
                    maxWidth={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={TempFaultsTable.ENTRANCEDATE}
                    minWidth={200}
                    flex={1}
                  />
                </AgGridReact>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FaultList;
