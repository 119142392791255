export enum NOTIFICATION_TYPES {
  SMS_LISTS = "SMS_LISTS",
  NOTIFICATION = "NOTIFICATION",
  WEBPUSH = "WEBPUSH",
  SMS = "SMS",
  NOTIFICATION_LOADING = "NOTIFICATION_LOADING",
  DISABLE_LOADING = "DISABLE_LOADING",
  NOTIFICATION_DELETE = "NOTIFICATION_DELETE",
  SMS_DELETE = "SMS_DELETE"
}
