import { Route, Routes } from "react-router-dom";
import AddCategory from "./add-category";
import AddSms from "./add-sms";
import EditCategory from "./edit-category";
import EditSms from "./edit-sms";
import SmsList from "./sms-list";

const Smss = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<SmsList />} />
        <Route path='/add-sms' element={<AddSms />} />
        <Route path='/edit-sms' element={<EditSms />} />
        <Route path='/add-category' element={<AddCategory />} />
        <Route path='/edit-category' element={<EditCategory />} />
      </Routes>
    </>
  );
};

export default Smss;
