import _ from "lodash";
import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import Collapsible from "react-collapsible";
import { NavLink } from "react-router-dom";
import { STATUS_TYPES, USER_ACCESS } from "../../../core/constants";
import { useActions, useTypedSelector } from "../../../core/hooks";
import { FaultCountType, USER_LEVEL } from "../../../core/types";
import {
  checkUserAccessToSection,
  getUserAccess as _getUserAccess
} from "../../../core/utility";
interface DrawerNavType {
  openDrawer?: Dispatch<SetStateAction<boolean>>;
}

const DrawerNav: React.FC<DrawerNavType> = ({ openDrawer }) => {
  const { me } = useTypedSelector((state) => state.auth);

  const { dataCenterOpen, owaOpen, papOpen, faultsOpen, taskOpen } =
    useTypedSelector((state) => state.drawer);
  const { tasksList, faultsList } = useTypedSelector(
    (state) => state.dashboard
  );

  const {
    drawerPapAction,
    drawerDataCenterAction,
    drawerFaultsAction,
    drawerOwasAction,
    drawerTaskAction,
    drawerCloseAllAction
  } = useActions();

  const style = useCallback(() => {
    return ({ isActive }: { isActive: boolean }) => {
      return {
        backgroundColor: isActive ? "#FF9B4144" : "",
        color: isActive ? "#FF9B41" : ""
      };
    };
  }, []);

  const userAccess = useMemo(() => {
    return checkUserAccessToSection(me?.access ?? []);
  }, [me]);

  const handleCloseDrawer = () => {
    if (openDrawer) openDrawer(false);
  };

  const getTasksCount = useCallback(() => {
    const foundTaskCount = _.sumBy(tasksList?.[0]?.tasks, "count");
    return foundTaskCount || "";

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksList]);

  const getNormalFaultCount = useMemo(
    () =>
      ((faultsList &&
        faultsList[0]?.faultCountByStatus!.find(
          (el: FaultCountType) => el._id === STATUS_TYPES.CUSTOMER
        )?.count) ??
        0) +
      ((faultsList &&
        faultsList[0]?.faultCountByStatus!.find(
          (el: FaultCountType) => el._id === STATUS_TYPES.NORMAL
        )?.count) ??
        0),
    [faultsList]
  );

  const getReferredToExpertFaultCount = useMemo(
    () => faultsList?.[0]?.faultCountByStatusOnlyNeedExpert?.[0]?.count ?? "",
    [faultsList]
  );

  const getInTelecomFaultCount = useMemo(
    () =>
      faultsList?.[0]?.faultCountByStatus.find(
        (el: FaultCountType) => el._id === STATUS_TYPES.IN_TELECOM
      )?.count ?? "",
    [faultsList]
  );

  const getTotalFaultCount = useMemo(
    () => faultsList?.[0]?.totalFaultCount[0]?.count ?? "",
    [faultsList]
  );

  const getUserAccess = useMemo(
    () => _getUserAccess.bind(null, userAccess, me),
    [userAccess, me]
  );
  return (
    <>
      <div className='drawer-wrapper__nav'>
        <nav>
          <ul>
            <li>
              <NavLink
                style={style()}
                onClick={() => {
                  drawerCloseAllAction();
                  handleCloseDrawer();
                }}
                to='/dashboard'>
                <i className='fas fa-regular fa-calendar-minus'></i>
                <p>داشبورد</p>
              </NavLink>
            </li>

            {me && me.level !== USER_LEVEL.THREE && (
              <li>
                <NavLink
                  style={style()}
                  onClick={() => {
                    drawerCloseAllAction();
                    handleCloseDrawer();
                  }}
                  to='/users'>
                  <i className='fa fa-users' />
                  <p>کاربران</p>
                </NavLink>
              </li>
            )}
            {getUserAccess(USER_ACCESS.PROVINCE) && (
              <li>
                <NavLink
                  style={style()}
                  onClick={() => {
                    drawerCloseAllAction();
                    handleCloseDrawer();
                  }}
                  to='/provinces'>
                  <i className='fas fa-city' />
                  <p>استان ها</p>
                </NavLink>
              </li>
            )}
            {getUserAccess(USER_ACCESS.CITY) && (
              <li>
                <NavLink
                  style={style()}
                  onClick={() => {
                    drawerCloseAllAction();
                    handleCloseDrawer();
                  }}
                  to='/cities'>
                  <i className='fa fa-building' />
                  <p>شهر ها</p>
                </NavLink>
              </li>
            )}
            {getUserAccess(USER_ACCESS.TELECOM) && (
              <li>
                <NavLink
                  style={style()}
                  onClick={() => {
                    drawerCloseAllAction();
                    handleCloseDrawer();
                  }}
                  to='/telecoms'>
                  <i className='fa fa-network-wired' />
                  <p>مراکز</p>
                </NavLink>
              </li>
            )}
            {getUserAccess(USER_ACCESS.PAPS) && (
              <li
                onClick={() => {
                  drawerPapAction();
                }}
                className='collapse-li'>
                <i className='fas fa-ethernet' />
                <p>پپ</p>

                <i className={`fas fa-chevron-down ${papOpen ? "open" : ""}`} />
              </li>
            )}

            <Collapsible
              transitionTime={250}
              easing='ease-out'
              trigger=''
              open={papOpen}>
              <li className='sub-li'>
                <NavLink
                  end
                  onClick={handleCloseDrawer}
                  style={style()}
                  to='/paps'>
                  <p>لیست پپ</p>
                </NavLink>
              </li>

              <li className='sub-li'>
                <NavLink
                  className='disabled-nav-link'
                  aria-disabled={true}
                  style={style()}
                  onClick={handleCloseDrawer}
                  to='/paps/shelves'>
                  <p>شلف ها</p>
                </NavLink>
              </li>

              <li className='sub-li'>
                <NavLink
                  className='disabled-nav-link'
                  aria-disabled={true}
                  style={style()}
                  onClick={handleCloseDrawer}
                  to='/paps/shelves/line-cards'>
                  <p>لاین کارت ها</p>
                </NavLink>
              </li>

              <li className='sub-li'>
                <NavLink
                  className='disabled-nav-link'
                  aria-disabled={true}
                  style={style()}
                  onClick={handleCloseDrawer}
                  to='/paps/racks'>
                  <p>رک ها</p>
                </NavLink>
              </li>
              <li className='sub-li'>
                <NavLink
                  className='disabled-nav-link'
                  aria-disabled={true}
                  style={style()}
                  onClick={handleCloseDrawer}
                  to='/paps/passives'>
                  <p>بوخت ها</p>
                </NavLink>
              </li>
              <li className='sub-li'>
                <NavLink
                  className='disabled-nav-link'
                  aria-disabled={true}
                  style={style()}
                  onClick={handleCloseDrawer}
                  to='/paps/miscellaneous'>
                  <p> متفرقه</p>
                </NavLink>
              </li>
            </Collapsible>
            {getUserAccess(USER_ACCESS.OWA) && (
              <>
                <li
                  onClick={() => {
                    drawerOwasAction();
                  }}
                  className='collapse-li'>
                  <i className='fas fa-broadcast-tower'></i>
                  <p>پاپ سایت </p>
                  <i
                    className={`fas fa-chevron-down ${owaOpen ? "open" : ""}`}
                  />
                </li>
                <Collapsible
                  transitionTime={250}
                  easing='ease-out'
                  trigger=''
                  open={owaOpen}>
                  <li className='sub-li'>
                    <NavLink
                      end
                      onClick={handleCloseDrawer}
                      style={style()}
                      to='/owas'>
                      <p>لیست پاپ سایت </p>
                    </NavLink>
                  </li>

                  <li className='sub-li'>
                    <NavLink
                      className='disabled-nav-link'
                      aria-disabled={true}
                      onClick={handleCloseDrawer}
                      style={style()}
                      to='/owas/items'>
                      <p>آیتم ها</p>
                    </NavLink>
                  </li>

                  <li className='sub-li'>
                    <NavLink
                      onClick={handleCloseDrawer}
                      className='disabled-nav-link'
                      aria-disabled={true}
                      style={style()}
                      to='/owas/radios'>
                      <p> رادیو ها</p>
                    </NavLink>
                  </li>
                </Collapsible>
              </>
            )}

            {(getUserAccess(USER_ACCESS.FAULTS_INSERT) ||
              getUserAccess(USER_ACCESS.FAULTS_READ) ||
              getUserAccess(USER_ACCESS.FAULTS_READ_ALL)) && (
              <>
                <li
                  onClick={() => {
                    drawerFaultsAction();
                  }}
                  className='collapse-li '>
                  <i className='fas fa-tools'></i>
                  <p>خرابی ها </p>
                  <span>{getTotalFaultCount}</span>
                  <i
                    className={`fas fa-chevron-down ${
                      faultsOpen ? "open" : ""
                    }`}
                  />
                </li>
                <Collapsible
                  transitionTime={250}
                  easing='ease-out'
                  trigger=''
                  open={faultsOpen}>
                  {getUserAccess(USER_ACCESS.FAULTS_INSERT) && (
                    <li className='sub-li'>
                      <NavLink
                        end
                        onClick={handleCloseDrawer}
                        aria-disabled={true}
                        style={style()}
                        to='/faults/insert'>
                        <p> ایجاد خرابی</p>
                      </NavLink>
                    </li>
                  )}

                  {(getUserAccess(USER_ACCESS.FAULTS_READ) ||
                    getUserAccess(USER_ACCESS.FAULTS_READ_ALL)) && (
                    <li className='sub-li'>
                      <NavLink
                        aria-disabled={true}
                        style={style()}
                        onClick={handleCloseDrawer}
                        to='/faults/in-telecom'>
                        <p> در دست بررسی از مرکز </p>
                        <span>{getInTelecomFaultCount}</span>
                      </NavLink>
                    </li>
                  )}
                  {(getUserAccess(USER_ACCESS.FAULTS_READ) ||
                    getUserAccess(USER_ACCESS.FAULTS_READ_ALL)) && (
                    <li className='sub-li'>
                      <NavLink
                        aria-disabled={true}
                        style={style()}
                        onClick={handleCloseDrawer}
                        to='/faults/normal-telecom'>
                        <p> مرکز بررسی شده</p>
                        <span>{getNormalFaultCount}</span>
                      </NavLink>
                    </li>
                  )}

                  {(getUserAccess(USER_ACCESS.FAULTS_READ) ||
                    getUserAccess(USER_ACCESS.FAULTS_READ_ALL)) && (
                    <li className='sub-li'>
                      <NavLink
                        aria-disabled={true}
                        style={style()}
                        onClick={handleCloseDrawer}
                        to='/faults/expert-refered-telecom'>
                        <p> لیست حضوری ها</p>
                        <span>{getReferredToExpertFaultCount}</span>
                      </NavLink>
                    </li>
                  )}

                  {(getUserAccess(USER_ACCESS.FAULTS_READ) ||
                    getUserAccess(USER_ACCESS.FAULTS_READ_ALL)) && (
                    <li className='sub-li'>
                      <NavLink
                        aria-disabled={true}
                        style={style()}
                        onClick={handleCloseDrawer}
                        to='/faults/done-faults'>
                        <p> پایان یافته</p>
                      </NavLink>
                    </li>
                  )}
                </Collapsible>
              </>
            )}

            {getUserAccess(USER_ACCESS.DATA_CENTER) && (
              <>
                <li
                  onClick={() => {
                    drawerDataCenterAction();
                  }}
                  className='collapse-li'>
                  <i className='fas fa-server' />
                  <p>دیتا سنتر </p>
                  <i
                    className={`fas fa-chevron-down ${
                      dataCenterOpen ? "open" : ""
                    }`}
                  />
                </li>
                <Collapsible
                  transitionTime={250}
                  easing='ease-out'
                  trigger=''
                  open={dataCenterOpen}>
                  <li className='sub-li'>
                    <NavLink
                      end
                      onClick={handleCloseDrawer}
                      style={style()}
                      to='/data-centers'>
                      <p>لیست دیتا سنتر </p>
                    </NavLink>
                  </li>

                  <li className='sub-li'>
                    <NavLink
                      className='disabled-nav-link'
                      aria-disabled={true}
                      style={style()}
                      to='/data-centers/items'
                      onClick={handleCloseDrawer}>
                      <p>آیتم ها</p>
                    </NavLink>
                  </li>

                  <li className='sub-li'>
                    <NavLink
                      className='disabled-nav-link'
                      aria-disabled={true}
                      style={style()}
                      onClick={handleCloseDrawer}
                      to='/data-centers/racks'>
                      <p> رک ها</p>
                    </NavLink>
                  </li>
                </Collapsible>
              </>
            )}
            {getUserAccess(USER_ACCESS.PHONEBOOK) && (
              <li>
                <NavLink
                  style={style()}
                  onClick={() => {
                    handleCloseDrawer();
                  }}
                  to='/phone-books'>
                  <i className='fa fa-phone-alt' />
                  <p>دفترچه تلفن</p>
                </NavLink>
              </li>
            )}

            {(getUserAccess(USER_ACCESS.TASKS_READ) ||
              getUserAccess(USER_ACCESS.TASKS_READ_ALL)) && (
              <>
                <li
                  className='collapse-li'
                  onClick={() => {
                    drawerTaskAction();
                  }}>
                  <i className='fas fa-tasks'></i>
                  <p> تسک‌ها </p>
                  <span>{getTasksCount()}</span>
                  <i
                    className={`fas fa-chevron-down ${taskOpen ? "open" : ""}`}
                  />
                </li>
                <Collapsible
                  transitionTime={250}
                  easing='ease-out'
                  trigger=''
                  open={taskOpen}>
                  <li className='sub-li'>
                    <NavLink
                      end
                      style={style()}
                      onClick={handleCloseDrawer}
                      to='/tasks/list'>
                      <p>لیست تسک‌ها </p>
                    </NavLink>
                  </li>
                  <li className='sub-li'>
                    <NavLink
                      onClick={handleCloseDrawer}
                      style={style()}
                      to='/tasks/done'>
                      <p> تسک‌های پایان یافته </p>
                    </NavLink>
                  </li>
                </Collapsible>
              </>
            )}

            {getUserAccess(USER_ACCESS.LOGS) && (
              <li>
                <NavLink
                  style={style()}
                  onClick={() => {
                    drawerCloseAllAction();
                    handleCloseDrawer();
                  }}
                  to='/logs'>
                  <i className='fas fa-clipboard-list'></i>
                  <p>لاگ‌ها </p>
                </NavLink>
              </li>
            )}
            {getUserAccess(USER_ACCESS.SMS) && (
              <li>
                <NavLink
                  style={style()}
                  onClick={() => {
                    handleCloseDrawer();
                  }}
                  to='/sms'>
                  <i className='fas fa-sms'></i>

                  <p> بخش اس ام اس</p>
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default DrawerNav;
