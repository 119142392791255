import { CellClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import copy from "copy-to-clipboard";
import { AgGridColumn } from "ag-grid-react/lib/shared/agGridColumn";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  DeleteModalContent,
  DetailModalContent,
  NoItem,
  SubmitButton
} from "../../../../../core/components";
import { USER_ACCESS } from "../../../../../core/constants";
import { defaultColumnDefinition } from "../../../../../core/constants/table";
import { useActions, useTypedSelector } from "../../../../../core/hooks";
import { BASE_API, PAPS_ROOT_API } from "../../../../../core/redux";
import {
  CRUD_TYPES,
  RackSchema,
  RacksTable,
  TelecomsType
} from "../../../../../core/types";
import {
  checkUserAccessByTelecomCrud,
  fileDownload
} from "../../../../../core/utility";

const RacksList = () => {
  let [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");
  const { fetchOnePap, deleteRack } = useActions();
  const { loading, item } = useTypedSelector((state) => state.pap);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<
    RackSchema | null | undefined
  >(null);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);

  const navigator = useNavigate();
  const { me } = useTypedSelector((state) => state.auth);
  const { token } = useTypedSelector((state) => state.auth);

  useEffect(() => {
    if (_id) {
      fetchOnePap(_id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const onSuccessDeleteModal = useCallback(() => {
    deleteRack(selectedItem?._id as string, item?._id as string);
    setIsOpen(false);
    setSelectedItem(null);
  }, [deleteRack, selectedItem?._id, item?._id]);

  const rowData = useCallback(() => {
    const racks = item?.racks;
    if (item !== undefined) {
      return racks?.map((item, index) => {
        return {
          [RacksTable.ROW]: `${index + 1}`,
          [RacksTable._ID]: item._id,
          [RacksTable.RACKNUMBER]: item.rackNumber,
          [RacksTable.BRANDNAME]: item.brandName,
          [RacksTable.PROPERTYNUMBER]: item.propertyNumber,
          [RacksTable.SERIALNUMBER]: item.serialNumber,
          [RacksTable.DIMENSION]: item.dimension,
          [RacksTable.CREATEDAT]: new Date(
            item.createdAt ?? ""
          ).toLocaleDateString("fa-IR"),
          [RacksTable.UPDATEDAT]: new Date(
            item.updatedAt ?? ""
          ).toLocaleDateString("fa-IR")
        };
      });
    }
    return [];
  }, [item]);

  const actionsRenderer = useCallback(
    (cellEvent: CellClickedEvent) => {
      return (
        <>
          <span
            onClick={() => {
              setSelectedItem(cellEvent?.data);
              setIsCommentModalOpen(true);
            }}>
            <i title='توضیحات' className='fas fa-comment comment-icon'></i>
          </span>
          {me &&
            item &&
            checkUserAccessByTelecomCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.PAPS,
              CRUD_TYPES.EDIT,
              item.provinceId!,
              (item.telecomId as unknown as TelecomsType)?._id!
            ) && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  navigator(
                    `edit?papId=${item?._id}&rackId=${cellEvent?.data[
                      RacksTable._ID
                    ]}`
                  );
                }}>
                <i title='ویرایش' className='fas fa-pen edit-icon'></i>
              </span>
            )}
          {me &&
            item &&
            checkUserAccessByTelecomCrud(
              me,
              me?.access ?? [],
              USER_ACCESS.PAPS,
              CRUD_TYPES.DELETE,
              item.provinceId!,
              (item.telecomId as unknown as TelecomsType)?._id!
            ) && (
              <span
                onClick={() => {
                  const _rack =
                    item &&
                    item?.racks?.find((rack) => {
                      return (
                        rack._id?.toString() ===
                        cellEvent?.data[RacksTable._ID].toString()
                      );
                    });

                  setSelectedItem(_rack);
                  setIsOpen(true);
                }}>
                <i title='حذف' className='fas fa-trash delete-icon'></i>
              </span>
            )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item?._id]
  );

  return (
    <>
      <DeleteModalContent
        isOpen={isOpen}
        title={"  حذف رک " + (selectedItem && selectedItem.rackNumber)}
        message='آیا از حذف رک مطمئن هستید؟'
        onCancel={() => {
          setIsOpen(false);
          setSelectedItem(null);
        }}
        onSuccess={onSuccessDeleteModal}
      />
      {isCommentModalOpen && (
        <DetailModalContent
          content={selectedItem}
          isOpen={isCommentModalOpen}
          message='توضیحی درباره این رک ثبت نشده است.'
          title={"توضیحات رک"}
          onClose={() => {
            setIsCommentModalOpen(false);
            setSelectedItem(null);
          }}
        />
      )}
      <div className='main-route-wrapper'>
        {" "}
        <div className='table-wrapper'>
          <div className='list-top'>
            <p>
              تعداد کل رک های پپ {item && item.type} مرکز{" "}
              {item && (item.telecomId as unknown as TelecomsType)?.name} :
              <span>{item && item.racks?.length} عدد</span>
              {item && (
                <i
                  onClick={(e) => {
                    e.stopPropagation();
                    const url = BASE_API + PAPS_ROOT_API;
                    fileDownload(url, token ?? "", "racks.xlsx", {
                      xlsx: true,
                      _id: item._id,
                      select: "racks"
                    });
                  }}
                  className='fas fa-file-excel excel-icon'></i>
              )}
            </p>
            {me &&
              item &&
              checkUserAccessByTelecomCrud(
                me,
                me?.access ?? [],
                USER_ACCESS.PAPS,
                CRUD_TYPES.EDIT,
                item.provinceId!,
                (item.telecomId as unknown as TelecomsType)?._id!
              ) && (
                <Link to={"./add?_id=" + _id}>
                  <SubmitButton loading={loading} title='اضافه کردن رک جدید' />
                </Link>
              )}
          </div>

          {(!item || !item.racks || item.racks.length === 0) && (
            <NoItem title='آیتمی ثبت نشده است.' />
          )}

          {item && item !== null && (
            <>
              <div
                className='ag-theme-alpine table-container'
                style={{ width: "100%" }}>
                <AgGridReact
                  animateRows={true}
                  onCellClicked={(e) => {
                    if (e.colDef.field !== RacksTable.ACTIONS) copy(e.value);
                  }}
                  defaultColDef={defaultColumnDefinition}
                  rowData={rowData()}
                  rowSelection={"single"}
                  enableRtl={true}>
                  <AgGridColumn
                    sortable={true}
                    field={RacksTable.ROW}
                    width={84}
                  />
                  <AgGridColumn
                    sortable={true}
                    cellRenderer={actionsRenderer}
                    field={RacksTable.ACTIONS}
                    width={140}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={RacksTable.RACKNUMBER}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={RacksTable.BRANDNAME}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={RacksTable.PROPERTYNUMBER}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={RacksTable.SERIALNUMBER}
                    minWidth={150}
                    flex={1}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={RacksTable.DIMENSION}
                    width={120}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={RacksTable.CREATEDAT}
                    width={150}
                  />
                  <AgGridColumn
                    sortable={true}
                    field={RacksTable.UPDATEDAT}
                    width={150}
                  />
                </AgGridReact>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RacksList;
