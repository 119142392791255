import { MongoObject } from ".";

export enum LogsTable {
  _ID = "_id",
  USERNAME = "نام کاربر",
  EVENT = "رویداد ",
  EVENTTYPE = "نوع رویداد ",
  DESCRIPTION = "توضیح ",
  DATE = "تاریخ ",
  ROW = "ردیف"
}
export interface LogsType extends MongoObject {
  comment?: string;
  fullName?: string;
  user?: string;
  eventTypes?: string;
}
