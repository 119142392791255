import { Route, Routes } from "react-router-dom";
import AddMiscellaneous from "./add-miscellaneous";
import EditMiscellaneous from "./edit-miscellaneous";
import MiscellaneousList from "./miscellaneous-list";

const Miscellaneous = () => {
  return (
    <>
      <Routes>
        <Route path='' element={<MiscellaneousList />} />
        <Route path='/add' element={<AddMiscellaneous />} />
        <Route path='/edit' element={<EditMiscellaneous />} />
      </Routes>
    </>
  );
};

export default Miscellaneous;
