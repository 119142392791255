import React, { memo } from "react";
import ReactModal from "react-modal";
import { DeleteButton, CancelButton } from ".";

interface DeleteModalContentType {
  message: string;
  onSuccess?: () => any;
  title: string;
  onCancel?: () => any;
  isOpen: boolean;
  actionTitle?: string;
}

const _DeleteModalContent: React.FC<DeleteModalContentType> = ({
  onCancel,
  onSuccess,
  title,
  isOpen,
  message,
  actionTitle,
  ...rest
}) => {
  return (
    <>
      <ReactModal
        style={{
          content: {
            maxWidth: 500,
            maxHeight: 360,
            margin: "auto",
            left: 16,
            right: 16
          }
        }}
        {...rest}
        isOpen={isOpen}
        onRequestClose={onCancel}
        ariaHideApp={false}>
        <div className='delete-modal__wrapper'>
          <h5 className='title'>{title}</h5>{" "}
          <h4 className='delete-modal__message'>{message}</h4>
          <div className='delete-modal__button-wrapper'>
            <div className='delete-modal__button-wrapper--item'>
              <CancelButton onClick={onCancel} title='لغو' />
            </div>
            <div className='delete-modal__button-wrapper--item'>
              <DeleteButton onClick={onSuccess} title={actionTitle ?? "حذف"} />
            </div>
          </div>
        </div>
        {/* {children} */}
      </ReactModal>
    </>
  );
};

const DeleteModalContent = memo(_DeleteModalContent);

export { DeleteModalContent };
