import { useCallback, useEffect, useMemo, useState } from "react";
import { HeaderDashboard } from "../../core/components";
import { refetchInterval, USER_ACCESS } from "../../core/constants";
import { useActions, useTypedSelector } from "../../core/hooks";
import {
  checkUserAccessToSection,
  getUserAccess as _getUserAccess
} from "../../core/utility";
import Drawer from "./drawer";
import Main from "./main";

const Dashboard = () => {
  const { getMeAction, fetchDashboardFaults, fetchDashboardTasks } =
    useActions();
  const [open, setOpen] = useState<boolean>(false);
  const { token, me } = useTypedSelector((state) => state.auth);

  const userAccess = useMemo(() => {
    return checkUserAccessToSection(me?.access ?? []);
  }, [me]);

  const getUserAccess = useMemo(
    () => _getUserAccess.bind(null, userAccess, me),
    [userAccess, me]
  );
  const fetchTasks = useCallback(() => {
    (getUserAccess(USER_ACCESS.FAULTS_READ) ||
      getUserAccess(USER_ACCESS.FAULTS_READ_ALL)) &&
      fetchDashboardTasks();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFaults = useCallback(() => {
    (getUserAccess(USER_ACCESS.FAULTS_READ) ||
      getUserAccess(USER_ACCESS.FAULTS_READ_ALL)) &&
      fetchDashboardFaults();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMeAction();
    fetchFaults();
    fetchTasks();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchFaults();
      fetchTasks();
    }, refetchInterval);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      <HeaderDashboard openDrawer={setOpen} />
      <div className='dashboard-wrapper'>
        <Drawer open={open} openDrawer={setOpen} />
        <main className='main-dashboard'>
          <Main />
        </main>
      </div>
    </>
  );
};

export default Dashboard;
