import React, { memo } from "react";

interface SubHeaderProps {
  title: string;
  classNames?: string;
}

const _NoItem: React.FC<SubHeaderProps> = ({ title, classNames }) => {
  return (
    <section className={`no-item-wrapper ${classNames ? classNames : ""}`}>
      <div>
        {title} <i className='fas fa-thermometer-empty' />
      </div>
    </section>
  );
};

const NoItem = memo(_NoItem);

export { NoItem };
