import {
  AdvancedResult,
  CitiesType,
  ProvincesType,
  TelecomsType
} from "../../types";
import { GeneralActionTypes } from "../actionTypes";
import { GENERAL_TYPES } from "../types";

interface StateType {
  loading: boolean;
  provinceList: AdvancedResult<ProvincesType> | null;
  citiesList: AdvancedResult<CitiesType> | null;
  telecomsList: AdvancedResult<TelecomsType> | null;
}

const initialState: StateType = {
  loading: false,
  provinceList: null,
  citiesList: null,
  telecomsList: null
};

const reducer = (
  state: StateType = initialState,
  action: GeneralActionTypes
): StateType => {
  switch (action.type) {
    case GENERAL_TYPES.GENERAL_CITIES_LIST:
      return {
        ...state,
        citiesList: action.payload,
        loading: false
      };
    case GENERAL_TYPES.GENERAL_TELECOMS_LIST:
      return {
        ...state,
        telecomsList: action.payload,
        loading: false
      };
    case GENERAL_TYPES.GENERAL_PROVINCE_LIST:
      return {
        ...state,
        provinceList: action.payload,
        loading: false
      };

    case GENERAL_TYPES.GENERAL_LOADING:
      return {
        ...state,
        loading: true
      };

    case GENERAL_TYPES.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

export default reducer;
