import { useFormik } from "formik";
import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import * as yup from "yup";
import { Input, SubmitButton } from "../../../../core/components";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { PhoneBookProvinceType, PhoneBookType } from "../../../../core/types";

const EditPhoneBook = () => {
  let [searchParams] = useSearchParams();
  const _id = searchParams.get("_id");

  const { editPhoneBook, getPhoneBookProvinces, fetchOnePhoneBook } =
    useActions();
  const { loading, item, provincesLoading, provinces, list } = useTypedSelector(
    (state) => state.phoneBook
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: item?.name ?? "",
      phone: item?.phone ?? "",
      category: item?.category ?? "",
      provinceId: item?.provinceId ?? ""
    },
    validationSchema: yup.object({
      name: yup.string().trim().required("نام شهر الزامی است"),
      phone: yup.string().trim().required("شماره تلفن الزامی است"),
      category: yup.string().trim().required("انتخاب دسته الزامی است"),
      provinceId: yup.string().trim().required("انتخاب استان الزامی است")
    }),

    onSubmit: ({ name, provinceId, phone, category }: PhoneBookType) => {
      editPhoneBook(
        {
          name,
          phone,
          category,
          provinceId
        },
        item?._id ?? ""
      );
    }
  });

  const getDefaultProvince = useCallback(() => {
    if (provinces?.data?.length !== 0) {
      const found = provinces?.data?.find((province) => {
        return (
          province._id?.toString() ===
          (item as PhoneBookType)?.provinceId?.toString()
        );
      });

      if (
        found &&
        (found as PhoneBookProvinceType)._id &&
        (found as PhoneBookProvinceType).name
      ) {
        return {
          value: found._id,
          label: found.name
        };
      }
      return {
        value: "",
        label: ""
      };
    }
    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinces?.data, item?.provinceId]);

  useEffect(() => {
    getPhoneBookProvinces();
    if (_id) {
      fetchOnePhoneBook(_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const getProvince = useCallback(() => {
    return provinces?.data.map((item) => {
      return { value: item._id, label: item.name };
    });
  }, [provinces?.data]);

  const { name, phone, category } = formik.values;
  const {
    name: nameError,
    provinceId: provinceError,
    phone: phoneError,
    category: categoryError
  } = formik.errors;
  const {
    name: nameTouched,
    provinceId: provinceTouched,
    phone: phoneTouched,
    category: categoryTouched
  } = formik.touched;
  const { setValues } = formik;

  const categories = useMemo(() => {
    if (list && list != null && Array.isArray(list.data)) {
      return [
        ...new Set(
          (list?.data).map((item) => {
            return item.category;
          })
        )
      ];
    }
    return [];
  }, [list]);

  return (
    <>
      {item && item !== null && (
        <div className='add-item-wrapper'>
          <h5 className='title'>ویرایش مخاطب {item?.name}</h5>{" "}
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <Input
              name='name'
              title='نام مخاطب : '
              autoComplete='off'
              error={nameTouched && nameError ? nameError : undefined}
              value={name}
              type='text'
              onChange={formik.handleChange}
            />
            <Input
              name='phone'
              title='شماره تلفن : '
              autoComplete='off'
              error={phoneTouched && phoneError ? phoneError : undefined}
              value={phone}
              type='text'
              onChange={formik.handleChange}
            />
            <Input
              name='category'
              title='دسته : '
              autoComplete='off'
              error={
                categoryTouched && categoryError ? categoryError : undefined
              }
              value={category}
              type='text'
              onChange={formik.handleChange}
            />
            <div className='select-chip'>
              {categories.map((category) => {
                return (
                  <div
                    key={category}
                    className='chip'
                    onClick={() => {
                      setValues({
                        ...formik.values,
                        category: category.toString() ?? ""
                      });
                    }}>
                    {category.toString()}
                  </div>
                );
              })}
            </div>

            <div className='select-wrapper--high '>
              <label>استان مربوطه:</label>
              <Select
                onChange={(e) => {
                  setValues({
                    ...formik.values,
                    provinceId: (typeof e !== "string" && e?.value) || ""
                  });
                }}
                isClearable
                isDisabled={!provinces || provinces.count === 0}
                placeholder='استان مربوطه را انتخاب کنید'
                openMenuOnClick={true}
                defaultValue={getDefaultProvince()}
                classNamePrefix='react-select'
                options={getProvince()}
                noOptionsMessage={() => {
                  return <h6>آیتمی یافت نشد</h6>;
                }}
              />
              {provinceTouched && provinceError && (
                <p className='input-error--select'>{provinceError}</p>
              )}
            </div>

            <SubmitButton
              title='ویرایش'
              loading={loading || provincesLoading}
              disabled={loading || provincesLoading}
              type='submit'
            />
          </form>
        </div>
      )}
    </>
  );
};

export default EditPhoneBook;
