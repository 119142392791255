import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { SubmitButtonSecondary } from "../../../../core/components";
import { Input } from "../../../../core/components/Input";
import { PhotoCropper } from "../../../../core/components/PhotoCropper";
import { SubmitButton } from "../../../../core/components/SubmitButton";
import { FILE_SIZES, papFaultRatio } from "../../../../core/constants";
import { useActions, useTypedSelector } from "../../../../core/hooks";
import { AlbumSchema } from "../../../../core/types";

const AddPhoto = () => {
  const navigator = useNavigate();

  const { uploadOwaPhoto } = useActions();
  const { loading, imageItem } = useTypedSelector((state) => state.owa);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      category: "",
      file: undefined
    },
    validationSchema: yup.object({
      title: yup.string().trim().required("عنوان تصویر الزامی است"),
      category: yup.string().trim().required("دسته تصویر الزامی است"),
      file: yup
        .mixed()
        .test(
          "fileSize",
          "اندازه فایل بیشتر از مقدار مورد نظر است",
          (value) => {
            if (!value || !value.length) return true;
            return value[0].size <= FILE_SIZES.FAULT_PHOTO_SIZE;
          }
        )
    }),
    onSubmit: ({ title, category }: AlbumSchema & { file?: File }) => {
      if (!file) {
        return window.openToastError("فایل الزامی می باشد");
      }
      uploadOwaPhoto(file, { _id: imageItem?._id, title, category });
    }
  });

  const { title, category, file } = formik.values;
  const { title: titleError, category: categoryError } = formik.errors;
  const { title: titleTouched, category: categoryTouched } = formik.touched;
  const [isOpenCropper, setIsOpenCropper] = useState<boolean>(false);

  const { handleChange, setValues } = formik;

  if (!imageItem) {
    navigator(-1);
  }

  return (
    <div className='owas-add-photo'>
      <PhotoCropper
        title='آپلود تصویر وایرلس'
        message='تصویر برند حداکثر تا سایز 200kb را آپلود نمایید'
        isOpen={isOpenCropper}
        cr={papFaultRatio}
        ext='jpg'
        onCancel={() => {
          setIsOpenCropper(false);
        }}
        onSuccess={(file: File) => {
          setValues((values) => {
            return { ...values, file };
          });
          setIsOpenCropper(false);
        }}
      />{" "}
      {imageItem && imageItem !== null && (
        <div className='add-item-wrapper '>
          <h5 className='title'>افزودن تصویر وایرلس {imageItem?.name}</h5>
          <form
            autoComplete='on'
            onSubmit={formik.handleSubmit}
            className='equal-columns'>
            <Input
              name='title'
              title='عنوان تصویر : '
              autoComplete='off'
              error={titleTouched && titleError ? titleError : undefined}
              value={title}
              onChange={handleChange}
            />

            <Input
              name='category'
              title='دسته : '
              autoComplete='off'
              error={
                categoryTouched && categoryError ? categoryError : undefined
              }
              value={category}
              onChange={handleChange}
            />

            <div className='show_upload_photo'>
              {file && <img src={URL.createObjectURL(file)} alt='تصویر مرکز' />}
              <div className='select-chip'>
                {imageItem &&
                  [
                    ...new Set(
                      imageItem.albums?.map((album) => {
                        return album.category;
                      })
                    )
                  ].map((album) => {
                    return (
                      <div
                        className='chip'
                        onClick={() => {
                          setValues({
                            ...formik.values,
                            category: album ?? ""
                          });
                        }}>
                        {album}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className='upload-image-button'>
              <SubmitButtonSecondary
                title={file ? "تغییر تصویر" : "افزودن تصویر"}
                loading={loading}
                disabled={loading}
                type='button'
                onClick={() => {
                  setIsOpenCropper(true);
                }}
              />
            </div>
            <SubmitButton
              title='ثبت تصویر'
              loading={loading}
              disabled={loading}
              type='submit'
            />
          </form>
        </div>
      )}
    </div>
  );
};

export default AddPhoto;
