import AuthReducer from "./AuthReducer";
import AuthLoader from "./AuthLoaderReducer";
import { combineReducers } from "redux";
import Province from "./ProvincesReducer";
import City from "./CitiesReducer";
import Telecom from "./TelecomsReducer";
import Pap from "./PapReducer";
import User from "./UserReducer";
import PhoneBook from "./PhoneBookReducer";
import Owa from "./OwaReducer";
import DataCenter from "./DataCenterReducer";
import Fault from "./FaultReducer";
import General from "./GeneralReducer";
import Log from "./LogReducer";
import Task from "./TaskReducer";
import Drawer from "./DrawerReducer";
import Notification from "./NotificationReducer";
import Dashboard from "./DashboardReducer";

const rootReducer = combineReducers({
  auth: AuthReducer,
  authLoading: AuthLoader,
  province: Province,
  city: City,
  telecom: Telecom,
  user: User,
  pap: Pap,
  phoneBook: PhoneBook,
  owa: Owa,
  dataCenter: DataCenter,
  fault: Fault,
  general: General,
  log: Log,
  task: Task,
  drawer: Drawer,
  notification: Notification,
  dashboard: Dashboard
});

export default rootReducer;

export type RootReducer = ReturnType<typeof rootReducer>;
