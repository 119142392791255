import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { store } from "..";
import { getCatch } from "../../catch";
import { customizedAxios } from "../../config";
import {
  DashboardFaultType,
  DashboardTaskType,
  QueryParams
} from "../../types";
import { DashboardActionTypes } from "../actionTypes";
import { DASHBOARD_ROOT_API } from "../api/DashboardApi";
import { DASHBOARD_TYPES } from "../types";

export const fetchDashboardFaults =
  (query?: QueryParams) => async (dispatch: Dispatch<DashboardActionTypes>) => {
    try {
      dispatch({ type: DASHBOARD_TYPES.DASHBOARD_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: query
      };

      let url = DASHBOARD_ROOT_API.concat("/faults");
      const res = await customizedAxios.get<DashboardFaultType>(url, config);
      dispatch({
        type: DASHBOARD_TYPES.DASHBOARD_FAULTS_LISTS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: DASHBOARD_TYPES.DISABLE_LOADING });
    }
  };

export const fetchDashboardTasks =
  (query?: QueryParams) => async (dispatch: Dispatch<DashboardActionTypes>) => {
    try {
      dispatch({ type: DASHBOARD_TYPES.DASHBOARD_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: query
      };

      let url = DASHBOARD_ROOT_API.concat("/tasks");
      const res = await customizedAxios.get<DashboardTaskType>(url, config);
      dispatch({
        type: DASHBOARD_TYPES.DASHBOARD_TASKS_LISTS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: DASHBOARD_TYPES.DISABLE_LOADING });
    }
  };
