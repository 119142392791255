import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { customizedAxios } from "../../../config";
import { getCatch } from "../../../catch";
import {
  AdvancedResult,
  QueryParams,
  InputObject,
  UploadType
} from "../../../types";
import { AddOwa, OwaType, OwaProvincesType, AlbumSchema } from "../../../types";
import { OwasActionTypes } from "../../actionTypes";
import { OWA_TYPES } from "../../types";
import { OWA_ROOT_API } from "../../api";
import { store } from "../../store";
import { DeleteType } from "../../../types";
import { createFormData } from "../../../constants";

export const fetchOwas =
  (query: QueryParams) => async (dispatch: Dispatch<OwasActionTypes>) => {
    try {
      dispatch({ type: OWA_TYPES.OWA_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        params: query
      };

      let url = OWA_ROOT_API;
      const res = await customizedAxios.get<AdvancedResult<OwaType>>(
        url,
        config
      );
      dispatch({
        type: OWA_TYPES.OWA_LISTS,
        payload: res.data
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: OWA_TYPES.DISABLE_LOADING });
    }
  };
export const addOwa =
  (body: AddOwa) => async (dispatch: Dispatch<OwasActionTypes>) => {
    try {
      dispatch({ type: OWA_TYPES.OWA_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = OWA_ROOT_API;
      await customizedAxios.post(url, body, config);

      dispatch({
        type: OWA_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" پاپ سایت با موفقیت افزوده شد.");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: OWA_TYPES.DISABLE_LOADING });
    }
  };

export const getOwaProvinces =
  () => async (dispatch: Dispatch<OwasActionTypes>) => {
    try {
      dispatch({ type: OWA_TYPES.OWA_PROVINCES_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = OWA_ROOT_API.concat("/provinces");
      const res = await customizedAxios.get<AdvancedResult<OwaProvincesType>>(
        url,
        config
      );

      dispatch({
        type: OWA_TYPES.OWA_PROVINCES,
        payload: res.data
      });
      dispatch({
        type: OWA_TYPES.DISABLE_OWA_PROVINCES_LOADING
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: OWA_TYPES.DISABLE_OWA_PROVINCES_LOADING });
    }
  };

export const editOwa =
  (body: OwaType, id: string) =>
  async (dispatch: Dispatch<OwasActionTypes>) => {
    try {
      dispatch({ type: OWA_TYPES.OWA_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = OWA_ROOT_API.concat("/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: OWA_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("پاپ سایت با موفقیت ویرایش شد");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: OWA_TYPES.DISABLE_LOADING });
    }
  };

export const fetchOneOwa =
  (id: string) => async (dispatch: Dispatch<OwasActionTypes>) => {
    try {
      dispatch({ type: OWA_TYPES.OWA_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = OWA_ROOT_API.concat("/" + id);
      const res = await customizedAxios.get<InputObject<OwaType>>(url, config);
      dispatch({
        type: OWA_TYPES.OWA,
        payload: res.data.data!
      });
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: OWA_TYPES.DISABLE_LOADING });
    }
  };

export const deleteOwa =
  (id: string) => async (dispatch: Dispatch<OwasActionTypes>) => {
    try {
      dispatch({ type: OWA_TYPES.OWA_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = OWA_ROOT_API.concat("/" + id);
      await customizedAxios.delete<DeleteType<OwaType>>(url, config);

      dispatch({
        type: OWA_TYPES.OWA_DELETE,
        payload: { owaId: id }
      });
      window.openToastSuccess("پاپ سایت با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: OWA_TYPES.DISABLE_LOADING });
    }
  };

export const uploadOwaPhoto =
  (file: File, { _id, category, title }: AlbumSchema) =>
  async (dispatch: Dispatch<OwasActionTypes>) => {
    try {
      dispatch({ type: OWA_TYPES.OWA_LOADING });

      let bodyData = createFormData(file, { title, category });

      const config: AxiosRequestConfig = {
        headers: {
          "Content-Type": "multipart/form-data; boundary=form._boundary",
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = OWA_ROOT_API.concat("/photo/" + _id);
      const res = await customizedAxios.put<UploadType<OwaType>>(
        url,
        bodyData,
        config
      );
      dispatch({
        type: OWA_TYPES.OWA_UPLOAD,
        payload: res.data.data.albums ?? []
      });
      window.openToastSuccess("تصویر با موفقیت افزوده شد");
      window.navigate(-1);
      return res.data.data;
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: OWA_TYPES.DISABLE_LOADING });
    }
  };

export const deleteOwaPhoto =
  (id: string, owaId: string) =>
  async (dispatch: Dispatch<OwasActionTypes>) => {
    try {
      dispatch({ type: OWA_TYPES.OWA_LOADING });
      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        data: {
          id
        }
      };

      let url = OWA_ROOT_API.concat("/photo/" + owaId);
      const res = await customizedAxios.delete<DeleteType<OwaType>>(
        url,
        config
      );

      dispatch({
        type: OWA_TYPES.OWA_UPLOAD,
        payload: res.data.data.albums ?? []
      });
      window.openToastSuccess("تصویر با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: OWA_TYPES.DISABLE_LOADING });
    }
  };
