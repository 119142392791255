import { USER_ACCESS } from "../constants";
import {
  CRUD_TYPES,
  SectionAccessDocument,
  UserType,
  USER_ROLES
} from "../types";

export type UserAccessType = {
  [key in USER_ACCESS]: boolean;
};

export const checkUserAccessToSection = (
  accessList: SectionAccessDocument[]
): UserAccessType => {
  let obj = {} as any;
  Object.values(USER_ACCESS).forEach((access) => {
    obj[access] = !!accessList.find((accessListItem) => {
      return accessListItem.section === access;
    });
  });

  return obj as UserAccessType;
};

export const checkUserAccessBySectionCrud = (
  user: UserType,
  accessList: SectionAccessDocument[],
  section: USER_ACCESS,
  crud: CRUD_TYPES
): boolean => {
  if (user && user.role === USER_ROLES.ADMIN) return true;
  let _found = accessList.find((access) => {
    return access.section === section;
  });

  return _found?.crud?.includes(crud) ?? false;
};

export const checkUserAccessByProvinceCrud = (
  user: UserType,
  accessList: SectionAccessDocument[],
  section: USER_ACCESS,
  crud: CRUD_TYPES,
  provinceId: string
): boolean => {
  if (user && user.role === USER_ROLES.ADMIN) return true;
  let _found = accessList.find((access) => {
    return access.section === section;
  });

  if (_found) {
    let _foundProvince = _found.province.find((_province) => {
      return _province.provinceId === provinceId;
    });
    if (_foundProvince) {
      return _foundProvince.crud?.includes(crud) ?? false;
    }
    return false;
  }
  return false;
};

export const checkUserAccessByCityCrud = (
  user: UserType,
  accessList: SectionAccessDocument[],
  section: USER_ACCESS,
  crud: CRUD_TYPES,
  provinceId: string,
  cityId: string
): boolean => {
  if (user && user.role === USER_ROLES.ADMIN) return true;
  let _found = accessList.find((access) => {
    return access.section === section;
  });

  if (_found) {
    let _foundProvince = _found.province.find((_province) => {
      return _province.provinceId === provinceId;
    });
    if (_foundProvince) {
      let _foundCity = _foundProvince.cities?.find((_city) => {
        return _city.cityId === cityId;
      });
      if (_foundCity) {
        return _foundCity.crud.includes(crud) ?? false;
      }
    }
    return false;
  }
  return false;
};

export const checkUserAccessByTelecomCrud = (
  user: UserType,
  accessList: SectionAccessDocument[],
  section: USER_ACCESS,
  crud: CRUD_TYPES,
  provinceId: string,
  telecomId: string
): boolean => {
  if (user && user.role === USER_ROLES.ADMIN) return true;
  let _found = accessList.find((access) => {
    return access.section === section;
  });

  if (_found) {
    let _foundProvince = _found.province.find((_province) => {
      return _province.provinceId === provinceId;
    });
    if (_foundProvince) {
      let _foundTelecom = _foundProvince.telecoms?.find((_telecom) => {
        return _telecom.telecomId === telecomId;
      });
      if (_foundTelecom) {
        return _foundTelecom.crud.includes(crud) ?? false;
      }
    }
    return false;
  }
  return false;
};

export const getUserAccess = (
  userAccess: UserAccessType,
  me: UserType | null,
  section: keyof UserAccessType
) => {
  return !!(me && (me.role === USER_ROLES.ADMIN || userAccess[section]));
};
