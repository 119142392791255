import { useLayoutEffect, useState } from "react";
import timeSince from "../utility/fromNowTime";

export const useCounter = (date: Date) => {
  const [value, setValue] = useState(timeSince(date));

  useLayoutEffect(() => {
    const internal = setInterval(() => {
      setValue(timeSince(date));
    }, 1000);
    return () => {
      clearInterval(internal);
    };
  }, [date]);

  return [value];
};
