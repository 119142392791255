import { AxiosRequestConfig } from "axios";
import { Dispatch } from "react";
import { customizedAxios } from "../../../config";
import { getCatch } from "../../../catch";
import { AddDCRack, DCRackSchema } from "../../../types";
import { DcRackActionTypes } from "../../actionTypes";
import { DC_RACK_TYPES } from "../../types";
import { DATACENTER_ROOT_API } from "../../api";
import { store } from "../../store";
import { DeleteType } from "../../../types";

export const addDcRack =
  (body: AddDCRack, id: string) =>
  async (dispatch: Dispatch<DcRackActionTypes>) => {
    try {
      dispatch({ type: DC_RACK_TYPES.DC_RACK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = DATACENTER_ROOT_API.concat("/rack/" + id);
      await customizedAxios.post(url, body, config);

      dispatch({
        type: DC_RACK_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess(" رک با موفقیت افزوده شد.");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: DC_RACK_TYPES.DISABLE_LOADING });
    }
  };

export const editDcRack =
  (body: DCRackSchema, id: string) =>
  async (dispatch: Dispatch<DcRackActionTypes>) => {
    try {
      dispatch({ type: DC_RACK_TYPES.DC_RACK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        }
      };

      let url = DATACENTER_ROOT_API.concat("/rack/" + id);
      await customizedAxios.put(url, body, config);

      dispatch({
        type: DC_RACK_TYPES.DISABLE_LOADING
      });
      window.openToastSuccess("رک با موفقیت ویرایش شد");
      window.navigate(-1);
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: DC_RACK_TYPES.DISABLE_LOADING });
    }
  };

export const deleteDcRack =
  (id: string, dataCenterId: string) =>
  async (dispatch: Dispatch<DcRackActionTypes>) => {
    try {
      dispatch({ type: DC_RACK_TYPES.DC_RACK_LOADING });

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${store.getState().auth.token}`
        },
        data: {
          id
        }
      };

      let url = DATACENTER_ROOT_API.concat("/rack/" + dataCenterId);
      await customizedAxios.delete<DeleteType<DCRackSchema>>(url, config);

      dispatch({
        type: DC_RACK_TYPES.DC_RACK_DELETE,
        payload: { _id: id }
      });

      window.openToastSuccess("رک با موفقیت حذف شد");
    } catch (err: any) {
      const { message } = getCatch(err);
      window.openToastError(message);
    } finally {
      dispatch({ type: DC_RACK_TYPES.DISABLE_LOADING });
    }
  };
