import { MongoObject } from "..";
import { RadioSchema, ItemSchema, AlbumSchema } from "..";

export enum OwasTable {
  _ID = "_id",
  PROVINCEID = "provinceId",
  NAME = "نام ",
  TOWERTYPE = "نوع دکل",
  TOWERSECTION = "تعداد سکشن",
  TOWERLENGTH = "ارتفاع دکل",
  PROVINCE = "استان ",
  ITEMS = "آیتم ها",
  RADIOS = "رادیو ها",
  ADDRESS = " آدرس",
  ACTIONS = "عملیات",
  ROW = "ردیف"
}

export interface OwaType extends MongoObject {
  name?: string;
  towerType?: string | undefined;
  towerSection?: string | undefined;
  towerLength?: string;
  provinceId?: string;
  province?: string;
  address?: string | undefined;
  albums?: Array<AlbumSchema>;
  items?: Array<ItemSchema>;
  radios?: Array<RadioSchema>;
}

export interface OwaProvincesType extends MongoObject {
  _id?: string;
  provinceId?: string;
  name: string;
}

export interface AddOwa {
  name?: string;
  towerType?: string | undefined;
  towerSection?: string | undefined;
  towerLength?: string;
  provinceId?: string;
  address?: string | undefined;
}
