import { Route, Routes } from "react-router-dom";
import AddOwa from "./add-owa";
import AddPhoto from "./add-photo";
import EditOwa from "./edit-owa";
import Items from "./items";
import OwasList from "./owa-list";
import Radios from "./radios";

const Owas = () => {
  return (
    <>
      <Routes>
        <Route path='' element={<OwasList />} />
        <Route path='/add-owa' element={<AddOwa />} />
        <Route path='/add-owa-photo' element={<AddPhoto />} />
        <Route path='/edit-owa' element={<EditOwa />} />
        <Route path='/items/*' element={<Items />} />
        <Route path='/radios/*' element={<Radios />} />

        {/* <Route path="/items/edit" element={<EditItem />} /> */}
      </Routes>
    </>
  );
};

export default Owas;
