import { AdvancedResult, OwaProvincesType, OwaType } from "../../types";

import { OWA_TYPES, RADIO_TYPES, ITEM_TYPES } from "../types";
import {
  ItemsActionTypes,
  OwasActionTypes,
  RadiosActionTypes
} from "../actionTypes";
interface StateType {
  list: AdvancedResult<OwaType> | null;
  item: OwaType | null;
  loading: boolean;
  provincesLoading: boolean;
  provinces: AdvancedResult<OwaProvincesType> | null;
  imageItem: OwaType | null;
}

const initialState: StateType = {
  list: null,
  item: null,
  loading: false,
  provincesLoading: false,
  provinces: null,
  imageItem: null
};

const reducer = (
  state: StateType = initialState,
  action: OwasActionTypes | RadiosActionTypes | ItemsActionTypes
): StateType => {
  switch (action.type) {
    case OWA_TYPES.OWA_LISTS:
      return {
        ...state,
        list: action.payload,
        loading: false,
        item: null
      };

    case OWA_TYPES.OWA:
      return {
        ...state,
        item: action.payload,
        loading: false
      };

    case OWA_TYPES.OWA_LOADING:
      return {
        ...state,
        loading: true
      };
    case OWA_TYPES.OWA_UPLOAD:
      return {
        ...state,
        imageItem: { ...state.imageItem!, albums: action.payload },
        loading: false
      };

    case OWA_TYPES.SELECTED_OWA_IMAGES:
      return {
        ...state,
        imageItem: action.payload
      };

    case OWA_TYPES.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };

    case OWA_TYPES.OWA_PROVINCES_LOADING:
      return {
        ...state,
        provincesLoading: true
      };
    case OWA_TYPES.OWA_PROVINCES:
      return {
        ...state,
        provinces: action.payload
      };
    case OWA_TYPES.DISABLE_OWA_PROVINCES_LOADING:
      return {
        ...state,
        provincesLoading: false
      };
    case RADIO_TYPES.RADIO_DELETE:
      let _item = state.item;
      _item!.radios = _item?.radios?.filter((radio) => {
        return radio?._id?.toString() !== action.payload._id?.toString();
      });

      return {
        ...state,
        item: _item,
        loading: false
      };
    case ITEM_TYPES.ITEM_DELETE:
      let __item = state.item;
      __item!.items = __item?.items?.filter((item) => {
        return item?._id?.toString() !== action.payload._id?.toString();
      });

      return {
        ...state,
        item: __item,
        loading: false
      };
    case OWA_TYPES.OWA_DELETE:
      let _list = state.list;
      if (_list) {
        _list.data = _list?.data?.filter((item) => {
          return item?._id?.toString() !== action.payload.owaId?.toString();
        });
        --_list.total;
      }
      return {
        ...state,
        list: _list,
        loading: false
      };
    case OWA_TYPES.RESET_IMAGE_ITEM:
      return {
        ...state,
        imageItem: null
      };
    default:
      return state;
  }
};

export default reducer;
