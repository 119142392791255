import { Route, Routes, Navigate } from "react-router-dom";

import { useTypedSelector } from "../../core/hooks";
import { Login } from "../auth/Login";
import { ForgetPassword } from "../auth/forgetPassword";
import { ResetPassword } from "../auth/resetPassword";
import Dashboard from "../dashboard";
import DownloadForm from "../dashboard/main/faults/download-form";

const MainRoutes = () => {
  const { token } = useTypedSelector((state) => state.auth);
  return (
    <Routes>
      {token && <Route path='*' element={<Dashboard />} />}
      <Route path='login' element={<Login />} />
      <Route path='download-form' element={<DownloadForm />} />
      <Route path='forget-password' element={<ForgetPassword />} />
      <Route path='password-reset' element={<ResetPassword />} />
      <Route path='404' element={<Login />} />
      <Route path='*' element={<Navigate to='/login' />} />
    </Routes>
  );
};

export { MainRoutes };
