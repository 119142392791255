import { useFormik } from "formik";
import React, { memo, useCallback } from "react";
import ReactModal from "react-modal";
import * as yup from "yup";
import { CancelButton, Input, SubmitButton } from ".";
import { FaultCommentType, UserType } from "../types";
import { getSinceTimeWithHour } from "../utility/getSinceTimeWithHour";
import { NoItem } from "./NoItem";

interface AddCommentModalContentType {
  onSuccess?: (comment: string) => any;
  comments?: FaultCommentType[];
  onClose?: () => any;
  isOpen: boolean;
  onlyComments?: boolean;
  faultPhone: string;
}

const _AddCommentModalContent: React.FC<AddCommentModalContentType> = ({
  comments,
  onSuccess,
  onClose,
  isOpen,
  faultPhone,
  onlyComments = false,
  ...rest
}) => {
  const submit = useCallback(
    (comment: string) => {
      onSuccess && onSuccess(comment);
    },
    [onSuccess]
  );
  const formik = useFormik({
    initialValues: {
      comment: ""
    },
    validationSchema: yup.object({
      comment: yup.string().required("توضیحات الزامی است")
    }),
    onSubmit: ({ comment }: any) => {
      submit(comment);
    }
  });

  const { comment } = formik.values;
  const { comment: commentError } = formik.errors;
  const { comment: commentTouched } = formik.touched;

  return (
    <>
      <ReactModal
        style={{
          content: {
            maxWidth: 800,
            maxHeight: 600,
            margin: "auto",
            padding: "0",
            left: 16,
            right: 16
          }
        }}
        {...rest}
        isOpen={isOpen}
        onRequestClose={onClose}
        ariaHideApp={false}>
        <div className='add-comment-modal__wrapper'>
          <form autoComplete='on' onSubmit={formik.handleSubmit}>
            <div className='add-comment-modal-header'>
              <div className='title'> توضیحات {faultPhone} </div>
              <div className='action-wrappers'>
                <CancelButton
                  onClick={onClose}
                  loading={false}
                  title='انصراف'
                  type='button'
                />
                {!onlyComments && (
                  <SubmitButton type='submit' title='ثبت توضیح' />
                )}
              </div>
            </div>

            {!onlyComments && (
              <Input
                placeholder=''
                name='comment'
                title='توضیحات  : '
                multiline
                autoComplete='off'
                error={
                  commentTouched && commentError ? commentError : undefined
                }
                value={comment}
                onChange={formik.handleChange}
              />
            )}
            {comments && comments.length !== 0 ? (
              <div className='content-wrapper'>
                {comments
                  .sort(function (a, b) {
                    return (
                      new Date(b.createdAt).getTime() -
                      new Date(a.createdAt).getTime()
                    );
                  })
                  .map((item) => {
                    return (
                      <div key={item._id}>
                        {!!item.createdAt && (
                          <span>
                            {`${(item.userId as unknown as UserType)
                              ?.fullName} ${getSinceTimeWithHour(
                              item.createdAt
                            )}`}
                          </span>
                        )}
                        &nbsp;
                        <div>{item.desc}</div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <NoItem title='کامنتی ثبت نشده است.' />
            )}
          </form>
        </div>
      </ReactModal>
    </>
  );
};

const AddCommentModalContent = memo(_AddCommentModalContent);

export { AddCommentModalContent };
